const items = () => ({
  label: 'Items',
  name: 'items',
  component: 'group',
  fields: [
    {
      label: 'Perk block items',
      name: 'items',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: {
        name: 'New Perk',
        heading: 'VIP access to product launches',
        text: 'new perk text',
      },
      fields: [
        {
          label: 'Perk name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Perk heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Perk text',
          name: 'text',
          component: 'text',
        },
        {
          label: 'Perk image',
          name: 'image',
          component: 'image',
        },
      ],
      defaultValue: [
        {
          name: 'phone',
          heading: 'VIP access to product launches',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
        {
          name: 'money',
          heading: 'Tons of complimentary product',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
        {
          name: 'member',
          heading: 'Exclusive member surprises & benefits',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
      ],
    },
  ],
});

export const Schema = ({ page }) => {
  if (page?.handle !== 'ambassadors') return null;

  return {
    label: 'Ambassador Perks',
    key: 'ambassadorPerks',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'The Ambassador Promise',
      },
      items(),
    ],
  };
};
