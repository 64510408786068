import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      // mx: 'auto',
      // maxWidth: ['600px', '700px', '850px'],
    };

    this.body = {
      variant: 'text.base',
    };

    this.link = {
      mt: [10, 12],
    };
  })()
);
