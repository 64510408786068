import { Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  useCart,
  useCartCheckout,
  useCartCount,
  useSettings,
} from '@backpackjs/storefront';

import { useSidebar } from '@hooks';
import { Locale } from '@snippets';

import { Prices } from './Prices';
import { themed } from './Totals.theme';
import { CartCashback } from '../CashBack';

export const Totals = themed(
  ({ theme: themes, inSidebar = false, inputRef, ...props }) => {
    const cart = useCart();
    const cartCount = useCartCount();
    const { cartCheckout } = useCartCheckout();
    const [{}, { closeSidebar }] = useSidebar();
    const settings = useSettings();
    const { shippingText } = { ...settings?.cart };

    const theme = inSidebar ? themes.sidebar : themes.page;

    return cartCount > 0 ? (
      <Flex
        data-comp={Totals.displayName}
        {...props}
        ref={inputRef}
        sx={{
          ...props.sx,
          ...theme.wrapper,
        }}
      >
        <Paragraph sx={theme.summary}>Order Summary</Paragraph>

        {/* Pricing table */}
        <Locale.CartTotals cart={cart}>
          <Locale.Price>
            {(props) => <Prices inSidebar={inSidebar} {...props} />}
          </Locale.Price>
        </Locale.CartTotals>

        <Paragraph sx={theme.shipping}>
          {shippingText || 'Shipping calculated at checkout'}
        </Paragraph>
        <CartCashback />
        {/* Checkout button */}
        <Button
          onClick={async (event) => {
            event.preventDefault();
            closeSidebar();
            await cartCheckout();
          }}
          sx={theme.checkout}
        >
          {cartCount > 0 ? 'Checkout' : 'Shop Now'}
        </Button>

        {/* View cart, Clear Cat  */}
        {/* <Ctas inSidebar={inSidebar} /> */}
      </Flex>
    ) : null;
  }
);

Totals.displayName = 'Totals';
Totals.propTypes = {
  inSidebar: PropTypes.bool,
};
