import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      fontFamily: 'body',
      px: [10, 0],
    };
    this.top = {
      variant: 'text.h5',
      paddingBottom: 6,
      borderBottom: '1px solid',
      borderColor: 'gray.light',
      mb: 10,
    };
    this.content = {
      flexDirection: ['column', 'row'],
      height: ['auto', '420px'],
    };
    this.imageWrap = {
      mr: [0, 10],
      minWidth: '60%',
      height: '100%',
      position: 'relative',
    };
    this.image = {
      width: '100%',
      height: '100%',
    };
    this.imageSx = {
      objectFit: 'cover',
      objectPosition: 'top',
    };
    this.linkWrap = {
      position: 'absolute',
      left: 10,
      bottom: 10,
    };
    this.products = {
      gridTemplateColumns: '1fr 1fr',
      gridGap: 10,
    };
  })()
);
