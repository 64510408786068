import Libs from './Libs';
import Page from './Page';
import Preconnects from './Preconnects';
import Fonts from './Fonts';

export const StorefrontHead = () => {
  return (
    <>
      <Libs />
      <Page />
      <Preconnects />
      <Fonts />
    </>
  );
};
