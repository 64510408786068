import { useMemo } from 'react';

export const useProductSwiperParams = (
  { singleSlideOnMobile } = { singleSlideOnMobile: true },
  { isProductRecs } = { isProductRecs: false }
) => {
  const productRecsParams = useMemo(
    () => ({
      spaceBetween: 8,
      slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
      autoHeight: true,
      navigation: false,
      threshold: 25,
      loop: false,
      breakpoints: {
        480: {
          spaceBetween: 8,
          slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        600: {
          spaceBetween: 8,
          slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        768: {
          spaceBetween: 8,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        824: {
          spaceBetween: 8,
          slidesPerView: 5,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        1280: {
          spaceBetween: 8,
          slidesPerView: 5,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
      },
    }),
    []
  );

  const productCarouselParams = useMemo(
    () => ({
      spaceBetween: 8,
      slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: false,
      autoHeight: true,
      navigation: false,
      loop: false,
      breakpoints: {
        480: {
          spaceBetween: 8,
          slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        600: {
          spaceBetween: 8,
          slidesPerView: singleSlideOnMobile ? 1.4 : 2.4,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        768: {
          spaceBetween: 8,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        824: {
          spaceBetween: 8,
          slidesPerView: 5,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        1280: {
          spaceBetween: 8,
          slidesPerView: 5,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
      },
    }),
    []
  );

  return isProductRecs ? productRecsParams : productCarouselParams;
};
