import { color, section, align, button } from '@settings/common';

export const Schema = ({ article }) => {
  if (!article) return null;

  return {
    label: 'Button Block',
    key: 'button',
    fields: [
      {
        label: 'Button settings',
        name: 'button',
        component: 'group',
        fields: [
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            label: 'Content alignment (mobile)',
            options: align.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlignDt',
            label: 'Content alignment (desktop)',
            options: align.options,
          },
        ],
        defaultValue: {
          cta: {
            text: '',
            url: '',
          },
          ctaStyle: button.value.primary,
          textAlign: align.value.center,
          textAlignDt: align.value.center,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
