import { useEffect, useRef, useState } from 'react';
import { Section, Markdown } from '@snippets';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Label,
  Text,
  Input as BaseInput,
} from 'theme-ui';

import { Schema } from './KlaviyoSubscribe.schema';
import { TextContent } from './TextContent';
import { PhoneField } from './PhoneField';

export const KlaviyoSubscribe = ({ cms }) => {
  const { section, image, listId, form } = cms;

  const {
    capturePhoneNumber,
    consentText,
    consentIsRequired,
    phoneNumberIsRequired,
    consentTextCheckbox,
  } = { ...form };

  const [acceptsMarketing, setAcceptsMarketing] = useState(false);

  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const emailRef = useRef();
  const phoneRef = useRef();

  const [phoneError, setPhoneError] = useState(null);

  const resetInput = () => {
    if (emailRef.current) {
      emailRef.current.value = '';
    }

    if (phoneRef.current) {
      phoneRef.current.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phoneError) {
      if (phoneRef.current) {
        phoneRef.current.value = '';
      }
      setErrors([{ message: phoneError }]);
      return;
    }

    try {
      const response = await fetch('/api/klaviyo', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          action: 'clientSubscribe',
          email: emailRef?.current?.value,
          phone: phoneRef?.current?.value || null,
          source: window.location.href,
          acceptsMarketing,
          listId,
        }),
      });
      const data = await response.json();

      if (!data.ok && data.errors) {
        setErrors([
          {
            message: data?.data || data?.errors,
          },
        ]);
      }
      setFinished(true);
      if (data) {
        setSuccess(true);
        setAcceptsMarketing(false);
        resetInput();
      }
    } catch (error) {
      console.error({ error });
      setErrors([
        {
          error,
          message:
            'There was an error with your submission. Please try again.**',
        },
      ]);
    }
  };

  useEffect(() => {
    setTimeout(() => setErrors([]), 5000);
    setFinished(false);
    setSuccess(false);
    setStarted(false);
  }, [errors.length]);

  return (
    <Section section={section} cms={cms}>
      <Container
        data-comp={KlaviyoSubscribe.displayName}
        sx={{
          height: ['100%', image?.image?.height || '100vh'],
          minHeight: [
            section?.mobile?.minHeight
              ? `${section.mobile.minHeight}px`
              : 'unset',
            section?.desktop?.minHeight
              ? `${section.desktop.minHeight}px`
              : 'unset',
          ],
          maxHeight: [
            section?.mobile?.maxHeight
              ? `${section.mobile.maxHeight}px`
              : 'unset',
            section?.desktop?.maxHeight
              ? `${section.desktop.maxHeight}px`
              : 'unset',
          ],
          borderRadius: [
            section?.mobile?.container !== 'container' ? '6px' : '0',
            section?.desktop?.container !== 'container' ? '6px' : '0',
          ],
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            background: 'no-repeat center center',
            backgroundImage: `url(${image?.image?.src})`,
            backgroundSize: 'cover',
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: ['80%', '100%'],
              my: [100, 0],
            }}
          >
            {!listId && (
              <Alert variant="alerts.error">Please provide a list ID</Alert>
            )}

            <TextContent text={cms.text} />

            {finished && success ? (
              <Box>
                <Markdown
                  text={form?.successMessage || 'Thank you for subscribing!'}
                  sx={{
                    textAlign: 'center',
                    variant: `text.${form?.successTextFontSize}`,
                    color: form?.successColor || 'text',
                    fontWeight: 400,
                    letterSpacing: 0,
                  }}
                />
              </Box>
            ) : (
              <Flex
                variant="flex.column"
                onSubmit={handleSubmit}
                as="form"
                sx={{
                  my: 14,
                  justifyContent: 'center',
                  minWidth: ['initial', '315px'],
                  width: ['100%', 'initial'],
                  maxWidth: ['100%', '500px'],
                }}
              >
                <BaseInput
                  ref={emailRef}
                  variant="account.input"
                  name="email"
                  label="Email"
                  type="email"
                  required
                  placeholder="Enter your email address"
                />
                {capturePhoneNumber && (
                  <PhoneField
                    ref={phoneRef}
                    required={phoneNumberIsRequired}
                    setPhoneError={setPhoneError}
                  />
                )}

                {consentText && (
                  <>
                    <Markdown
                      text={consentText || 'Submission sent!'}
                      sx={{
                        variant: `text.${form?.consentTextFontSize}`,
                        color: form?.consentColor || 'text',
                      }}
                    />
                    {consentIsRequired && (
                      <Label
                        sx={{
                          display: 'flex',
                          alignItems: 'center',

                          mt: 2,
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: form?.consentColor || 'text',
                            border: 'none',
                            ':focus': {
                              outline: 'none !important',
                              boxShadow: 'none !important',
                            },
                            appearance: 'none',
                            ':checked': {
                              backgroundColor: 'primary',
                            },
                          }}
                          name="acceptMarketing"
                          required
                          checked={acceptsMarketing}
                          onChange={({ target: { checked } }) =>
                            setAcceptsMarketing(checked)
                          }
                        />
                        <Text
                          sx={{
                            variant: `text.${form?.consentTextFontSize}`,
                            color: form?.consentColor || 'text',
                            fontWeight: 400,
                            letterSpacing: 0,
                          }}
                        >
                          {consentTextCheckbox}
                        </Text>
                      </Label>
                    )}
                  </>
                )}

                {errors?.length
                  ? errors.map(({ error, message }, index) => (
                      <Alert
                        key={index}
                        variant="alerts.error"
                        sx={{
                          textAlign: 'center',
                          backgroundColor: '#fdb9b9',
                        }}
                        mb={6}
                      >
                        {message}
                      </Alert>
                    ))
                  : null}

                <Button
                  variant={form?.buttonStyle || 'primary'}
                  disabled={started || (finished && success)}
                  sx={{
                    m: '0 auto',
                    mt: 10,
                    width: ['100%', 'initial'],
                  }}
                >
                  {form?.buttonText || 'Subscribe'}
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      </Container>
    </Section>
  );
};

KlaviyoSubscribe.displayName = 'KlaviyoSubscribe';
KlaviyoSubscribe.Schema = Schema;
