import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      width: '100%',
      flexDirection: ['column', 'row'],
      mb: [7, 9],
      borderBottom: (t) => `1px solid ${t.colors.gray.mediumLight}`,
    };

    this.heading = {
      variant: 'text.h3',
      mb: [3, 5],
    };

    this.groups = {
      width: ['100%', 'auto'],
      height: ['23px', 'auto'],
      position: 'relative',
      ml: [0, null, null, 10],
      mt: [0, null, null, 11],
      flex: 1,
    };

    this.list = {
      display: 'flex',
      alignItems: 'flex-end',
      overflowY: 'hidden',
      overflowX: 'hidden',
      px: [0, 10],
    };

    this.listItem = {
      minWidth: 'auto',
    };

    this.button = {
      variant: 'buttons.plain',
      minWidth: 'unset',
      fontFamily: 'body',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
      width: 'auto',
      color: 'black',
      whiteSpace: 'nowrap',
      position: 'relative',
      overflow: 'hidden',
      pb: 1,
      ':after': {
        transition: 'transform 0.2s ease-in-out',
        content: '""',
        position: 'absolute',
        top: 'calc(100% - 2px)',
        left: 0,
        width: '100%',
        height: '2px',
        bg: 'black',
      },
      '@media screen and (min-width: 480px)': {
        ':hover:after': {
          transform: 'translateX(0)',
        },
      },
    };

    this.link = {
      mb: 7,
    };
  })()
);
