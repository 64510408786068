import { gsap } from 'gsap';

const revealYotpoStars = ({ maskRef, starsRef }) => {
  const icons = starsRef.current
    ? starsRef.current.querySelectorAll(`.yotpo-icon`)
    : null;

  const mask = maskRef.current ? maskRef.current : null;

  // animate in
  const timeline = gsap.timeline();
  timeline.pause();

  if (icons) {
    timeline.to(icons, {
      alpha: 1,
      stagger: 0.1,
      // ease: 'expo'
    });
  }

  if (mask) {
    timeline.to(
      mask,
      {
        y: '100%',
        ease: 'expo',
        duration: 2,
      },
      '-=.4'
    );
  }

  timeline.play();
};

const revealReviewListItem = () => {
  const reviewItems = document.querySelectorAll(
    `[data-comp="YotpoListReviewItem"]`
  );

  gsap.timeline().to(reviewItems, {
    alpha: 1,
    stagger: 0.1,
  });
};

export default {
  // yotpo
  revealYotpoStars,
  revealReviewListItem,
};
