export const text = ({ color }) => ({
  label: 'Text settings',
  name: 'text',
  description: 'Text Heading and description',
  component: 'group',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'description',
      label: 'Description',
      component: 'markdown',
    },
    {
      component: 'select',
      name: 'color',
      label: 'Text color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    heading: 'New Offers',
    description: 'See our new offers',
    color: '',
  },
});
