import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Text } from 'theme-ui';

import { useBreakpointValue, useProductColors } from '@hooks';
import { fetchProductsFromHandles } from '@utils';

import { Swatch } from './Swatch';
import { themed } from './SwatchesContainer.theme';

const DESIRED_COLOR_GROUPS_ORDER = [
  'colors',
  'Staple',
  'Seasonal',
  'Last Chance',
];

export const SwatchesContainer = themed(
  ({
    theme,
    swatches,
    colorGroupsMap,
    selectedVariant,
    setProductFromColorSelector,
    setVariantFromColorSelector,
    initialProduct,
  }) => {
    const { totalColors } = useProductColors(
      colorGroupsMap,
      DESIRED_COLOR_GROUPS_ORDER
    );

    const [productMapByColor, setProductMapByColor] = useState(null);
    const [variantMapByColor, setVariantMapByColor] = useState(null);

    const renderedColors = useBreakpointValue([4, 6]);

    const colors = useMemo(() => {
      if (!initialProduct || !totalColors) return [];
      const initialColor = initialProduct.optionsMap?.color?.[0];
      return initialColor
        ? [initialColor, ...totalColors.filter((c) => c !== initialColor)]
        : totalColors;
    }, [initialProduct?.id, totalColors]);

    const generateProductMapByColor = useCallback(async () => {
      if (!initialProduct.grouping?.isTransformed) return;
      const products = await fetchProductsFromHandles(
        initialProduct.grouping.products
      );
      setProductMapByColor(
        products?.reduce((acc, groupProduct) => {
          return {
            ...acc,
            [groupProduct.optionsMap?.color?.[0]]: groupProduct,
          };
        }, {})
      );
    }, [initialProduct.id]);

    const generateVariantMapByColor = useCallback(() => {
      if (initialProduct.grouping?.isTransformed) return;
      setVariantMapByColor(
        initialProduct.variants?.reduce((acc, variant) => {
          const variantColor = variant.selectedOptionsMap?.Color;
          if (acc[variantColor]) return acc;
          return {
            ...acc,
            [variantColor]: variant,
          };
        }, {})
      );
    }, [initialProduct.id]);

    const handleOptionClick = useCallback(
      (color) => {
        if (initialProduct.grouping?.isTransformed) {
          if (!productMapByColor) return;
          setProductFromColorSelector(productMapByColor[color]);
          setVariantFromColorSelector(productMapByColor[color]?.variants?.[0]);
        } else {
          setProductFromColorSelector(initialProduct);
          setVariantFromColorSelector(variantMapByColor?.[color]);
        }
      },
      [initialProduct.id, productMapByColor, variantMapByColor]
    );

    useEffect(() => {
      generateProductMapByColor();
      generateVariantMapByColor();
    }, [initialProduct.id]);

    return (
      <Flex data-comp={SwatchesContainer.displayName} sx={theme.container}>
        {colors.slice(0, renderedColors).map((color, index) => {
          const swatch = swatches[color.toLowerCase()];
          const isWhite = color === 'white';
          const isSelected =
            color === selectedVariant?.selectedOptionsMap?.color;

          return (
            <Swatch
              key={`color-${index}`}
              color={swatch?.color}
              colorName={color}
              isWhite={isWhite}
              size={renderedColors === 4 ? 16 : 18}
              isSelected={isSelected}
              setProductFromColorSelector={setProductFromColorSelector}
              setVariantFromColorSelector={setVariantFromColorSelector}
              handleClick={handleOptionClick}
            />
          );
        })}
        {totalColors.length > renderedColors && (
          <Text variant="text.xs">
            + {totalColors.length - renderedColors} more
          </Text>
        )}
      </Flex>
    );
  }
);

SwatchesContainer.displayName = 'SwatchesContainer';
