import { SidebarNav } from '@snippets';
import { Schema } from './CollectionsSidebarSection.schema';

export const CollectionsSidebarSection = ({ cms }) => {
  const { breadcrumb, sections } = cms;
  return (
    <SidebarNav
      collectionTitle={breadcrumb.collection}
      breadcrumbsLevelOneUrl={breadcrumb?.link?.url}
      breadcrumbsLevelTwo={breadcrumb.level2}
      linksByCollection={sections}
      breadcrumbsLevelOne={breadcrumb.level1}
    />
  );
};

CollectionsSidebarSection.displayName = 'CollectionsSidebarSection';
CollectionsSidebarSection.Schema = Schema;
