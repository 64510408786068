export const content = ({ position, align, padding }) => ({
  label: 'Content settings',
  name: 'content',
  description: 'Content alignment, content position',
  component: 'group',
  fields: [
    {
      label: 'Override individual alignment  ',
      description: 'Override the individual text alignment for each slide',
      name: 'globalAlignment',
      component: 'toggle',
      toggleLabels: {
        true: 'Global alignment',
        false: 'Individual alignment',
      },
      defaultValue: false,
    },
    {
      name: 'alignment',
      label: 'Content alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      description:
        'Set the content alignment globally \nGlobal alignment must be enabled',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Content position (mobile)',
      options: position.flex,
    },
    {
      name: 'yRelativePositionMb',
      component: 'number',
      label: 'Vertical content relative position (mobile)',
      description:
        'Move content to a specific location vertically using pixels',
    },
    {
      name: 'xRelativePositionMb',
      component: 'number',
      label: 'Horizontal content relative position (mobile)',
      description:
        'Move content to a specific location horizontally using pixels',
    },
    {
      name: 'pyMb',
      label: 'Vertical padding (mobile)',
      component: 'select',
      options: padding.options,
    },
    {
      name: 'pxMb',
      label: 'Horizontal padding (mobile)',
      component: 'select',
      options: padding.options,
    },
    {
      name: 'alignmentDt',
      label: 'Content alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      description:
        'Set the content alignment globally \nGlobal alignment must be enabled',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Content position (desktop)',
      options: position.flex,
    },
    {
      name: 'yRelativePositionDt',
      component: 'number',
      label: 'Vertical content relative position (desktop)',
      description:
        'Move content to a specific location vertically using pixels',
    },
    {
      name: 'xRelativePositionDt',
      component: 'number',
      label: 'Horizontal content relative position (desktop)',
      description:
        'Move content to a specific location horizontally using pixels',
    },
    {
      name: 'pyDt',
      label: 'Vertical padding (desktop)',
      component: 'select',
      options: padding.options,
    },
    {
      name: 'pxDt',
      label: 'Horizontal padding (desktop)',
      component: 'select',
      options: padding.options,
    },
    {
      name: 'maxWidth',
      component: 'number',
      label: 'Max content pixel width',
    },
  ],
  defaultValue: {
    alignment: 'center',
    position: 'center center',
    pyMb: 's',
    pxMb: 's',
    alignmentDt: 'center',
    positionDt: 'center center',
    pyDt: 's',
    pxDt: 's',
    maxWidth: 800,
  },
});
