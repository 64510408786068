import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.text = {
      variant: 'text.sm',
      px: 10,
    };
  })()
);
