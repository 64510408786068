import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.iconWrapper = {
      alignItems: 'center',
    };

    this.icon = {
      maxWidth: 50,
      mr: 2,
    };

    this.text = {
      color: 'gray.dark',
      variant: 'text.sm',
      mb: '2px',
      fontSize: ['11px', '14px'],
    };
  })()
);
