import { store } from '@backpackjs/storefront';

const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
});

const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
});

const modalProps = store.recoil.atom({
  key: 'global/modalProps',
  default: {},
});

const sidebar = store.recoil.atom({
  key: 'global/sidebar',
  default: null, // null || 'cart' || 'search'
});

const cookiesMap = store.recoil.atom({
  key: 'global/cookiesMap',
  default: {},
});

const country = store.recoil.atom({
  key: 'global/country',
  default: {},
});

export default {
  overlay,
  modal,
  modalProps,
  sidebar,
  cookiesMap,
  country,
};
