import { useMemo, Children, cloneElement } from 'react';
import { useLocalizedVariant } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { formatAmountItem } from './formatAmountItem';

export const LocaleVariant = ({
  variant: variantFakeColorOption,
  selectedPlan,
  children,
  ...props
}) => {
  // the color option is added via a custom transform and is not a real shopify product option
  // here we remove it so that we can find the variant's price
  const isBundle = variantFakeColorOption?.product?.tags.includes('bundle');

  const variant = {
    ...variantFakeColorOption,
    selectedOptions: isBundle
      ? variantFakeColorOption?.bundleSelectedOptions?.filter(
          ({ name }) => name !== 'color'
        )
      : variantFakeColorOption?.selectedOptions.filter(
          ({ name }) => name !== 'color'
        ),
  };

  const { localized, ...localeStatus } = useLocalizedVariant({ variant });

  const Components = useMemo(() => {
    let PriceComp = null;
    let LoaderComp = null;
    let ErrorComp = null;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Locale.Price': {
          const hasSubscription = localized?.sellingPlanAllocations?.length > 0;
          const isSubscribing =
            hasSubscription && selectedPlan && typeof selectedPlan === 'object';
          const oneTimeSale =
            parseFloat(localized?.compareAtPriceV2?.amount) >
            parseFloat(localized?.priceV2?.amount);

          const subscriptionSale = isSubscribing
            ? selectedPlan?.priceAdjustments?.[0]?.adjustmentValue
                ?.adjustmentPercentage
            : false;

          const isOnSale = oneTimeSale || subscriptionSale || false;
          const priceProps = localized
            ? {
                key: 'Locale.Price',
                priceV2: formatAmountItem(localized.priceV2),
                unitPrice: localized.unitPrice,
                compareAtPriceV2: formatAmountItem(localized.compareAtPriceV2),
                sellingPlanAllocations: localized.sellingPlanAllocations,
                sellingPlans: localized?.sellingPlans || null,
                sellingPlan:
                  localized?.sellingPlans?.[selectedPlan?.id] || null,
                locale: localized.locale,
                isOnSale,
                hasSubscription,
                isSubscribing,
                ...child.props,
              }
            : null;
          PriceComp = priceProps ? cloneElement(child, priceProps) : null; // return null while localized is being fetched..
          break;
        }

        case 'Locale.Error':
          ErrorComp = localeStatus?.errors?.length
            ? cloneElement(child, {
                key: 'Locale.Error',
                errors: localeStatus.errors,
                ...child.props,
              })
            : null;
          break;

        case 'Locale.Loader':
          LoaderComp =
            !localeStatus?.finished &&
            !localized &&
            !localeStatus?.errors?.length
              ? cloneElement(child, {
                  key: 'Locale.Loader',
                  ...localeStatus,
                  ...child.props,
                })
              : null;
          break;

        default:
          break;
      }
    });

    // active components
    return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
  }, [
    localeStatus?.started,
    localeStatus?.finished,
    localized,
    selectedPlan?.id,
    localized?.compareAtPriceV2?.amount,
    localized?.sellingPlanAllocations?.length,
  ]);

  return (
    <Box data-comp={LocaleVariant.displayName} {...props} sx={{ ...props.sx }}>
      {Components.map((Component) => Component)}
    </Box>
  );
};

LocaleVariant.displayName = 'Locale.Variant';
