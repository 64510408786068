import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.media = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      mb: 8,
    };
  })()
);
