import { useRef, useCallback } from 'react';
import { isBrowser } from '@utils';

const DEBUG = false;

const initial = {
  html: {
    overflowY: '',
    paddingTop: '',
  },
  header: {
    position: '',
    top: '',
  },
  promo: {
    position: '',
    top: '',
  },
  customizer: {
    marginRight: '',
  },
};

export const useBodyScrollLock = (props) => {
  const scrollElement = props?.scrollElement || null;
  const header = useRef();
  const promo = useRef();
  const customizer = useRef();
  const html = useRef(
    isBrowser ? scrollElement || document.documentElement : null
  );

  if (!isBrowser) {
    // SSR default
    return {
      lockScroll: () => {},
      unlockScroll: () => {},
    };
  }

  const runCallback = useCallback((props) => {
    if (typeof props?.onComplete === 'function') {
      props.onComplete();
    }
  }, []);

  const bindRefs = useCallback(() => {
    if (!header.current) {
      header.current = document.querySelector('header');
    }
    if (!promo.current) {
      promo.current = document.querySelector('[data-comp=PromoBar]');
    }
    if (!customizer.current) {
      customizer.current = document.querySelector(
        'div[class^="StyledToolbar-sc-"]'
      );
    }
  }, []);

  const cacheInitial = useCallback(() => {
    // Cache initial values of element styles that will be modified
    const htmlStyles = window.getComputedStyle(html.current);
    initial.html.overflowY = htmlStyles.overflowY;
    initial.html.paddingTop = htmlStyles.paddingTop;

    const headerStyles = window.getComputedStyle(header.current);
    initial.header.position = headerStyles.position;
    initial.header.top = headerStyles.top;

    const promoStyles = window.getComputedStyle(promo.current);
    initial.promo.position = promoStyles.position;
    initial.promo.top = promoStyles.top;

    if (customizer.current) {
      const customizerStyles = window.getComputedStyle(customizer.current);
      initial.customizer.marginRight = customizerStyles.marginRight;
    }
  }, []);

  const lock = useCallback(() => {
    // prevent scroll and mark as locked
    html.current.classList.toggle('scroll-locked');
    html.current.style.overflowY = 'hidden';

    const atTheTop = window.scrollY === 0;

    // no scroll — no need to apply lock styles
    if (atTheTop) {
      runCallback(props);
      return;
    }

    // Have scrolled
    /*
      To prevent the body from jumping horizontally
      (due to scrollbar disappearing) after we set overflowY=hidden
      we capture the scrollbar width and set it as margin-right
    */
    const scrollbarWidthPx = window.innerWidth - document.body.clientWidth || 0;
    const headerHeight = header.current.clientHeight;
    const promoHeight = promo.current.clientHeight;
    const headerPromoHeight = headerHeight + promoHeight;

    // console.log({ scrollY: window.scrollY, headerHeight, promoHeight, headerPromoHeight })

    // set header from sticky to fixed

    // header.current.style.position = 'fixed';
    // header.current.style.right = `${scrollbarWidthPx/2}px`;

    // scrolled but not passed promobar + header height
    if (window.scrollY <= headerPromoHeight) {
      promo.current.style.position = 'fixed';
      promo.current.style.top = `-${window.scrollY}px`;

      // scrolled but no pass the promo height
      if (window.scrollY <= promoHeight) {
        header.current.style.top = `${promoHeight - window.scrollY}px`;
      }

      html.current.style.paddingTop =
        'calc(var(--header-height) + var(--promoBar-height))';
    } else {
      // scrolled past the header + promo
      html.current.style.paddingTop = 'var(--header-height)';
    }

    /*
      add margin to the tina container
      for the disabled scroll bar during lock
    */
    if (customizer.current) {
      customizer.current.style.marginRight = `${scrollbarWidthPx}px`;
    }
  }, []);

  const resetLock = useCallback(() => {
    // reset original document overflow value
    html.current.style.overflowY = initial.html.overflowY;

    // reset locked html
    html.current.classList.toggle('scroll-locked');
    html.current.style.paddingTop = initial.html.paddingTop; // 'unset';

    promo.current.style.position = initial.promo.position;
    promo.current.style.top = initial.promo.top; // 'unset';

    if (window.scrollY) {
      header.current.style.position = initial.header.position; // 'sticky';
      header.current.style.top = initial.header.top; // `0px`;
    }

    if (customizer.current) {
      customizer.current.style.marginRight = initial.customizer.marginRight; // '0px'
    }
  }, []);

  const lockScroll = useCallback((props) => {
    const isLocked = html.current.classList.contains('scroll-locked');

    if (isLocked) {
      runCallback(props);
      return;
    }

    DEBUG && console.log('🔒 Locking scroll...');

    /**
      Make sure the position-sticky header and relative promoBar
      do not disappear on scroll lock by switching it to fixed and adding
      header height to the scroll container to prevent the vertical jump
    */
    try {
      bindRefs();
      cacheInitial();

      DEBUG &&
        console.log('🔒 Lock', { initial, html, header, promo, customizer });
      lock();
      runCallback(props);
    } catch (error) {
      console.error('🔒 lockScroll:error', error.message);
    }
  });

  /**
   * Unlock body scroll by resetting all lock scroll styles
   */
  const unlockScroll = useCallback((props) => {
    const isLocked = html.current.classList.contains('scroll-locked');

    if (!isLocked) {
      DEBUG && console.log('🔓 unlockScroll: nothing to unlock. Good Bye');
      return;
    }

    DEBUG && console.log('🔓 Unlocking scroll...');

    // reset header to sticky
    try {
      bindRefs();
      resetLock();
      runCallback(props);
    } catch (error) {}
  });

  return { lockScroll, unlockScroll };
};
