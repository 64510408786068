import { Flex, Button, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Header.theme';

export const Header = themed(
  ({
    theme,
    isOpen,
    iconColor,
    textColor,
    minHeight,
    index,
    children,
    plusIcon = false,
    ...props
  }) => {
    return (
      <Button
        data-comp={Header.displayName}
        role="tab"
        aria-controls={`tabpanel-id-${index}`}
        aria-expanded={isOpen}
        variant="buttons.plain"
        {...props}
        sx={{
          ...theme.header,
          minHeight,
          lineHeight: minHeight,
          ...props.sx,
        }}
      >
        <Flex sx={theme.flex}>
          <Box sx={theme.text}>{children}</Box>
          {plusIcon && (
            <>
              {isOpen ? (
                <Svg
                  src="/svgs/minus.svg#minus"
                  alt="chevron"
                  viewBox="0 0 48 48"
                  sx={{
                    ...theme.icon,
                    color: iconColor,
                  }}
                />
              ) : (
                <Svg
                  src="/svgs/plus.svg#plus"
                  alt="chevron"
                  viewBox="0 0 48 48"
                  sx={{
                    ...theme.icon,
                    color: iconColor,
                  }}
                />
              )}
            </>
          )}
          {!plusIcon && (
            <Svg
              src={isOpen ? '/svgs/minus.svg#minus' : '/svgs/plus.svg#plus'}
              alt={isOpen ? 'minus' : 'plus'}
              viewBox="0 0 48 48"
              sx={{
                ...theme.icon,
                color: iconColor,
              }}
            />
          )}
        </Flex>
      </Button>
    );
  }
);

Header.displayName = 'Header';
