import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
    };

    this.title = {
      variant: 'text.nav',
      pr: 6,
      flex: 1,
      my: 4,
    };
    this.colorName = {
      variant: 'text.label.1',
    };
    this.price = {
      lineThrough: {
        variant: 'text.base',
        color: 'gray.medium',
        textDecoration: 'line-through',
        mr: 4,
      },
      regularRed: {
        variant: 'text.base',
        color: 'red',
      },

      regular: {
        variant: 'text.base',
      },
    };
  })()
);
