import { useMemo } from 'react';
import { Flex } from 'theme-ui';
import { Autoplay, EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCountry } from '@backpackjs/storefront';
import { Svg } from '@snippets';

import { PromoBarMessage } from './PromoBarMessage';
import { usePromobar } from './usePromobar';
import { themed } from './Promo.theme';

export const PromoBar = themed(
  ({ theme, inputRef, rotatingBarRef, ...props }) => {
    const [
      {
        promoBarRef,
        swiperRef,
        currentColor,
        bgColor,
        isDisabled,
        singleMessage,
        promoBar,
        rotatingBar,
      },
      { animateClose, updateActiveIndex, setSwiper },
    ] = usePromobar();

    const { isSticky: promoBarIsSticky } = { ...promoBar };
    const { isSticky: rotatingBarIsSticky } = { ...rotatingBar };
    const { country } = useCountry();

    const topPosition = useMemo(() => {
      let pos = null;
      if (promoBarIsSticky) {
        pos =
          rotatingBarIsSticky && rotatingBarRef?.current?.offsetHeight
            ? rotatingBarRef.current.offsetHeight
            : 0;
      } else {
        pos = 'unset';
      }
      return pos;
    }, [promoBarIsSticky, rotatingBarIsSticky]);

    return isDisabled ? null : (
      <Flex
        data-comp={PromoBar.displayName}
        ref={(el) => {
          promoBarRef(el);
          inputRef.current = el;
        }}
        sx={{
          ...theme.wrapper,
          color: currentColor,
          bg: bgColor,
          position: promoBarIsSticky ? 'sticky' : 'relative',
          top: topPosition,
          left: promoBarIsSticky ? 0 : 'unset',
        }}
      >
        {/* Close X */}
        {/* <Svg
        data-comp="PromoBarClose"
        alt="Close promotional bar"
        src="/svgs/close.svg#close"
        viewBox="0 0 48 48"
        onClick={animateClose}
        sx={theme.close}
      /> */}

        {/* Single message (no swiper), multiple swiper */}
        {singleMessage ? (
          <PromoBarMessage {...promoBar.messages[0]} isActive />
        ) : (
          <Swiper
            data-comp="PromoBarMessagesSlider"
            init
            autoplay={{
              enabled: true,
              delay: promoBar.duration * 1000,
            }}
            fadeEffect={{ crossFade: true }}
            speed={promoBar.speed * 1000}
            effect="fade"
            sx={theme.swiper}
            navigation
            threshold={25}
            modules={[EffectFade, Autoplay, Navigation]}
            loop
          >
            {promoBar.messages
              .filter(
                (item) =>
                  item?.locations?.includes(country?.isoCode) ||
                  item?.locations?.length < 1
              )
              .map((message, index) => (
                <SwiperSlide key={index}>
                  {({ isActive }) => {
                    return <PromoBarMessage {...message} isActive={isActive} />;
                  }}
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </Flex>
    );
  }
);

PromoBar.displayName = 'PromoBar';
