import dynamic from 'next/dynamic';
import { Box, Container } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { Link, Markdown, ProductItem } from '@snippets';

import { colors } from '@theme/theme.colors';

import { themed } from './Content.theme';

const Media = dynamic(() => import('../Media').then((module) => module.Media), {
  ssr: false,
  suspense: false,
});

export const Content = themed(({ theme, products, media, cta, showButton }) => {
  const invertModelPictures = useRecoilValue(store.invertModelPictures);
  return (
    <Container
      sx={{
        ...theme.container,
        gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(4, 1fr)'],
      }}
    >
      <Box
        sx={{
          ...theme.collection,
          gridArea: ' 1 / 1 / 2 / 3',
          mb: 4,
        }}
      >
        <Link variant="buttons.plain" href={cta.url}>
          <Media media={media}>
            {showButton && (
              <Link
                href={cta.url}
                variant="buttons.outline"
                sx={{
                  ...theme.cta,
                  backgroundColor: colors.white,
                  px: 10,
                }}
              >
                {cta.text}
              </Link>
            )}
          </Media>
        </Link>

        {media?.description && (
          <Markdown
            text={media.description}
            textSx={{ variant: 'text.base' }}
            sx={{ mt: 4 }}
          />
        )}
      </Box>
      {products &&
        products.map((product, index) => (
          <ProductItem
            key={`${product.product.handle}-${index}`}
            product={product.product}
            showBadges={product?.showBadge}
            badgeVariant={product?.badgeStyle}
            invertImages={invertModelPictures}
            index={index}
          />
        ))}
    </Container>
  );
});

Content.displayName = 'Content';
