export const formatAmount = ({
  amount = 0,
  currency = 'USD',
  symbol = '$',
}) => {
  if (amount !== 0 && !amount) return null;
  const total =
    typeof amount === 'string'
      ? parseFloat(amount).toFixed(2).replace('.00', '')
      : amount.toFixed(2).replace('.00', '');
  let locale;
  if (currency === 'EUR') {
    locale = `${total.replaceAll('.', ',')} €`;
  } else if (currency === 'GBP') {
    locale = `£${total}`;
  } else if (currency === 'CAD') {
    locale = `$${total} CAD`;
  } else if (currency === 'AUD') {
    locale = `$${total} AUD`;
  } else {
    locale = `${symbol}${total}`;
  }
  return locale;
};

function extractSymbol(str) {
  const regex = /[^0-9,\.]/g;
  const result = str.match(regex);
  return result ? result.join('').trim() : '';
}

export const formatAmountItem = (item, qty, defaultLocale) => {
  if (!item) return null;

  const total = item?.amount;
  const currency = item?.currencyCode;
  const symbol = item?.locale && extractSymbol(item?.locale);

  return qty
    ? {
        ...item,
        localeTotal: formatAmount({ amount: total, currency, symbol }),
        locale: formatAmount({
          amount: total / qty,
          currency,
          symbol,
        }),
      }
    : {
        ...item,
        locale: formatAmount({ amount: total, currency }),
      };
};
