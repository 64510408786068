import { section, color, align, button } from '@settings/common';

export const Schema = ({ article, blog, collection }) => {
  if (article || blog || collection) return null;

  return {
    label: 'Featured slider',
    key: 'featured-slider',
    fields: [
      {
        name: 'products',
        label: 'Products',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        defaultItem: {
          title: 'Classic Pacific Blue',
          description:
            'The Classic Pacific Blue is a perfect fit for any rider.',
          product: { handle: 'pacific-blue-legacy-bomber' },
          buttons: [
            {
              cta: {
                text: 'Men',
                url: '/collections/all',
              },
              style: 'outline',
            },
            {
              cta: {
                text: 'Women',
                url: '/collections/all',
              },
              style: 'outline',
            },
          ],
        },
        fields: [
          {
            name: 'title',
            label: 'Title',
            component: 'text',
            defaultValue: 'Legacy Bomber',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
            defaultValue:
              'Timeless and sophisticated, the Legacy Bomber delivers confidence all year round.',
          },
          {
            name: 'buttons',
            label: 'Buttons',
            component: 'group-list',
            description: 'Max of 2 buttons',
            itemProps: {
              label: '{{item.cta.text}}',
            },
            validate: {
              maxItems: 2,
            },
            defaultItem: {
              cta: {
                text: 'Men',
                url: '/collections/all',
              },
              style: 'primary',
            },
            fields: [
              {
                name: 'cta',
                component: 'link',
                label: 'Button',
              },
              {
                name: 'style',
                label: 'Button style',
                component: 'select',
                options: button.options,
              },
            ],
            defaultValue: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
          {
            label: 'Select Image Source',
            description:
              'Select the source of the image \n you can choose from the media library or search for a product',
            name: 'mediaType',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Product', value: 'product' },
            ],
            defaultValue: 'product',
          },
          {
            label: 'Image Settings',
            name: 'customImage',
            description: 'If "Image" is selected, configure settings here.',
            component: 'group',
            fields: [
              {
                name: 'imageLinkOverride',
                component: 'link',
                label: 'Image Link',
              },
              {
                name: 'ratio',
                label: 'Image Aspect Ratio',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  {
                    value: '0.75',
                    label: '3:4',
                  },
                  {
                    value: '1',
                    label: '1:1',
                  },
                ],
                defaultValue: '0.75',
              },
              {
                label: 'Image',
                name: 'imageOverride',
                component: 'image',
              },
              {
                label: 'Hover Image',
                name: 'hoverImageOverride',
                component: 'image',
              },
            ],
          },

          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
            description: 'If "Product" is selected, choose a product here.',
          },
        ],
        defaultValue: [
          {
            product: { handle: 'black-legacy-bomber' },
            title: 'Legacy Bomber',
            description:
              'Timeless and sophisticated, the Legacy Bomber delivers confidence all year round.',
            buttons: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
          {
            title: 'Classic Pacific Blue',
            description:
              'The Classic Pacific Blue is a perfect fit for any rider.',
            product: { handle: 'pacific-blue-legacy-bomber' },
            buttons: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
          {
            product: { handle: 'nord-pine-hoodie-split-hem' },
            title: 'Split Hem',
            description:
              'Timeless and sophisticated, the Legacy Bomber delivers confidence all year round.',
            buttons: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
          {
            product: { handle: 'ao-long-sleeve-henley-curve-hem-agave' },
            title: 'Long Sleeve Henley',
            description:
              'Timeless and sophisticated, the Heater green delivers confidence all year round.',
            buttons: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
          {
            product: { handle: 'nord-l-s-sage-hooded-elongated' },
            title: 'Nord L/S Sage',
            description:
              'Timeless and sophisticated, the Hooded delivers confidence all year round.',
            buttons: [
              {
                cta: {
                  text: 'Men',
                  url: '/collections/all',
                },
                style: 'primary',
              },
              {
                cta: {
                  text: 'Women',
                  url: '/collections/all',
                },
                style: 'primary',
              },
            ],
          },
        ],
      },

      {
        label: 'Content settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            name: 'showHeading',
            label: 'Hide / Show Heading',
            description: 'Show heading',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showTitle',
            label: 'Hide / Show Title',
            description: 'Show title',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showDescription',
            label: 'Hide / Show Description',
            description: 'Show description',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'hoverDescription',
            label: 'Hover Description',
            description:
              'Hover to expand description body height. Seen when description is longer than 2 lines.',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showLineBreak',
            label: 'Hide / Show Line Break',
            description: 'Show line break',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showButtons',
            label: 'Hide / Show Buttons',
            description: 'Show buttons',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'customText',
            label: 'Use custom title - description ',
            description:
              'if value is ON title and description will be the value defined in each product',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
        ],
        defaultValue: {
          heading: 'Trending Now',
          textAlign: align.value.left,
          showTitle: true,
          showDescription: true,
          showButtons: true,
          customText: true,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
