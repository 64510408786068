import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      px: [10, 20],
      maxWidth: '860px',
      margin: '0 auto',
      // py: [14, 18],
    };
    this.title = {
      variant: 'text.h3',
      mb: 12,
      textAlign: 'center',
    };
    this.accordion = {
      border: 'none',
    };
    this.accordionItem = {
      backgroundColor: 'gray.light',
      mb: 10,
      border: 'none',
    };
    this.top = {
      whiteSpace: 'initial',
    }
    this.content = {
      fontSize: '15px',
    }
    this.accordionHeader = {
      px: 10,
    };
    this.accordionBody = {
      px: 10,
      p: {
        m: 0,
      },
    };
    this.more = {
      display: 'block',
      variant: 'buttons.plain',
      margin: '0 auto',
      textDecoration: 'underline',
    };
  })()
);
