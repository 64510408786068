import { useProductByHandle } from '@backpackjs/storefront';
import { AddToCart } from '@templates/product/Main/Details/AddToCart';
import { Box, Flex } from 'theme-ui';

export const AddProduct = ({ product, buttonText, buttonStyle }) => {
  const { product: fullProduct } = useProductByHandle({
    handle: product?.handle,
  });

  const separatedText = (btnText) => {
    const [firstPart, secondPart] = btnText.split('{');

    return (
      <Flex sx={{ justifyContent: 'space-evenly' }}>
        <span>{firstPart.trim()}</span>
        {secondPart && (
          <Box as="span" sx={{ ml: [10, 15] }}>
            {secondPart.replace('}', '').trim()}
          </Box>
        )}
      </Flex>
    );
  };
  const selectedVariant = fullProduct?.variants[0] || fullProduct;
  return (
    <AddToCart
      product={fullProduct}
      selectedVariant={selectedVariant}
      buttonText={separatedText(buttonText)}
      style={{ button: { ...buttonStyle } }}
    />
  );
};
