// global
import { useSidebar } from './useSidebar';
import { useModal } from './useModal';
import { useOverlay } from './useOverlay';

// product
import { useAddToCart } from './useAddToCart';
import { useSelectedOptionIsAvailable } from './useSelectedOptionIsAvailable';
import { useSelectedOptions } from './useSelectedOptions';
import { useSelectedPlan } from './useSelectedPlan';
import { useSelectedVariant } from './useSelectedVariant';
import { useSelectedTags } from './useSelectedTags';
import { useSwatchColors } from './useSwatchColors';
import { useGroupingTags } from './useGroupingTags';
import { useProductColors } from './useProductColors';

// utils
import { useBodyScrollLock } from './useBodyScrollLock';
import { useBottomToIosFooterBar } from './useBottomToIosFooterBar';
import { useBreakpoint } from './useBreakpoint';
import { useBreakpointValue } from './useBreakpointValue';
import { useDebounce } from './useDebounce';
import { usePickedSections } from './usePickedSections';
import { useSetHeaderHeightCssVar } from './useSetHeaderHeightCssVar';
import { useSetViewportHeightCssVar } from './useSetViewportHeightCssVar';
import { useLocalStorage } from './useLocalStorage';
import { useMatchMedia } from './useMatchMedia';
import { useCountries } from './useCountries';

// cms
import { useHideCustomizerBar } from './useHideCustomizerBar';
import { useTrackSettings } from './useTrackSettings';

// api
import { useSearchspring } from './useSearchspring';
import { useProductRecommendations } from './useProductRecommendations';
import { useSearchspringBeacon } from './useSearchspringBeacon';

// Kustomer chat
import { useKustomerChat } from './kustomerChat';

// searchspring
import { useIntelliSuggest } from './searchspring';
// gift cards
import { useVariantsToLocalePrices } from './useVariantsToLocalePrices';

// geolocation
import { useGeolocation } from './useGeolocation';

// shopify-analytics
import { useShopifyAnalytics } from './shopify-analytics';

// customer window obj
import { useGlobalCustomer } from './useGlobalCustomer';

// datalayer
import { useDataLayerActions } from './datalayer';
import { usePackCookie } from './usePackCookie';
import { useAxonCookie } from './useAxonCookie';

// localization
import {
  useLocalizedProducts,
  useLocalizedSelectedVariant,
} from './localization';

import { useInveterate } from './inveterate';

// fondue cashback
import { useFondueCashback } from './useFondueCashback';

// @hooks
export {
  useAddToCart,
  useBodyScrollLock,
  useBottomToIosFooterBar,
  useBreakpoint,
  useBreakpointValue,
  useCountries,
  useDataLayerActions,
  useDebounce,
  useFondueCashback,
  useGeolocation,
  useGlobalCustomer,
  useGroupingTags,
  useHideCustomizerBar,
  useInveterate,
  useIntelliSuggest,
  useKustomerChat,
  useLocalStorage,
  useLocalizedProducts,
  useLocalizedSelectedVariant,
  useMatchMedia,
  useModal,
  useOverlay,
  usePackCookie,
  useAxonCookie,
  usePickedSections,
  useProductColors,
  useProductRecommendations,
  useSearchspring,
  useSearchspringBeacon,
  useSelectedOptionIsAvailable,
  useSelectedOptions,
  useSelectedPlan,
  useSelectedTags,
  useSelectedVariant,
  useSetHeaderHeightCssVar,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useSidebar,
  useSwatchColors,
  useTrackSettings,
  useVariantsToLocalePrices,
};
