import { Heading, Flex } from 'theme-ui';
import { Price } from '../Price';

import { themed } from './Header.theme';

import { useKitProducts } from '../../useKitProducts';

export const Header = themed(({ theme, cms, isMobile }) => {
  const { products } = useKitProducts({
    products: cms?.products?.list,
  });

  return (
    <Flex
      sx={{
        ...theme.metadata,
        display: [isMobile ? 'block' : 'none', isMobile ? 'none' : 'block'],
        mt: isMobile ? 8 : 0,
        mb: 7,
        px: isMobile ? 6 : 0,
      }}
    >
      <Heading as="h1" sx={theme.title}>
        {cms?.title}
      </Heading>

      <Price
        price={cms?.price}
        compareAtPrice={cms?.compareAtPrice}
        products={products}
      />
    </Flex>
  );
});

Header.displayName = 'Header';
