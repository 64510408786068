import { useCallback } from 'react';
import { Box } from 'theme-ui';
import { useCartAddItems } from '@backpackjs/storefront';

import store, { useRecoilCallback } from '@store';
import { StatefulButton } from '@snippets';

import { themed } from './AddToCart.theme';

export const AddToCart = themed(({ theme, disabled, activeKit }) => {
  const { cartAddItems, ...status } = useCartAddItems();

  const toggleCartSidebar = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, null))
            .map(({ set }) => set(store.modalProps, {}))
            .map(({ set }) => set(store.overlay, true))
            .map(({ set }) => set(store.sidebar, 'cart'));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  const handleAddToCart = useCallback(async () => {
    if (disabled || !activeKit) return;

    const itemsToAdd = activeKit.reduce((acc, variant) => {
      let attributes = [{ key: 'kit', value: 'true' }];

      // if product contains bundle and product contains packqty::, add line item properties
      if (variant?.product.tags?.includes('bundle')) {
        const [, packqty] =
          variant.product.tags
            .find((tag) => tag.indexOf('packqty') > -1)
            ?.split('::') || [];
        if (packqty) {
          attributes = [
            ...attributes,
            { key: '_packqty', value: packqty },
            { key: '_bundle', value: 'true' },
          ];
        }
      }

      const sameItem = acc.find((item) => item.merchandiseId === variant.id);
      if (sameItem) {
        sameItem.quantity += 1;
        return acc;
      }

      return [
        ...acc,
        {
          attributes,
          merchandiseId: variant.id,
          quantity: 1,
        },
      ];
    }, []);

    await cartAddItems(itemsToAdd);

    toggleCartSidebar();
  }, [disabled, activeKit]);

  return (
    <Box data-comp={AddToCart.displayName} sx={theme.wrapper}>
      <StatefulButton
        disabled={disabled}
        sx={{
          ...theme.button,
          ...(disabled
            ? {
                ...theme.button.unavailable,
                ...theme.button.unavailable.oneTime,
              }
            : {
                ...theme.button.available,
                ...theme.button.available.oneTime,
              }),
        }}
        {...status}
        text={{
          default: 'Add to Bag',
          started: 'Adding',
          success: 'Added',
          error: 'Add to Bag',
        }}
        onClick={handleAddToCart}
      />
    </Box>
  );
});

AddToCart.displayName = 'AddToCart';
