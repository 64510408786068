import { gsap } from 'gsap';

const revealModal = () =>
  new Promise((resolve) => {
    const animIn = gsap.timeline();
    animIn
      // fade in modal
      .to('[data-comp="Modal"]', {
        alpha: 1,
        duration: 0.5,
      })
      .to(
        `[data-comp='ModalContent']`,
        {
          alpha: 1,
          y: 0,
          duration: 0.3,
          onComplete: resolve,
        },
        '-=.2'
      );
  });

const hideModal = () =>
  new Promise((resolve) => {
    const animOut = gsap.timeline();
    animOut
      // close reveal
      .to(`[data-comp='ModalContent']`, {
        alpha: 0,
        y: 10,
        duration: 0.15,
      })
      // fade in modal
      .to('[data-comp="Modal"]', {
        alpha: 0,
        duration: 0.3,
        onComplete: resolve,
      });
  });

export default {
  revealModal,
  hideModal,
};
