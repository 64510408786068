// Implements shopify image sizing, croping, scale and formatting
// @see: https://www.shopify.com/partners/blog/img-url-filter

export const shopifySrcFilter = (src, options) => {
  if (!src) {
    console.warn('shopifySrcFilter requires an image src as first parameter');
    return '/svgs/noprecache/svg/default-image.svg';
  }

  const isShopify = src.includes('cdn.shopify.com');
  const isSizeable = /\.png|\.jpg|\.gif|\.jpeg|\.webp/.test(src);
  const hasCropping = /_x\d+\./.test(src);

  if (!isShopify || !isSizeable || hasCropping) {
    return src;
  }

  // src is from shopify's cdn and sizeable
  const width = (options && options.width) || null;
  const height = (options && options.height) || null;
  const crop = (options && options.crop) || 'center'; // top, center, bottom, left, right
  const scale = (options && options.scale) || 2; // 2, 3
  const format = (options && options.format) || 'pjpg'; // jpg, pjpg

  const isPJPG = format === 'pjpg';
  const haveWidthOrHeight = width || height;

  if (!haveWidthOrHeight) {
    // nothing to resize
    return src;
  }

  let config = '';

  // sizing: width, height or both
  if (haveWidthOrHeight) {
    config = '_';
    if (width && height) {
      config = `${config}${width}x${height}`;
    } else if (width) {
      config = `${config}${width}x`;
    } else if (height) {
      config = `${config}x${height}`;
    }
  }

  if (crop) {
    config = `${config}_crop_${crop}`;
  }

  if (scale) {
    config = `${config}@${scale}x`;
  }

  if (format && isPJPG) {
    config = `${config}.progressive`;
  }

  return src.replace(/(.*)(\..*)(\?*)/, `$1${config}$2`);
};
