import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.metadata = {
      width: '100%',
      variant: 'flex.row.between.center',
      mb: 7,
    };

    this.title = {
      variant: 'text.h4',
    };

    this.price = {
      variant: 'text.base',
    };
  })()
);
