import {
  sectionWithHeight,
  color,
  align,
  position,
  text,
} from '@settings/common';
import { animation } from './animation.schema';
import { superheading, heading } from './text.schema';

export const Schema = () => ({
  label: 'Featured Text',
  key: 'featured-text',
  fields: [
    {
      label: 'Text settings',
      name: 'text',
      description: 'Heading, superheading,  color',
      component: 'group',
      fields: [
        superheading({ color, text }),
        heading({ color, text }),
        {
          name: 'alignmentMb',
          label: 'Text alignment (mobile)',
          component: 'radio-group',
          direction: 'horizontal',
          description:
            'Set the content alignment globally \nGlobal alignment must be enabled',
          variant: 'radio',
          options: align.options,
        },
        {
          name: 'positionMb',
          component: 'select',
          label: 'Content position (mobile)',
          options: position.flex,
        },
        {
          name: 'alignmentDt',
          label: 'Text alignment (desktop)',
          component: 'radio-group',
          direction: 'horizontal',
          description:
            'Set the content alignment globally \nGlobal alignment must be enabled',
          variant: 'radio',
          options: align.options,
        },
        {
          name: 'positionDt',
          component: 'select',
          label: 'Content position (desktop)',
          options: position.flex,
        },
        {
          name: 'maxWidth',
          component: 'number',
          label: 'Max content pixel width',
        },
      ],
      defaultValue: {
        heading: {
          text: '# To outfit the world’s most ambitious people.',
          color: '',
          textVariant: text.value.h3,
        },
        superheading: {
          text: '<u>Our Mission</u>',
          color: '',
          textVariant: text.value.xs,
        },
        alignmentMb: 'center',
        alignmentDt: 'center',
        positionMb: 'center center',
        positionDt: 'center center',
        maxWidth: 600,
      },
    },
    animation(),
    {
      ...sectionWithHeight,
      defaultValue: {
        mobile: {
          maxHeight: 160,
          minHeight: 160,
        },
        desktop: {
          maxHeight: 275,
          minHeight: 275,
        },
      },
    },
  ],
});
