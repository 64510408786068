import { Box, Heading, Flex, Container, Button } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { themed } from './Header.theme';

export const Header = themed(
  ({
    theme,
    activeIndex,
    groups,
    heading,
    setActiveIndex,
    textAlign = 'center',
  }) => {
    const isGroups = groups?.length > 1;

    return (
      <Container
        data-comp={Header.displayName}
        sx={{
          ...theme.header,
          justifyContent: [
            'center',
            null,
            null,
            isGroups ? 'space-between' : convertAlignToFlex(textAlign),
          ],
          alignItems: [convertAlignToFlex(textAlign), 'flex-end'],
        }}
      >
        <Heading
          sx={{
            ...theme.heading,
            textAlign: [textAlign, isGroups ? 'left' : textAlign],
            flex: isGroups ? 0.5 : 1,
          }}
        >
          {heading}
        </Heading>

        {isGroups && (
          <Box sx={{ ...theme.groups }}>
            <Flex
              role="tablist"
              sx={{
                ...theme.list,
                justifyContent: [convertAlignToFlex(textAlign), 'flex-start'],
              }}
            >
              {groups.map(({ title }, index, arr) => {
                const isActive = index === activeIndex;
                return (
                  <Box
                    key={index}
                    sx={{
                      ...theme.listItem,
                      mr: index !== arr.length - 1 ? ['28px', null, '44px'] : 0,
                      button: {
                        ':after': {
                          transform: isActive
                            ? 'translateX(0)'
                            : 'translateX(-101%)',
                        },
                      },
                    }}
                  >
                    <Button
                      role="tab"
                      aria-selected={isActive ? 'true' : 'false'}
                      onClick={() => setActiveIndex(index)}
                      sx={theme.button}
                    >
                      {title}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        )}
      </Container>
    );
  }
);

Header.displayName = 'Header';
