import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      bg: 'gray.mediumLight',
      py: [16, 18],
      px: [10, 18],
    };
    this.container = {
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
      gridRowGap: ['36px'],
      gridGap: ['36px'],
      justifyContent: 'space-between',
      px: [10, 12, 14],
      counterReset: 'count',
    };
    this.title = {
      variant: 'text.h4',
      display: 'flex',
      justifyContent: 'center',
      mb: 12,
    };
    this.sliderWrap = {
      '.swiper': {
        overflow: 'visible',
      },
      '.swiper-pagination': {
        bottom: '-30px',
        display: 'flex',
        variant: 'flex.row.end.center',
      },
    };
    this.swiperBullet = {
      backgroundColor: 'gray.medium',
      width: '8px',
      height: '8px',
    };
    this.swiperBulletActive = {
      backgroundColor: 'gray.medium',
      width: '12px',
      height: '12px',
    };
    this.slide = {
      height: ['auto', '510px'],
      flexDirection: ['column', 'row'],
      backgroundColor: 'white',
      '& > div': {
        width: ['100%', '50%'],
      },
    };
    this.slideLeft = {
      height: ['300px', '100%'],
      position: 'relative',
    };
    this.slideImage = {
      position: 'absolute',
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      picture: {
        aspectRatio: '1',
      },
    };
    this.slideContent = {
      px: [10, 18],
      py: [14, 0],
      p: {
        mb: '8px',
      },
    };
  })()
);
