import { useState } from 'react';
import { Paragraph, Flex, Text } from 'theme-ui';
import { Picture, Markdown } from '@snippets';

import { themed } from './SizeGuide.theme';

export const SizeGuideModal = themed(({ theme, sizeGuide }) => {
  const [chartUnits, setChartUnits] = useState('in');

  if (!sizeGuide) return null;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        px: '24px',
        py: '24px',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Paragraph
          variant="text.label.2"
          sx={{
            fontSize: '16px',
            mb: 8,
          }}
        >
          Size Guide
        </Paragraph>
        {sizeGuide.name && (
          <Paragraph
            variant="text.h3"
            sx={{
              mb: 8,
            }}
          >
            {sizeGuide.name}
          </Paragraph>
        )}
        {sizeGuide.body && (
          <Markdown variant="text.body.lg" text={sizeGuide.body} />
        )}
      </Flex>

      {sizeGuide.sizeChart && sizeGuide.sizeChartCm && (
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            mb: 4,
          }}
        >
          <Paragraph
            onClick={() => setChartUnits('in')}
            sx={{
              variant: 'buttons.link.large',
              pt: 1,
              textDecoration: chartUnits === 'in' ? 'underline' : 'none',
              fontWeight: 700,
            }}
            role="button"
          >
            IN
          </Paragraph>
          <Paragraph
            sx={{
              mx: '12px',
              fontWeight: 700,
            }}
          >
            |
          </Paragraph>
          <Paragraph
            onClick={() => setChartUnits('cm')}
            sx={{
              variant: 'buttons.link.large',
              pt: 1,
              textDecoration: chartUnits === 'cm' ? 'underline' : 'none',
              fontWeight: 700,
            }}
            role="button"
          >
            CM
          </Paragraph>
        </Flex>
      )}

      <Flex sx={{ overflowX: 'auto', mb: 8 }}>
        <Markdown
          text={
            chartUnits === 'in' ? sizeGuide.sizeChart : sizeGuide.sizeChartCm
          }
          sx={{
            mb: 8,
            minWidth: '100%',
            'th, td': {
              py: '5px',
              px: '5px',
              textAlign: 'left',
              minWidth: '60px',
            },
            'th:nth-of-type(odd)': {
              backgroundColor: '#E6E6E6',
            },
            'th:nth-of-type(even)': {
              backgroundColor: '#F2F2F2',
            },

            'tr:nth-of-type(even)': {
              backgroundColor: 'unset',
            },

            'td:nth-of-type(even)': {
              backgroundColor: '#F2F2F2',
            },
            'td:nth-of-type(odd)': {
              backgroundColor: '#E6E6E6',
            },
          }}
        />
      </Flex>
      {sizeGuide.image.src && (
        <Picture
          alt={`${sizeGuide.name} size guide`}
          images={[
            {
              src: sizeGuide.image.src,
              ratio: 2,
              width: 720,
            },
          ]}
          sx={{
            maxWidth: '100%',
            width: '100%',
            mb: 8,
            bg: '#F2F2F2',
          }}
          imageSx={{ objectFit: 'contain' }}
        />
      )}
      {sizeGuide.contactText && (
        <Markdown
          variant="text.body.lg"
          text={sizeGuide.contactText}
          textSx={{ a: { textDecoration: 'none', fontWeight: 700 } }}
        />
      )}
    </Flex>
  );
});

SizeGuideModal.displayName = 'SizeGuideModal';
