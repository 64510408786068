import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.wrapperOuter = {
      minHeight: [300, 200],
      width: '100%',
      height: '100%',
      display: 'flex',
      pr: ['15px', '20px'],
    };

    this.wrapperInner = {
      width: '100%',
      height: '100%',
      border: `1px solid ${colors.gray.mediumLight}`,
      display: 'flex',
      flexDirection: ['row', 'column'],
      justifyContent: 'space-between',
    };

    this.title = {
      fontSize: '18px',
      fontWeight: 700,
      mb: 3,
    };

    this.image = {
      minHeight: '100%',
      width: '100%',
    };

    this.imageSx = { width: '100%', height: '100%', margin: '0 auto' };

    this.details = {
      py: [5, 8],
      px: [6, 6],
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: ['55%', null, '50%', '47.5%'],
    };

    this.detailsInner = {
      flexDirection: 'column',
      overflow: 'hidden',
    };

    this.flex = {
      alignItems: 'center',
    };

    this.text = {
      color: '#727272',
      fontSize: '14px',
    };

    this.link = {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '&:before': {
        display: 'none',
      },
    };

    this.linkText = {
      fontSize: ['14px', '15px'],
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline',
    };

    this.icon = {
      width: '30px',
      height: '30px',
      minWidth: '30px',
      mr: 2,
    };
  })()
);
