import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Mobile Navigation',
    key: 'mobile-navigation',
    fields: [
      {
        label: 'Show On Desktop',
        name: 'showOnDesktop',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        label: 'Nav Items',
        name: 'navItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Media',
            name: 'media',
            component: 'group',
            fields: [
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'Activate overlay',
                description: 'Activate image overlay',
                name: 'imageOverlay',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                defaultValue: false,
              },
            ],
          },
        ],
        defaultItem: {
          link: {
            url: '/collections/bestsellers',
            text: 'Best Sellers',
            type: 'isPage',
          },
          media: {
            image: {
              id: 'gid://shopify/MediaImage/25933963886680',
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
              url: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
              size: 87543,
              type: 'file',
              width: 750,
              format: 'image/jpeg',
              height: 1000,
              altText: '',
              filename: 'mobile-nav-best-sellers.jpg?v=1721681042',
              directory: '',
              mediaType: 'IMAGE',
              previewSrc:
                'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
              aspectRatio: 0.75,
            },
            imageOverlay: true,
          },
        },
        defaultValues: [
          {
            link: {
              url: '/collections/bestsellers',
              text: 'Best Sellers',
              type: 'isPage',
            },
            media: {
              image: {
                id: 'gid://shopify/MediaImage/25933963886680',
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
                url: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
                size: 87543,
                type: 'file',
                width: 750,
                format: 'image/jpeg',
                height: 1000,
                altText: '',
                filename: 'mobile-nav-best-sellers.jpg?v=1721681042',
                directory: '',
                mediaType: 'IMAGE',
                previewSrc:
                  'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-best-sellers.jpg?v=1721681042',
                aspectRatio: 0.75,
              },
              imageOverlay: true,
            },
          },
          {
            link: {
              type: 'isPage',
              url: '/collections/womens-tanks',
              text: 'Womens Tanks',
            },
            media: {
              image: {
                id: 'gid://shopify/MediaImage/25933964279896',
                type: 'file',
                directory: '',
                filename: 'mobile-nav-womens-tanks.png?v=1721681047',
                previewSrc:
                  'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tanks.png?v=1721681047',
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tanks.png?v=1721681047',
                url: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tanks.png?v=1721681047',
                size: 1284115,
                width: 900,
                height: 1200,
                format: 'image/png',
                altText: '',
                mediaType: 'IMAGE',
                aspectRatio: 0.75,
              },
              imageOverlay: true,
            },
          },
          {
            link: {
              url: '/collections/womens-all-tops',
              text: 'Womens Tops',
              type: 'isPage',
            },
            media: {
              image: {
                id: 'gid://shopify/MediaImage/25933964345432',
                type: 'file',
                directory: '',
                filename: 'mobile-nav-womens-tops.jpg?v=1721681050',
                previewSrc:
                  'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tops.jpg?v=1721681050',
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tops.jpg?v=1721681050',
                url: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-womens-tops.jpg?v=1721681050',
                size: 183313,
                width: 1200,
                height: 1500,
                format: 'image/jpeg',
                altText: '',
                mediaType: 'IMAGE',
                aspectRatio: 0.8,
              },
              imageOverlay: true,
            },
          },
          {
            link: {
              url: '/pages/mens-shop-all',
              text: 'Summer Staples',
              type: 'isPage',
            },
            media: {
              image: {
                id: 'gid://shopify/MediaImage/25933963919448',
                type: 'file',
                directory: '',
                filename: 'mobile-nav-summer-staples.jpg?v=1721681045',
                previewSrc:
                  'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-summer-staples.jpg?v=1721681045',
                src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-summer-staples.jpg?v=1721681045',
                url: 'https://cdn.shopify.com/s/files/1/1368/3463/files/mobile-nav-summer-staples.jpg?v=1721681045',
                size: 249908,
                width: 1200,
                height: 1500,
                format: 'image/jpeg',
                altText: '',
                mediaType: 'IMAGE',
                aspectRatio: 0.8,
              },
              imageOverlay: true,
            },
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        fields: [
          {
            label: 'Content Position',
            name: 'contentPosition',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                label: 'Inside',
                value: 'inside',
              },
              {
                label: 'Below',
                value: 'below',
              },
            ],
            defaultValue: 'below',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            mt: 'none',
            py: 's',
            container: 'container',
          },
          desktop: {
            mt: 'none',
            py: 'none',
            container: 'container',
          },
        },
      },
    ],
  };
};
