import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      maxHeight: 'calc(var(--viewport-height) - 2rem)',
      width: 'calc(100% - 2rem)',
      maxWidth: '48rem',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      bg: 'white',
      zIndex: 99,
    };

    this.close = {
      position: 'absolute',
      cursor: 'pointer',
      width: '34px',
      right: '10px',
      top: '10px',
      height: '24px',
      variant: 'flex.row.center',
      display: 'flex',
      zIndex: 20,
    };

    this.icon = {
      width: [16, null, 18],
      cursor: 'pointer',
    };

    this.content = {
      maxHeight: 'calc(var(--viewport-height) - 2rem)',
      overflowY: 'auto',
    };
  })()
);
