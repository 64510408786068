import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './FeaturedSlider.schema';
import { themed } from './FeaturedSlider.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const FeaturedSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { content, section, products } = cms;

    return (
      <Section section={section}>
        <Container data-comp={FeaturedSlider.displayName} sx={theme.wrapper}>
          {content?.showHeading && (
            <Box sx={theme.header}>
              {inView && <Header content={content} />}
            </Box>
          )}
          <Box sx={theme.slider}>
            {inView && <Slider products={products} settings={content} />}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FeaturedSlider.displayName = 'FeaturedSlider';
FeaturedSlider.Schema = Schema;
