import { store } from '@backpackjs/storefront';

// instant un-debounced search term (not used)
const rawTerm = store.recoil.atom({
  key: 'search/rawTerm',
  default: '',
});

// debounced term used
const term = store.recoil.atom({
  key: 'search/term',
  default: '',
});

// if search term originated from autocomplete
const isAutocomplete = store.recoil.atom({
  key: 'search/isAutocomplete',
  default: false,
});

// true when search input is focused
const searchIsFocused = store.recoil.atom({
  key: 'search/searchIsFocused',
  default: false,
});

// holds both results
const results$ = store.recoil.selector({
  key: 'search/results$',
  get: async ({ get }) => {
    const _term = get(term);
    if (!_term) return null;
    const response = await searchShopify(_term);
    if (response && response.error) {
      throw response.error;
    }
    return response;
  },
});

// search fetch function
const searchShopify = async (term) => {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ term }),
    };
    const request = await fetch(`/api/shopify/search`, options);
    const { response } = await request.json();
    return response?.results || null;
  } catch (e) {
    console.error('searchShopify:error', e);
    return [];
  }
};

const searchData = store.recoil.atom({
  key: 'search/searchData',
  default: null,
});

const searchPageData = store.recoil.atom({
  key: 'search/searchPageData',
  default: null,
});

const searchResults = store.recoil.atom({
  key: 'search/searchResults',
  default: [],
});

const searchSuggestions = store.recoil.atom({
  key: 'search/searchSuggestions',
  default: [],
});

const searchPageSuggestions = store.recoil.atom({
  key: 'search/searchPageSuggestions',
  default: [],
});

const searchSuggested = store.recoil.atom({
  key: 'search/searchSuggested',
  default: null,
});

const searchPageSuggested = store.recoil.atom({
  key: 'search/searchPageSuggested',
  default: null,
});

const searchAlternatives = store.recoil.atom({
  key: 'search/searchAlternatives',
  default: [],
});

const searchTrending = store.recoil.atom({
  key: 'search/searchTrending',
  default: [],
});

const searchSelectedSort = store.recoil.atom({
  key: 'search/selectedSort',
  default: null,
});

const searchSelectedResultsPerPage = store.recoil.atom({
  key: 'search/selectedResultsPerPage',
  default: 10,
});

const searchCurrentResultsPage = store.recoil.atom({
  key: 'search/currentResultsPage',
  default: 1,
});

const searchSelectedFilters = store.recoil.atom({
  key: 'search/selectedFilters',
  default: [],
});

export default {
  // atoms
  rawTerm,
  term,
  isAutocomplete,
  searchIsFocused,
  searchPageData,
  searchData,
  searchResults,
  searchSuggestions,
  searchPageSuggested,
  searchPageSuggestions,
  searchSuggested,
  searchAlternatives,
  searchTrending,
  searchSelectedSort,
  searchSelectedResultsPerPage,
  searchCurrentResultsPage,
  searchSelectedFilters,

  // selectors
  results$,
};
