import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      quantity: {
        variant: 'flex.row',
        mt: 4,
        width: 'fit-content',
        height: '32px',
        border: '1px solid',
        borderColor: 'gray.light',
        px: '20px',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },

        item: {
          variant: 'flex.row.center',
          input: {
            // variant: 'text.13',
            fontSize: '13px',
            fontWeight: 700,
            textAlign: 'center',
            p: 0,
            width: '40px',
            border: 'none',
            '@media screen and (min-width: 480px)': {
              ':hover': {
                boxShadow: 'none',
              },
            },
          },
          icon: {
            cursor: 'pointer',
            width: '12px',
            height: '12px',
          },
        },
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      quantity: {
        ...this.sidebar.quantity,

        item: {
          ...this.sidebar.quantity.item,

          input: {
            ...this.sidebar.quantity.item.input,
          },

          icon: {
            ...this.sidebar.quantity.item.icon,
          },
        },
      },
    };
  })()
);
