import { section, align, color } from '@settings/common';
import snakeCase from 'lodash.snakecase';

const inputField = (type) => ({
  label: type,
  key: snakeCase(type),
  defaultItem: {
    name: snakeCase(type),
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Label Position',
      name: 'labelPosition',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          label: 'Top',
          value: 'top',
        },
        {
          label: 'Inside',
          value: 'inside',
        },
      ],
      defaultValue: 'inside',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
});

const multiChoiceField = (type) => ({
  label: type,
  key: snakeCase(type),
  defaultItem: {
    name: snakeCase(type),
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Label Position',
      name: 'labelPosition',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          label: 'Top',
          value: 'top',
        },
        {
          label: 'Inside',
          value: 'inside',
        },
      ],
      defaultValue: 'inside',
    },
    ...(type === 'Select'
      ? [
          {
            label: 'Placeholder',
            name: 'placeholder',
            component: 'text',
          },
        ]
      : []),
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Options',
      name: 'options',
      component: 'list',
      field: { component: 'text' },
    },
    ...(type === 'Radio' || type === 'Multiple Checkbox'
      ? [
          {
            label: 'Direction',
            name: 'direction',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Horizontal', value: 'horizontal' },
              { label: 'Vertical', value: 'vertical' },
            ],
            defaultValue: 'horizontal',
          },
        ]
      : []),
    ...(type !== 'Multiple Checkbox'
      ? [
          {
            label: 'Required',
            name: 'required',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ]
      : []),
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    ...(type === 'Multiple Checkbox'
      ? [
          {
            label: 'Hide Label',
            name: 'hideLabel',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ]
      : []),
  ],
});

const countryField = {
  label: 'Country',
  key: 'country',
  defaultItem: {
    name: 'country',
    label: 'Country',
  },
  itemProps: (item) => ({
    label: `Country${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Label Position',
      name: 'labelPosition',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          label: 'Top',
          value: 'top',
        },
        {
          label: 'Inside',
          value: 'inside',
        },
      ],
      defaultValue: 'inside',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const labelField = {
  label: 'Label',
  key: 'label',
  defaultItem: {
    label: 'Label Text',
  },
  itemProps: (item) => ({
    label: `Label${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
  ],
};

const checkboxField = {
  label: 'Checkbox',
  key: 'checkbox',
  defaultItem: {
    name: 'checkbox',
    label: 'Checkbox',
  },
  itemProps: (item) => ({
    label: `Checkbox${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Label Position',
      name: 'labelPosition',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          label: 'Top',
          value: 'top',
        },
        {
          label: 'Inside',
          value: 'inside',
        },
      ],
      defaultValue: 'inside',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const fileField = {
  label: 'File',
  key: 'file',
  defaultItem: {
    name: 'file',
    label: 'File Upload',
  },
  itemProps: (item) => ({
    label: `File${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Label Position',
      name: 'labelPosition',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          label: 'Top',
          value: 'top',
        },
        {
          label: 'Inside',
          value: 'inside',
        },
      ],
      defaultValue: 'inside',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export const Schema = () => {
  return {
    label: 'Form Builder',
    key: 'form-builder',
    fields: [
      {
        label: 'Form Endpoint',
        name: 'endpoint',
        component: 'text',
        description:
          'Submit button will be disabled until entered.\ne.g. https://getform.io/f/1234-5678-90abcdef',
      },
      {
        label: 'Content Alignment (desktop)',
        name: 'alignment',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: 'left',
      },
      {
        label: 'Content Alignment (mobile)',
        name: 'alignmentMob',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: 'left',
      },
      {
        label: 'Prevent Duplicate Applicants',
        name: 'preventDuplicateApplicants',
        component: 'group',
        fields: [
          {
            label: 'Enable',
            name: 'enable',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Customer Tag',
            name: 'customerTag',
            description: 'Prevents submission if a customer tag contains value',
            component: 'text',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Form',
      },
      {
        label: 'Fields',
        name: 'fields',
        component: 'blocks',
        templates: {
          checkbox: checkboxField,
          country: countryField,
          date: inputField('Date'),
          email: inputField('Email'),
          file: fileField,
          label: labelField,
          multipleCheckbox: multiChoiceField('Multiple Checkbox'),
          number: inputField('Number'),
          phone: inputField('Phone'),
          radio: multiChoiceField('Radio'),
          select: multiChoiceField('Select'),
          text: inputField('Text'),
          textArea: inputField('Text Area'),
          time: inputField('Time'),
          url: inputField('Url'),
        },
      },
      {
        label: 'Submit Button Text',
        name: 'submitText',
        component: 'text',
        defaultValue: 'Submit',
      },
      {
        label: 'Form Success Message',
        name: 'formSuccessMessage',
        component: 'markdown',
        defaultValue: 'Submission sent!',
      },
      {
        label: 'reCAPTCHA Enabled',
        name: 'recaptchaEnabled',
        component: 'toggle',
        description:
          'reCAPTCHA site key must first be set as environment variable',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'contentNarrow',
            mt: 'none',
            py: 's',
          },
          mobile: {
            container: 'contentNarrow',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
