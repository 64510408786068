import { isBrowser } from '@utils';
import { useEffect } from 'react';

const DEBUG = true;

export const refreshYotpoWidgets = ({ productId = null }) => {
  DEBUG && console.log('🔄 Mounted yotpo widgets', window.yotpo.widgets);
  if (window.yotpo?.widgets) {
    const alreadyMounted =
      productId &&
      window.yotpo?.widgets?.find(
        ({ settings }) => settings?.pid === productId
      );
    if (!alreadyMounted) {
      DEBUG && console.log('🔄 Refreshing Yotpo widget', productId);
      window.yotpo.refreshWidgets();
    }
  }
};

export const useYotpoRefreshOnMount = ({ productId = null }) => {
  const refreshWidgets = isBrowser && window.yotpo?.refreshWidgets;
  const yotpoReady = typeof refreshWidgets === 'function';

  useEffect(() => {
    DEBUG && console.log(`🔄 Yotpo element ${productId} ready`);
    if (yotpoReady) {
      setTimeout(() => {
        refreshYotpoWidgets({ productId });
      }, 2000);
    }
  }, [refreshWidgets, yotpoReady, productId]);
};
