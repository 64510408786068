const items = () => ({
  label: 'Items',
  name: 'howItems',
  component: 'group',
  fields: [
    {
      label: 'Perk block items',
      name: 'items',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      validate: {
        maxItems: 3,
      },
      defaultItem: {
        icon: 'percent',
        text: 'new perk text',
      },
      fields: [
        {
          label: 'How item image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'How item heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'How item text',
          name: 'text',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          image:
            'https://cdn.shopify.com/s/files/1/1368/3463/files/how1.jpg?v=1651753404',
          heading: 'Apply',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
        {
          image:
            'https://cdn.shopify.com/s/files/1/1368/3463/files/how2.jpg?v=1651755342',
          heading: 'Share',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
        {
          image:
            'https://cdn.shopify.com/s/files/1/1368/3463/files/how3.jpg?v=1651755365',
          heading: 'Connect',
          text: 'Nullam quis risus eget urna mollis ornare vel eu leo',
        },
      ],
    },
  ],
});

export const Schema = ({ page }) => {
  if (page?.handle !== 'ambassadors') return null;

  return {
    label: 'Ambassador How',
    key: 'ambassadorHow',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'How It Works',
      },
      {
        name: 'instagramLink',
        label: 'Instagram link',
        component: 'text',
        defaultValue: 'https://www.instagram.com/cuts/',
      },
      {
        name: 'facebookLink',
        label: 'Facebook link',
        component: 'text',
        defaultValue: 'https://www.facebook.com/ShopCutsClothing',
      },
      items(),
    ],
  };
};
