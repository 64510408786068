import { Box, Container, Flex, Heading, Image } from 'theme-ui';

import { Markdown } from '@snippets';
import { themed } from './TestimonialSlide.theme';

export const TestimonialSlideV2 = themed(
  ({ theme, media, text, location, visible, stars }) => {
    const {
      alignment,
      alignmentDt,
      heading,
      headingColor,
      subheading,
      subheadingColor,
      quote,
      quoteColor,
    } = text;

    return (
      <Container data-comp={TestimonialSlideV2.displayName}>
        <Flex
          sx={{
            width: '100%',
            position: 'relative',
            alignItems: 'center',
            px: [4, 16],
            visibility: visible ? 'visible' : 'hidden',
            flexDirection: ['column', 'row'],
            justifyContent: 'center',
            gap: [8, 10],
          }}
        >
          <Flex
            sx={{
              gap: 4,
              backgroundColor: 'none',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Image
              alt={media?.alt}
              src={media?.image?.src}
              style={{
                height: '100%',
                maxWidth: '91px',
                borderRadius: '50%',
              }}
            />
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: [
                  alignment === 'center' ? 'center' : 'flex-start',
                  alignmentDt === 'center' ? 'center' : 'flex-start',
                ],
                gap: 3,
              }}
            >
              <Flex
                sx={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                {stars?.number &&
                  Array.from(
                    { length: stars < 5 ? stars : 5 },
                    (value, index) => {
                      return (
                        <Box
                          key={`star-${index + 1}`}
                          sx={{
                            display: 'block',
                            color: stars?.color || 'black',
                            fontSize: 3,
                            px: 1,
                          }}
                        >
                          &#9733;
                        </Box>
                      );
                    }
                  )}
              </Flex>
              <Heading
                as="h6"
                sx={{
                  ...theme.subheading,
                  color: subheadingColor || 'primary',
                  textAlign: [alignment, alignmentDt],
                }}
              >
                {subheading}
              </Heading>
              <Heading
                as="h6"
                sx={{
                  ...theme.heading,
                  textAlign: [alignment, alignmentDt],
                  color: headingColor || 'primary',
                }}
              >
                {heading}
              </Heading>
            </Flex>
          </Flex>

          <Flex>
            <Markdown
              text={quote}
              textAlign={[alignment, alignmentDt]}
              variant="p"
              sx={{
                ...theme.quote,
                color: quoteColor || 'primary',
              }}
            />
          </Flex>
        </Flex>
      </Container>
    );
  }
);
