import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: 0,
    };

    this.spinner = {
      width: '30px',
      color: 'lightGray',
      m: 'auto',
    };
  })()
);
