import { Button } from 'theme-ui';

import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaCart = themed(({ theme, transparentBGEnabled, ...props }) => {
  const [, { openCartSidebar }] = useSidebar();

  return (
    <Button
      data-comp={CtaCart.displayName}
      {...props}
      onClick={openCartSidebar}
      aria-label="Open cart drawer"
      variant="buttons.plain"
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Svg
        alt="Open cart drawer"
        viewBox="0 0 24 24"
        src="/svgs/cta/cart.svg#cart"
        sx={{
          ...theme.cta.icon,
          color: transparentBGEnabled ? 'white' : 'black',
          fill: transparentBGEnabled ? 'white' : 'black',
        }}
      />
    </Button>
  );
});

CtaCart.displayName = 'CtaCart';
