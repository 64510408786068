import { useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  EffectFade,
  Thumbs,
  FreeMode,
  Autoplay,
  Pagination,
} from 'swiper';
import { Box, Image } from 'theme-ui';

import { themed } from './Slider.theme';

import { TestimonialSlide } from '../Testimonial/TestimonialSlide';

export const Slider = themed(({ theme, testimonials, slider }) => {
  const [swiper, setSwiper] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
    if (!swiper || testimonials?.length < 2) return;
    swiper?.on('slideChange', () => {
      setActiveIndex(swiper?.realIndex);
    });
  }, [swiper, testimonials?.length]);

  useEffect(() => {
    setActiveIndexAndSlideToOnSlideChange();
  }, [swiper, testimonials?.length]);
  return (
    <Box
      data-comp={Slider.displayName}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Swiper
        onSwiper={setSwiper}
        autoplay={
          slider?.autoplay
            ? { delay: slider?.delay || 5000, disableOnInteraction: false }
            : false
        }
        effect={slider?.effect === 'fade' ? 'fade' : null}
        fadeEffect={{ crossFade: true }}
        centeredSlides={false}
        spaceBetween={1}
        navigation={false}
        thumbs={{ swiper: thumbsSwiper }}
        threshold={25}
        modules={[FreeMode, Pagination, Thumbs, Autoplay, EffectFade]}
        sx={{
          ...theme.slider,
        }}
      >
        {testimonials?.map((slide, index) => (
          <SwiperSlide key={index}>
            <TestimonialSlide
              text={slide.testimonialText}
              visible={activeIndex === index}
              color={slide.testimonialColor}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={testimonials?.length}
        freeMode={false}
        modules={[FreeMode, Navigation, Thumbs]}
        threshold={25}
        sx={{
          ...theme.thumbsSwiper(testimonials?.length * 7 || 5),
        }}
      >
        {testimonials?.map((slide, index) => (
          <SwiperSlide key={index}>
            <Image
              alt={slide.alt}
              src={slide.image.src}
              style={{
                maxHeight: '90px',
                opacity:
                  activeIndex === index ? 1 : slider?.opacity / 100 || 0.5,
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
});

Slider.displayName = 'Slider';
