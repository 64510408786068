import { Button } from 'theme-ui';

import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaSearch = themed(({ theme, transparentBGEnabled, ...props }) => {
  const [{}, { openSearchSidebar }] = useSidebar();

  return (
    <Button
      data-comp={CtaSearch.displayName}
      onClick={openSearchSidebar}
      {...props}
      variant="buttons.plain"
      aria-label="Open search sidebar"
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Svg
        alt="Open search"
        viewBox="0 0 24 20"
        src="/svgs/cta/search.svg#search"
        sx={{
          ...theme.cta.icon,
          color: transparentBGEnabled ? 'white' : 'black',
        }}
      />
    </Button>
  );
});

CtaSearch.displayName = 'CtaSearch';
