import {
  useState,
  useEffect,
  useCallback,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import { breakpoints } from 'src/theme/theme.breakpoints';

import { usePicture } from './usePicture';
import { PictureSource } from './PictureSource';
import { PictureImage } from './PictureImage';
import { themed } from './Picture.theme';

export const Picture = themed(
  ({
    // inView settings
    aboveTheFold = false,
    alt = 'Image',
    children,
    foreignHover = false, // set it hovered from an external source/element
    hoverImages = [],
    id,
    inputRef,
    images = [{ src: '/svgs/default-image.svg', width: 600, ratio: 1 }],
    imageSx = {},
    rootMargin = '100px',
    theme,
    threshold = 0.01,
    triggerOnce = true,
    variant = null,
    // events
    onError = () => {},
    onInView = () => {},
    onLoad = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...props
  }) => {
    const [picture, hoverPicture] = usePicture({ images, hoverImages });
    const [hovered, setHovered] = useState(foreignHover);

    const haveHoverPicture = hoverPicture?.sources?.length;

    useEffect(() => {
      setHovered(foreignHover);
    }, [foreignHover]);

    const [inViewRef, inView] = useInView({
      rootMargin,
      triggerOnce,
      threshold,
    });

    const setRefs = useCallback(
      (node) => {
        if (inputRef) inputRef.current = node;
        inViewRef(node);
      },
      [inViewRef]
    );

    useEffect(() => {
      onInView(inView);
    }, [inView]);

    return (
      <Box
        data-comp={Picture.displayName}
        id={id}
        {...props}
        ref={setRefs}
        sx={{
          ...theme.wrapper,
          // overwriteable
          ...props.sx,
          variant,
          ':before': {
            content: '""',
            paddingBottom: picture.paddingBottom,
            width: 0,
          },
        }}
        onMouseEnter={() => {
          onMouseEnter();
          setHovered(true);
        }}
        onMouseLeave={() => {
          onMouseLeave();
          setHovered(false);
        }}
      >
        {/* hover <picture> */}
        {haveHoverPicture ? (
          <Box
            data-comp={`${Picture.displayName}PictureHover`}
            as="picture"
            sx={{
              ...theme.hoverImage,
              opacity: hovered ? 1 : 0,
              transition: 'opacity .3s ease-in-out',
            }}
          >
            {/* loop through <source(s)> */}
            {hoverPicture.sources.map((source, index, sources) => {
              const hoverImage = hoverImages?.[index] || null;
              const breakpoint = breakpoints?.[index] || null;
              const breakpointPrev = breakpoints?.[index - 1] || null;
              const isLast = index === sources.length - 1;
              if (!breakpoint || !hoverImage) return null;

              return (
                <PictureSource
                  key={`Hover ${
                    index + (hoverImage?.width || hoverImage?.ratio || '')
                  }`}
                  breakpoint={breakpoint}
                  breakpointPrev={breakpointPrev}
                  width={hoverImage.width}
                  src={source}
                  isLast={isLast}
                />
              );
            })}

            {/* <img /> */}
            <PictureImage
              alt={alt}
              initialSrc={hoverPicture.sources.find(Boolean)} // default to first breakpoint (mobile)
              imageSx={imageSx}
              aboveTheFold
              inView={inView}
            />
          </Box>
        ) : null}

        {/* <picture> */}
        <Box
          data-comp={`${Picture.displayName}Picture`}
          as="picture"
          sx={{
            ...theme.image,
            opacity: hovered && haveHoverPicture ? 0 : 1,
            transition: 'opacity .2s ease-in-out',
          }}
        >
          {/* loop through <source(s)> */}
          {picture.sources.map((source, index, sources) => {
            const image = images?.[index] || null;
            const breakpoint = breakpoints?.[index] || null;
            const breakpointPrev = breakpoints?.[index - 1] || null;
            const isLast = index === sources.length - 1;
            if (!breakpoint || !image) return null;
            return (
              <PictureSource
                key={index + (image?.width || image?.ratio || '')}
                breakpoint={breakpoint}
                breakpointPrev={breakpointPrev}
                width={image.width}
                src={source}
                isLast={isLast}
              />
            );
          })}

          {/* <img /> */}
          <PictureImage
            alt={alt}
            initialSrc={picture.sources.find(Boolean)} // default to first breakpoint (mobile)
            imageSx={imageSx}
            onError={onError}
            onLoad={onLoad}
            aboveTheFold={aboveTheFold}
            inView={inView}
          />
        </Box>

        {/* Render inner elements */}
        {Children.map(children, (child) => {
          if (!isValidElement(child)) return null;
          return cloneElement(child, {
            // Don't change 'relative'
            style: { position: 'relative' },
          });
        })}
      </Box>
    );
  }
);

Picture.displayName = 'Picture';
Picture.propTypes = {
  aboveTheFold: PropTypes.bool,
  alt: PropTypes.string,
  children: PropTypes.node,
  foreignHover: PropTypes.bool,
  hoverImages: PropTypes.arrayOf(
    PropTypes.exact({
      src: PropTypes.string,
      width: PropTypes.number,
      ratio: PropTypes.number,
    })
  ),
  id: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  imageSx: PropTypes.object,
  rootMargin: PropTypes.string,
  sx: PropTypes.object,
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  variant: PropTypes.string,
  onError: PropTypes.func,
  onInView: PropTypes.func,
  onLoad: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
