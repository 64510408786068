export const slider = ({ color }) => ({
  label: 'Slider settings',
  name: 'slider',
  description:
    'Autoplay, delay, transition effect, bullet color, content position, hidden heading',
  component: 'group',
  fields: [
    {
      label: 'Enable autoplay',
      name: 'autoplay',
      description: 'Refresh page to observe change',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'delay',
      component: 'number',
      description: 'Refresh page to observe change',
      label: 'Delay between transitions (ms)',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'effect',
      label: 'Effect between transitions',
      description: 'Refresh page to observe change',
      options: [
        { label: 'Slide', value: 'slide' },
        { label: 'Fade', value: 'fade' },
      ],
    },
    {
      label: 'Enable navigation controls',
      name: 'navigation',
      description: 'Refresh page to observe change',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      component: 'select',
      name: 'activeNavigationColor',
      label: 'Active navigation color',
      options: color.options,
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'contentPosition',
      label: 'Content position (mobile)',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'contentPositionDt',
      label: 'Content position (desktop)',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      name: 'hiddenHeading',
      component: 'text',
      label: 'Hidden Heading',
      description:
        'Creates a hidden heading within the slider section. This will only be added if none of the slides have a heading or subheading.',
    },
  ],
  defaultValue: {
    autoplay: false,
    delay: 8000,
    effect: 'fade',
    navigation: true,
    activeNavigationColor: color.value.white,
    contentPosition: 'absolute',
    contentPositionDt: 'absolute',
  },
});
