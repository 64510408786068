import { Flex, Paragraph } from 'theme-ui';

import { themed } from '../Details/Details.theme';

export const Amount = themed(
  ({
    theme,
    compareAtPriceV2,
    isOnSale,
    isSubscribing,
    priceV2,
    sellingPlan,
    isBundle,
  }) => {
    return (
      <Flex data-comp={Amount.displayName} sx={theme.price}>
        {isSubscribing ? (
          <Flex>
            <Paragraph sx={theme.price.lineThrough}>
              {compareAtPriceV2?.locale ||
                sellingPlan?.priceAdjustments[0]?.compareAtPrice?.locale}
            </Paragraph>
            <Paragraph sx={theme.price.regular}>
              {sellingPlan?.priceAdjustments[0]?.price?.locale}
            </Paragraph>
          </Flex>
        ) : isOnSale ? ( // onetime on sale
          <Flex>
            <Paragraph sx={theme.price.lineThrough}>
              {compareAtPriceV2.locale}
            </Paragraph>
            <Paragraph
              sx={isBundle ? theme.price.regular : theme.price.regularRed}
            >
              {priceV2.locale}
            </Paragraph>
          </Flex>
        ) : (
          // onetime full price
          <Paragraph sx={theme.price.regular}>{priceV2.locale}</Paragraph>
        )}
      </Flex>
    );
  }
);

Amount.displayName = 'Amount';
