import { useSettings } from '@backpackjs/storefront';

export const useSwatchColors = () => {
  const settings = useSettings();
  const swatches = settings?.product?.swatches || [];

  return swatches.reduce((swatchMap, swatch) => {
    swatchMap[swatch.name.toLowerCase()] = swatch;
    return swatchMap;
  }, {});
};
