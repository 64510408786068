import { Flex, Paragraph, Link } from 'theme-ui';

import { withInView } from '@utils';

import { themed } from './Marquee.theme';
import { Schema } from './Marquee.schema';

export const Marquee = withInView(
  themed(({ theme, cms, inView }) => {
    const { background, color, enabled, heightSize, messages } = cms;

    if (!enabled || messages?.length < 1) return null;

    return (
      <Flex
        variant="flex.row.center"
        data-comp={Marquee.displayName}
        sx={theme.wrapper({ background, heightSize })}
      >
        {inView && (
          <>
            {[...Array(4).keys()].map((_, index) => {
              return (
                <Flex
                  key={index}
                  sx={theme.messageTextWrapper({ color, heightSize })}
                >
                  {messages?.map((message, index) => {
                    const { copy, link } = message;
                    if (!copy) return null;
                    return (
                      <Paragraph key={index}>
                        <Link
                          href={link?.url}
                          sx={{
                            textDecoration: link?.url ? 'underline' : '',
                          }}
                        >
                          {message.copy}
                        </Link>
                      </Paragraph>
                    );
                  })}
                </Flex>
              );
            })}
          </>
        )}
      </Flex>
    );
  }),
  { triggerOnce: true }
);

Marquee.displayName = 'Marquee';
Marquee.Schema = Schema;
