import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.video = {
      width: '100%',
      height: '100%',
      position: 'relative',
    };

    this.icon = {
      position: 'absolute',
      left: 10,
      bottom: 10,
      ml: 0,
      width: [150, 200],
    };
  })()
);
