import { useRef } from 'react';
import { Text } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { Link } from '@snippets';
import { useMenuDrawer } from '../MenuDrawer';
import { themed } from './Menu.theme';

export const NavItem = themed(
  ({ theme, item, index, transparentBGEnabled, ...props }) => {
    const ref = useRef();
    const [{ hovered }, { hoverItem, unHoverItem }] = useMenuDrawer();
    const router = useRouter();

    const isHovered = hovered?.link?.text === item?.link?.text;

    return (
      // Level 1 Menu nav item
      <Link
        ref={ref}
        data-comp={NavItem.displayName}
        variant="buttons.plain"
        href={item.link?.url}
        aria-label={item.link?.text}
        onClick={unHoverItem}
        onMouseLeave={unHoverItem}
        onMouseEnter={() => hoverItem(item)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            router.push(item.link?.url);
          }
        }}
        sx={theme.item(transparentBGEnabled)}
      >
        {item.link?.text}

        <Text
          sx={{
            ...theme.item(transparentBGEnabled).hover,
            ...(isHovered
              ? theme.item(transparentBGEnabled).hover.visible
              : theme.item(transparentBGEnabled).hover.hidden),
          }}
        />
      </Link>
    );
  }
);

NavItem.displayName = 'NavItem';
