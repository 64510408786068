import { useMemo } from 'react';
import { Button, Paragraph, Flex, Text, Image } from 'theme-ui';
import store, { useRecoilValue } from '@store';

import { Svg } from '@snippets';
import { useFondueCashback } from '@hooks';
import { useSettings } from '@backpackjs/storefront';

export const CartCashback = () => {
  const { cashbackProduct } = useFondueCashback();
  const cashbackInCart = useRecoilValue(store.cashbackInCart);
  const totalsRegular = useRecoilValue(store.cartTotal);
  const settings = useSettings();

  const cashbackPercentage = cashbackProduct?.handle.split('-')[0];
  const cashBackValue = !Number.isNaN(parseFloat(cashbackPercentage))
    ? Number(cashbackPercentage) / 100
    : null;

  const message =
    settings?.cart?.cashback?.cashbackMessage || 'You get {value} cashback';

  const [beforeValue, afterValue] = message.split('{value}');

  const cashbackTotal = useMemo(() => {
    if (Number(totalsRegular) && cashbackInCart) {
      return (Number(totalsRegular) * cashBackValue)?.toFixed(2);
    }
    return null;
  }, [totalsRegular, cashbackInCart]);

  const image = cashbackProduct?.images[0]?.src || '';
  if (!cashbackInCart || !cashbackTotal) return null;
  if (!settings?.cart?.cashback?.widgetEnabled) return null;
  return (
    <Flex
      variant="flex.row.start.center"
      sx={{
        mb: 9,
        p: 2,
        position: 'relative',
      }}
    >
      <Image
        src={image}
        alt="cashback product "
        sx={{
          height: 40,
          width: 40,
          borderRadius: '50%',
        }}
      />

      <Button
        sx={{
          p: 0,
          m: 0,
          minWidth: 0,
          minHeight: 0,
          position: 'absolute',
          top: 2,
          right: 2,
          backgroundColor: 'transparent',
          border: 'none',
        }}
        onClick={() => console.log('click')}
      >
        <Svg
          alt=""
          src="/svgs/info.svg#info"
          sx={{ height: 14, width: 14, color: 'gold' }}
          viewBox="0 0 14 14"
        />
      </Button>

      <Flex
        variant="flex.column.start.center"
        sx={{
          ml: 8,
          mr: '20px',
        }}
      >
        <Paragraph variant="text.sm" sx={{ color: 'text' }}>
          {beforeValue}
          <Text variant="text.sm" sx={{ color: '#50cca2' }}>
            ${cashbackTotal}
          </Text>
          {afterValue}
        </Paragraph>
      </Flex>
    </Flex>
  );
};

CartCashback.displayName = 'CartCashback';
