import { useState } from 'react';
import { Box, Container, Flex, Grid, Text } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';
import { Timer } from './Timer';
import { FridayProductItem } from './FridayProjectItem';

import { Schema } from './FridayProjects.schema';
import { themed } from './FridayProjects.theme';

export const FridayProjects = withInView(
  themed(({ theme, cms }) => {
    const {
      section,
      liveProducts,
      upcomingProducts,
      archiveProducts,
      badgeText,
      active,
    } = cms;

    const [selected, setSelected] = useState(() => {
      let defaultVal = active || 'live';

      if (typeof window !== 'undefined') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.upcoming !== undefined) defaultVal = 'upcoming';
        if (params.archive !== undefined) defaultVal = 'archive';
      }

      return defaultVal;
    });

    const nextDropTime = cms.nextDropTime || '2023-03-28T00:00:00Z';
    const [dayOfWeek, month, day] = new Date(nextDropTime)
      ?.toDateString()
      ?.split(' ');

    let products = liveProducts?.products || [];
    if (selected === 'upcoming') products = upcomingProducts?.products;
    if (selected === 'archive') products = archiveProducts?.products;

    return (
      <Section section={section}>
        <Container data-comp={FridayProjects.displayName} sx={theme.wrapper}>
          <Flex
            sx={{
              bg: 'white',
              height: '64px',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #E6E6E6',
            }}
          >
            <Grid
              sx={{
                gridTemplateColumns: '1fr 1fr 1fr',
                width: '100%',
                maxWidth: '400px',
                textAlign: 'center',
              }}
            >
              <Box>
                <Text
                  sx={{
                    ...theme.tab,
                    borderBottom:
                      selected === 'live' ? '1px solid black' : null,
                  }}
                  onClick={() => setSelected('live')}
                >
                  Live
                </Text>
              </Box>

              <Box>
                <Text
                  sx={{
                    ...theme.tab,
                    borderBottom:
                      selected === 'upcoming' ? '1px solid black' : null,
                  }}
                  onClick={() => setSelected('upcoming')}
                >
                  Upcoming
                </Text>
              </Box>

              <Box>
                <Text
                  sx={{
                    ...theme.tab,
                    borderBottom:
                      selected === 'archive' ? '1px solid black' : null,
                  }}
                  onClick={() => setSelected('archive')}
                >
                  Archive
                </Text>
              </Box>
            </Grid>
          </Flex>

          <Flex
            sx={{
              bg: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #E6E6E6',
              height: '50px',
            }}
          >
            <Timer nextDropTime={nextDropTime} />
          </Flex>
          <Box>
            <Grid
              sx={{
                gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
                gridGap: 0,
              }}
            >
              {products?.map((product, index) => {
                let productMonth = null;
                let productDay = null;

                if (product.upcomingDate && new Date(product.upcomingDate)) {
                  const dateArray = new Date(product.upcomingDate)
                    ?.toDateString()
                    ?.split(' ');
                  productMonth = dateArray?.[1];
                  productDay = dateArray?.[2];
                }

                return (
                  <FridayProductItem
                    key={`${selected}-${index}`}
                    {...{
                      selected,
                      handle: product.product?.handle,
                      image: product.image,
                      imageHover: product.imageHover,
                      title: product.title,
                      subTitle: product.subTitle,
                      price: product.price,
                      soldOut: product.soldOut,
                      showBadge: product.showBadge,
                      upcomingDate: product.upcomingDate,
                      link: product.link,
                      month: productMonth || month,
                      day: productDay || day,
                      badgeText,
                    }}
                  />
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FridayProjects.displayName = 'FridayProjects';
FridayProjects.Schema = Schema;
