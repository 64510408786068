import { useState, useCallback } from 'react';
import { Flex, Box } from 'theme-ui';
import Link from 'next/link.js';

import { useProductByHandle } from '@backpackjs/storefront';
import { Picture, BackInStockModal } from '@snippets';
import { useModal } from '@hooks';
import { themed } from './FridayProjects.theme';

const LiveItem = themed(
  ({
    theme,
    handle,
    link,
    image,
    imageHover,
    title,
    subTitle,
    price,
    showBadge = true,
    soldOut = false,
    badgeText,
  }) => {
    return (
      <Box>
        {showBadge && (
          <Box sx={theme.badge}>{badgeText || 'FRIDAY PROJECTS'}</Box>
        )}
        <Link href={link?.url || `/products/${handle}`}>
          <Box sx={{ cursor: 'pointer' }}>
            <Media image={image?.src} imageHover={imageHover?.src} />
            <Details {...{ subTitle, title, price, soldOut }} />
          </Box>
        </Link>
      </Box>
    );
  }
);

const UpcomingItem = themed(
  ({
    theme,
    image,
    imageHover,
    title,
    subTitle,
    price,
    showBadge = true,
    soldOut = false,
    month,
    day,
    badgeText,
  }) => {
    return (
      <Box>
        {showBadge && (
          <Box sx={theme.badge}>{badgeText || 'FRIDAY PROJECTS'}</Box>
        )}
        <Box sx={theme.date}>
          <Box>
            {month || 'APR'}
            <Box sx={theme.day}>{day || '7'}</Box>
          </Box>
        </Box>

        <Box>
          <Media image={image?.src} imageHover={imageHover?.src} />
          <Details {...{ subTitle, title, price, soldOut }} />
        </Box>
      </Box>
    );
  }
);

const ArchiveItem = themed(
  ({
    theme,
    selected,
    handle,
    image,
    imageHover,
    title,
    subTitle,
    price,
    showBadge = true,
    soldOut = false,
    month,
    day,
    badgeText,
  }) => {
    if (!handle) return null;

    const { product: fullProduct } = useProductByHandle({ handle: handle });
    const selectedVariant = fullProduct?.variants?.[0];

    const { openModal } = useModal()[1];

    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          title={selectedVariant?.product?.title || title}
        />
      );
    }, [selectedVariant?.id]);

    return (
      <Box>
        {showBadge && (
          <Box sx={theme.badge}>{badgeText || 'FRIDAY PROJECTS'}</Box>
        )}

        <Box>
          <Flex sx={{ justifyContent: 'center' }}>
            <Box sx={theme.notify} onClick={handleOutOfStock}>
              NOTIFY ME WHEN AVAILABLE
            </Box>
          </Flex>
          <Media image={image?.src} imageHover={imageHover?.src} />
          <Details {...{ subTitle, title, price, soldOut: true }} />
        </Box>
      </Box>
    );
  }
);

export const FridayProductItem = themed(({ theme, selected, ...props }) => {
  const isLive = selected == 'live';
  const isUpcoming = selected == 'upcoming';
  const isArchive = selected == 'archive';

  return (
    <Box
      data-comp={FridayProductItem.displayName}
      sx={{
        ...theme.itemWrapper,
        ...props.sx,
      }}
    >
      {isLive && <LiveItem {...props} />}
      {isUpcoming && <UpcomingItem {...props} />}
      {isArchive && <ArchiveItem {...props} />}
    </Box>
  );
});

FridayProductItem.displayName = 'FridayProductItem';

const Media = themed(({ theme, image, imageHover }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          ...theme.mediaWrapper,
          ':before': {
            content: '""',
            paddingBottom: `100%`,
            width: 0,
          },
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        {/* hover media */}
        {imageHover ? (
          <Box
            sx={{
              ...theme.hoverMedia,
              opacity: hovered ? 1 : 0,
              transition: 'opacity .3s ease-in-out',
            }}
          >
            <Picture
              alt={'hover image'}
              images={[
                {
                  src: imageHover,
                  width: '100%',
                  ratio: 1,
                },
              ]}
            ></Picture>
          </Box>
        ) : null}

        {/* media */}
        <Box
          sx={{
            ...theme.media,
            opacity: hovered && imageHover ? 0 : 1,
            transition: 'opacity .2s ease-in-out',
          }}
        >
          <Picture
            alt={'hover image'}
            images={[
              {
                src: image,
                width: '100%',
                ratio: 1,
              },
            ]}
          ></Picture>
        </Box>
      </Box>
    </Box>
  );
});

const Details = ({ subTitle, title, price, soldOut }) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ['8px 15px 15px 15px', '16px 24px 24px 24px'],
      fontFamily: 'Fragment Mono !important',
      textTransform: 'uppercase',
    }}
  >
    <Box>
      <Box sx={{ color: '#727272', fontSize: ['10px', '11px'] }}>
        {subTitle}
      </Box>
      <Box sx={{ color: 'black', fontSize: ['10px', '14px'] }}>{title}</Box>
    </Box>
    <Flex sx={{ alignItems: 'center' }}>
      {soldOut ? (
        <Box
          sx={{
            color: 'black',
            bg: '#F2F2F2',
            fontSize: ['10px', '14px'],
            px: '8px',
            px: '4px',
            borderRadius: '2px',
          }}
        >
          SOLDOUT
        </Box>
      ) : (
        <>
          <Box sx={{ width: '12px', height: '1px', bg: 'black', mr: 5 }}></Box>
          <Box sx={{ color: 'black', fontSize: ['10px', '14px'] }}>
            ${price}
          </Box>
        </>
      )}
    </Flex>
  </Flex>
);
