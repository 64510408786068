import PropTypes from 'prop-types';
import { Input, Paragraph, Flex, Button } from 'theme-ui';
import {
  useCartUpdateItem,
  useCartRemoveItem,
  useCartUpdatedAt,
} from '@backpackjs/storefront';

import { Svg } from '@snippets';

import { themed } from './Quantity.theme';

export const Quantity = themed(
  ({
    theme: themes,
    inSidebar,
    id,
    quantity,
    quantityDisabled,
    ariaTitle,
    isPreOrder,
    maxQuantity = 5,
    ...props
  }) => {
    const { cartUpdateItem } = useCartUpdateItem({ autoReset: 100 });
    const { cartRemoveItem } = useCartRemoveItem({ autoReset: 100 });
    const cartUpdatedAt = useCartUpdatedAt();

    // Apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    const handleDecreaseQuantity = async () => {
      const newQuantity = quantity - 1;
      if (newQuantity > 0) {
        await cartUpdateItem({ lineId: id, quantity: newQuantity });
      } else {
        await cartRemoveItem({ lineId: id });
      }
    };

    const handleIncreaseQuantity = async () => {
      const newQuantity = isPreOrder
        ? Math.min(quantity + 1, maxQuantity)
        : quantity + 1;
      await cartUpdateItem({ lineId: id, quantity: newQuantity });
    };

    const handleBlur = async (event) => {
      const typedQuantity = parseInt(event.target.value, 10);
      const newQuantity = isPreOrder
        ? Math.min(typedQuantity, maxQuantity)
        : typedQuantity;
      if (newQuantity > 0) {
        await cartUpdateItem({ lineId: id, quantity: newQuantity });
      } else {
        await cartRemoveItem({ lineId: id });
      }
    };

    return !id ? (
      'Loading...'
    ) : (
      <Flex data-comp={Quantity.displayName} {...props} sx={theme.quantity}>
        <Flex
          sx={{
            ...theme.quantity.item,
            visibility: quantityDisabled ? 'hidden' : 'visible',
          }}
        >
          <Button
            aria-label={`Decrease quantity of ${ariaTitle} by 1`}
            sx={{
              variant: 'buttons.plain',
              py: '0 !important',
              height: '100%',
            }}
            onClick={handleDecreaseQuantity}
          >
            <Svg
              data-comp="QuantityReduce"
              viewBox="0 0 48 48"
              alt="Reduce line item quantity"
              src="/svgs/minus.svg#minus"
              sx={theme.quantity.item.icon}
            />
          </Button>
        </Flex>

        {/* Changing key on update ensures re-renders on changes */}
        <Paragraph
          key={cartUpdatedAt}
          as={Input}
          type="number"
          min="1"
          max={isPreOrder ? maxQuantity : undefined}
          name="quantity"
          tabIndex="-1"
          defaultValue={quantity}
          disabled={quantityDisabled}
          onBlur={handleBlur}
          sx={theme.quantity.item.input}
        />

        <Flex
          sx={{
            ...theme.quantity.item,
            visibility: quantityDisabled ? 'hidden' : 'visible',
          }}
        >
          <Button
            aria-label={`Increase quantity of ${ariaTitle} by 1`}
            sx={{
              variant: 'buttons.plain',
              py: '0 !important',
              height: '100%',
            }}
            onClick={handleIncreaseQuantity}
          >
            <Svg
              data-comp="QuantityIncrease"
              viewBox="0 0 48 48"
              alt="Increase line item quantity"
              src="/svgs/plus.svg#plus"
              sx={theme.quantity.item.icon}
            />
          </Button>
        </Flex>
      </Flex>
    );
  }
);

Quantity.displayName = 'Quantity';
Quantity.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
