import { forwardRef, cloneElement } from 'react';

export const create = (theme) => (FnComp) => {
  return forwardRef((compProps, ref) => {
    return cloneElement(<FnComp />, {
      ...compProps,
      theme,
      ...(ref ? { inputRef: ref } : null),
    });
  });
};
