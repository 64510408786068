import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Flex, Paragraph, Link } from 'theme-ui';

import { themed } from './RotatingBar.theme';

export const RotatingBar = themed(({ theme, inputRef }) => {
  const settings = useSettings();
  const { rotatingBar } = { ...settings?.header };
  const { background, color, enabled, heightSize, isSticky, messages } = {
    ...rotatingBar,
  };

  const topPosition = useMemo(() => {
    let pos = null;
    if (isSticky) {
      pos = 0;
    } else {
      pos = 'unset';
    }
    return pos;
  }, [isSticky]);

  if (!enabled || messages?.length < 1) return null;

  return (
    <Flex
      variant="flex.row.center"
      data-comp={RotatingBar.displayName}
      sx={{
        ...theme.wrapper({ background, heightSize }),
        zIndex: 32,
        position: isSticky ? 'sticky' : 'relative',
        top: topPosition,
        left: isSticky ? 0 : 'unset',
      }}
      ref={inputRef}
    >
      {[...Array(4).keys()].map((_, index) => {
        return (
          <Flex
            key={index}
            sx={theme.messageTextWrapper({ color, heightSize })}
          >
            {messages?.map((message, index) => {
              const { copy, link } = message;
              if (!copy) return null;
              return (
                <Paragraph key={index}>
                  <Link
                    href={link?.url}
                    sx={{
                      textDecoration: link?.url ? 'underline' : '',
                    }}
                  >
                    {message.copy}
                  </Link>
                </Paragraph>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
});

RotatingBar.displayName = 'RotatingBar';
