import { Flex, Text } from 'theme-ui';

import { Link } from '@snippets';

import { themed } from './Details.theme';
import { ContentIcons } from '../ContentIcons/ContentIcons';

export const Details = themed(({ theme, product, showTitle, showContent }) => {
  return (
    <Flex data-comp={Details.displayName} sx={theme.wrapper}>
      {/* Text */}
      {showTitle && <Text sx={theme.title}>{product?.title}</Text>}
      {showContent && <ContentIcons icons={product?.icons} />}
    </Flex>
  );
});

Details.displayName = 'Details';
