import { useEffect, useCallback } from 'react';
import store, { useRecoilValueLoadable } from '@store';

import { useProduct } from '@backpackjs/storefront';

const defaultProps = {
  legacyResourceId: null,
  perPage: 5,
  page: 1,
  free_text_search: '',
  topic_names: null,
  scores: null,
};

export const useYotpoSearchReviews = ({
  legacyResourceId: passedId,
  perPage,
  page,
  ...params
} = defaultProps) => {
  const product = useProduct();
  const legacyResourceId = passedId || product?.legacyResourceId;

  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoSearchReviews$({
      legacyResourceId,
      per_page: perPage || 5,
      page: page || 1,
      ...params,
    })
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    ...(state === 'hasValue' && contents
      ? {
        ...contents,
      }
      : null),
    page,
  };
};
