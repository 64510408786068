import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      bg: 'lightestGray',
      py: [16, 18],
    };
    this.container = {
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
      gridRowGap: ['36px'],
      gridGap: ['36px'],
      justifyContent: 'space-between',
      maxWidth: '1360px',
      margin: '0 auto',
      px: [10, 12, 14],
    };
    this.title = {
      variant: 'text.h4',
      display: 'flex',
      justifyContent: 'center',
      mb: 15,
    };
    this.item = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flex: ['50%', null, 1],
      color: 'text',
    };
    this.icon = {
      width: ['100px', '140px'],
      height: ['100px', '140px'],
      mb: [8, 10],
    };
    this.itemHeading = {
      variant: 'text.h5',
      mb: '10px',
    };
    this.itemText = {
      variant: 'text.base',
    };
  })()
);
