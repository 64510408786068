import { Markdown } from '@snippets';

import { themed } from './Promo.theme';

export const PromoBarMessageCopy = themed(({ theme, copy, align }) => {
  return (
    <Markdown
      text={copy}
      textSx={{
        ...theme.message.copy.html,
        mb: 0,
      }}
      sx={{
        textAlign: align,
        mb: 0,
      }}
    />
  );
});

PromoBarMessageCopy.displayName = 'PromoBarMessageCopy';
