import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import store, { useRecoilState } from '@store';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';

import { ProductItem } from '@snippets';

import { Navigation } from './Navigation';
import { useProductSwiperParams } from './useProductSwiperParams';
import { themed } from './ProductSwiper.theme';

import 'swiper/css/bundle';

export const ProductSwiper = themed(
  ({
    theme,
    products,
    disableProductItemMobileSwiper,
    singleSlideOnMobile,
    params = {},
    isProductRecs,
    children,
    ...props
  }) => {
    const defaultParams = useProductSwiperParams({
      isProductRecs,
      singleSlideOnMobile,
    });
    const [swiper, setSwiper] = useState();
    const [invertModelPictures, setInvertModelPictures] = useRecoilState(
      store.invertModelPictures
    );

    return (
      <Box
        data-comp={ProductSwiper.displayName}
        role="list"
        {...props}
        sx={{
          ...theme.swiper,
          ...props.sx,
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          {...defaultParams}
          {...params}
          modules={[A11y]}
          a11y={{ enabled: false }} // TODO: this module enables autofocus on swiper causing a glitch on quick add to cart
        >
          {products?.map((item, index) => {
            const product = isProductRecs ? item : item.product;
            if (!product?.handle) return null;
            return (
              <SwiperSlide key={product.handle + index}>
                <ProductItem
                  disableProductItemMobileSwiper={
                    disableProductItemMobileSwiper
                  }
                  product={product}
                  showBadges={item?.showBadge}
                  badgeVariant={item?.badgeStyle}
                  invertImages={invertModelPictures}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {products?.length > 0 && <Navigation swiper={swiper} />}
      </Box>
    );
  }
);

ProductSwiper.displayName = 'ProductSwiper';
ProductSwiper.propTypes = {
  products: PropTypes.array,
  params: PropTypes.object,
  isProductRecs: PropTypes.bool,
};
