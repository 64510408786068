import { button } from '@settings/common';

const infoBlock = {
  label: 'Info block',
  name: 'info',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'We make returns and exchanges easy.',
    },
    {
      label: 'Sub heading',
      name: 'subHeading',
      component: 'text',
      defaultValue: '',
    },
    {
      label: 'text',
      name: 'text',
      component: 'markdown',
      defaultValue: `
        Returns and exchanges must be made within 20 days from the delivery date
        All items must be unused, unwashed, and returned in their barcoded individual black bag
        Shipping costs are non-refundable
        Refunds are issued to your original form of payment only
        All accessory items, including masks, are final sale and therefore cannot be returned or exchanged.
        With over 4,600 drop-off locations nationwide, we partner with Happy Returns for a safe and seamless experience. 
        Simply start your return by clicking "Start A Return" to receive a QR code and swing by the Returns Bar closest to you.
      `,
    },
    {
      label: 'Link',
      name: 'cta',
      component: 'link',
    },
    {
      name: 'ctaStyle',
      label: 'Link Style',
      component: 'select',
      options: button.options,
    },
  ],
  defaultValue: {
    heading: 'We make returns and exchanges easy.',
    subheading: 'Standard Return Policy',
    cta: {
      text: 'Start A Return',
      url: 'https://cutsclothing.happyreturns.com/',
    },
  },
};

const bottomBlock = {
  label: 'Bottom block',
  name: 'bottom',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Sub heading',
      name: 'subHeading',
      component: 'text',
    },
    {
      label: 'Link',
      name: 'cta',
      component: 'link',
    },
    {
      name: 'ctaStyle',
      label: 'Link Style',
      component: 'select',
      options: button.options,
    },
  ],
  defaultValue: {
    heading: 'Have more questions or an international order?',
    subheading: "Contact us directly and we'll get you sorted",
    cta: {
      text: 'Contact us',
      url: 'https://cutsclothing.kustomer.help/contact/contact-us-HkIWrZkZ_',
    },
  },
};

const iconsBlock = {
  label: 'Icons block',
  name: 'iconsBlock',
  component: 'group',
  fields: [
    {
      label: 'Icons',
      name: 'icons',
      component: 'group-list',
      itemProps: {
        label: '{{item.text}}',
      },
      defaultItem: {
        icon: 'avatar',
        text: 'new icon text',
      },
      fields: [
        {
          name: 'icon',
          component: 'select',
          label: 'Assurance item icon',
          options: [
            {
              value: 'avatar',
              label: 'Avatar',
            },
            {
              value: 'map-marker',
              label: 'Map marker',
            },
            {
              value: 'heart-message',
              label: 'Heart message',
            },
          ],
        },
        {
          label: 'Icon text',
          name: 'text',
          component: 'text',
        },
      ],
      defaultValue: [
        {
          icon: 'avatar',
          name: 'Avatar',
          text: 'Free In Person',
        },
        {
          icon: 'map-marker',
          name: 'Map marker',
          text: 'Over 2,600 drop off locations',
        },
        {
          icon: 'heart-message',
          name: 'Heart message',
          text: 'Easy. No Hassle',
        },
      ],
    },
  ],
};

export const Schema = ({ page }) => {
  if (page?.handle !== 'returns') return null;
  return {
    label: 'Returns',
    key: 'returns',
    fields: [
      {
        label: 'Hero Title',
        name: 'heroTitle',
        component: 'text',
        defaultValue: 'Returns & Exchanges',
      },
      infoBlock,
      iconsBlock,
      bottomBlock,
    ],
  };
};
