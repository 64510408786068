import React from 'react';
import { themed } from './NewArrivals.theme';
import { withInView, convertAlignToFlex } from '@utils';
import { Section, Picture, Link, ProductItem } from '@snippets';
import { Schema } from './NewArrivals.schema';
import { Box, Container, Flex, Grid } from 'theme-ui';

export const NewArrivals = withInView(
  themed(({ theme, cms }) => {
    const {
      heading,
      image: { image, alt, button },
      products,
      section,
    } = cms;
    return (
      <Section section={section}>
        <Container sx={theme.container}>
          <Box sx={theme.top}>{heading}</Box>
          <Flex sx={theme.content}>
            <Box sx={theme.imageWrap}>
              <Picture
                alt={alt}
                images={[
                  {
                    src: image.src,
                    ratio: 1,
                  },
                ]}
                sx={theme.image}
              />
              <Flex
                sx={{
                  ...theme.linkWrap,
                  justifyContent: [
                    convertAlignToFlex(button?.alignment),
                    convertAlignToFlex(button?.alignmentDt),
                  ],
                }}
              >
                <Link
                  variant={`buttons.${button.style || 'primary'}`}
                  href={button.cta.url}
                  sx={theme.link}
                >
                  {button.cta.text}
                </Link>
              </Flex>
            </Box>
            <Grid sx={theme.products}>
              {products.map(({ product }) => (
                <ProductItem key={product.handle} product={product} />
              ))}
            </Grid>
          </Flex>
        </Container>
      </Section>
    );
  })
);

NewArrivals.displayName = 'NewArrivals';
NewArrivals.Schema = Schema;
