import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      position: 'absolute',
      bottom: '-5px',
      left: 0,
      right: 0,
      py: 6,
      width: 'auto',
      backgroundColor: 'text',
      color: 'background',
      display: 'flex',
      opacity: 0,
      transition: ' opacity 300ms ease 0s',
      '@media screen and (min-width: 480px)': {
        ':hover': { opacity: 1.0 },
      },
    };

    this.firstText = {
      margin: 0,
      minWidth: 0,
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '2px',
      textAlign: 'center',
    };

    this.sizeContainer = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.0,
      border: `1px solid ${colors.gray.mediumLight}`,
      transition: 'opacity 300ms',
      '@media screen and (min-width: 480px)': {
        ':hover': { opacity: 1.0 },
      },
    };

    this.button = (isLast, isDisabled) => ({
      minWidth: 0,
      margin: '0px',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 1,
      fontSize: '10px',
      letterSpacing: '1px',
      borderRadius: 0,
      transition: 'color 0s ease 0s, background-color 300ms ease 0s;',
      px: 2,
      flex: 1,
      cursor: 'pointer',
      border: 0,
      color: isDisabled && colors.white,
      bg: isDisabled && colors.gray.mediumLight,
      borderRight: isLast ? 0 : `1px solid ${colors.gray.mediumLight}`,
      '@media screen and (min-width: 480px)': {
        '&[disabled]': {
          ':hover': {
            cursor: 'not-allowed',
            bg: colors.gray.mediumLight,
          },
        },
      },
    });
  })()
);
