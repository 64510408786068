import { useCallback, useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { useCountry } from '@backpackjs/storefront';

import { useDataLayerActions, useLocalizedProducts } from '@hooks';
import { fetchProductsFromHandles } from '@utils';

import { Badge } from './Badge';
import { Image } from './Image';
import { Details } from './Details';
import { themed } from './KitItem.theme';

export const KitItem = themed(({ theme, kit, index, ...props }) => {
  const { getLocalizedProductsWithFirstVariant } = useLocalizedProducts();
  const { sendClickProductBundleItemEvent } = useDataLayerActions();
  const { country } = useCountry();
  const countryCode = country?.isoCode;

  const [status, setStatus] = useState(null);
  const [localizedProducts, setLocalizedProducts] = useState(null);

  const {
    handle,
    title,
    price,
    compareAtPrice,
    dateTimes,
    image,
    qty,
    products,
  } = kit;

  const getLocalizedProducts = useCallback(
    async ({ countryCode: _countryCode, products: _products }) => {
      const fullProducts = await fetchProductsFromHandles(
        _products.slice(0, 12).map((p) => p?.product?.handle)
      );
      const _localizedProducts = await getLocalizedProductsWithFirstVariant({
        products: fullProducts,
        countryCode: _countryCode,
      });
      setLocalizedProducts(_localizedProducts);
    },
    []
  );

  const handleClick = useCallback(() => {
    sendClickProductBundleItemEvent({
      listIndex: index,
      localizedProducts,
    });
  }, [index, localizedProducts]);

  useEffect(() => {
    if (!products?.length || !countryCode) return;
    getLocalizedProducts({ countryCode, products });
  }, [products, countryCode]);

  return (
    <Box data-comp={KitItem.displayName} {...props} sx={theme.wrapper}>
      <Badge status={status} setStatus={setStatus} dateTimes={dateTimes} />

      <Image
        src={image?.src}
        title={title}
        handle={handle}
        status={status}
        handleClick={handleClick}
      />

      <Details
        title={title}
        price={price}
        compareAtPrice={compareAtPrice}
        qty={qty}
        handle={handle}
        handleClick={handleClick}
      />
    </Box>
  );
});

KitItem.displayName = 'KitItem';
