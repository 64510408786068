import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import LinkNext from 'next/link';
import { Box, Link as LinkUI } from 'theme-ui';
import { useModal } from '@hooks';
import { MembershipPopup } from '@snippets';
import { YouTubeVideoPopup } from '../popups/YouTubeVideoPopup';

export const Link = forwardRef(
  (
    {
      href,
      newTab = false,
      hiddenForSeo = false,
      prefetch = false,
      children,
      query,
      sx,
      ...restProps
    },
    ref
  ) => {
    const { closeModal, openModal } = useModal()[1];

    const isMembershipPopupBtn = href?.includes('#membership-popup');
    const isYoutubeVideo = href?.includes('youtube');

    const onClickPopupBtn = (event) => {
      event.preventDefault();
      openModal(
        isYoutubeVideo ? (
          <YouTubeVideoPopup closeModal={closeModal} youtubeLink={href} />
        ) : (
          <MembershipPopup closeModal={closeModal} />
        )
      );
    };

    if (isMembershipPopupBtn || isYoutubeVideo) {
      return (
        <LinkUI
          onClick={onClickPopupBtn}
          data-comp="Link-membership"
          ref={ref}
          sx={{
            display: 'block',
            width: 'auto',
            height: 'auto',
            fontFamily: 'body',
            ...sx,
          }}
          {...restProps}
        >
          {children}
        </LinkUI>
      );
    }

    if (hiddenForSeo) {
      return (
        <LinkUI
          data-comp="Link-Hidden"
          href={href}
          ref={ref}
          sx={{ display: 'none' }}
        />
      );
    }

    if (!href) {
      return (
        <Box
          data-comp="Link-NoHref"
          ref={ref}
          sx={{
            cursor: 'default',
            fontFamily: 'body',
            ...sx,
          }}
          {...restProps}
        >
          {children}
        </Box>
      );
    }

    return (
      <LinkNext
        href={typeof query === 'object' ? { pathname: href, query } : href}
        prefetch={prefetch}
        passHref
      >
        <LinkUI
          data-comp={newTab ? 'Link-External' : 'Link-Internal'}
          ref={ref}
          sx={{
            display: 'block',
            width: 'auto',
            height: 'auto',
            fontFamily: 'body',
            ...sx,
          }}
          target={newTab ? '_blank' : null}
          {...restProps}
        >
          {children}
        </LinkUI>
      </LinkNext>
    );
  }
);

Link.propTypes = {
  href: PropTypes.string,
  newTab: PropTypes.bool,
  hiddenForSeo: PropTypes.bool,
  prefetch: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  query: PropTypes.object,
  sx: PropTypes.object,
};
