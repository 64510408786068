import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.pdp',
      gridTemplateColumns: ['1fr', null, 'auto 524px'],
      columnGap: [0, null, '60px', '80px'],
      pt: [10, 11, 14],
      width: '100%',
      mb: [8, null, 10, 12],

      metadata: {
        maxWidth: '100%',

        title: {
          text: 'text.h2',
        },

        price: {
          text: 'text.h2',
        },

        description: {
          mt: 3,
          lineHeight: '18px',
        },
      },

      ctas: {
        variant: 'flex.column.start',
      },
    };
  })()
);
