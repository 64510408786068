import { useMemo } from 'react';
import { shopifySrcFilter } from '@utils';

export const usePicture = ({ images = [], hoverImages = [] }) => {
  const refresh = [...images, ...hoverImages]
    .map(
      (image, index) =>
        index + image?.src + image?.ratio + image?.width + image?.height
    )
    .join(',');

  return useMemo(() => {
    // replace nulls images with the previous image value in the array
    const fullImages = images.map(nullValuesToPrevious);
    const picture = fullImages.reduce(imagesToPaddingAndSources, {
      paddingBottom: [],
      sources: [],
      lastUniqueRatio: null,
    });

    const fullHoverImages = hoverImages.map(nullValuesToPrevious);
    const hoverPicture = fullHoverImages.reduce(imagesToPaddingAndSources, {
      paddingBottom: [],
      sources: [],
      lastUniqueRatio: null,
    });

    return [picture, hoverPicture];
  }, [refresh, images, hoverImages]);
};

/* Helpers */
/**
 * Converts a width/height ratio to a paddingBottom %
 * @param {float} decimal width/height ratio value
 * @returns percentage of bottom padding for a given ratio
 */
const getPaddingFromRatio = (decimal) => {
  for (var denominator = 1; (decimal * denominator) % 1 !== 0; denominator++);
  const bottomPadding = (denominator / (decimal * denominator)) * 100;
  return `${bottomPadding}%`;
};

/**
 * Returns the current image if it not null, otherwise return the previous
 * @param {imageObject} image — e.g { src: '..', width: 123, ratio: 0.8 }
 * @param {*} index
 * @param {*} images
 * @returns
 */
const nullValuesToPrevious = (image, index, _images) => {
  const previous = _images[index - 1];
  return image ?? previous;
};

const imagesToPaddingAndSources = (_picture, image, index, _images) => {
  const isNull = image === null; // null is allowed just like inside sx
  const isDefined = typeof image !== 'undefined';
  const isFirst = index === 0;
  const imageSrc = image?.src || '/svgs/default-image.svg';

  // Validate props
  if (isDefined && !isNull) {
    if (!image?.ratio)
      image.ratio = 1;
    // throw new Error(`Image #${index + 1} "ratio" not provided`);
    if (typeof image.ratio === 'string') {
      if (image.ratio.includes('.')) {
        image.ratio = parseFloat(image.ratio);
      } else {
        image.ratio = parseInt(image.ratio);
      }
    }
    if (!isInt(image.ratio) && !isFloat(image.ratio)) {
      throw new Error(
        `Image #${index + 1
        } "ratio" must be in decimal (width/height) format: ${image.ratio
        } ${typeof image.ratio}`
      );
    }
    if (!isInt(image?.width) && !isInt(image?.height)) {
      image.width = 768; // default width if not provided
    }
  }

  // convert width/height provided ratio to padding bottom
  const prevRatio = isFirst ? null : _picture.paddingBottom[index - 1];

  const currentRatio = image?.ratio ? getPaddingFromRatio(image.ratio) : null;

  const isSameAsLastUniqueRatio =
    prevRatio === currentRatio ||
    _picture.lastUniqueRatio === currentRatio ||
    currentRatio === null;

  if (isSameAsLastUniqueRatio) {
    _picture.paddingBottom.push(null);
  } else {
    _picture.lastUniqueRatio = currentRatio;
    _picture.paddingBottom.push(currentRatio);
  }

  // Crop the image source fo the given bp
  const isShopifySrc = imageSrc
    ? /^https\:\/\/cdn\.shopify\.com/.test(image.src)
    : false;

  let bpSrc = isShopifySrc ? shopifySrcFilter(image.src, image) : imageSrc;

  const bpSrcUrl = imageSrc && (image?.width || image?.height) ? bpSrc : null;

  // array of width-cropped sources (for all breakpoints)
  _picture.sources.push(bpSrcUrl);

  return _picture;
};

const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};
