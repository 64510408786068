export const box = {
  // <Box variant="box.mobile" />
  mobile: {
    display: ['block', null, null, null, 'none'],
    1: {
      display: ['inline-block', null, null, null, 'none'],
    },
  },
  // <Box variant="box.desktop" />
  desktop: {
    display: ['none', null, null, null, 'block'],
    1: {
      display: ['none', null, null, null, 'inline-block'],
    },
  },
  // <Box variant="box.absolute-fill" />
  fill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
