import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      px: [0, 18],
      position: 'relative',
    };
    this.picture = {
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      picture: {
        '@media only screen and (max-width: 767px)': {
          aspectRatio: '0.8',
        },
        '@media only screen and (max-width: 620px)': {
          aspectRatio: '0.6',
        },
        '@media only screen and (max-width: 480px)': {
          aspectRatio: '0.4',
        },
      },
    };
    this.image = {
      objectPosition: 'center top',
      objectFit: 'cover',
    };
    this.info = {
      position: 'relative',
      zIndex: 3,
      variant: 'flex.column.center.center',
      padding: ['24px 20px 44px', 0],
    };
    this.heading = {
      variant: 'text.h4',
      mb: '16px',
    };
    this.subheading = {
      fontSize: ['200px', '400px'],
      mb: '32px',
      fontWeight: '700',
      lineHeight: ['200px', '400px'],
      letterSpacing: '-0.04em',
    };
    this.link = {
      width: 'fit-content',
    };
  })()
);
