import { Container } from 'theme-ui';
import { Section } from '@snippets';

import { Header } from './Header';

import { Schema } from './SuperCollection.schema';
import { Content } from './Content';

export const SuperCollection = ({ cms }) => {
  const { section, content, products: allProducts, media } = cms;

  const { products, cta, showButton } = allProducts;

  return (
    <Section section={section}>
      <Container>
        <Header header={content} section={section} />
        <Content
          products={products}
          cta={cta}
          showButton={showButton}
          media={media}
        />
      </Container>
    </Section>
  );
};

SuperCollection.displayName = 'SuperCollection';
SuperCollection.Schema = Schema;
