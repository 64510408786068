export const settings = ({ align }) => ({
  label: 'Tile settings',
  name: 'tileSettings',
  description: 'Image ratio, text position, text align, link style',
  component: 'group',
  fields: [
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'imageRatio',
      label: 'Image aspect ratio (mobile)',
      options: [
        { label: '3:4', value: 'portrait' },
        { label: '1:1', value: 'square' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'imageRatioDt',
      label: 'Image aspect ratio (desktop)',
      options: [
        { label: '3:4', value: 'portrait' },
        { label: '1:1', value: 'square' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'overlay',
      label: 'Image overlay',
      options: [
        { label: 'None', value: 'none' },
        { label: 'Dark', value: 'dark' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'textPosition',
      label: 'Text position',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'textAlign',
      label: 'Text alignment',
      options: align.options,
    },
  ],
  defaultValue: {
    imageRatio: 'square',
    imageRatioDt: 'square',
    overlay: 'none',
    textPosition: 'absolute',
    textAlign: align.value.left,
  },
});
