import { section, button, color, align } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Product recs',
    key: 'product-recs',
    fields: [
      {
        name: 'tag',
        label: 'Searchspring Product Recs Tag (DEPRECATED)',
        component: 'text',
        description:
          'The product recommendations profile tag taken from Searchspring admin, e.g. "customers-also-bought", "home", "recently-viewed"',
        defaultValue: 'customers-also-bought',
      },
      {
        name: 'tags',
        label: 'Searchspring Product Recs Tag',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            name: 'title',
            label: 'Title',
            component: 'text',
          },
          {
            name: 'tag',
            label: 'Tag',
            component: 'text',
            defaultValue: 'customers-also-bought',
          },
        ],
        description:
          'The product recommendations profile tag taken from Searchspring admin, e.g. "customers-also-bought", "home", "recently-viewed"',
      },
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'You may also like',
      },
      {
        name: 'textAlign',
        label: 'Heading align',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: align.value.center,
      },
      {
        name: 'limit',
        label: 'Number of recommendations',
        component: 'number',
        defaultValue: 8,
      },
      {
        name: 'slidesPerViewMobile',
        label: 'Slides Per View (mobile)',
        description: 'Change between 1 and 2 slides per view on mobile layout.',
        component: 'radio-group',
        direction: 'horizontal',
        options: [
          { label: '1', value: 'one' },
          { label: '2', value: 'two' },
        ],
        defaultValue: 'one',
      },
      {
        name: 'ctas',
        label: 'Button',
        component: 'group-list',
        description: 'Max of 1 button',
        itemProps: {
          label: '{{item.cta.text}}',
        },
        validate: {
          maxItems: 1,
        },
        defaultItem: {
          cta: {
            text: 'Shop All Products',
            url: '/collections/all',
          },
          style: button.value.primary,
        },
        fields: [
          {
            name: 'cta',
            component: 'link',
            label: 'Button',
          },
          {
            name: 'style',
            label: 'Button style',
            component: 'select',
            options: button.options,
          },
          {
            name: 'align',
            label: 'Button align',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
            defaultValue: align.value.center,
          },
        ],
        defaultValue: [
          {
            cta: {
              text: 'Shop All Products',
              url: '/collections/all',
            },
            style: button.value.primary,
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
