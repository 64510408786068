import { gsap } from 'gsap';

const DEBUG = false;
/**
 * Promobar
 */
const closePromoBar = (callback = () => {}) => {
  gsap
    .timeline()
    // fade out copy upwards
    .to('[data-comp="PromoBarMessageCopy"]', {
      y: '-100%',
      alpha: 0,
      duration: 0.6,
      ease: 'expo',
    })
    // zero-out promobar height, and remove node;
    .to(
      '[data-comp="PromoBarMessage"]',
      {
        scaleY: 0,
        transformOrigin: 'top',
        duration: 0.45,
        ease: 'expo',
        onComplete: callback,
      },
      '<+=0.1'
    );
};

const animateLogo = (selectors, callback = () => {}) => {
  gsap
    .timeline()
    .to(selectors.ringRef.current, {
      rotation: 30,
      duration: 0.3,
      transformOrigin: '50% 50%',
    })
    .to(
      selectors.needlesRef.current,
      {
        scaleX: -1,
        duration: 0.3,
        transformOrigin: '50% 50%',
      },
      '-=.3'
    );
};

const resetLogo = (selectors, callback = () => {}) => {
  gsap
    .timeline()
    .to(selectors.ringRef.current, {
      rotation: 0,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .to(
      selectors.needlesRef.current,
      {
        scaleX: 1,
        transformOrigin: '50% 50%',
      },
      '-=.2'
    );
};

/**
 *  MenuDrawer (desktop)
 */
const drawerShellIn = () =>
  new Promise((resolve) => {
    const drawerShell = document.querySelector('[data-comp="MenuDrawerShell"]');
    return (
      gsap
        .timeline()
        // category columns out
        .to(drawerShell, {
          autoAlpha: 1,
          duration: 0.3,
          onComplete: resolve,
          opacity: 1,
        })
    );
  });

const drawerShellOut = () =>
  new Promise((resolve) => {
    const drawerShell = document.querySelector('[data-comp="MenuDrawerShell"]');
    return (
      gsap
        .timeline()
        // category columns out
        .to(drawerShell, {
          autoAlpha: 0,
          duration: 0.3,
          onComplete: resolve,
          opacity: 0,
        })
    );
  });

const drawerContentOut = () =>
  new Promise((resolve) => {
    const subNavCats = document.querySelectorAll(
      `[data-comp="SubNavCategory"]`
    );
    const subNavMedias = document.querySelectorAll(`[data-comp="SubNavMedia"]`);
    // const menuDrawerEl = document.querySelector(`[data-comp="MenuDrawer"]`)

    gsap
      .timeline()
      // category columns out
      .to(subNavCats, {
        alpha: 0,
        y: -5,
        duration: 0.3,
      })

      // media columns out
      .to(
        subNavMedias,
        {
          alpha: 0,
          y: -7,
          duration: 0.3,
          onComplete: resolve,
        },
        '-=.1'
      );
  });

/**
 * MenuSideBar (mobile)
 */

// sideBar shell
const sidebarIn = () =>
  new Promise((resolve) => {
    gsap.timeline().to(`[data-comp='MenuSidebarShell']`, {
      x: 0,
      ease: 'expo',
      duration: 0.6,
      onComplete: resolve,
    });
  });

const sideBarOut = () =>
  new Promise((resolve) => {
    gsap.timeline().to(`[data-comp='MenuSidebarShell']`, {
      x: '-100%',
      duration: 0.4,
      ease: 'expo',
      onComplete: resolve,
    });
  });

// sideBar content
const sidebarContentIn = () =>
  new Promise((resolve) => {
    gsap
      .timeline()
      // fade in accordion nav items
      .to(`[data-comp='AccordionNavItem']`, {
        alpha: 1,
        stagger: 0.1,
        duration: 0.3,
      });
  });

const sidebarContentOut = () =>
  new Promise((resolve) => {
    gsap
      .timeline()
      // fade in accordion nav items
      .to(`[data-comp='AccordionNavItem']`, {
        alpha: 0,
        x: -5,
        duration: 0.2,
        stagger: 0,
        onComplete: resolve,
      });
  });

// nestedSideBar
const nestedSidebarIn = (selectors) =>
  new Promise((resolve) => {
    try {
      const menuSidebar = document.querySelector(selectors.menuSidebar);
      const selectedAccordion = menuSidebar.querySelector(
        selectors.selectedAccordion
      );
      // add a class to exclude it from the all selector on the first step
      selectedAccordion.classList.toggle('selected');

      const firstAccordion = menuSidebar.querySelector(
        selectors.firstAccordion
      );

      const selectedAccordionTop =
        selectedAccordion.getBoundingClientRect().top;
      const firstAccordionTop = firstAccordion.getBoundingClientRect().top;
      const yDistanceTop = selectedAccordionTop - firstAccordionTop;

      const el = {
        selectedAccordion,
        selectedArrow: selectedAccordion.querySelector('img'),
        unSelectedAccordions: menuSidebar.querySelectorAll(
          selectors.unSelectedAccordions
        ),
        nestedSidebar: menuSidebar.querySelector(selectors.nestedSidebar),
        menuLinks: menuSidebar.querySelectorAll(selectors.menuLinks),
      };

      const timeline = gsap.timeline();
      timeline.pause();

      // fade out non clicked accordion items first
      timeline
        .to(el.unSelectedAccordions, {
          alpha: 0,
          duration: 0.3,
        })

        // fade in selected item's arrow
        .to(
          el.selectedArrow,
          {
            alpha: 0,
            duration: 0.3,
          },
          '-=.15'
        )

        // fade out Menulinks (direct links)
        .to(
          el.menuLinks,
          {
            y: 10,
            alpha: 0,
            duration: 0.25,
            stagger: 0.1,
          },
          '-=.15'
        )

        // move selected accordion item to the top row
        .to(
          el.selectedAccordion,
          {
            y: -yDistanceTop,
            duration: 0.3,
          },
          '-=.2'
        )

        // slide left the nested sideBar
        .to(
          el.nestedSidebar,
          {
            x: 0,
            ease: 'expo',
            duration: 0.3,
          },
          '-=.3'
        );

      timeline.eventCallback('onComplete', resolve);
      timeline.play();
    } catch (error) {
      console.error('nestedSidebarIn', error.message);
    }
  });

const nestedSidebarOut = (selectors) =>
  new Promise((resolve) => {
    try {
      const menuSidebar = document.querySelector(selectors.menuSidebar);
      const selectedAccordion = menuSidebar.querySelector(
        selectors.selectedAccordion
      );

      // add a class to exclude it from the all selector on the first step
      selectedAccordion.classList.toggle('selected');

      const el = {
        selectedAccordion,
        selectedArrow: selectedAccordion.querySelector('img'),
        unSelectedAccordions: menuSidebar.querySelectorAll(
          selectors.unSelectedAccordions
        ),
        nestedSidebar: menuSidebar.querySelector(selectors.nestedSidebar),
        menuLinks: menuSidebar.querySelectorAll(selectors.menuLinks),
      };

      const timeline = gsap.timeline();
      timeline.pause();

      // slide nested sidebar right
      timeline
        .to(el.nestedSidebar, {
          x: '110%',
          duration: 0.25,
          ease: 'expo',
        })

        // move selected accordion item back to where it was originally
        .to(
          el.selectedAccordion,
          {
            y: 0,
            duration: 0.3,
          },
          '-=.1'
        )

        // fade in MenuLinks
        .to(
          el.menuLinks,
          {
            y: 0,
            alpha: 1,
            duration: 0.4,
            stagger: 0.1,
          },
          '-=.2'
        )

        // fade in selected item's arrow
        .to(
          el.selectedArrow,
          {
            alpha: 1,
            duration: 0.5,
          },
          '-=.3'
        )

        // fade in non clicked accordion items
        .to(
          el.unSelectedAccordions,
          {
            alpha: 1,
            duration: 0.3,
          },
          '-=.4'
        );

      timeline.eventCallback('onComplete', resolve);
      timeline.play();
    } catch (error) {
      console.error('nestedSidebarOut', error.message);
    }
  });

export default {
  // promoBar
  closePromoBar,

  // logo
  animateLogo,
  resetLogo,

  // MenuDrawer (desktop)
  drawerShellIn,
  drawerShellOut,
  drawerContentOut,

  // MenuSideBar (mobile)
  sidebarIn,
  sideBarOut,
  sidebarContentIn,
  sidebarContentOut,

  // Nested sidebar (mobile)
  nestedSidebarIn,
  nestedSidebarOut,
};
