import header from './header';
import modal from './modal';
import yotpo from './yotpo';
import fadeInReveal from './fadeInReveal.js';

export default {
  ...header,
  ...modal,
  ...yotpo,
  ...fadeInReveal,
};
