export const icons = {
  options: [
    { label: 'none', value: 'none' },
    {
      label: '4-way-stretch',
      value: '/svgs/noprecache/fabric/4-way-stretch.svg#4-way-stretch',
    },
    {
      label: 'anti-odor',
      value: '/svgs/noprecache/fabric/anti-odor.svg#anti-odor',
    },
    {
      label: 'anti-pilling',
      value: '/svgs/noprecache/fabric/anti-pilling.svg#anti-pilling',
    },
    {
      label: 'breathable',
      value: '/svgs/noprecache/fabric/breathable.svg#breathable',
    },
    {
      label: 'buttery-soft',
      value: '/svgs/noprecache/fabric/buttery-soft.svg#buttery-soft',
    },
    {
      label: 'climate-control',
      value: '/svgs/noprecache/fabric/climate-control.svg#climate-control',
    },
    {
      label: 'color-and-fit-retention-bottoms',
      value:
        '/svgs/noprecache/fabric/color-and-fit-retention-bottoms.svg#color-and-fit-retention-bottoms',
    },
    {
      label: 'color-and-fit-retention',
      value:
        '/svgs/noprecache/fabric/color-and-fit-retention.svg#color-and-fit-retention',
    },
    { label: 'durable', value: '/svgs/noprecache/fabric/durable.svg#durable' },
    {
      label: 'easy-care',
      value: '/svgs/noprecache/fabric/easy-care.svg#easy-care',
    },
    {
      label: 'engineered-support',
      value:
        '/svgs/noprecache/fabric/engineered-support.svg#engineered-support',
    },
    {
      label: 'goose-down',
      value: '/svgs/noprecache/fabric/goose-down.svg#goose-down',
    },
    {
      label: 'insulated-warmth',
      value: '/svgs/noprecache/fabric/insulated-warmth.svg#insulated-warmth',
    },
    // {
    //   label: 'lightly-textured',
    //   value: '/svgs/noprecache/fabric/lightly-textured.svg#lightly-textured',
    // },
    {
      label: 'lightweight-packable',
      value:
        '/svgs/noprecache/fabric/lightweight-packable.svg#lightweight-packable',
    },
    {
      label: 'lightweight',
      value: '/svgs/noprecache/fabric/lightweight.svg#lightweight',
    },
    // {
    //   label: 'marbled-color',
    //   value: '/svgs/noprecache/fabric/marbled-color.svg#marbled-color',
    // },
    {
      label: 'moisture-wicking',
      value: '/svgs/noprecache/fabric/moisture-wicking.svg#moisture-wicking',
    },
    {
      label: 'natural-softness',
      value: '/svgs/noprecache/fabric/natural-softness.svg#natural-softness',
    },
    { label: 'opaque', value: '/svgs/noprecache/fabric/opaque.svg#opaque' },
    {
      label: 'packable',
      value: '/svgs/noprecache/fabric/packable.svg#packable',
    },
    {
      label: 'pre-shrunk',
      value: '/svgs/noprecache/fabric/pre-shrunk.svg#pre-shrunk',
    },
    {
      label: 'quick-dry',
      value: '/svgs/noprecache/fabric/quick-dry.svg#quick-dry',
    },
    {
      label: 'recycled-material',
      value: '/svgs/noprecache/fabric/recycled-material.svg#recycled-material',
    },
    {
      label: 'secured-storage',
      value: '/svgs/noprecache/fabric/secure-storage.svg#secure-storage',
    },
    {
      label: 'sheepskin-suede-leather',
      value:
        '/svgs/noprecache/fabric/sheepskin-suede-leather.svg#sheepskin-suede-leather',
    },
    {
      label: 'slim-fit',
      value: '/svgs/noprecache/fabric/slim-fit.svg#slim-fit',
    },
    {
      label: 'soft-texture',
      value: '/svgs/noprecache/fabric/soft-texture.svg#soft-texture',
    },
    {
      label: 'textured-materials',
      value:
        '/svgs/noprecache/fabric/textured-materials.svg#textured-materials',
    },
    {
      label: 'thermoregulating',
      value: '/svgs/noprecache/fabric/thermoregulating.svg#thermoregulating',
    },
    { label: 'trash', value: '/svgs/noprecache/fabric/trash.svg#trash' },
    // {
    //   label: 'tailored-fit',
    //   value: '/svgs/noprecache/fabric/tailored-fit.svg#tailored-fit',
    // },
    // { label: 'trim-fit', value: '/svgs/noprecache/fabric/trim-fit.svg#trim-fit' },
    {
      label: 'uv-protection',
      value: '/svgs/noprecache/fabric/uv-protection.svg#uv-protection',
    },
    {
      label: 'vintage-wash',
      value: '/svgs/noprecache/fabric/vintage-wash.svg#vintage-wash',
    },
    {
      label: 'water-resistant',
      value: '/svgs/noprecache/fabric/water-resistant.svg#water-resistant',
    },
    // { label: 'waterproof', value: '/svgs/noprecache/fabric/waterproof.svg#waterproof' },
    {
      label: 'wind-proof',
      value: '/svgs/noprecache/fabric/wind-proof.svg#wind-proof',
    },
    {
      label: 'wrinkle-resistant',
      value: '/svgs/noprecache/fabric/wrinkle-free.svg#wrinkle-free',
    },
    {
      label: 'access',
      value: '/svgs/noprecache/vip/access.svg#access',
    },
    {
      label: 'cashback',
      value: '/svgs/noprecache/vip/cashback.svg#cashback',
    },
    {
      label: 'community',
      value: '/svgs/noprecache/vip/community.svg#community',
    },
    {
      label: 'credit',
      value: '/svgs/noprecache/vip/credit.svg#credit',
    },
    {
      label: 'shipping',
      value: '/svgs/noprecache/vip/shipping.svg#shipping',
    },
    {
      label: 'vip_customer',
      value: '/svgs/noprecache/vip/vip_customer.svg#vip_customer',
    },
    {
      label: 'earning',
      value: '/svgs/noprecache/vip/earning.svg#earning',
    },
    {
      label: 'membership',
      value: '/svgs/noprecache/vip/membership.svg#membership',
    },
    {
      label: 'redeem',
      value: '/svgs/noprecache/vip/redeem.svg#redeem',
    },
    {
      label: 'create-account',
      value: '/svgs/noprecache/vip/create_account.svg#create_account',
    },
    {
      label: 'check',
      value: '/svgs/noprecache/vip/check.svg#check',
    },
  ],
  value: {
    none: 'none',
    antiOdor: '/svgs/noprecache/fabric/anti-odor.svg#anti-odor',
    antiPilling: '/svgs/noprecache/fabric/anti-pilling.svg#anti-pilling',
    breathable: '/svgs/noprecache/fabric/breathable.svg#breathable',
    butterySoft: '/svgs/noprecache/fabric/buttery-soft.svg#buttery-soft',
    climateControl:
      '/svgs/noprecache/fabric/climate-control.svg#climate-control',
    colorAndFitRetention:
      '/svgs/noprecache/fabric/color-and-fit-retention.svg#color-and-fit-retention',
    colorAndFitRetentionBottoms:
      '/svgs/noprecache/fabric/color-and-fit-retention.svg#color-and-fit-retention-bottoms',
    durable: '/svgs/noprecache/fabric/durable.svg#durable',
    easyCare: '/svgs/noprecache/fabric/easy-care.svg#easy-care',
    engineeredSupport:
      '/svgs/noprecache/fabric/engineered-support.svg#engineered-support',
    fourWayStretch: '/svgs/noprecache/fabric/4-way-stretch.svg#4-way-stretch',
    gooseDown: '/svgs/noprecache/fabric/goose-down.svg#goose-down',
    insulatedWarmth:
      '/svgs/noprecache/fabric/insulated-warmth.svg#insulated-warmth',
    // lightlyTextured: '/svgs/noprecache/fabric/lightly-textured.svg#lightly-textured',
    lightweightPackable:
      '/svgs/noprecache/fabric/lightweight-packable.svg#lightweight-packable',
    lightweight: '/svgs/noprecache/fabric/lightweight.svg#lightweight',
    moistureWicking:
      '/svgs/noprecache/fabric/moisture-wicking.svg#moisture-wicking',
    naturalSoftness:
      '/svgs/noprecache/fabric/natural-softness.svg#natural-softness',
    opaque: '/svgs/noprecache/fabric/opaque.svg#opaque',
    packable: '/svgs/noprecache/fabric/packable.svg#packable',
    preShrunk: '/svgs/noprecache/fabric/pre-shrunk.svg#pre-shrunk',
    quickDry: '/svgs/noprecache/fabric/quick-dry.svg#quick-dry',
    recycledMaterial:
      '/svgs/noprecache/fabric/recycled-material.svg#recycled-material',
    slimFit: '/svgs/noprecache/fabric/slim-fit.svg#slim-fit',
    texturedMaterials:
      '/svgs/noprecache/fabric/textured-materials.svg#textured-materials',
    thermoregulating:
      '/svgs/noprecache/fabric/thermoregulating.svg#thermoregulating',
    trash: '/svgs/noprecache/fabric/trash.svg#trash',
    uvProtection: '/svgs/noprecache/fabric/uv-protection.svg#uv-protection',
    vintageWash: '/svgs/noprecache/fabric/vintage-wash.svg#vintage-wash',
    waterResistant:
      '/svgs/noprecache/fabric/water-resistant.svg#water-resistant',
    windProof: '/svgs/noprecache/fabric/wind-proof.svg#wind-proof',
    wrinkleFree: '/svgs/noprecache/fabric/wrinkle-free.svg#wrinkle-free',
    // marbledColor: '/svgs/noprecache/fabric/marbled-color.svg#marbled-color',
    // tailoredFit: '/svgs/noprecache/fabric/tailored-fit.svg#tailored-fit',
    credit: '/svgs/noprecache/vip/credit.svg#credit',
    access: '/svgs/noprecache/vip/access.svg#access',
    cashback: '/svgs/noprecache/vip/cashback.svg#cashback',
    community: '/svgs/noprecache/vip/community.svg#community',
    shipping: '/svgs/noprecache/vip/shipping.svg#shipping',
    vipCustomer: '/svgs/noprecache/vip/vip_customer.svg#vip_customer',
    earning: '/svgs/noprecache/vip/earning.svg#earning',
    membership: '/svgs/noprecache/vip/membership.svg#membership',
    redeem: '/svgs/noprecache/vip/redeem.svg#redeem',
    createAccount: '/svgs/noprecache/vip/create_account.svg#create_account',
    check: '/svgs/noprecache/vip/check.svg#check',
  },
};
