import { image } from './image.schema';
import { video } from './video.schema';

export const media = ({ position, color }) => ({
  label: 'Media settings',
  name: 'media',
  description:
    'Select Media Type (image - video), position, placement, aspect ratio',
  component: 'group',
  fields: [
    image({ position, color }),
    video({ color }),
    {
      label: 'Select Media Type (mobile)',
      description: 'Allows select between image or video for mobile',
      name: 'mediaTypeMb',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'image',
    },
    {
      label: 'Select Media Type (desktop)',
      description: 'Allows select between image or video for desktop',
      name: 'mediaTypeDt',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'video',
    },
  ],
});
