export const fonts = {
  body: '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  heading: '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  monospace:
    '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  // body: '"Akzidenz-Grotesk BQ", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  // heading:
  //   '"Akzidenz-Grotesk BQ", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  // monospace:
  //   '"Akzidenz-Grotesk BQ", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
};
