import { Box, Flex, Text } from 'theme-ui';

import { Picture, IconWithDescription, Link, Svg } from '@snippets';

import { themed } from './SlideItem.theme';

export const SlideItem = themed(({ theme, item, isLast, index }) => {
  if (!item) return null;

  const images = [
    {
      src: item?.image?.src,
      width: 350,
      ratio: 3 / 4,
    },
    {
      src: item?.image?.src,
      width: 350,
      ratio: 1,
    },
  ];

  return (
    <Box data-comp={SlideItem.displayName} sx={theme.wrapperOuter}>
      <Box sx={theme.wrapperInner}>
        <Link
          href={item?.link.url}
          sx={{
            flexBasis: ['45%', null, '50%', '52.5%'],
          }}
        >
          <Picture
            alt={item?.title}
            images={images}
            imageSx={theme.imageSx}
            sx={theme.image}
          />
        </Link>
        <Box sx={theme.details}>
          <Flex sx={theme.detailsInner}>
            <Text sx={theme.title}>{item?.title}</Text>
            {item?.icons?.slice(0, 4)?.map((icon) => (
              <Box
                sx={{
                  mb: [4, 2],
                }}
              >
                <IconWithDescription
                  icon={icon.icon}
                  text={icon.text}
                  theme={theme}
                  viewBox="0 0 360 360"
                />
              </Box>
            ))}
          </Flex>
          <Flex
            variant="flex.row.end"
            sx={{
              mt: 'auto',
            }}
          >
            {item?.link?.url && (
              <Link
                variant="buttons.withArrow"
                href={item?.link.url}
                sx={theme.link}
              >
                <Text sx={theme.linkText}>{item?.link.text}</Text>
                <Svg
                  alt="chevron"
                  viewBox="0 0 48 48"
                  src="/svgs/chevron/right.svg#right"
                  sx={{
                    width: ['10px', '12px'],
                    ml: '10px',
                  }}
                />
              </Link>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
});

SlideItem.displayName = 'SlideItem';
