export default {
  label: 'Collection Settings',
  name: 'collection',
  component: 'group',
  fields: [
    {
      label: 'View Per Row (Desktop)',
      name: 'viewPerRowDesktop',
      description: 'Sets number of grid items per row on desktop',
      component: 'radio-group',
      options: [
        {
          label: '3',
          value: 3,
        },
        {
          label: '5',
          value: 5,
        },
      ],
      defaultValue: 3,
    },
    {
      label: 'View Per Row (Mobile)',
      name: 'viewPerRowMobile',
      description: 'Sets number of grid items per row on mobile',
      component: 'radio-group',
      options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
      ],
      defaultValue: 2,
    },
    {
      label: 'Flat/Model Settings',
      name: 'flatModel',
      component: 'group',
      fields: [
        {
          label: 'Collections',
          name: 'collections',
          component: 'group-list',
          itemProps: {
            label: '{{item.handle}}',
          },
          fields: [
            {
              label: 'Collection Handle',
              name: 'handle',
              component: 'text',
            },
            {
              label: 'Set Default Image',
              name: 'defaultImageOption',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: [
                {
                  value: 'flat',
                  label: 'Flat',
                },
                {
                  value: 'model',
                  label: 'Model',
                },
              ],
              defaultValue: 'flat',
            },
          ],
        },
      ],
    },
    {
      name: 'hideBreadcrumbs',
      component: 'tags',
      label: 'Hide Breadcrumbs',
      description: 'Insert collection name to hide breadcrumbs in mobile',
    },
  ],
};
