import Script from "next/script";

export function FueledKustomerScript({ withKey: kustomerApiKey }) {
  return(
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('kustomerLoaded', function() {
            Kustomer.start();
          });

          // Dynamically adds the SDK to the page.
          var script = document.createElement('script');
          script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
          script.setAttribute('data-kustomer-api-key', '${kustomerApiKey}');
          window.document.body.appendChild(script);
        `
      }}
    />
  )
}