import { useMemo, forwardRef } from 'react';
import { Box, Select } from 'theme-ui';

import { useCountries } from '@hooks';

const firstCountries = [
  'United States',
  'Canada',
  'United Kingdom',
  'France',
  'Australia',
  'New Zealand',
  '--',
];

export const CountryField = forwardRef(
  ({ name, required, placeholder }, ref) => {
    const { countryNames } = useCountries();

    const countryOptions = useMemo(() => {
      if (!countryNames?.length) return null;
      return ['', ...firstCountries, ...countryNames];
    }, [countryNames?.length, firstCountries]);

    return (
      <Select
        data-comp="Country"
        id={name}
        name={name}
        ref={ref}
        required={required}
        arrow={
          <Box
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="currentcolor"
            sx={{
              ml: '-25px',
              alignSelf: 'center',
              pointerEvents: 'none',
            }}
          >
            <path
              d="M8.8 1.2L7.6 0L4.4 3.2L1.2 0L0 1.2L4.4 5.6L8.8 1.2Z"
              fill="#282828"
            />
          </Box>
        }
      >
        {countryOptions?.map((country, index) => (
          <option
            disabled={index === 0 || country === '--'}
            key={`${country}.${index}`}
            selected={index === 0}
            value={country}
          >
            {country || placeholder}
          </option>
        ))}
      </Select>
    );
  }
);

CountryField.displayName = 'CountryField';
