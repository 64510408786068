const getRandomId = () => {
  return String(Math.random()).replace('0.', '');
};

const Slide1 = () => ({
  id: getRandomId(),
  image:
    'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
  heading: 'AMBASSADOR',
  name: 'Name of Ambassador',
  text: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
  Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
});

const Slide2 = () => ({
  id: getRandomId(),
  image:
    'https://cdn.shopify.com/s/files/1/1368/3463/files/full-width-hero-tile.jpg?v=1651521822',
  heading: 'AMBASSADOR',
  name: 'Name of Ambassador',
  text: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
  Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
});

const slides = () => ({
  label: 'Slides',
  name: 'slides',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}',
  },
  defaultItem: {
    name: 'New slide',
  },
  fields: [
    {
      label: 'Ambassador Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'AmbassadorSlide heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Ambassador name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'AmbassadorSlide text',
      name: 'text',
      component: 'text',
    },
  ],
  defaultValue: [Slide1(), Slide2(), Slide1()],
});

const slider = () => ({
  label: 'Slider settings',
  name: 'slider',
  description:
    'Autoplay, delay, transition effect, bullet color, content position',
  component: 'group',
  fields: [
    {
      label: 'Enable autoplay',
      name: 'autoplay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      description: 'Refresh page to observe change',
    },
    {
      name: 'delay',
      component: 'number',
      label: 'Delay between transitions (ms)',
      description: 'Refresh page to observe change',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'effect',
      label: 'Effect between transitions',
      description: 'Refresh page to observe change',
      options: [
        { label: 'Slide', value: 'slide' },
        { label: 'Fade', value: 'fade' },
      ],
    },
  ],
  defaultValue: {
    autoplay: false,
    delay: 8000,
    effect: 'fade',
  },
});
export const Schema = ({ page }) => {
  if (page?.handle !== 'ambassadors') return null;

  return {
    label: 'Our ambassadors slider',
    key: 'ambassadorsSlider',
    fields: [
      {
        label: 'Section title',
        name: 'title',
        component: 'text',
        defaultValue: 'Our Ambassadors',
      },
      slides(),
      slider(),
    ],
  };
};
