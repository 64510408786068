import { Container } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './TwoTile.schema';
import { GridDisplay } from './GridDisplay';

const Header = dynamic(
  () => import('./Content').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Footer = dynamic(
  () => import('./Content').then((module) => module.Footer),
  { ssr: false, suspense: false }
);

export const TwoTile = withInView(
  ({ cms, inView }) => {
    const { section, header, tiles } = cms;

    return (
      <Section section={section}>
        <Container data-comp={TwoTile.displayName}>
          {inView && <Header header={header} section={section} />}

          <GridDisplay tiles={tiles} inView={inView} />

          {inView && <Footer header={header} section={section} />}
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

TwoTile.displayName = 'TwoTile';
TwoTile.Schema = Schema;
