import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      variant: 'flex.column',
      bg: 'background',
      zIndex: 60,
    };
  })()
);
