import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      width: ['100%', '850px'],
      fontFamily: 'body',
    };
    this.wrap = {
      p: 10,
      pt: 14,
    };
  })()
);
