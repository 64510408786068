import { useRef } from 'react';
import { Box, Heading, Flex, Grid } from 'theme-ui';

import { useBreakpointValue } from '@hooks';
import { Markdown, Link, Picture } from '@snippets';

import { FeatureCallout } from '../FeatureCallout';
import { positionStyles, contentWidthStyles } from './comparisonTileStyles';

export const ComparisonTile = ({ tile, index, numOfTiles }) => {
  const { heading, description, image, cta, featureCallout } = { ...tile };
  const positionValues = positionStyles(index, numOfTiles);
  const tileRef = useRef();

  const showFeatureCallout = useBreakpointValue([null, null, true]);

  return (
    <Grid
      ref={tileRef}
      sx={{
        gridGap: ['20px', '40px', '60x'],
        gridTemplateRows: 'auto 1fr',
        position: 'relative',
        ...positionValues,
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {image?.src && (
          <Picture
            alt="test"
            images={[
              {
                src: image.src,
                width: 768,
                ratio: 0.9,
              },
              {
                src: image.src,
                width: 1024,
                ratio: 0.9,
              },
              {
                src: image.src,
                width: 1280,
                ratio: 0.9,
              },
              {
                src: image.src,
                width: 1440,
                ratio: 0.9,
              },
            ]}
          />
        )}
        {showFeatureCallout && (
          <FeatureCallout
            ref={tileRef}
            index={index}
            props={featureCallout}
            numOfTiles={numOfTiles}
          />
        )}
      </Box>
      <Flex
        variant="flex.column"
        sx={{
          ...contentWidthStyles(numOfTiles),
          px: '20px',
          margin: '0 auto',
        }}
      >
        <Flex variant="flex.row.start.center">
          <Flex
            variant="flex.row.center"
            sx={{
              fontSize: 4,
              fontWeight: 700,
              lineHeight: '1',
              width: '32px',
              height: '32px',
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '100%',
              mr: 4,
            }}
          >
            {index + 1}
          </Flex>
          <Heading
            variant="text.base"
            sx={{
              fontWeight: 700,
              letterSpacing: '0.25px',
            }}
          >
            {heading}
          </Heading>
        </Flex>
        {description && (
          <Box>
            <Markdown
              text={description}
              sx={{
                ul: {
                  ml: 11,
                  px: 0,
                },
                p: {
                  fontSize: 4,
                },
              }}
            />
          </Box>
        )}

        {cta?.text && (
          <Link
            href={cta?.url}
            variant="buttons.link.large"
            sx={{
              pointerEvents: cta?.url ? 'auto' : 'none',
              fontSize: 2,
            }}
          >
            {cta.text}
          </Link>
        )}
      </Flex>
    </Grid>
  );
};

ComparisonTile.displayName = 'ComparisonTile';
