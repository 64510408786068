export const colorsOrder = {
  label: 'Colors Group',
  name: 'colorsGroup',
  description: 'Manage product colors group order',
  component: 'group-list',
  itemProps: {
    label: '{{item.color}}' || 'Color',
  },
  defaultItem: {
    color: 'Staple',
  },
  fields: [
    {
      label: 'color group name',
      name: 'color',
      component: 'text',
      description: 'Must match the color group tag.',
    },
  ],
  defaultValue: [
    {
      color: 'colors',
    },
    {
      color: 'Staple',
    },
    {
      color: 'Seasonal',
    },
    {
      color: 'Last Chance',
    },
  ],
};
