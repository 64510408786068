import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mt: [12, 15],
      mb: [15, 18],
      mx: 'auto',
    };

    this.grid = {
      display: 'grid',
      width: '100%',
      columnGap: [4, 4, null, 8],
      rowGap: [1, 2, null, 4],
    };

    this.noProducts = {
      variant: 'flex.column',
      text: {
        variant: 'text.lg',
        mt: 20,
        textAlign: 'center',
      },
    };

    this.toggle = {
      width: [18, 22],
      ml: 'auto',
      display: ['none', 'flex'],
    };
  })()
);
