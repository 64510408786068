import dynamic from 'next/dynamic';
import { Container, Box } from 'theme-ui';

import { themed } from './Displays.theme';

import 'swiper/css/bundle';

const Tile = dynamic(() => import('../Tile').then((module) => module.Tile), {
  ssr: false,
  suspense: false,
});

export const GridDisplay = themed(({ theme, tiles, tile, inView }) => {
  return (
    <Container data-comp={GridDisplay.displayName} sx={theme.grid}>
      {tiles?.slice(0, 3).map((item, index) => {
        return (
          <Box
            key={index}
            // Adds minimum aspect ratio before ThreeileItem js loads
            sx={tile?.imageRatio === 'square' ? theme.square : theme.portrait}
          >
            {inView && <Tile item={item} tile={tile} />}
          </Box>
        );
      })}
    </Container>
  );
});

GridDisplay.displayName = 'GridDisplay';
