import { section, color } from '@settings/common';

export const Schema = ({ page }) => {
  if (page?.handle !== 'ambassadors') return null;
  return {
    label: 'Ambassador Video',
    key: 'ambassadorVideo',
    fields: [
      {
        label: 'Video url',
        name: 'videoScr',
        component: 'text',
        defaultValue:
          'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
      },
      {
        label: 'Overlay',
        name: 'overlay',
        component: 'image',
      },
      {
        label: 'Play button text',
        name: 'button',
        component: 'text',
        defaultValue: 'Watch now',
      },
      section,
    ],
  };
};
