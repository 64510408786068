import { Box } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { useYotpoRefreshOnMount } from '../hooks';

export const YotpoProductReviewsWidget = ({
  cms,
  selectedVariant: passedSelectedVariant,
}) => {
  const recoilSelectedVariant = useRecoilValue(store.selectedVariant());
  const selectedVariant = passedSelectedVariant || recoilSelectedVariant;
  useYotpoRefreshOnMount({
    productId: selectedVariant?.product?.legacyResourceId,
  });

  return selectedVariant ? (
    <Box
      id="YoptoProductReviews"
      data-comp="YoptoProductReviews"
      className="yotpo yotpo-main-widget"
      data-product-id={selectedVariant?.product?.legacyResourceId}
      data-price={selectedVariant?.price}
      data-currency={selectedVariant?.currencyCode}
      data-name={selectedVariant?.title}
      sx={{
        width: '100%',
        minHeight: cms?.sectionMinHeights || 'initial',
        '.write-question-review-buttons-container': {
          display: 'none',
        },
        '.yotpo-nav-tab:last-of-type': {
          display: 'none',
        },
      }}
    />
  ) : null;
};

YotpoProductReviewsWidget.displayName = 'YotpoProductReviewsWidget';
