export const image = ({ color, position }) => ({
  label: 'Image settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
      label: 'Alt',
    },
    {
      name: 'image',
      label: 'Image (mobile)',
      component: 'image',
    },
    {
      name: 'position',
      component: 'select',
      label: 'Image position (mobile)',
      options: position.object,
    },
    {
      name: 'imageDt',
      label: 'Image (desktop)',
      component: 'image',
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Image position (desktop)',
      options: position.object,
    },
    {
      label: 'Activate overlay',
      description: 'Activate image overlay',
      name: 'imageOverlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      defaultValue: 50,
    },
  ],
  defaultValue: {
    alt: 'Hero image',
    image: {
      src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
    },
    position: 'center center',
    imageDt: {
      src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
    },
    positionDt: 'center center',
    overlayOpacity: 50,
    overlayColor: '',
    imageOverlay: false,
  },
});
