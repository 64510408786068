import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import MarkdownJsx from 'markdown-to-jsx';

import { themed } from './Markdown.theme';

const textHtmlTags = `p, h1, h2, h3, h4, h5, h6, span, label, div, strong, em, blockquote, pre, code, i, b, ul, ol, li`;

export const Markdown = themed(
  ({
    theme,
    inputRef,
    text,
    children,
    sx,
    textSx, // Sx applied across all markdown to preserve a specific style, e.g. headings, descriptions
    textAlign,
    ...props
  }) => {
    const markdownText = typeof children === 'string' ? children : text || '';

    return (
      <Box
        data-comp={Markdown.displayName}
        ref={inputRef}
        {...props}
        sx={{
          ...theme.markdown,
          [textHtmlTags]: {
            textAlign: textAlign || null,
            mt: textSx ? 0 : null,
            '&:last-of-type': textSx ? { mb: 0 } : {},
            ...textSx,
          },
          ...sx,
        }}
      >
        <MarkdownJsx options={{ forceBlock: true }}>{markdownText}</MarkdownJsx>
      </Box>
    );
  }
);

Markdown.displayName = 'Markdown';

Markdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  sx: PropTypes.object,
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  textSx: PropTypes.object,
};
