import { Container, Paragraph } from 'theme-ui';
import SwiperCore, { EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { themed } from './AmbassadorSlider.theme';
import { Schema } from './AmbassadorSlider.schema';

import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';
import 'swiper/css';

import { AmbassadorSlide } from './AmbassadorSlide';

SwiperCore.use([Pagination, EffectFade]);

export const AmbassadorSlider = withInView(
  themed(({ theme, cms }) => {
    const { title, section, slider, slides } = cms;

    if (!slides?.length) return null;
    return (
      <Section section={section}>
        <Container sx={theme.wrapper}>
          <Paragraph sx={theme.title} variant="text.h4">
            {title}
          </Paragraph>
          <Container
            sx={{
              ...theme.sliderWrap,
              '.swiper-pagination-bullet': {
                ...theme.swiperBullet,
              },
              '.swiper-pagination-bullet-active': {
                ...theme.swiperBulletActive,
              },
            }}
          >
            {slides.length > 1 ? (
              <Swiper
                {...defaultParams}
                autoplay={
                  slider?.autoplay ? { delay: slider.delay || 5000 } : false
                }
                loop
                effect={slider?.effect === 'fade' ? 'fade' : null}
              >
                {slides?.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <AmbassadorSlide slide={slide} />
                  </SwiperSlide>
                ))}
                {/* Bullets nav */}
                <div className="swiper-pagination" sx={theme.bullets} />
              </Swiper>
            ) : (
              <AmbassadorSlide slide={slides[0]} />
            )}
          </Container>
        </Container>
      </Section>
    );
  })
);

const defaultParams = {
  centeredSlides: false,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: false,
  navigation: false,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 25,
  watchOverflow: true,
};

AmbassadorSlider.displayName = 'AmbassadorSlider';
AmbassadorSlider.Schema = Schema;
