import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.button = {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      margin: 'auto',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      border: 'none',
      bg: 'transparent',
      zIndex: '1',
      transition: 'all 0.3s ease-in -out',
      '@media screen and (min-width: 480px)': {
        ':hover': {
          backgroundColor: 'rgba(237, 237, 237, .1)',
        },
      },
      ':active': {
        background: 'none',
        outline: 'none',
        borderStyle: 'none',
      },
    };

    this.icon = {
      margin: '0 auto',
      width: '50%',
    };
  })()
);
