import { Box } from 'theme-ui';

import { useOverlay } from '@hooks';

import { themed } from './Overlay.theme';

export const Overlay = themed(({ theme, ...props }) => {
  const [{ overlay }, { closeOverlay }] = useOverlay();

  return (
    <Box
      data-comp={Overlay.displayName}
      {...props}
      onClick={closeOverlay}
      sx={{
        ...(overlay ? theme.open : theme.closed),
        ...props.sx,
      }}
    />
  );
});

Overlay.displayName = 'Overlay';
