import { useLocalizedVariant } from '@backpackjs/storefront';

export const useVariantsToLocalePrices = (product) => {

  const isGiftCard = product?.handle === 'gift-card';
  if (!isGiftCard) return { currency: null };
  let currency;
  if (product && isGiftCard) {
    currency = product.variants.map(variant => {
      const { localized, ...localeStatus } = useLocalizedVariant({ variant });
      return localized?.priceV2.locale && localized?.priceV2.locale;
    }).filter(variant => variant);
  };

  return { currency };
};
