import { useEffect } from 'react';
import store, {
  useRecoilValue,
  useRecoilCallback,
  useGotoRecoilSnapshot,
} from '@store';
import { useBodyScrollLock } from '@hooks';

export const useOverlay = () => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const overlay = useRecoilValue(store.overlay);
  const { lockScroll, unlockScroll } = useBodyScrollLock();

  const closeOverlay = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();

        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, null))
            .map(({ set }) => set(store.modalProps, {}))
            .map(({ set }) => set(store.sidebar, false))
            .map(({ set }) => set(store.overlay, false));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      },
    []
  );

  useEffect(() => {
    if (overlay) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [overlay]);

  return [{ overlay }, { closeOverlay }];
};
