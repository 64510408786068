import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = ({ background, heightSize }) => ({
      height: heightSize === 'tall' ? '80px' : '40px',
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: background || '#000000',
    });

    this.messageTextWrapper = ({ color, heightSize }) => ({
      display: 'flex',
      flexShrink: 0,
      position: 'relative',
      animation: 'marquee 30s infinite linear',
      p: {
        fontSize: heightSize === 'tall' ? '22px' : '11px',
        textTransform: 'uppercase',
        fontWeight: 700,
        lineHeight: heightSize === 'tall' ? '22px' : '11px',
        mx: ['20px', '40px'],
        color: color || 'ffffff',
      },
      '@keyframes marquee': {
        '0%': {
          transform: 'translate3d(0px, 0px, 0px)',
        },
        '100%': {
          transform: 'translate3d(-100%, 0px, 0px)',
        },
      },
    });
  })()
);
