import { Box, Grid, Paragraph } from 'theme-ui';
import { Section, Picture } from '@snippets';
import { withInView } from '@utils';
import { themed } from './AmbassadorPerks.theme';
import { Schema } from './AmbassadorPerks.schema';

export const AmbassadorPerks = withInView(
  themed(({ theme, cms }) => {
    const {
      heading,
      items: { items },
      section,
    } = cms;

    return (
      <Section section={section}>
        <Box sx={theme.wrapper}>
          <Paragraph sx={theme.title}>{heading}</Paragraph>
          <Grid as="ul" sx={theme.container}>
            {items?.map(
              ({ icon, heading: perkHeading, text, image }, index) => (
                <Box as="li" key={icon} sx={theme.item}>
                  <Picture
                    images={[
                      {
                        src:
                          image?.src ||
                          `/images/ambassador/perk${index + 1}.jpg`,
                        ratio: 1,
                      },
                    ]}
                    alt={icon}
                    sx={theme.icon}
                  />
                  <Box sx={theme.itemHeading}>{perkHeading}</Box>
                  <Box sx={theme.itemText}>{text}</Box>
                </Box>
              )
            )}
          </Grid>
        </Box>
      </Section>
    );
  })
);

AmbassadorPerks.displayName = 'AmbassadorPerks';
AmbassadorPerks.Schema = Schema;
