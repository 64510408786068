import { Container } from 'theme-ui';
import PropTypes from 'prop-types';
import { layout } from '@theme/theme.layout';
import { margin, padding } from '@settings/common';

export const Section = ({ section, children, ...props }) => {
  if (!section) {
    console.warn(
      `"section" prop not provided to Section. Rendering ${
        children?.props?.['data-comp'] || ''
      } children without Section.`
    );
    return children;
  }

  const layoutMb = layout[section.mobile?.container];
  const layoutDt = layout[section.desktop?.container];
  const mtMb = margin?.value?.[section.mobile?.mt];
  const mtDt = margin?.value?.[section.desktop?.mt];
  const pyMb = padding?.value?.[section.mobile?.py];
  const pyDt = padding?.value?.[section.desktop?.py];

  return (
    <Container
      data-comp={Section.displayName}
      {...props}
      sx={{
        bg: section.bgColor || 'background',
        color: section.color || 'text',
        mt: [
          mtMb?.[0] || 0,
          mtDt?.[1] || 0,
          mtDt?.[2] || null,
          mtDt?.[3] || null,
        ],
        py: [
          pyMb?.[0] || 0,
          pyDt?.[1] || 0,
          pyDt?.[2] || null,
          pyDt?.[3] || null,
        ],
      }}
    >
      <Container
        sx={{
          position: [
            layoutMb?.position || 'relative',
            layoutDt?.position || 'relative',
          ],
          px: [
            layoutMb?.px?.[0] || 0,
            layoutDt?.px?.[1] || 0,
            layoutDt?.px?.[2] || null,
            layoutDt?.px?.[3] || null,
          ],
          mx: [layoutMb?.mx || 'auto', layoutDt?.mx || 'auto'],
          maxWidth: [
            layoutMb?.maxWidth || 'unset',
            layoutDt?.maxWidth || 'unset',
          ],
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

Section.displayName = 'Section';

Section.propTypes = {
  cms: PropTypes.object,
  children: PropTypes.node,
};
