import { store } from '@backpackjs/storefront';

const collection = store.recoil.atom({
  key: 'collection',
  default: null,
});

const selectedSort = store.recoil.atom({
  key: 'collection/selectedSort',
  default: null,
});

const selectedFilters = store.recoil.atom({
  key: 'collection/selectedFilters',
  default: [],
});

const selectedResultsPerPage = store.recoil.atom({
  key: 'collection/selectedResultsPerPage',
  default: 40,
});

const currentResultsPage = store.recoil.atom({
  key: 'collection/currentResultsPage',
  default: 1,
});
const invertModelPictures = store.recoil.atom({
  key: 'superCollection/invertModelPictures',
  default: false,
});

export default {
  // atoms
  collection,
  selectedSort,
  selectedFilters,
  selectedResultsPerPage,
  currentResultsPage,
  invertModelPictures,
};
