
const productFields = [
  {
    name: 'image',
    label: 'Image',
    component: 'image',
  },
  {
    name: 'imageHover',
    label: 'Image (Hover)',
    component: 'image',
  },
  {
    name: 'subTitle',
    label: 'Sub Title',
    component: 'text',
  },
  {
    name: 'title',
    label: 'Title',
    component: 'text',
  },
  {
    name: 'price',
    label: 'Price',
    component: 'number',
  },
  {
    name: 'link',
    label: 'Link',
    component: 'link',
  },
  {
    name: 'showBadge',
    label: 'Hide / Show badge',
    component: 'toggle',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: true,
  },
  {
    name: 'soldOut',
    label: 'Hide / Show soldout badge',
    component: 'toggle',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: false,
  },
  {
    label: 'Upcoming Date',
    name: 'upcomingDate',
    component: 'text',
    description: 'Must be in UTC format 2023-06-30T00:00:00Z',
  },
  {
    name: 'product',
    component: 'productSearch',
    label: 'Product',
  },
];

export const Schema = ({ article, blog, collection }) => {
  if (article || blog || collection) return null;

  return {
    label: 'Friday Projects',
    key: 'friday-projects',
    fields: [
      {
        label: 'Next Drop',
        name: 'nextDropTime',
        component: 'text',
        description: 'Must be in UTC format 2023-06-30T00:00:00Z',
        defaultValue: '2023-06-30T00:00:00Z',
      },
      {
        name: 'badgeText',
        label: 'Badge Text',
        component: 'text',
        defaultValue: 'FRIDAY PROJECTS',
      },
      {
        name: 'active',
        label: 'Active',
        component: 'select',
        description: 'Select the initial active section',
        options: [
          { label: 'Live', value: 'live' },
          {
            label: 'Upcoming',
            value: 'upcoming',
          },
          {
            label: 'Archive',
            value: 'archive',
          },
        ],
        defaultValue: 'live',
      },
      {
        label: 'Live',
        name: 'liveProducts',
        component: 'group',
        fields: [
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            defaultItem: {
              product: {
                handle: 'black-crew-curve-hem',
              },
              showBadge: true,
            },
            fields: productFields,
          },
        ],
      },
      {
        label: 'Upcoming',
        name: 'upcomingProducts',
        component: 'group',
        fields: [
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            defaultItem: {
              product: {
                handle: 'black-ao-jogger',
              },
              showBadge: true,
            },
            fields: productFields,
          },
        ],
      },
      {
        label: 'Archive',
        name: 'archiveProducts',
        component: 'group',
        fields: [
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            defaultItem: {
              product: {
                handle: 'heather-grey-hoodie-classic',
              },
              showBadge: true,
            },
            fields: productFields,
          },
        ],
      },
    ],
  };
};
