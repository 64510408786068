import store, { useRecoilValueLoadable } from '@store';

export const useYotpoStars = ({ legacyResourceId, bottomline }) => {
  const { state, contents } = bottomline
    ? // if we pass a bottomline we use it
      {
        state: 'hasValue',
        contents: bottomline,
      }
    : useRecoilValueLoadable(store.getYotpoBottomLine$(legacyResourceId));

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',

    // state
    stars: state === 'hasValue' ? contents : null,
  };
};
