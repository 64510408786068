import { useEffect, useState, useCallback } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

export const useKitProducts = ({ products: productsProp }) => {
  const [productsByType, setProductsByType] = useState(null);
  const [products, setProducts] = useState(null);

  // list of live products
  // Ensure initial array is always 6 items long to keep rerenders consistent
  const initialProductsByHandle = [
    ...(productsProp || []),
    ...[...Array(6).fill(null)],
  ]
    .slice(0, 6)
    .reduce((arr, item) => {
      const { product } = useProductByHandle({
        // TODO: useProductByHandle can't be used in a callback
        handle: item?.product?.handle || 'empty-product',
      });
      return [...arr, product];
    }, []);

  // Slice initial array to length of intended array
  const productsByHandle = initialProductsByHandle?.slice(
    0,
    productsProp?.length || 0
  );

  // Check every item is not null
  const productsReady = productsByHandle?.every((item) => !!item);

  const productsDep = productsReady
    ? productsByHandle?.map((item) => item?.id).join('')
    : null;

  const parseKitProductsOnMount = useCallback(() => {
    if (!productsDep) return;

    const _productsByType = productsByHandle?.reduce((obj, product) => {
      // Filter out only variants of product
      const variants = product?.variants?.filter((variant) => {
        return variant.product?.handle === product?.handle;
      });

      if (!variants?.length) return obj;

      let type = product.productType || product.title;

      if (product.optionsMap.Waist && product.optionsMap.Inseam) {
        type = 'Pants';
      }

      // two types of bottoms
      // - shorts with size option uses alpha sizing: 'XS', 'S', ...
      // - shorts with waist option uses numeric sizing: 28, 30, ...

      const getSize = (_type, variant) => {
        switch (true) {
          case _type === 'Pants':
            return `${variant?.selectedOptionsMap?.Waist} / ${variant?.selectedOptionsMap?.Inseam}`;
          case _type === 'Bottoms' && !!variant?.selectedOptionsMap?.Waist:
            return variant?.selectedOptionsMap?.Waist;
          default:
            return variant?.selectedOptionsMap?.Size;
        }
      };

      // Object of sizes
      let sizesTable = variants.reduce((sizesObj, variant) => {
        const size = getSize(type, variant);
        if (!size) return sizesObj;
        sizesObj[size] = variant;
        return sizesObj;
      }, {});

      const sizes = Object.keys(sizesTable);

      if (!sizes.length && type === 'Accessories' && variants.length === 1) {
        sizesTable = { 'O / S': variants[0] };
      } else if (
        !sizes.length &&
        type === 'Gift Wrap' &&
        variants.length === 1
      ) {
        sizesTable = { 'O / S': variants[0] };
      }

      // Place sizes table into final object by its product type
      if (obj[type]) {
        obj[type] = [...obj[type], sizesTable];
      } else {
        obj[type] = [sizesTable];
      }

      return obj;
    }, {});

    setProductsByType(_productsByType);
    setProducts(productsByHandle);
  }, [productsDep]);

  useEffect(() => {
    parseKitProductsOnMount();
  }, [productsDep]);

  return { productsByType, products };
};
