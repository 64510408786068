import { useState, useMemo } from 'react';
import { Box, Flex, Container, Heading } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';

import { Picture } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './ProductEducation.schema';
import { themed } from './ProductEducation.theme';
import { swiperDefaultParams } from './swiperDefaultParams';

import { SlideItem } from './Content/SlideItem';
import { Navigation } from './Navigation';

import 'swiper/css/pagination';
import 'swiper/css/bundle';

export const ProductEducation = withInView(
  themed(({ theme, cms }) => {
    const { heading, headingColor, location, showImageHeader, image, items } =
      cms;

    const defaultParams = swiperDefaultParams;
    const [swiper, setSwiper] = useState();

    const showNavigation = items?.length > 3;

    const textAlignment = useMemo(() => {
      if (location === 'top') return 'flex-start';
      if (location === 'middle') return 'center';
      return 'flex-end';
    }, [location]);

    return (
      <Flex>
        <Container
          data-comp={ProductEducation.displayName}
          sx={theme.container}
        >
          <Flex>
            {showImageHeader && (
              <Flex sx={{ minWidth: [160, 330], display: ['none', 'flex'] }}>
                <Box
                  sx={{
                    position: 'relative',
                    minWidth: [160, 310],
                    mr: '20px',
                  }}
                >
                  <Picture
                    sx={theme.image}
                    images={[{ src: image?.src, ratio: 1 }]}
                    imageSx={theme.imageSx}
                  />
                  <Heading
                    sx={{
                      ...theme.heading,
                      color: headingColor || 'white',
                      alignItems: textAlignment,
                    }}
                  >
                    {heading}
                  </Heading>
                </Box>
              </Flex>
            )}

            <Flex sx={{ flexGrow: 1, position: 'relative' }}>
              <Box sx={theme.swiper}>
                <Swiper
                  onSwiper={setSwiper}
                  {...defaultParams}
                  modules={[Pagination, A11y]}
                  a11y={{ enabled: true }}
                  pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                  }}
                >
                  {items?.map((item, index) => {
                    const isLast = index + 1 == items.length;
                    return (
                      <SwiperSlide key={item.title + index}>
                        <SlideItem {...{ item, isLast }} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="swiper-pagination" sx={theme.bullets} />
                {showNavigation && <Navigation swiper={swiper} />}
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    );
  })
);

ProductEducation.displayName = 'ProductEducation';
ProductEducation.Schema = Schema;
