import { store } from '@backpackjs/storefront';

const cartTotal = store.recoil.atom({
  key: 'cart/cartTotal',
  default: '0',
});

const upsellProductsNotInCart = store.recoil.atom({
  key: 'cart/upsellProductsNotInCart',
  default: [],
});

/*
  Fondue Cashback
*/
const cashbackInCart = store.recoil.atom({
  key: 'cart/cashbackInCart',
  default: null,
});

export default {
  // atoms
  cartTotal,
  upsellProductsNotInCart,
  cashbackInCart,
};
