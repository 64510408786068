import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.closed = {
      zIndex: 40,
      position: 'fixed',
      // p: 7,
      top: ['10%', '50%'],
      left: ['50%'],
      transform: ['translateX(-50%)', 'translateX(-50%) translateY(-50%)'],
      bg: 'background',
      opacity: 0,
      pointerEvents: 'none',
      minWidth: ['90%', '520px'],
      maxWidth: '900px',
      maxHeight: '80vh',
      width: ['calc(100vw - 40px)', 'auto'],
    };

    this.open = {
      ...this.closed,
      opacity: 1,
      pointerEvents: 'auto',
    };

    this.close = {
      position: 'absolute',
      cursor: 'pointer',
      width: '34px',
      right: '10px',
      top: '10px',
      height: '24px',
      variant: 'flex.row.center',
      display: 'flex',
      bg: 'background_medium',
      zIndex: 20,
    };

    this.icon = {
      width: [16, null, 18],
      cursor: 'pointer',
    };

    this.contentHidden = {
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .3s ease-in-out',
    };

    this.contentVisible = {
      ...this.contentHidden,
      opacity: 1,
    };
  })()
);
