import React from 'react';
import { Container } from 'theme-ui';

import { Picture, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Image.schema';

export const Image = withInView(
  ({ cms }) => {
    const { alt, mobile, desktop, section } = cms;

    return (
      <Section section={section}>
        <Container data-comp={Image.displayName}>
          <Picture
            data-comp={Image.displayName}
            alt={alt}
            images={[
              {
                src: mobile?.src?.src || mobile?.src,
                width: 768,
                ratio: mobile?.ratio || 1,
              },
              {
                src: desktop?.src?.src || desktop?.src,
                width: 1024,
                ratio: desktop?.ratio || 1.75,
              },
              {
                src: desktop?.src?.src || desktop?.src,
                width: 1280,
                ratio: desktop?.ratio || 1.75,
              },
              {
                src: desktop?.src?.src || desktop?.src,
                width: 1440,
                ratio: desktop?.ratio || 1.75,
              },
            ]}
            imageSx={{
              objectPosition: [mobile?.position, desktop?.position],
            }}
            sx={{
              minHeight: [mobile?.minHeight || 'unset', 'unset'],
              maxHeight: ['unset', desktop?.maxHeight || 'unset'],
            }}
          />
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

Image.displayName = 'Image';
Image.Schema = Schema;
