/*
  Small hack to prevent converting CollectionGrid into a
  section and adding this section to every collection.
  This section only needed for section settings
*/

import { Schema } from './CollectionGrid.schema';

export const CollectionGrid = ({ cms }) => {
  return <></>;
};

CollectionGrid.displayName = 'CollectionGrid';
CollectionGrid.Schema = Schema;
