import { useState } from 'react';
import dynamic from 'next/dynamic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from 'theme-ui';

import { themed } from './Displays.theme';

import 'swiper/css/bundle';

const Tile = dynamic(() => import('../Tile').then((module) => module.Tile), {
  ssr: false,
  suspense: false,
});

export const Slider = themed(({ theme, tiles, tile, inView }) => {
  const [swiper, setSwiper] = useState();

  return (
    <Container
      data-comp={Slider.displayName}
      sx={{
        ...theme.swiper,
        // Adds minimum aspect ratio before ThreeileItem js loads
        '.swiper-slide': {
          ...(tile?.imageRatio === 'square' ? theme.square : theme.portrait),
        },
      }}
    >
      <Swiper onSwiper={setSwiper} {...defaultParams}>
        {tiles?.slice(0, 4).map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {inView && <Tile item={item} tile={tile} />}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
});

Slider.displayName = 'Slider';

const defaultParams = {
  spaceBetween: 20,
  slidesPerView: 1.3,
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  slidesPerGroup: 1,
  centeredSlides: false,
  centeredSlidesBounds: true,
  centerInsufficientSlides: true,
  autoHeight: true,
  navigation: false,
  loop: false,
  watchOverflow: true,
  threshold: 25,
  breakpoints: {
    480: {
      spaceBetween: 20,
      slidesPerView: 1.3,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
    },
    600: {
      spaceBetween: 20,
      slidesPerView: 3.4,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
    },
    768: {
      spaceBetween: 20,
      slidesPerView: 4,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1024: {
      spaceBetween: 20,
      slidesPerView: 4,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1280: {
      spaceBetween: 20,
      slidesPerView: 4,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
  },
};
