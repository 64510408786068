import { Flex, Heading, Container } from 'theme-ui';
import { Section, Picture, Link } from '@snippets';

import { Schema } from './MobileNav.schema';
import { themed } from './MobileNav.theme';

export const MobileNav = themed(({ theme, cms }) => {
  const { section, showOnDesktop, content, navItems } = cms;

  const { contentPosition } = { ...content };

  if (!navItems?.length) return null;

  return (
    <Section section={section}>
      <Container
        data-comp={MobileNav.displayName}
        sx={theme.wrapper(showOnDesktop)}
      >
        <Flex variant="flex.row" as="ul" sx={theme.nav}>
          {navItems.map(({ link, media }) => {
            const { image, imageOverlay } = {
              ...media,
            };

            return (
              <Flex
                variant="flex.column.center.center"
                as="li"
                sx={theme.navItem}
              >
                <Picture
                  alt={image?.alt || link?.text}
                  images={[
                    {
                      src: image?.src,
                      width: 200,
                      ratio: 1,
                    },
                  ]}
                  sx={theme.picture({
                    overlayActive: imageOverlay,
                  })}
                />
                <Heading
                  as="h6"
                  sx={theme.navText({
                    contentPosition,
                  })}
                >
                  <Link href={link?.url}>{link?.text}</Link>
                </Heading>
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
});

MobileNav.displayName = 'MobileNav';
MobileNav.Schema = Schema;
