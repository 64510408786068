import { color, section, align } from '@settings/common';

export const Schema = ({ article, blog, collection }) => {
  if (blog || collection) return null;

  return {
    label: 'Text Block',
    key: 'text-block',
    fields: [
      {
        label: 'Content settings',
        name: 'content',
        description: 'Heading, body, link, text align',
        component: 'group',
        fields: [
          {
            name: 'body',
            label: 'Body',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            label: 'Content alignment (mobile)',
            options: align.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlignDt',
            label: 'Content alignment (desktop)',
            options: align.options,
          },
        ],
        defaultValue: {
          body:
            'Aenean lacinia bibendum nulla sed consectetur. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nullam id dolor id nibh ultricies vehicula ut id elit.\n' +
            '\n' +
            'Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam quis risus eget urna mollis ornare vel eu leo. Sed posuere consectetur est at lobortis.',
          cta: {
            text: '',
            url: '',
          },
          textAlign: align.value.left,
          textAlignDt: align.value.left,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
