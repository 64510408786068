import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slider = {
      '.swiper-container': {
        height: '100%',
        width: '100%',
      },
      height: '100%',
      width: '100%',
    };

    this.bullets = {
      display: 'flex',
      alignItems: 'center',
      height: 10,
      justifyContent: 'end',
      span: {
        backgroundColor: 'gray',
        height: 10,
        width: 10,
        mx: 2,
      },
      bottom: 0,
      cursor: 'pointer',
    };

    this.contentSlide = {
      position: 'relative',
    };
  })()
);
