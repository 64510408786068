import { useState, useEffect } from 'react';
import { Container, Box, Heading, Paragraph } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { CountrySelector, PortalModal } from '@snippets';

import { useGeolocation, useLocalStorage } from '@hooks';

export const GeolocationModal = ({ theme, ...props }) => {
  const settings = useSettings();
  const { userHeaders, currentCountry } = useGeolocation();
  const [modalClosed, setModalClosed] = useLocalStorage('countryModalClosed', false);
  const [openModal, setOpenModal] = useState(false);
  const [country, setCountry] = useState();
  //TODO: locations is returning just one result
  const {
    heading = '',
    subheading = '',
    countrySelector = true,
    locations = [],
  } = { ...settings?.header?.userLocationModal };

  const isUSA = userHeaders?.isoCode === 'US';

  const hideInThisCountry =
    locations.length === 0 || !locations.includes(userHeaders?.isoCode);

  const hideModal = !isUSA || hideInThisCountry;

  const handleOnClose = () => {
    setModalClosed(true);
    setOpenModal(false);
  };

  const handleCountryChange = () => {
    handleOnClose();
  };

  useEffect(() => {
    setCountry(currentCountry);
  }, []);

  useEffect(() => {
    if (userHeaders?.isoCode) {
      setOpenModal(!modalClosed && hideModal);
    }
  }, [hideInThisCountry, userHeaders?.isoCode, locations]);

  useEffect(() => {
    if (currentCountry?.name !== country?.name && modalClosed) {
      handleOnClose();
    }
  }, [currentCountry]);

  if (modalClosed) return null;
  if (!heading && !subheading) return null;
  return (
    <PortalModal showModal={openModal} onClose={handleOnClose}>
      <Container
        data-comp={GeolocationModal.displayName}
        sx={{
          backgroundColor: 'background_medium',
          overflow: 'auto',
          minHeight: 500,
          width: '100%',
          minWidth: '300px',
          '@media only screen and (max-width: 479px)': {
            minWidth: '250px',
          },
          textAlign: 'center',
          p: 10,
          px: 40,
        }}
      >
        <Heading
          as="h2"
          variant="text.h2"
          sx={{
            mb: 6,
            textAlign: 'center',
          }}
        >
          {heading}
        </Heading>
        {subheading && (
          <Paragraph variant="text.base-lg" my={8}>
            {subheading}
          </Paragraph>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '3rem',
          }}
        >
          {countrySelector && (
            <CountrySelector handleOnChange={handleCountryChange} />
          )}
        </Box>
      </Container>
    </PortalModal>
  );
};

GeolocationModal.displayName = 'GeolocationModal';
