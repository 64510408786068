import { useCallback, useEffect } from 'react';

import store, { useRecoilState, useSetRecoilState } from '@store';
import { useDebounce } from '@hooks';
import { useSearch } from './useSearch';

export const useSearchInput = () => {
  const [
    { suggested, isAutocomplete },
    { getSearchResults, getSearchSuggestions },
  ] = useSearch();
  const [rawTerm, setRawTerm] = useRecoilState(store.rawTerm);
  const [term, setTerm] = useRecoilState(store.term);
  const setSearchIsFocused = useSetRecoilState(store.searchIsFocused);
  const debouncedTerm = useDebounce(rawTerm, 500);

  const isSearching = rawTerm && rawTerm !== '';

  // clear input
  const resetRawTerm = useCallback(() => setRawTerm(''), []);

  const searchTerm = isAutocomplete ? suggested?.text : debouncedTerm;

  // update the search term with the debounced value
  const updateTermOnDebouncedTermChange = useCallback(() => {
    if (term !== debouncedTerm) {
      setTerm(debouncedTerm);
    }
  }, [debouncedTerm, term]);

  // update search suggestions and results on debounced term change
  const getSearchResultsOnDebouncedTermChange = useCallback(() => {
    getSearchResults({ query: searchTerm, isAutocomplete });
  }, [searchTerm, isAutocomplete]);

  useEffect(() => {
    getSearchSuggestions({ query: debouncedTerm });
  }, [debouncedTerm]);

  useEffect(() => { updateTermOnDebouncedTermChange() }, [debouncedTerm, term]);
  useEffect(() => { getSearchResultsOnDebouncedTermChange() }, [searchTerm]);

  return [
    { term, rawTerm, isSearching, suggested },
    {
      setTerm,
      setRawTerm,
      resetRawTerm,
      setSearchIsFocused,
    },
  ];
};
