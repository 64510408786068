import { useMemo } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { themed } from './Price.theme';

export const Price = themed(({ theme, price, compareAtPrice, products }) => {
  const prices = useMemo(() => {
    const priceStr = price?.toFixed(2);

    let _compareAtPrice = compareAtPrice;
    if (!compareAtPrice) {
      const compareAt = products?.reduce((acc, item) => {
        return acc + parseFloat(item?.variants?.[0]?.price || '0');
      }, 0);
      const compareAtStr = compareAt?.toFixed(2);
      _compareAtPrice = compareAtStr?.endsWith('.00')
        ? `${compareAt}`
        : compareAtStr;
    }

    return {
      final: priceStr?.endsWith('.00') ? `${price}` : priceStr,
      compareAt: _compareAtPrice,
    };
  }, [compareAtPrice, price, products?.map((v) => v?.id).join('')]);

  return (
    <Box data-comp={Price.displayName} sx={theme.wrapper}>
      <Flex sx={theme.price}>
        <Flex>
          <Paragraph sx={theme.price.lineThrough}>
            ${prices.compareAt}
          </Paragraph>
          <Paragraph sx={theme.price.regularRed}>${prices.final}</Paragraph>
        </Flex>
      </Flex>
    </Box>
  );
});

Price.displayName = 'Price';
