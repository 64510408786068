import { useMemo, useEffect, useRef } from 'react';
import { Box, Container, Flex } from 'theme-ui';
import animations from '@animations';
import { calcResponsiveHeight, parseContentPosition, withInView } from '@utils';
import { useBreakpointValue } from '@hooks';
import { Markdown, Section } from '@snippets';
import { space } from '@theme/theme.space';

import { Schema } from './FeaturedText.schema';
import { themed } from './FeaturedText.theme';

export const FeaturedText = withInView(
  themed(({ theme, cms }) => {
    const { text, section, animation } = cms;

    const revealRef = useRef(null);
    const container = useRef(null);

    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    const textAlign = useBreakpointValue([
      text?.alignmentMb,
      text?.alignmentDt,
    ]);

    const posMb = useMemo(
      () => parseContentPosition(text?.positionMb),
      [text?.positionMb]
    );

    const posDt = useMemo(
      () => parseContentPosition(text?.positionDt),
      [text?.positionDt]
    );

    const heading = text?.heading;

    const superheading = text?.superheading;

    useEffect(() => {
      if (animation?.showAnimation) {
        animations.fadeInReveal({ revealRef, container }, animation?.time);
      }
    }, []);

    return (
      <Section section={section}>
        <Container
          ref={container}
          data-comp={FeaturedText.displayName}
          sx={{
            ...theme.hero,
            height,
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <Flex
            sx={{
              ...theme.contentWrapper,
              alignItems: [posMb.alignItems, posDt.alignItems],
              justifyContent: [posMb.justifyContent, posDt.justifyContent],
            }}
          >
            <Box
              ref={revealRef}
              sx={{
                ...theme.content,
                maxWidth: text?.maxWidth
                  ? [
                      text.maxWidth + space[12] * 2,
                      text.maxWidth + space[15] * 2,
                    ]
                  : 'none',
              }}
            >
              {superheading?.text && (
                <Markdown
                  text={superheading?.text}
                  textSx={{
                    ...theme.superheading,
                    textAlign,
                    color: superheading?.color,
                    variant: `text.${superheading?.textVariant}`,
                  }}
                />
              )}
              {heading?.text && (
                <Markdown
                  text={heading?.text}
                  textSx={{
                    ...theme.heading,
                    textAlign,
                    color: heading?.color,
                    variant: `text.${heading?.textVariant}`,
                  }}
                />
              )}
            </Box>
          </Flex>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FeaturedText.displayName = 'FeaturedText';
FeaturedText.Schema = Schema;
