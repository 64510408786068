export default {
  label: 'Login Settings',
  name: 'login',
  component: 'group',
  fields: [
    // LoginForm settings
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Sign in',
    },
    {
      label: 'Sub heading',
      name: 'subHeading',
      component: 'textarea',
      defaultValue: '',
    },
    {
      label: 'Button text',
      name: 'buttonText',
      component: 'text',
      defaultValue: 'Sign In',
    },
    {
      label: 'Forgot password text',
      name: 'resetText',
      component: 'text',
      defaultValue: 'Forgot password?',
    },
    {
      label: 'Subscription Login Link Copy',
      name: 'subscriptionLoginLinkCopy',
      component: 'text',
      defaultValue: 'Manage Your VIP Membership',
    },
  ],
};
