import { store } from '@backpackjs/storefront';

const siteSettings = store.recoil.atom({
  key: 'cms/siteSettings',
  default: null,
});

export const sections = store.recoil.atomFamily({
  key: 'cms/sections',
  default: null,
  effects_UNSTABLE: [
    // store.effects.logStateChange('sections'),
  ],
});

export default {
  // atoms
  siteSettings,
  sections,
};
