export const sizeGuides = {
  label: 'Size Guides',
  name: 'sizeGuides',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}',
  },
  defaultItem: {
    name: 'Shirts',
    tag: 'ss-curve',
  },
  fields: [
    {
      label: 'Tag',
      name: 'tag',
      component: 'text',
      description: 'Must match a size-guide::tag',
    },
    {
      label: 'Category Name',
      name: 'name',
      component: 'text',
      defaultValue: "Men's Pants",
    },
    {
      label: 'Body Text',
      name: 'body',
      component: 'markdown',
      defaultValue:
        'Designed for the perfect fit. Use the size chart to find your new wardrobe stable.',
    },
    {
      label: 'Size Chart (in)',
      name: 'sizeChart',
      component: 'markdown',
    },
    {
      label: 'Size Chart (cm)',
      name: 'sizeChartCm',
      component: 'markdown',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'Contact Text',
      name: 'contactText',
      component: 'markdown',
      defaultValue:
        'For any other questions regarding size or fit. Please contact our team info@cutsclothing.com',
    },
  ],
};
