import { useState, useCallback } from 'react';
import { Flex, Paragraph } from 'theme-ui';

import { Svg } from '@snippets';

export const YotpoVotes = ({ alignedRight = true, endpoint, id, ...props }) => {
  if (!endpoint) throw new Error('[endpoint] not provided');
  if (!id) throw new Error('[id] not provided');

  const [votesUp, setVotesUp] = useState(props.votesUp);
  const [votedUp, setVotedUp] = useState(false);
  const [votesDown, setVotesDown] = useState(props.votesDown);
  const [votedDown, setVotedDown] = useState(false);

  const voteUp = useCallback(async () => {
    if (votedUp) {
      console.log('Un-voted up', endpoint);
      // un-vote up
      setVotesUp((state) => state - 1);
      setVotedUp(false);

      await fetch(`/api/yotpo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'voteUp',
          undo: true,
          endpoint,
          id,
        }),
      });
    } else {
      console.log('voted up', endpoint);
      setVotesUp((state) => state + 1);
      setVotedUp(true);

      await fetch(`/api/yotpo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'voteUp',
          undo: false,
          endpoint,
          id,
        }),
      });
    }
  }, [votedUp, id]);

  const voteDown = useCallback(async () => {
    if (votedDown) {
      console.log('un-voted down', endpoint);
      setVotesDown((state) => state - 1);
      setVotedDown(false);

      await fetch(`/api/yotpo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'voteDown',
          undo: true,
          endpoint,
          id,
        }),
      });
    } else {
      setVotesDown((state) => state + 1);
      setVotedDown(true);

      await fetch(`/api/yotpo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'voteDown',
          undo: false,
          endpoint,
          id,
        }),
      });
    }
  }, [votedDown, id]);

  return (
    <Flex
      data-comp={`${YotpoVotes.displayName}`}
      variant="flex.row.start.center"
    >
      {/* vote up */}
      <Flex
        variant="flex.row.start.center"
        sx={{
          flexDirection: alignedRight ? 'row' : 'row-reverse',
        }}
      >
        <Paragraph
          variant="akzidenzGrotesk"
          sx={{
            fontSize: 1,
          }}
        >
          {votesUp}
        </Paragraph>

        <Svg
          alt="Likes"
          src={
            votesUp
              ? '/svgs/noprecache/svg/liked.svg#liked'
              : '/svgs/noprecache/svg/like.svg#like'
          }
          width="16px"
          sx={{
            ml: alignedRight ? 3 : 'unset',
            mr: !alignedRight ? 3 : 'unset',
            cursor: 'pointer',
            color: votedUp || votesUp ? 'red' : 'darkGreen',
            '@media screen and (min-width: 480px)': {
              '&:hover': {
                color: 'red',
              },
            },
          }}
          onClick={voteUp}
        />
      </Flex>

      {/* vote down */}
      {/* <Flex variant='flex.row.start.center' sx={{ ml: 8 }}>
        <Svg
          alt="Dislikes"
          src='/svgs/noprecache/svg/disliked.svg#disliked'
          width="16px"
          sx={{
            mr: 3,
            cursor: 'pointer',
            color: votedDown ? 'green' : 'red'
          }}
          onClick={voteDown}
        />

        <Paragraph
          variant='akzidenzGrotesk'
          sx={{
            fontSize: 1,
          }}
        >
          {votesDown}
        </Paragraph>
      </Flex> */}
    </Flex>
  );
};

YotpoVotes.displayName = 'YotpoVotes';
