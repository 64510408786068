import { Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

import { themed } from './SocialLinks.theme';

export const SocialLinks = themed(({ theme, size = 20, ...props }) => {
  const settings = useSettings();
  const socialLinks = settings?.social?.links;

  return socialLinks?.length ? (
    <Flex data-comp={SocialLinks.displayName} {...props}>
      {socialLinks.map(({ network, handle }, index) => {
        return (
          <Link
            key={index}
            newTab
            href={
              networks[network] && handle
                ? `${networks[network].url}${handle}`
                : ''
            }
            aria-label={network}
            sx={{
              ...theme.link,
              width: size,
              height: size,
            }}
          >
            <Svg
              src={`/svgs/noprecache/social/${network}.svg#${network}`}
              alt={network}
              viewBox="0 0 24 24"
              sx={{
                ...theme.icon,
                width: size,
                height: size,
              }}
            />
          </Link>
        );
      })}
    </Flex>
  ) : null;
});

SocialLinks.displayName = 'SocialLinks';

const networks = {
  facebook: {
    url: 'https://www.facebook.com/',
  },
  instagram: {
    url: 'https://www.instagram.com/',
  },
  twitter: {
    url: 'https://www.twitter.com/',
  },
  pinterest: {
    url: 'https://www.pinterest.com/',
  },
  youtube: {
    url: 'https://www.youtube.com/',
  },
  vimeo: {
    url: 'https://www.vimeo.com/',
  },
  tiktok: {
    url: 'https://www.tiktok.com/@',
  },
};
