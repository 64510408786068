import { Link } from '@snippets';
import { Box } from 'theme-ui';
import { BreadCrumbs } from './BreadCrumbs';
import { MenuItems } from './MenuItems';

export const SidebarNav = ({
  collectionTitle,
  breadcrumbsLevelOneUrl,
  breadcrumbsLevelTwo,
  linksByCollection,
  breadcrumbsLevelOne,
}) => {
  const showBreadcrumbs =
    breadcrumbsLevelOne || breadcrumbsLevelTwo || collectionTitle;
  const showMenuItems = linksByCollection && linksByCollection.length > 0;
  const showAllCollectionLink = breadcrumbsLevelOne && breadcrumbsLevelOneUrl;

  return (
    <Box
      data-comp={SidebarNav.displayName}
      sx={{
        px: [0, 0, 0, 15],
      }}
    >
      {showBreadcrumbs && (
        <BreadCrumbs
          levelOne={breadcrumbsLevelOne}
          levelTwo={breadcrumbsLevelTwo}
          collectionTitle={collectionTitle}
        />
      )}

      {showMenuItems && <MenuItems links={linksByCollection} />}

      {showAllCollectionLink && (
        <Link
          animate
          href={breadcrumbsLevelOneUrl}
          title={breadcrumbsLevelOne}
          variant="text.link"
          sx={{ ml: 4 }}
        >
          All {breadcrumbsLevelOne}
        </Link>
      )}
    </Box>
  );
};

SidebarNav.displayName = 'SidebarNav';
