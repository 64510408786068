import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Shop by cut',
    key: 'shopByCut',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Shop By Cut',
      },
      {
        label: 'Items',
        name: 'items',
        component: 'group',
        itemProps: {
          label: '{{item.name}}',
        },
        defaultItem: {
          text: 'New item',
        },
        fields: [
          {
            name: 'name',
            component: 'text',
            label: 'Item name',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            name: 'link',
            component: 'link',
            label: 'Link to collection',
          },
        ],
        defaultValue: [
          {
            name: 'Crew',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/4KTASxg030RtbsjANGK5WQ/9ea32aac7570fccd96741c14d7ed0de9/COLLAR_TEMPLATE_crew_2.png',
            },
            link: {
              text: '',
              url: '/collections/crew-neck',
            },
          },
          {
            name: 'V-Neck',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/UjK67kpZ6j4xWfa3lckHe/f278efe832570b7b9c4bcc3e9ee83349/COLLAR_TEMPLATE_v_neck2.png',
            },
            link: {
              text: '',
              url: '/collections/v-neck',
            },
          },
          {
            name: 'Henley',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/2ynwnvhzZVgTsvBcF1rII/34990518804c093bb3890aaffc5770e5/COLLAR_TEMPLATE_henley_2.png',
            },
            link: {
              text: '',
              url: '/collections/henley',
            },
          },
          {
            name: 'Classic',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/54i8JA81emC2tmeRvqohqG/5f50f7a6e7338b1317ff96ae54c05dda/BOTTOM_CUT_Curve_2.png',
            },
            link: {
              text: '',
              url: '/collections/classic',
            },
          },
          {
            name: 'Split',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/3LNpZzdL5MxU8W9lLjFDyy/f91fc492e49366d99add44e1da5029cb/BOTTOM_CUT_Split.png',
            },
            link: {
              text: '',
              url: '/collections/split-hem',
            },
          },
          {
            name: 'Elongated',
            image: {
              src: 'https://images.ctfassets.net/gm2gqumrpcat/4mzQejCZQKrstrpqqKNrgU/15ded5d9c33c4354c4263c26020bbf25/BOTTOM_CUT_Elongated_1.png',
            },
            link: {
              text: '',
              url: '/collections/elongated',
            },
          },
        ],
      },
      section,
    ],
  };
};
