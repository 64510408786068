import { requestYotpoLoyalty } from '@utils';

const getYotpoCustomer = async ({ email }) => {
  try {
    return requestYotpoLoyalty({
      action: 'getCustomer',
      data: { email },
    });
  } catch (error) {
    throw error;
  }
};

export const getYotpoLoyaltyData = async (props) => {
  const action =
    typeof props?.action === 'function' ? props?.action : () => null;

  const email = props?.email;

  try {
    const [yotpoCustomer] = await Promise.all([
      getYotpoCustomer({ email: email }),
    ]);

    if (yotpoCustomer?.data?.error) {
      throw yotpoCustomer.data.error;
    }

    const data = {
      yotpoCustomer: yotpoCustomer.data,
    };

    action(data);
  } catch (error) {
    throw error;
  }
};
