import { sectionWithHeight, color, align } from '@settings/common';

export const Schema = () => ({
  label: 'Testimonials VIP',
  key: 'testimonials-vip',
  fields: [
    {
      label: 'Testimonial settings',
      name: 'slider',
      description:
        'Autoplay, delay, transition effect, bullet color, content position',
      component: 'group',
      fields: [
        {
          label: 'Enable autoplay',
          name: 'autoplay',
          description: 'Refresh page to observe change',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'delay',
          component: 'number',
          description: 'Refresh page to observe change',
          label: 'Delay between transitions (ms)',
        },
        {
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          name: 'effect',
          label: 'Effect between transitions',
          description: 'Refresh page to observe change',
          options: [
            { label: 'Slide', value: 'slide' },
            { label: 'Fade', value: 'fade' },
          ],
        },
        {
          name: 'opacity',
          component: 'number',
          description: 'Refresh page to observe change',
          label: 'Opacity unselected thumbs (%)',
          defaultValue: 50,
        },
      ],
      defaultValue: {
        autoplay: true,
        delay: 5000,
        effect: 'fade',
        defaultValue: 50,
      },
    },
    {
      name: 'title',
      label: 'Title',
      component: 'text',
      description: 'Section title',
      defaultValue: 'Member Testimonials',
    },
    {
      label: 'Testimonial Version',
      name: 'isVersion2',
      component: 'toggle',
      description: 'Choose testimonial version',
      toggleLabels: {
        true: 'Version 2',
        false: 'Version 1',
      },
      defaultValue: false,
    },
    {
      label: 'Testimonials',
      name: 'testimonials',
      description: 'Text, image and color',
      component: 'group-list',
      defaultItem: {
        testimonialText: {
          heading: 'Andrew',
          headingColor: color.value.black,
          subheading: 'New VIP Member',
          subheadingColor: color.value.black,
          quote:
            "“As a proud member of the Cuts Clothing VIP membership program, I can confidently say it's the best thing that ever happened to my wardrobe! The quality products and incredible deals have not only saved me money, but the membership practically pays for itself. It's truly a game-changer for anyone looking to elevate their style without breaking the bank.”",
          quoteColor: color.value.black,
          alignment: align.value.left,
          alignmentDt: align.value.left,
        },
        media: {
          alt: 'Logo',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/TestimonialsVIP.png?v=1713291281',
          },
        },
      },
      fields: [
        {
          name: 'testimonialText',
          component: 'group',
          label: 'Heading - Subheading - Quote',
          description: 'Heading, subheading and quote',
          fields: [
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
              defaultValue: 'Andrew',
              description: 'Testimonial Name',
            },
            {
              label: 'Heading color',
              name: 'headingColor',
              component: 'select',
              options: color.options,
              defaultValue: color.value.black,
            },
            {
              label: 'Subheading',
              name: 'subheading',
              component: 'text',
              defaultValue: 'New VIP Member',
            },
            {
              label: 'Subheading color',
              name: 'subheadingColor',
              component: 'select',
              options: color.options,
              defaultValue: color.value.black,
            },
            {
              label: 'Quote',
              name: 'quote',
              component: 'markdown',
              defaultValue:
                "“As a proud member of the Cuts Clothing VIP membership program, I can confidently say it's the best thing that ever happened to my wardrobe! The quality products and incredible deals have not only saved me money, but the membership practically pays for itself. It's truly a game-changer for anyone looking to elevate their style without breaking the bank.”",
            },
            {
              label: 'Quote color',
              name: 'quoteColor',
              component: 'select',
              options: color.options,
              defaultValue: color.value.black,
            },
            {
              label: 'Content alignment (mobile)',
              name: 'alignment',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: align.options,
            },
            {
              label: 'Content alignment (desktop)',
              name: 'alignmentDt',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: align.options,
            },
          ],
        },
        {
          name: 'stars',
          label: 'Stars',
          component: 'group',
          description: 'Stars',
          fields: [
            {
              name: 'color',
              component: 'select',
              options: color.options,
              defaultValue: color.value.yellow,
            },
            {
              label: 'Stars Number',
              name: 'number',
              component: 'number',
              description: 'Number of stars to display. Max 5',
              defaultValue: 5,
            },
          ],
        },
        {
          name: 'media',
          label: 'Media',
          component: 'group',
          description: 'Image',
          fields: [
            {
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
              label: 'Alt',
            },
            {
              label: 'Image',
              name: 'image',
              component: 'image',
              description: 'image or logo',
            },
          ],
        },
        {
          label: 'Placement (desktop)',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          name: 'locationDt',
          options: [
            {
              value: 'left',
              label: 'Left',
            },
            {
              value: 'right',
              label: 'Right',
            },
          ],
          defaultValue: 'left',
        },
      ],
      defaultValue: [
        {
          testimonialText: {
            heading: 'Andrew',
            headingColor: color.value.black,
            subheading: 'New VIP Member',
            subheadingColor: color.value.black,
            quote:
              "“As a proud member of the Cuts Clothing VIP membership program, I can confidently say it's the best thing that ever happened to my wardrobe! The quality products and incredible deals have not only saved me money, but the membership practically pays for itself. It's truly a game-changer for anyone looking to elevate their style without breaking the bank.”",
            quoteColor: color.value.black,
            alignment: align.value.left,
            alignmentDt: align.value.left,
          },
          media: {
            alt: 'Logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/TestimonialsVIP.png?v=1713291281',
            },
          },
        },
      ],
    },
    {
      ...sectionWithHeight,
      defaultValue: {
        mobile: {
          maxHeight: 324,
          minHeight: 324,
        },
        desktop: {
          maxHeight: 600,
          minHeight: 600,
        },
      },
    },
  ],
});
