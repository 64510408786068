import { Button } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './VideoPlayButton.theme';

export const VideoPlayButton = themed(
  /**
   * @param {import ('./types/videoPlayButton.types').Props } props
   */
  ({
    src = '/svgs/play.svg#play',
    alt = 'Play',
    onClick,
    showIcon,
    iconStyles,
    theme,
    styles,
    viewBox = '-55 0 150 68',
    ...props
  }) => {
    return (
      <Button
        data-comp={VideoPlayButton.displayName}
        onClick={onClick}
        sx={{
          ...theme.button,
          ...styles,
        }}
        {...props}
      >
        {showIcon && (
          <Svg
            alt={alt}
            src={src}
            draggable={false}
            viewBox={viewBox}
            sx={{ ...theme.icon, ...iconStyles }}
          />
        )}
      </Button>
    );
  }
);

VideoPlayButton.displayName = 'VideoPlayButton';
