import { forwardRef, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

export const WithRevealMask = forwardRef(
  ({ children, color = 'lightCream', exit, id, ...props }, ref) => {
    return (
      <Box
        id={id}
        data-comp="WithRevealMask"
        {...props}
        sx={{
          ...props.sx,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {Children.map(children, (child) =>
          cloneElement(child, { sx: { zIndex: 0 } })
        )}

        {/* Mask, will be (exit) up, down, left or right */}
        <Box
          data-comp="WithRevealMaskOverlay"
          ref={ref}
          sx={{
            position: 'absolute',
            border: 0,
            m: 0,
            p: 0,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            willChange: 'transform',
            bg: color,
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
      </Box>
    );
  }
);
