import { useState } from 'react';
import { Container, Heading, Button, Box, Flex } from 'theme-ui';

import { pxWithFullBleed, convertAlignToFlex } from '@utils/common';
import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductRecs.schema';
import { themed } from './ProductRecs.theme';

import { Recommendations } from './Slider/Recommendations';
import { Header } from './Header';

export const ProductRecs = withInView(
  themed(({ theme, cms, inView }) => {
    const {
      heading,
      textAlign,
      limit,
      ctas,
      section,
      tags,
      tag,
      slidesPerViewMobile,
    } = cms;

    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section}>
        <Container data-comp={ProductRecs.displayName} sx={theme.wrapper}>
          {tags?.length ? (
            <Box sx={{ ...theme.header, ...pxWithFullBleed(section) }}>
              {inView && (
                <Header
                  activeIndex={activeIndex}
                  groups={tags}
                  heading={heading}
                  textAlign={textAlign}
                  setActiveIndex={setActiveIndex}
                  section={section}
                />
              )}
            </Box>
          ) : (
            <Heading
              sx={{
                ...theme.heading,
                textAlign,
                ...pxWithFullBleed(section),
              }}
            >
              {heading}
            </Heading>
          )}

          {(tags?.length > 0 || tag) && (
            <Recommendations
              theme={theme}
              tag={tags?.length > 0 ? tags[activeIndex]?.tag : tag}
              limit={limit}
              slidesPerViewMobile={slidesPerViewMobile}
              inView={inView}
              currentIndex={activeIndex}
            />
          )}

          {ctas?.[0]?.cta?.text && (
            <Flex
              sx={{
                ...pxWithFullBleed(section),
                justifyContent: convertAlignToFlex(ctas[0].align),
              }}
            >
              <Button
                href={ctas[0].cta.href}
                variant={`buttons.${ctas[0].style || 'primary'}`}
                sx={{
                  mt: [10, 12],
                }}
              >
                {ctas[0].cta.text}
              </Button>
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductRecs.displayName = 'ProductRecs';
ProductRecs.Schema = Schema;
