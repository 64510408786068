const object = [
  {
    value: 'left top',
    label: 'Left Top',
  },
  {
    value: 'left center',
    label: 'Left Center',
  },
  {
    value: 'left bottom',
    label: 'Left Bottom',
  },
  {
    value: 'right top',
    label: 'Right Top',
  },
  {
    value: 'right center',
    label: 'Right Center',
  },
  {
    value: 'right bottom',
    label: 'Right Bottom',
  },
  {
    value: 'center top',
    label: 'Center Top',
  },
  {
    value: 'center center',
    label: 'Center Center',
  },
  {
    value: 'center bottom',
    label: 'Center Bottom',
  },
];

const flex = [
  {
    value: 'flex-start flex-start',
    label: 'Left Top',
  },
  {
    value: 'flex-start center',
    label: 'Left Center',
  },
  {
    value: 'flex-start flex-end',
    label: 'Left Bottom',
  },
  {
    value: 'flex-end flex-start',
    label: 'Right Top',
  },
  {
    value: 'flex-end center',
    label: 'Right Center',
  },
  {
    value: 'flex-end flex-end',
    label: 'Right Bottom',
  },
  {
    value: 'center flex-start',
    label: 'Center Top',
  },
  {
    value: 'center center',
    label: 'Center Center',
  },
  {
    value: 'center flex-end',
    label: 'Center Bottom',
  },
];

export const position = {
  flex,
  object,
};
