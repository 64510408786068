import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.footer = {
      position: 'relative',
      height: 'max-content',
    };
  })()
);
