import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paragraph, Flex, Text, Button } from 'theme-ui';
import { useCartRemoveItem, useSettings } from '@backpackjs/storefront';

import { Svg, Picture, Link, Locale } from '@snippets';
import { useSidebar } from '@hooks';

import { Quantity } from './Quantity';
import { Price } from './Price';
import { themed } from './LineItem.theme';

export const LineItem = themed(
  ({
    theme,
    inSidebar,
    isLast,
    item,
    showDiscount,
    isCashbackProduct,
    ...props
  }) => {
    const { cartRemoveItem, ...cartRemoveStatus } = useCartRemoveItem();
    const [{}, { closeSidebar }] = useSidebar();
    const settings = useSettings();

    const discountText =
      settings?.cart?.discountsText || 'Discounts will reflect in checkout';
    const discountDescription =
      item?.discountAllocations?.[0]?.discountApplication?.description;

    const isKitProduct = useMemo(
      () => discountDescription?.toLowerCase().includes('kit'),
      [discountDescription]
    );

    const isUpSellProduct = useMemo(
      () =>
        item.attributes.some(
          ({ key, value }) => key === 'upsell' && value === 'true'
        ),
      [item.attributes]
    );

    const upsellPrice = useMemo(() => {
      if (!isUpSellProduct) return null;
      return (
        item.attributes.find(({ key }) => key === 'upsellTierPrice')?.value ||
        null
      );
    }, [isUpSellProduct, item.attributes]);

    const upsellCartItem = useMemo(() => {
      if (!upsellPrice) return item;
      return {
        ...item,
        estimatedCost: {
          ...item.estimatedCost,
          totalAmount: {
            amount: `${upsellPrice}`,
            currencyCode: 'USD',
          },
        },
      };
    }, [upsellPrice, item]);

    const isPreOrder = useMemo(
      () => item?.attributes?.some((attr) => attr.key === 'pre-order'),
      [item?.attributes]
    );

    const productUrl = `/products/${item.variant.product.handle}?variant=${item.variant.id}`;
    const isGiftCard = item?.variant?.product?.handle === 'gift-card';

    // Apply the sidebar or cart page context
    const themeContext = inSidebar ? theme.sidebar : theme.page;

    const ariaTitle = `${item.variant?.product?.title} ${
      item.variant?.title === 'Default Title' ? '' : item.variant?.title
    }`;

    const { message, textColor, maxQty } = settings?.cart?.preOrder || {};
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          ...(isLast ? themeContext.item.last : themeContext.item.between),
        }}
      >
        <Grid
          data-comp={LineItem.displayName}
          {...props}
          sx={{
            ...props.sx,
            ...themeContext.item,
          }}
        >
          {/* Left column — picture */}
          <Flex sx={themeContext.column.left}>
            <Link
              href={productUrl}
              aria-label={item.variant?.product?.title}
              tabIndex="-1"
              onClick={() => {
                if (inSidebar) closeSidebar();
              }}
            >
              <Picture
                alt={item.variant?.altText}
                images={[
                  {
                    src:
                      item.variant?.image?.src ||
                      '/svgs/noprecache/svg/default-image.svg',
                    ratio:
                      item.variant.image?.width && item.variant.image?.height
                        ? item.variant.image.width / item.variant.image.height
                        : 1,
                    width: 70,
                  },
                ]}
                sx={themeContext.picture}
              />
            </Link>
          </Flex>

          {/* Center column — title, quantity, etc. */}
          <Flex sx={themeContext.column.center}>
            {/* Variant option title */}
            {isPreOrder && (
              <Paragraph
                sx={{
                  ...themeContext.title.variant,
                  color: textColor || 'text',
                  fontSize: 1,
                  fontWeight: 'bold',
                }}
              >
                {message || 'Pre-Order'}
              </Paragraph>
            )}
            {!isGiftCard && (
              <Paragraph
                sx={{
                  ...themeContext.title.variant,
                  color: isPreOrder ? textColor : 'gray.dark',
                }}
              >
                {item.variant.title === 'Default Title'
                  ? ''
                  : item.variant.title}
              </Paragraph>
            )}

            {/* Product title */}
            <Link
              href={productUrl}
              aria-label={item.variant.product.title}
              sx={{ mb: 1 }}
            >
              <Paragraph
                sx={themeContext.title.product}
                onClick={() => {
                  if (inSidebar) closeSidebar();
                }}
              >
                {item.variant.product.title}
              </Paragraph>
            </Link>

            {/* Show subscription option */}
            {item?.sellingPlanAllocation?.sellingPlan?.name && (
              <Paragraph sx={themeContext.title.plan}>
                {item?.variant?.product?.handle === 'vip-membership'
                  ? item.sellingPlanAllocation.sellingPlan.name.replace(
                      'Delivery',
                      'Renews'
                    )
                  : item.sellingPlanAllocation.sellingPlan.name}
              </Paragraph>
            )}

            {!isCashbackProduct && (
              <Quantity
                id={item.id}
                inSidebar={inSidebar}
                quantity={item.quantity}
                quantityDisabled={isKitProduct || isUpSellProduct}
                ariaTitle={ariaTitle}
                isPreOrder={isPreOrder}
                maxQuantity={maxQty}
              />
            )}
          </Flex>

          {/* Right column — price, remove */}
          <Flex sx={themeContext.column.right}>
            <Button
              onClick={async () => {
                await cartRemoveItem({ lineId: item.id });
              }}
              aria-label={`Remove ${ariaTitle} from cart`}
              sx={{ variant: 'buttons.plain', mb: 'auto', py: '0 !important' }}
            >
              <Svg
                alt="Remove item from cart"
                src="/svgs/remove-trash.svg#remove-trash"
                viewBox="0 0 16 16"
                sx={themeContext.remove}
              />
            </Button>

            {/* Price */}
            <Locale.CartLine line={isUpSellProduct ? upsellCartItem : item}>
              <Locale.Price>
                {(priceProps) => (
                  <Price
                    {...priceProps}
                    inSidebar={inSidebar}
                    discountDescription={discountDescription}
                    isDiscounted={!!item?.discountAllocations?.length}
                  />
                )}
              </Locale.Price>
            </Locale.CartLine>
          </Flex>
        </Grid>
        {(showDiscount || !!item?.discountAllocations?.length) && (
          <Text sx={themeContext.showDiscount}>{discountText}</Text>
        )}
      </Flex>
    );
  }
);
LineItem.displayName = 'LineItem';
LineItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  inSidebar: PropTypes.bool,
};
