/**
 * Function to flat nested object
 * @param {*} obj
 * @param {*} roots
 * @param {string} sep string to concatenate the keys
 * @returns
 */
export const flattenObject = (obj, roots = [], sep = '_') =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? flattenObject(obj[prop], roots.concat([prop]), sep)
          : { [roots.concat([prop]).join(sep)]: obj[prop] }
      ),
    {}
  );
