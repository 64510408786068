import Script from 'next/script';
import { useMemo, useEffect, useRef, useState } from 'react';

import { useCustomer, useCart } from '@backpackjs/storefront';

import { getValidId } from '@utils';

export const CartHead = () => {
  const scriptRef = useRef(null);
  const customer = useCustomer();
  const cart = useCart();
  const [mounted, setMounted] = useState(false);

  const cartItems = cart?.lines;

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 2000);
  }, []);

  const customerId = useMemo(
    () => (customer?.id ? getValidId(customer?.id) : ''),
    [customer?.id]
  );
  const handleScript = async () => {
    try {
      if (!cart?.updatedAt || !mounted) return;
      const body = document.getElementsByTagName('body')[0];
      if (scriptRef.current) {
        await body.removeChild(scriptRef.current);
      }

      const skus = JSON.stringify(
        cartItems?.map((item) => item.variant.sku) || []
      );

      const haveItemArr = cartItems?.map((item) => {
        return `IntelliSuggest.haveItem({sku:"${item.variant.sku}", qty:"${item.quantity
          }", price:"${(
            item.estimatedCost.totalAmount.amount / item.quantity
          ).toFixed(2)}"});`;
      });
      const haveItems = haveItemArr?.join('\n') || '';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ss-intellisuggest-cart-script';
      script.innerHTML = `
        try{
          IntelliSuggest.init({siteId:'${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}', context:'Basket', seed:${skus}});
          IntelliSuggest.setShopperId('${customerId}');
          ${haveItems}
          IntelliSuggest.inBasket({});
        } catch(err) {}`;
      scriptRef.current = script;
      if (body) body.appendChild(script);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleScript();
  }, [cart?.updatedAt, mounted]);

  return (
    <Script
      type="text/javascript"
      src="https://cdn.searchspring.net/intellisuggest/is.min.js"
    />
  );
};

CartHead.displayName = 'CartHead';
