import { Box, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Accordions, Accordion, Link } from '@snippets';

import { themed } from './MobileMenu.theme';

export const MobileMenu = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const menu = settings?.footer?.menu;

  return (
    <Box data-comp={MobileMenu.displayName} {...props} sx={theme.grid}>
      <Accordions textColor="white" borderColor="white" iconColor="white">
        {menu?.map((menuItem, index) => (
          <Accordion
            key={index}
            sx={{
              color: 'white',
            }}
          >
            <Accordion.Header>{menuItem.name}</Accordion.Header>

            <Accordion.Body>
              {menuItem.subMenuItems?.map((subMenuItem, index) => {
                return (
                  <Flex key={index} as="li">
                    <Link href={subMenuItem.cta?.url} sx={theme.link}>
                      {subMenuItem.cta?.text}
                    </Link>
                  </Flex>
                );
              })}
            </Accordion.Body>
          </Accordion>
        ))}
      </Accordions>
    </Box>
  );
});

MobileMenu.displayName = 'MobileMenu';
