import Head from 'next/head';

const PageHead = () => {
  return (
    <Head>
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      {/* pwa (progressive web app) */}
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/icon-32x32.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta
        name="theme-color"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#000000'}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta
        name="msapplication-TileColor"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#000000'}
      />
      <meta name="msapplication-tap-highlight" content="no" />
    </Head>
  );
};

export default PageHead;
