import { useCallback, useState, useEffect } from 'react';
import { useCartItems, useCustomer } from '@backpackjs/storefront';

import { useSearchspring } from '@hooks';
import { isBrowser } from '@utils';

// https://searchspring.zendesk.com/hc/en-us/articles/360052114611-Personalized-Recommendations-API-Request

export const useProductRecommendations = ({ tag, productId, limit }) => {
  const { requestSearchspring } = useSearchspring();
  const customer = useCustomer();
  const cartItems = useCartItems();
  const [recommendations, setRecommendations] = useState(null);
  const [profileData, setProfileData] = useState();

  const shopper = customer?.id || '';
  const cart =
    tag === 'view-cart'
      ? cartItems?.map((item) => item.variant.sku).join(',') || ''
      : '';
  const lastViewed =
    isBrowser && tag === 'recently-viewed'
      ? window.localStorage.getItem('lastViewed') || ''
      : '';

  // Get product recommendations from Searchspring
  const getProductRecommendations = useCallback(async () => {
    try {
      if (!tag) {
        // Tag derived from product recommendation profile from Searchspring
        // E.g. 'customers-also-bought', 'customers-also-viewed', 'recently-viewed', 'home', 'view-cart'
        throw new Error('Tag is required to get product recommendations');
      }

      const data = await requestSearchspring({
        action: 'getRecommendations',
        params: {
          tags: tag, // required; comma-separated list of profile ids from ss
          product: productId || '', // product id or sku of product being viewed
          shopper, // customer id (if logged in)
          cart, // comma-separated list of product skus in the user's cart
          lastViewed, // comma-separated list of 5 most recent product ids or skus
          limits: limit > 20 ? 20 : limit, // max of 20
        },
      });

      const results = data?.[0]?.results?.map((item) => {
        return {
          handle: item.mappings?.core?.url?.split('/').pop() || '',
          id: item.id,
        };
      });

      setProfileData(data?.[0] || null);
      setRecommendations(results || null);
      const formattedRecs = data?.[0]?.results?.map((item) => {
        return {
          handle: item.mappings?.core?.url?.split('/').pop() || '',
        };
      });

      setRecommendations(formattedRecs || null);
    } catch (error) {
      console.error(`Error with getProductRecommendations: ${error.message}`);
      throw error;
    }
  }, [cart, shopper, tag, productId, limit, lastViewed]);

  useEffect(() => {
    getProductRecommendations();
  }, [cart, shopper, tag, productId, limit, lastViewed]);

  return {
    profileData,
    profileTag: profileData?.profile?.tag || null,
    recommendations,
    getProductRecommendations,
  };
};
