import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.link = {
      mr: 10,
      transition: 'opacity 0.2s ease-in-out',
      '@media screen and (min-width: 480px)': {
        ':hover': {
          opacity: 0.8,
        },
      },
    };

    this.icon = {
      color: 'currentColor',
    };
  })()
);
