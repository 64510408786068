export const defaultSlide1 = () => ({
  media: {
    mediaTypeDt: 'video',
    mediaTypeMb: 'image',
    image: {
      alt: 'Hero image',
      image: {
        src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
      },
      position: 'center center',
      imageDt: {
        src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
      },
      positionDt: 'center center',
    },
    video: {
      titleMb: 'Hero video',
      videoMb:
        'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
      titleDt: 'Hero video',
      videoDt:
        'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
    },
  },
  text: {
    heading: {
      headingText: '# The New Business Casual',
      headingAlignmentMb: 'center',
      headingAlignmentDt: 'center',
      headingColorMb: '',
    },
    superheading: {
      superheadingText: '',
      superheadingAlignmentMb: 'center',
      superheadingAlignmentDt: 'center',
      superheadingColorMb: '',
    },
    subheading: {
      subheadingText: 'Versatile. Confident. Made for the Modern Work Setting.',
      subheadingAlignmentMb: 'center',
      subheadingAlignmentDt: 'center',
      subheadingColorMb: '',
    },
  },
  button: {
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
    ],
  },
  content: {
    globalAlignment: false,
    alignment: 'center',
    position: 'center center',
    alignmentDt: 'center',
    positionDt: 'center center',
    maxWidth: 1000,
  },
});

export const defaultSlide2 = ({ color }) => ({
  media: {
    mediaTypeDt: 'video',
    mediaTypeMb: 'image',
    image: {
      alt: 'Hero image',
      image: {
        src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
      },
      position: 'center center',
      imageDt: {
        src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
      },
      positionDt: 'center center',
    },
    video: {
      titleMb: 'Hero video',
      videoMb:
        'https://cdn.shopify.com/s/files/1/1368/3463/files/STAPLES_3x1_Hero_01.mp4?v=1649123562',
      titleDt: 'Hero video',
      videoDt:
        'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
    },
  },
  text: {
    heading: {
      headingText: '# Explore New Products',
      headingAlignmentMb: 'center',
      headingAlignmentDt: 'center',
      headingColorMb: color.value.white,
      headingColorDt: color.value.white,
    },
    subheading: {
      superheadingText: 'Backpack Storefronts',
      superheadingAlignmentMb: 'center',
      superheadingAlignmentDt: 'center',
      superheadingColorMb: '',
    },
    subheading: {
      subheadingText: 'New products are added every day.',
      subheadingAlignmentMb: 'center',
      subheadingAlignmentDt: 'center',
      subheadingColorMb: '',
    },
    showLogo: true,
    width: 25,
    image: {
      src: '',
      ratio: 0.5,
    },
  },
  button: {
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      {
        cta: {
          text: 'Promos',
          url: '/collections/all',
        },
        style: 'primary',
      },
    ],
  },
  content: {
    globalAlignment: true,
    alignment: 'center',
    position: 'center center',
    alignmentDt: 'center',
    positionDt: 'center center',
    maxWidth: 1000,
  },
});
