import { color, button, text, margin, padding } from '@settings/common';

export const vipMembership = {
  label: 'VIP membership',
  name: 'vipMembership',
  component: 'group',
  fields: [
    {
      label: 'VIP Membership heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Perks list',
      name: 'perksList',
      component: 'markdown',
    },
    {
      label: 'Link heading',
      name: 'linkHeading',
      component: 'text',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Bottom description',
      name: 'description',
      component: 'text',
    },
  ],
  defaultValue: {
    heading: 'the perks of being a member',
    perksList:
      '• 25% off on all items for 12 months\n\n• Free US & CA Standard Shipping \n\n• #1 priority for early access\n\n• 1.5 Points Multiplier\n\n• Early Access to Sales\n\n• Early Access to New Products\n\n• Exclusive Offers',
    linkHeading: 'Looking to share this with someone special?',
    link: {
      text: 'Purchase as Gift',
      url: '/products/gift-card',
    },
    description: 'Please note membership purchases are non-refundable.',
  },
};
