import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.shell = {
      bg: 'background',
      left: 0,
      position: 'absolute',
      display: ['block', null, 'none'],
      top: 'var(--header-height)',
      transform: 'translateX(-100%)',
      width: ['100%', '375px'],
      willChange: 'transform',
      zIndex: 1,
    }),
      (this.scroll = {
        pb: '100px',
        pt: 0,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: 'gray.mediumLight',
        height: `calc(var(--viewport-height) - var(--header-height))`,
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      });

    this.sidebar = {};

    this.accordionNav = {
      px: 10,
    };

    this.bestSellers = {
      // pl: 10,
    };

    this.links = {
      variant: 'flex.column',
      mt: 14,
      py: 14,
      px: 10,
      bg: 'gray.light',

      link: {
        variant: 'text.navList',
      },
    };

    this.newsletter = {
      mt: 11,
      variant: 'flex.column',

      heading: {
        variant: 'text.label.1',
        mb: 8,
      },
    };
  })()
);
