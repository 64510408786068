import { useMemo } from 'react';

export const useProductColors = (colorGroupsMap, desireOrder = []) => {

  const orderedColorGroups = useMemo(() => {
    const groups = Object.keys(colorGroupsMap).sort();
    const arrayMap = groups.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: currentValue,
      }),
      {}
    );
    return desireOrder.map((key) => arrayMap[key]).filter((value) => value);
  }, [Object.keys(colorGroupsMap).join('')]);

  const totalColors = orderedColorGroups
    .map((colorGroupName) => colorGroupsMap[colorGroupName])
    .flat()
    .sort();

  return { totalColors, orderedColorGroups };
};
