const { flattenObject } = require('./flattenObject');

const getUrlGroupsInfo = (dataSet, matchFn) => {
  // console.log('matchFn', matchFn);
  // console.log('dataSet', dataSet);
  return dataSet.reduce((current, row) => {
    if (typeof row === 'object') {
      for (const key in row) {
        if (Array.isArray(row[key])) {
          return getUrlGroupsInfo(row[key], matchFn);
        }
        if (typeof row[key] === 'object') {
          for (const innerKey in row[key]) {
            if (matchFn(row[key][innerKey])) {
              current.push({
                url: row[key][innerKey],
                text: row[key].text,
                group: row.group,
              });
            }
          }
        }
      }
    }
    return current;
  }, []);
};

const getLinksByGroup = (links) => {
  const groups = new Map();
  links.forEach((link) => {
    if (groups.has(link.group)) {
      groups.set(link.group, [...groups.get(link.group), link]);
    } else {
      groups.set(link.group, [link]);
    }
  });
  return Array.from(groups, ([group, links]) => ({ group, links }));
};

export const getCurrentMenuItems = (products, currentPath) => {
  for (const element of products) {
    const [answer] = getUrlGroupsInfo(
      [flattenObject(element)],
      (element) =>
        element && element.toString().toLowerCase().indexOf(currentPath) !== -1
    );
    // console.log('answer', answer);
    if (answer) {
      const { link_text, links, link_url } = flattenObject(element);
      const linksByGroup = getLinksByGroup(links);
      return {
        collectionTitle: answer.text,
        breadcrumbsLevelOne: link_text,
        breadcrumbsLevelOneUrl: link_url,
        breadcrumbsLevelTwo: answer.group,
        linksByCollection: linksByGroup,
      };
    }
  }
};

export const getMenuItems = (products, currentPath) => {
  const menuItems = getCurrentMenuItems(products, currentPath);
  if (!menuItems) {
    for (const element of products) {
      const { link_text, links, link_url } = flattenObject(element);
      const linksByGroup = getLinksByGroup(links);
      return {
        collectionTitle: link_text,
        breadcrumbsLevelOne: link_text,
        breadcrumbsLevelOneUrl: link_url,
        breadcrumbsLevelTwo: currentPath.split('/')[1],
        linksByCollection: linksByGroup,
      };
    }
  }
  return menuItems;
};
