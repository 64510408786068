import { color } from '../common';

export const rotatingBar = {
  label: 'Rotating bar',
  name: 'rotatingBar',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    {
      name: 'isSticky',
      label: 'Sticky',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      name: 'heightSize',
      label: 'Height Size',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          value: 'regular',
          label: 'Regular',
        },
        {
          value: 'tall',
          label: 'Tall',
        },
      ],
      defaultValue: 'regular',
    },
    {
      label: 'Background color',
      name: 'background',
      component: 'color',
      defaultValue: color.value.text,
    },
    {
      label: 'Text color',
      name: 'color',
      component: 'color',
      defaultValue: color.value.background,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.copy}}',
      },
      fields: [
        {
          label: 'Copy',
          name: 'copy',
          component: 'text',
          defaultValue: 'Free U.S. Returns & Shipping $150+',
        },
        {
          label: 'Link (optional)',
          name: 'link',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          copy: 'NEW SHORTS AVAILABLE [SHOP NOW]',
          link: {
            url: '/collections/all-shorts',
            text: 'ALL SHORTS',
          },
        },
        {
          copy: 'Free U.S. Returns & Shipping $150+',
          link: {
            url: '/pages/shipping-protection-policy',
            text: 'Shipping Protection Policy | Cuts Clothing',
          },
        },
        {
          copy: 'Just Dropped: [Amalfi Trunks]',
          link: {
            url: '/products/amalfi-trunk-black',
            text: 'Amalfi Trunk | Black 7" Slim-Fit',
          },
        },
        {
          copy: 'Free International Shipping $200+ ',
        },
      ],
    },
  ],
};
