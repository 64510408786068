export const header = ({ button, align }) => ({
  label: 'Heading settings',
  name: 'header',
  description: 'Heading, text align, heading button',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'textAlign',
      description: "Doesn't apply on desktop if button is in header",
      label: 'Heading alignment',
      options: align.options,
    },
    {
      name: 'ctas',
      label: 'Heading Button',
      component: 'group-list',
      description: 'Max of 1 button',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate: {
        maxItems: 1,
      },
      defaultItem: {
        cta: {
          text: 'Shop All',
          url: '/collections/all',
        },
        style: button.value.outline,
      },
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
      ],
    },
  ],
  defaultValue: {
    heading: '',
    textAlign: align.value.center,
    ctas: [],
  },
});
