import animations from '@animations';
import { useEffect } from 'react';
import { Box, Flex } from 'theme-ui';

import { YotpoListReviewItem } from './YotpoListReviewItem';

export const YotpoListReviews = ({
  items = [],
  page,
  activeTerm,
  onMounted = () => {},
}) => {
  if (!items?.length) return null;

  useEffect(() => {
    onMounted();
    animations.revealReviewListItem();
  }, [page, activeTerm]);

  return (
    <Box data-comp={YotpoListReviews.displayName}>
      <Flex
        data-comp={`${YotpoListReviews.displayName}Items`}
        variant="flex.column"
      >
        {items.map((review, index) => {
          return (
            <YotpoListReviewItem key={review.id} index={index} {...review} />
          );
        })}
      </Flex>
    </Box>
  );
};

YotpoListReviews.displayName = 'YotpoListReviews';
