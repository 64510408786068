import { Box, Flex, Text, Paragraph } from 'theme-ui';
import store, { useRecoilState } from '@store';

export const YotpoListPagination = ({ pages, activePagerId = '' }) => {
  const [activePage, setYotpoActivePage] = useRecoilState(
    store.yoptoActivePage(activePagerId)
  );
  const isSinglePage = pages === 1;
  const isFirstPage = activePage === 1;
  const isLastPage = activePage === pages;

  return isSinglePage ? null : ( // e.g — no more reviews to paginate
    <Box
      data-comp={YotpoListPagination.displayName}
      sx={{
        mb: 12,
      }}
    >
      <Flex variant="flex.row.center">
        {/* Prev */}
        <Paragraph
          variant="akzidenzGrotesk"
          sx={{
            fontSize: 0,
            ...(isFirstPage
              ? {
                  cursor: 'none',
                  opacity: 0.2,
                }
              : {
                  cursor: 'pointer',
                  opacity: 1,
                }),
          }}
          onClick={() => {
            setYotpoActivePage((state) => {
              return state - 1 > 1 ? state - 1 : 1;
            });
          }}
        >
          Prev
        </Paragraph>

        {/* only show 1/10 if pages (count) is provided  */}
        {pages ? (
          <Paragraph
            variant="akzidenzGrotesk"
            sx={{
              mx: 8,
              minWidth: '80px',
              fontSize: 3,
            }}
          >
            <Text>{activePage}</Text>/{pages}
          </Paragraph>
        ) : (
          <Paragraph
            variant="akzidenzGrotesk"
            sx={{
              mx: 8,
              minWidth: '80px',
              fontSize: 3,
            }}
          >
            <Text>{activePage}</Text>
          </Paragraph>
        )}

        {/* Next */}
        <Paragraph
          variant="akzidenzGrotesk"
          sx={{
            fontSize: 0,
            ...(isLastPage
              ? {
                  cursor: 'none',
                  opacity: 0.3,
                }
              : {
                  cursor: 'pointer',
                  opacity: 1,
                }),
          }}
          onClick={() => {
            setYotpoActivePage((state) => {
              return pages
                ? state + 1 <= pages
                  ? state + 1
                  : pages
                : state + 1;
            });
          }}
        >
          Next
        </Paragraph>
      </Flex>
    </Box>
  );
};

YotpoListPagination.displayName = 'YotpoListPagination';
