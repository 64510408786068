import { Container, Grid } from 'theme-ui';

import { withInView } from '@utils';

import { Media } from './Media';
import { Details } from './Details';
import { Header } from './Details/Header';
import { themed } from './Kit.theme';
import { Schema } from './Kit.schema';

export const Kit = withInView(
  themed(({ theme, cms }) => {
    return (
      <Container data-comp={Kit.displayName}>
        <Grid sx={theme.wrapper}>
          <Header cms={cms} isMobile />

          {/* Product images */}
          <Media medias={cms?.images?.list} />

          {/* Title, Options, Add to cart */}
          <Details cms={cms} />
        </Grid>
      </Container>
    );
  }),
  { triggerOnce: true }
);

Kit.displayName = 'Kit';
Kit.Schema = Schema;
