import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.headingContainer = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.heading = {
      variant: 'text.h4',
      m: 0,
      textAlign: 'center',
      quotes: 'initial',
      maxWidth: ['335px', '725px'],
    };
  })()
);
