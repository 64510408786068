import React from 'react';
import { themed } from './ShopByCut.theme';
import { Schema } from './ShopByCut.schema';
import { Section, Link, Picture } from '@snippets';
import { withInView } from '@utils';
import { Box, Container, Heading, Text } from 'theme-ui';

export const ShopByCut = withInView(
  themed(({ theme, cms }) => {
    const { section, heading, items } = cms;
    return (
      <Section section={section}>
        <Container data-comp={ShopByCut.displayName} sx={theme.container}>
          <Heading sx={theme.title}>{heading}</Heading>
          <Box sx={theme.list}>
            {items.map((item, index) => (
              <Link href={item.link?.url} sx={theme.item} key={item.name}>
                <Text sx={theme.name}>{item.name}</Text>
                <Picture
                  sx={theme.image}
                  images={[
                    {
                      src: item.image?.src,
                      ratio: 1,
                    },
                  ]}
                  imageSx={{
                    ...theme.imageSx,
                    ...(index >= 3 && {
                      px: [10, 0],
                      objectPosition: 'center top',
                    }),
                  }}
                />
              </Link>
            ))}
          </Box>
        </Container>
      </Section>
    );
  })
);

ShopByCut.displayName = 'ShopByCut';
ShopByCut.Schema = Schema;
