import { CookiesOneTrustScript } from './CookiesScript';

export const ManageCookies = () => {
  return (
    <a
      id="ot-sdk-btn"
      className="ot-sdk-show-settings"
      sx={{
        backgroundColor: 'transparent !important',
        border: 'none !important',
        color: 'white !important',
        paddingTop: '3px !important',
        paddingBottom: '0 !important',
        paddingX: '5px !important',
        variant: 'text.navList',
        fontSize: ['12px !important', '14px !important'],
        textAlign: ['center', 'left'],
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ':after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '100%',
          maxWidth: 0,
          height: '1px',
          bg: 'currentColor',
          transition: 'max-width 0.2s ease-in-out',
        },
        ':hover:after': {
          maxWidth: '100%',
        },
      }}
    >
      <CookiesOneTrustScript />
    </a>
  );
};
