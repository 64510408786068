export const buttons = ({ button }) => ({
  label: 'Button settings',
  name: 'button',
  description: 'Buttons, clickable tile',
  component: 'group',
  fields: [
    {
      label: 'Clickable Tile',
      description:
        'Allows entire tile to be clickable, using first button link as url',
      name: 'clickableTile',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Hide buttons',
      name: 'hideButtons',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'buttons',
      label: 'Buttons',
      component: 'group-list',
      description: 'Max of 2 buttons',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate: {
        maxItems: 2,
      },
      defaultItem: {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Shop Now',
            url: '/collections/all',
          },
          style: 'primary',
        },
      ],
    },
  ],
});
