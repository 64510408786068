import { useEffect } from 'react';

export const PostScript = () => {
  useEffect(() => {
    const viewItemListener = (event) => {
      const [product] = event.detail.ecommerce.detail.products;

      console.log(' window.postscript.event(page_view', {
        shop_id: process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || 343383,
        url: window.location.href || `${window.location.origin}${product?.url}`,
        search_params: {
          variant:
            new URLSearchParams(new URL(window.location.href).search).get(
              'variant'
            ) || '',
        },
        page_type: 'product',
        referrer: product?.list,
        resource: {
          category: product?.category,
          name: product?.name,
          price_in_cents: Math.round(Number(product?.price) * 100),
          resource_id: Number(product?.product_id),
          resource_type: 'product',
          sku: product?.sku,
          variant_id: Number(product?.variant_id),
          vendor: product?.vendor,
        },
      });
      window.postscript.event('page_view', {
        shop_id: process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || 343383,
        url: window.location.href || `${window.location.origin}${product?.url}`,
        search_params: {
          variant:
            new URLSearchParams(new URL(window.location.href).search).get(
              'variant'
            ) || '',
        },
        page_type: 'product',
        referrer: product?.list,
        resource: {
          category: product?.category,
          name: product?.name,
          price_in_cents: Math.round(Number(product?.price) * 100),
          resource_id: Number(product?.product_id),
          resource_type: 'product',
          sku: product?.sku,
          variant_id: Number(product?.variant_id),
          vendor: product?.vendor,
        },
      });
    };

    const addToCartListener = (event) => {
      const [product] = event.detail.ecommerce.add.products;

      console.log(' window.postscript.event(add_to_cart', {
        shop_id: process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || 343383,
        url: window.location.href || `${window.location.origin}${product?.url}`,
        search_params: {
          variant:
            new URLSearchParams(new URL(window.location.href).search).get(
              'variant'
            ) || '',
        },
        page_type: 'product',
        referrer: product?.list,
        resource: {
          category: product?.category,
          name: product?.name,
          price_in_cents: Math.round(Number(product?.price) * 100),
          resource_id: Number(product?.product_id),
          resource_type: 'product',
          sku: product?.sku,
          variant_id: Number(product?.variant_id),
          vendor: product?.vendor,
        },
      });

      window.postscript.event('add_to_cart', {
        shop_id: process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || 343383,
        url: window.location.href || `${window.location.origin}${product?.url}`,
        search_params: {
          variant:
            new URLSearchParams(new URL(window.location.href).search).get(
              'variant'
            ) || '',
        },
        page_type: 'product',
        referrer: product?.list,
        resource: {
          category: product?.category,
          name: product?.name,
          price_in_cents: Math.round(Number(product?.price) * 100),
          resource_id: Number(product?.product_id),
          resource_type: 'product',
          sku: product?.sku,
          variant_id: Number(product?.variant_id),
          vendor: product?.vendor,
        },
      });
    };

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
    };
  }, []);

  return null;
};
