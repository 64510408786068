import { useMemo } from 'react';
import { Flex, Text, Paragraph } from 'theme-ui';

import { Link } from '@snippets';

import { themed } from './Details.theme';

export const Details = themed(
  ({ theme, title, price, compareAtPrice, qty, handle, handleClick }) => {
    const prices = useMemo(() => {
      const priceStr = price?.toFixed(2);
      const compareAtStr = compareAtPrice?.toFixed(2);

      return {
        final: priceStr?.endsWith('.00') ? `${price}` : priceStr,
        compareAtPrice: compareAtStr?.endsWith('.00')
          ? `${compareAtPrice}`
          : compareAtStr,
      };
    }, [price, compareAtPrice]);

    return (
      <Flex data-comp={Details.displayName} sx={theme.wrapper}>
        <Text sx={theme.colorName}>{`${qty}-Pack`}</Text>

        {/* Title */}
        <Link
          href={`/pages/${handle}`}
          tabIndex="-1"
          sx={theme.title}
          onClick={handleClick}
        >
          {title}
        </Link>

        {/* Price */}
        <Flex>
          {prices.compareAtPrice && (
            <Paragraph sx={theme.price.lineThrough}>
              ${prices.compareAtPrice}
            </Paragraph>
          )}
          <Paragraph sx={theme.price.regularRed}>${prices.final}</Paragraph>
        </Flex>
      </Flex>
    );
  }
);

Details.displayName = 'Details';
