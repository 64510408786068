import { useMemo, Children, cloneElement } from 'react';
import { useLocalizedProduct } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { formatAmountItem } from './formatAmountItem';

// Target is product item
// TODO: add sellingPlan info
export const LocaleProduct = ({ product, children, ...props }) => {
  if (!product) {
    return null;
  }

  const { localized, ...localeStatus } = useLocalizedProduct({ product });

  // TODO: can I make this DRY: useLocaleComponents ??
  const Components = useMemo(() => {
    let PriceComp = null;
    let LoaderComp = null;
    let ErrorComp = null;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Locale.Price':
          const isVariablePrice =
            localized?.priceRange?.minVariantPrice?.amount <
            localized?.priceRange?.maxVariantPrice?.amount;
          const isOnSale = localized?.variants?.find(
            (variant) => variant.compareAtPrice
          );

          const priceProps = localized
            ? {
              key: 'Locale.Price',
              minVariantPrice: formatAmountItem(
                localized?.priceRange?.minVariantPrice
              ),
              maxVariantPrice: formatAmountItem(
                localized?.priceRange?.maxVariantPrice
              ),
              isVariablePrice,
              isOnSale,
              locale: localized.locale,
              ...child.props,
            }
            : null;

          PriceComp = localized ? cloneElement(child, priceProps) : null;
          break;

        case 'Locale.Error':
          ErrorComp = localeStatus?.errors?.length
            ? cloneElement(child, {
              key: 'Locale.Error',
              errors: localeStatus.errors,
              ...child.props,
            })
            : null;
          break;

        case 'Locale.Loader':
          LoaderComp =
            !localeStatus?.finished &&
              !localized &&
              !localeStatus?.errors?.length
              ? cloneElement(child, {
                key: 'Locale.Loader',
                ...localeStatus,
                ...child.props,
              })
              : null;
          break;

        default:
          break;
      }
    });

    // active components
    return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
  }, [localeStatus?.started, localeStatus?.finished, localized]);

  return (
    <Box data-comp={LocaleProduct.displayName} {...props} sx={{ ...props.sx }}>
      {Components.map((Component) => Component)}
    </Box>
  );
};

LocaleProduct.displayName = 'Locale.Product';
