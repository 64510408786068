export const align = {
  options: [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
  ],
  value: {
    left: 'left',
    center: 'center',
    right: 'right',
  },
};
