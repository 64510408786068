export const badges = {
  default: {
    fontSize: 0,
    fontWeight: 700,
    lineHeight: 1.185,
    textTransform: 'uppercase',
    height: 'fit-content',
  },
  onSale: {
    color: 'background',
    bg: 'teal',
    py: 4,
    px: 6,
    fontSize: 1,
    fontWeight: 400,
  },
  sellingPlan: {
    color: 'background',
    fontSize: '9px',
    width: 'auto',
    lineHeight: 1,
    letterSpacing: '.08em',
    borderRadius: '7px',
    bg: 'text',
    py: 2,
    px: 3,
    fontWeight: 400,
  },
  primary: {
    borderRadius: '4px',
    variant: 'badges.default',
    fontSize: '10px',
    letterSpacing: '.08em',
    bg: 'text',

    py: 3,
  },
  secondary: {
    borderRadius: '4px',
    variant: 'badges.default',
    fontSize: '10px',
    letterSpacing: '.08em',
    py: 3,
    color: 'text',
    bg: 'background',
    boxShadow: 'inset 0 0 0 1px',
  },
};
