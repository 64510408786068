import { useSettings } from '@backpackjs/storefront';
import { Grid } from 'theme-ui';

import { CountrySelector, Link } from '@snippets';

import { CtaSearch } from './CtaSearch';
import { CtaAccount } from './CtaAccount';
import { CtaCart } from './CtaCart';
import { themed } from '../Menu.theme';

export const Ctas = themed(({ theme, transparentBGEnabled }) => {
  const settings = useSettings();
  const { enable, color, desktop } = { ...settings?.header?.salesLinks };
  const { link } = { ...desktop };

  return (
    <Grid data-comp={Ctas.displayName} sx={theme.ctas}>
      {enable && link?.url && link?.text && (
        <Link
          href={link.url}
          aria-label={link.text}
          sx={{
            ...theme.item(transparentBGEnabled),
            mr: 0,
            display: ['none', null, 'block'],
            ...(color && { color }),
          }}
        >
          {link.text}
        </Link>
      )}

      {/* Search toggle (desktop only) */}
      <CtaSearch transparentBGEnabled={transparentBGEnabled} />

      <CtaAccount
        transparentBGEnabled={transparentBGEnabled}
        sx={{
          display: ['none', null, 'block'],
        }}
      />

      <CtaCart transparentBGEnabled={transparentBGEnabled} />
    </Grid>
  );
});

Ctas.displayName = 'Ctas';
