import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'theme-ui';

import { themed } from './Dropdown.theme';

export const DropdownSelected = themed(
  ({ children, selected, selecting, theme, toggle, variant, ...props }) => {
    const toggleDropdown = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    }, []);

    return (
      <Button
        data-comp={DropdownSelected.displayName}
        variant="buttons.plain"
        role="button"
        aria-selected="true"
        aria-haspopup="listbox"
        aria-live="assertive"
        aria-expanded={selecting ? 'true' : 'false'}
        onClick={toggleDropdown}
        {...props}
        sx={{
          ...theme[variant]?.selected,
          ...props.sx,
        }}
      >
        {children({ selecting, selected })}
      </Button>
    );
  }
);

DropdownSelected.displayName = 'DropdownSelected';

DropdownSelected.propTypes = {
  children: PropTypes.func,
  selected: PropTypes.any,
  selecting: PropTypes.bool,
  toggle: PropTypes.func,
};
