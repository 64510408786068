import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      bg: 'transparent',
      width: '100%',
      color: 'currentColor',
      border: 'none',
      px: 0,

      '@media screen and (min-width: 480px)': {
        '&:hover': {
          bg: 'transparent',
          color: 'currentColor',
        },
      },
    };

    this.flex = {
      variant: 'flex.row.between.center',
      width: '100%',
      height: '100%',
    };

    this.text = {
      variant: 'text.label.2',
      flex: 1,
      whiteSpace: 'nowrap',
      textAlign: 'left',
      color: 'currentColor',
    };

    this.icon = {
      width: '10px',
      ml: 2,
      transition: 'transform .4s ease-in-out',
    };
  })()
);
