import React from 'react';
import { withInView } from '@utils';
import { Markdown, Svg, Section } from '@snippets';
import { Container, Paragraph, Flex, Box, Image, Heading } from 'theme-ui';
import { text as textBase } from '@theme/theme.text';

import { Schema } from './IconRow.schema';
import { themed } from './IconRow.theme';

export const IconRow = withInView(
  themed(({ theme, cms }) => {
    const { heading, description, icons, section } = cms;

    return (
      <Section section={section}>
        <Container data-comp={IconRow.displayName} sx={theme.container}>
          {heading && (
            <Flex sx={theme.outer}>
              {heading && <Paragraph sx={theme.heading}>{heading}</Paragraph>}
              {description && (
                <Markdown text={description} textSx={theme.description} />
              )}
            </Flex>
          )}
          {icons.length > 0 && (
            <Box
              sx={{
                ...theme.icons,
                display: 'flex',
                flexDirection: ['column', 'column', 'row'],
                alignItems: 'flex-start',
                gap: [4, 8],
                // gridTemplateColumns: [
                //   'repeat(3, 1fr)',
                //   'repeat(auto-fit, minmax(180px, 300px))',
                // ],
              }}
            >
              {icons.map(
                (
                  {
                    icon,
                    roundBorder,
                    borderWidth,
                    borderColor,
                    text,
                    iconColor,
                    image,
                    iconSize,
                    heading: iconHeading,
                    headingSize,
                  },
                  index
                ) => {
                  return (
                    <Flex key={index} sx={theme.iconWrap}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: roundBorder ? '50%' : '0',
                          border: `${borderWidth}px`,
                          borderColor,
                          backgroundColor: roundBorder
                            ? borderColor
                            : 'transparent',
                          padding: `${borderWidth || 1}px`,
                        }}
                      >
                        {image?.src ? (
                          <Image
                            src={image.src}
                            alt={image.alt}
                            sx={{
                              ...theme.icon,
                              fill: iconColor,
                              color: iconColor,
                              borderRadius: roundBorder ? '50%' : '0',

                              width: roundBorder ? '150px' : '100%',
                              height: roundBorder ? '90px' : '100%',
                            }}
                          />
                        ) : (
                          <Svg
                            src={`${icon}`}
                            alt={icon.icon}
                            viewBox="0 0 32 32"
                            sx={{
                              ...theme.icon,
                              fill: iconColor,
                              color: iconColor,
                              width: iconSize,
                            }}
                          />
                        )}
                      </Box>
                      <Flex
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          width: '100%',
                          gap: 4,
                          mt: 4,
                        }}
                      >
                        {iconHeading && (
                          <Heading
                            as="h4"
                            sx={{
                              my: 2,
                              lineHeight: '28px',
                              fontWeight: '600',
                              ...textBase[headingSize],
                            }}
                          >
                            {iconHeading}
                          </Heading>
                        )}
                        {text && <Markdown text={text} textSx={theme.text} />}
                      </Flex>
                    </Flex>
                  );
                }
              )}
            </Box>
          )}
        </Container>
      </Section>
    );
  })
);
IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
