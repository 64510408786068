import { section, button, color, align } from '@settings/common';

const image = {
  label: 'Image settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
      label: 'Alt',
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      label: 'Button settings',
      name: 'button',
      component: 'group',
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
        {
          name: 'alignment',
          label: 'Content alignment (mobile)',
          component: 'radio-group',
          direction: 'horizontal',
          description: 'Set the button alignment globally',
          variant: 'radio',
          options: align.options,
        },
        {
          name: 'alignmentDt',
          label: 'Content alignment (desktop)',
          component: 'radio-group',
          direction: 'horizontal',
          description: 'Set the button alignment globally',
          variant: 'radio',
          options: align.options,
        },
      ],
    },
  ],
  defaultValue: {
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/how3.jpg?v=1651755365',
    },
    alt: 'New Arrivals',
    button: {
      cta: {
        text: 'Shop Now',
        url: '/collections/all',
      },
      style: 'inverse',
      alignment: align.value.right,
      alignmentDt: align.value.left,
    },
  },
};

export const Schema = () => {
  return {
    label: 'New Arrivals',
    key: 'newArrivals',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Recommended For You',
      },
      image,
      {
        name: 'products',
        label: 'Products',
        component: 'group-list',
        itemProps: {
          label: '{{item.product.handle}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          product: { handle: 'black-crew-curve-hem' },
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
        defaultValue: [
          {
            product: { handle: 'black-crew-curve-hem' },
          },
          {
            product: { handle: 'hoodie-classic-mini-logo' },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
