import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useRouter } from '@backpackjs/storefront';

import { useBreakpointValue } from '@hooks';
import { Content } from './Content';

import { themed } from './Hero.theme';

const Video = dynamic(() => import('./Video').then((module) => module.Video), {
  ssr: false,
  suspense: false,
});
const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const Hero = themed(({ theme, hero }) => {
  const router = useRouter();
  const { button, media } = hero;
  const cta = button?.buttons?.[0]?.cta;
  const clickable = button?.clickableHero && cta?.url;

  const mediaType = useBreakpointValue([
    media?.mediaTypeMb,
    media?.mediaTypeDt,
  ]);

  return (
    <Box
      data-comp={Hero.displayName}
      onClick={() => (clickable ? router.push(cta?.url) : null)}
      tabIndex={clickable && button?.hideButtons ? '0' : '-1'}
      aria-label={clickable && button?.hideButtons ? cta?.text : ''}
      sx={{
        ...theme.slide,
      }}
    >
      {mediaType === 'image' ? (
        <Image hero={hero} />
      ) : (
        <Video video={media?.video} />
      )}

      <Content hero={hero} />
    </Box>
  );
});

Hero.displayName = 'Hero';
