import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      position: 'relative',
      display: 'grid',
      gridGap: 0,
      gridTemplateColumns: ['1fr', '1fr 1fr'],
    };

    this.media = {
      gridArea: 'media',
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
    };

    this.content = (hasRoundImage) => ({
      gridArea: 'content',
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
      mt: hasRoundImage ? ['32px', 0] : 0,
    });
  })()
);
