// src/store

import { isBrowser } from '@utils';
import { store } from '@backpackjs/storefront';

const showHideYotpoReviews = ({ onSet }) => {
  onSet((newValue) => {
    const yotpoReviews =
      typeof document === 'undefined'
        ? null
        : document.querySelector('.yotpo.testimonials');

    if (!yotpoReviews) return;

    if (newValue) {
      // fade out
      yotpoReviews.style.transition = 'opacity .1s ease-in-out';
      yotpoReviews.style.opacity = 0;
    } else {
      // fade in
      yotpoReviews.style.transition = 'opacity .1s ease-in-out .3s';
      yotpoReviews.style.opacity = 1;
    }

    return newValue;
  });
};

// update the "variant" query param on selected variant change
export const onSetUpdateVariantUrlParam =
  () =>
    ({ onSet }) => {
      onSet((selectedVariant, oldValue) => {
        if (!isBrowser || !window?.location) return;
        if (!selectedVariant?.legacyResourceId) return;

        const { origin, search } = window.location;

        // update variant param
        const params = new URLSearchParams(search);
        params.set('variant', selectedVariant.legacyResourceId);

        const updatedUrl = `${origin}/products/${selectedVariant.product.handle}?${params}`;

        window.history.replaceState('', '', updatedUrl);

        return selectedVariant;
      });
    };

export default {
  ...store.effects,
  // more effects here
  onSetUpdateVariantUrlParam,
  showHideYotpoReviews,
};
