import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      bg: 'gray.mediumLight',
      py: [16, 18],
    };
    this.container = {
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
      gridRowGap: ['36px'],
      gridGap: ['36px'],
      justifyContent: 'space-between',
      px: [10, 12, 14],
      counterReset: 'count',
    };
    this.title = {
      variant: 'text.h4',
      display: 'flex',
      justifyContent: 'center',
      mb: 12,
    };
    this.item = {
      display: 'flex',
      maxWidth: '420px',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flex: ['50%', null, 1],
      color: 'text',
      counterIncrement: 'section',
    };
    this.image = {
      width: '100%',
      height: ['342px', '420px'],
      mb: [16, 18],
      position: 'relative',
      overflow: 'visible',
      ':after': {
        variant: 'text.h4',
        content: 'counter(section)',
        width: ['48px', '72px'],
        height: ['48px', '72px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        borderRadius: '50%',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    };
    this.itemHeading = {
      variant: 'text.h5',
      mb: '10px',
    };
    this.itemText = {
      variant: 'text.base',
      maxWidth: '70%',
    };
    this.iconWrap = {
      display: 'flex',
      variant: 'flex.row.center',
      width: '26px',
      height: '26px',
      backgroundColor: 'black',
      borderRadius: '50%',
      mt: '16px',
      '& + &': {
        marginLeft: '8px',
      },
    };
    this.icon = {
      width: '16px',
      fill: 'white',
      color: 'white',
    };
  })()
);
