import { useCallback } from 'react';
import { Box, Heading, Flex, Paragraph } from 'theme-ui';

export const YotpoListReviewItemUser = ({ user, ...review }) => {
  if (!user) return null;

  const toMmDdYYYY = useCallback((isoDate) => {
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return `${month}/${dt}/${year}`;
  }, []);

  return (
    <Box data-comp={`${YotpoListReviewItemUser.displayName}`}>
      <Heading
        variant="label2"
        sx={{
          color: 'lightGreen',
        }}
      >
        {user.displayName}
      </Heading>

      <Box
        sx={{
          mt: 8,
        }}
      >
        {review.verifiedBuyer ? (
          <Paragraph
            variant="sm"
            sx={{
              color: 'lightGreen',
            }}
          >
            Verified User
          </Paragraph>
        ) : null}

        <Paragraph
          variant="sm"
          sx={{
            color: 'lightGreen',
          }}
        >
          {toMmDdYYYY(review.createdAt)}
        </Paragraph>
      </Box>
    </Box>
  );
};

YotpoListReviewItemUser.displayName = 'YotpoListReviewItemUser';
