import { Box, Paragraph, Flex, Text } from 'theme-ui';

import { useYotpoStars, useYotpoScrollToReviews } from '@hooks/yotpo';

import { YotpoStarsIcons } from './YotpoStarsIcons';

// Fetches stars data from yotpo and then renders them
export const YotpoStars = ({
  legacyResourceId,
  bottomline = null,
  isProductItem = false,
  withCta = true,
  isSelectable = false,
  withScroll = true,
  onClickedStar = () => {},
  maskColor = 'white',
  fullColor = 'black',
  emptyColor = 'black',
  ...props
}) => {
  const { stars } = useYotpoStars({
    legacyResourceId,
    bottomline,
  });
  const { scrollToReviews } = useYotpoScrollToReviews();

  switch (true) {
    case Boolean(stars): {
      // product stars info returned by yotpo
      const { averageScore, totalReviews } = stars;
      const noReviews = totalReviews === 0;

      return (
        <Flex
          data-comp={YotpoStars.displayName}
          key={averageScore + totalReviews}
        >
          <Flex
            variant="flex.row.start.center"
            tabIndex="0"
            {...props}
            sx={{
              minHeight: '18px',
              mb: 2,
              cursor: 'pointer',
              ...props.sx,
            }}
            onClick={withScroll ? scrollToReviews : null}
          >
            <YotpoStarsIcons
              totalReviews={totalReviews}
              averageScore={averageScore}
              onClickedStar={onClickedStar}
              isSelectable={isSelectable}
              fullColor={fullColor}
              emptyColor={emptyColor}
            />

            {withCta ? (
              <Paragraph
                data-comp={`${YotpoStars.displayName}Total`}
                aria-label={`${totalReviews} reviews`}
                variant="link2"
                sx={{
                  ml: 2,
                  mt: '-1px',
                  cursor: withScroll || isSelectable ? 'pointer' : 'initial',
                  textDecoration:
                    withScroll || isSelectable ? 'underline' : 'none',
                  mr: 'auto',
                  lineHeight: 18,
                  span: {
                    variant: 'text.sm',
                    lineHeight: 18,
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',
                  },
                }}
              >
                {totalReviews ? (
                  <>
                    <Text
                      sx={{
                        display: ['inline-block', 'none'],
                      }}
                    >
                      {totalReviews}
                    </Text>
                    <Text
                      sx={{
                        display: ['none', 'inline-block'],
                      }}
                    >
                      ({totalReviews})
                    </Text>
                  </>
                ) : isSelectable ? (
                  <Text sx={{ display: ['none', 'inline-block'] }}>
                    Write a Review
                  </Text>
                ) : (
                  <Text sx={{ display: ['none', 'inline-block'] }}>
                    0 Reviews
                  </Text>
                )}
              </Paragraph>
            ) : null}
          </Flex>
        </Flex>
      );
    }

    // case starsStatus.errors.length > 0:
    //   return <Alert>{starsStatus.errors[0]}</Alert>

    default:
      return <Box sx={{ minHeight: '18px', mb: 2 }} />;
  }
};

YotpoStars.displayName = 'YotpoStars';
