import { Flex, Paragraph } from 'theme-ui';

import { Product } from './Product';

export const Products = ({ products }) => {
  return products?.length ? (
    <Flex
      data-comp={Products.displayName}
      sx={{
        flexDirection: 'column',
        width: '100%',
        mb: '16px',
        pt: 8,
        borderBottom: (t) => `1px solid ${t.colors.gray.mediumLight}`,
        borderTop: (t) => `1px solid ${t.colors.gray.mediumLight}`,
      }}
    >
      <Paragraph
        sx={{
          variant: 'text.label.2',
        }}
      >
        {products.length} Products Included
      </Paragraph>

      {products.map((product, index, arr) => (
        <Product
          product={product}
          isLast={index === arr.length - 1}
          key={index}
        />
      ))}
    </Flex>
  ) : null;
};

Products.displayName = 'Products';
