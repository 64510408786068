import { sectionWithHeight, color } from '@settings/common';

export const Schema = ({ page }) => {
  return {
    label: 'Video section',
    key: 'videoSection',
    fields: [
      {
        name: 'source',
        label: 'Upload Video (desktop)',
        component: 'image',
        defaultValue:
          'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',

      },
      {
        label: 'Overlay',
        name: 'overlay',
        component: 'image',
      },
      {
        component: 'select',
        name: 'playBtnColor',
        label: 'Play button color ',
        options: [{ label: 'Default', value: '' }, ...color.options],
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: 324,
            minHeight: 324,
          },
          desktop: {
            container: 'content',
            maxHeight: 400,
            minHeight: 400,
          },
        },
      },
    ],
  };
};
