export const helper = {
  'none-block': {
    display: ['none', 'block'],
  },
  'none-flex': {
    display: ['none', 'flex'],
  },
  'none-grid': {
    display: ['none', 'grid'],
  },
  'block-none': {
    display: ['block', 'none'],
  },
  'flex-none': {
    display: ['flex', 'none'],
  },
  'grid-none': {
    display: ['grid', 'none'],
  },
};
