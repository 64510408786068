import { store } from '@backpackjs/storefront';

const yoptoActivePage = store.recoil.atomFamily({
  key: 'yotpo/activePage',
  default: 1,
});

// ugc
const yoptoActivePost = store.recoil.atom({
  key: 'yotpo/yoptoActivePost',
  default: null,
});

// term
const yoptoActiveTerm = store.recoil.atomFamily({
  key: 'yotpo/activeTerm',
  default: 'all',
});

const yoptoActiveView = store.recoil.atomFamily({
  key: 'yotpo/activeView',
  default: 'reviews',
});

const yotpoLoyaltyData = store.recoil.atom({
  key: 'yotpo/data',
  default: {
    yotpoCustomer: null,
  },
});

const getYotpoBottomLine$ = store.recoil.selectorFamily({
  key: 'yotpo/stars',
  get:
    (legacyResourceId) =>
      async ({ get }) => {
        if (!legacyResourceId)
          throw new Error('Yotpo stars legacyResourceId not provided');

        return await yotpoApiClient('getProductsBottomline')({
          legacyResourceId,
        });
      },
});

const getYotpoProductReviews$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoProductReviews$',
  get:
    ({ legacyResourceId, ...params }) =>
      async ({ get }) => {
        if (!legacyResourceId)
          throw new Error('Yotpo legacyResourceId not provided');

        return await yotpoApiClient('getProductReviews')({
          legacyResourceId,
          ...params,
        });
      },
});

const getYotpoProductQuestions$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoProductQuestions$',
  get:
    ({ legacyResourceId, ...params }) =>
      async ({ get }) => {
        if (!legacyResourceId)
          throw new Error('Yotpo legacyResourceId not provided');

        return await yotpoApiClient('getProductQuestions')({
          legacyResourceId,
          ...params,
        });
      },
});

const getYotpoProductTopics$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoProductTopics$',
  get:
    ({ legacyResourceId, ...params }) =>
      async ({ get }) => {
        if (!legacyResourceId)
          throw new Error('Yotpo legacyResourceId not provided');

        return await yotpoApiClient('getProductTopics')({ legacyResourceId });
      },
});

const getYotpoSearchReviews$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoSearchReviews$',
  get:
    ({ legacyResourceId, ...params }) =>
      async ({ get }) => {
        if (!legacyResourceId)
          throw new Error('Yotpo legacyResourceId not provided');

        return await yotpoApiClient('getSearchReviews')({
          legacyResourceId,
          ...params,
        });
      },
});

const getYotpoSitesReviews$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoSiteReviews$',
  get:
    (params) =>
      async ({ get }) => {
        return await yotpoApiClient('getSiteReviews')(params);
      },
});

const getYotpoUgcAlbums$ = store.recoil.selector({
  key: 'yotpo/getYotpoUgcAlbum$',
  get: async ({ get }) => {
    return await yotpoApiClient('getUgcAlbums')();
  },
});

const getYotpoUgcAlbumByName$ = store.recoil.selectorFamily({
  key: 'yotpo/getYotpoUgcAlbumByName$',
  get:
    ({ name, ...params }) =>
      async ({ get }) => {
        if (!name) throw new Error('Yotpo album name not provided');

        return await yotpoApiClient('getUgcAlbumByName')({ name, ...params });
      },
});

/**
 * Helpers
 */
const yotpoApiClient = (action) => async (data) => {
  try {
    const request = await fetch('/api/yotpo', {
      method: 'POST',
      body: JSON.stringify({ action, ...data }),
    });

    if (!request.ok) {
      throw new Error(`Yotpo ${action} request not ok`);
    }

    const response = await request.json();

    return response.data;
  } catch (error) {
    console.error(action, error.message);
    throw error;
  }
};

export default {
  // atom;
  yoptoActivePage,
  yoptoActivePost,
  yoptoActiveView,
  yoptoActiveTerm,
  yotpoLoyaltyData,

  // selectors
  getYotpoUgcAlbumByName$,
  getYotpoUgcAlbums$,
  getYotpoBottomLine$,
  getYotpoProductReviews$,
  getYotpoProductQuestions$,
  getYotpoProductTopics$,
  getYotpoSearchReviews$,
  getYotpoSitesReviews$,
};
