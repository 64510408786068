import { useMemo } from 'react';
import { getValueFromTag } from '@utils/getValueFromTag';
import { Badge, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';
import { useBreakpoint } from '@hooks';
import { themed } from './Badges.theme';

export const Badges = themed(
  ({
    theme,
    productBadgeStyle,
    fabricBadgeStyle,
    badgeWrapperStyle,
    variant,
    product,
    selectedVariant,
    display, // 'all' | 'product' | 'fabric'
  }) => {
    const { s, m, _m } = useBreakpoint();
    const isMobile = s || m || _m;
    const settings = useSettings();
    const showProductBadges = display === 'all' || display === 'product';
    const showFabricBadges = display === 'all' || display === 'fabric';

    const productBadge = getValueFromTag(product, 'badge');
    const fabricBadge = getValueFromTag(product, 'fabricBadge');

    const preOrderTag = getValueFromTag(product, 'Badge');
    const isPreOrder = product?.tags.includes('pre-order');
    const hasSaleBadge = getValueFromTag(product, 'saleBadge');

    const saleBadge = useMemo(() => {
      if (hasSaleBadge === 'true' && selectedVariant) {
        const percentOff = (
          100 -
          (Number(selectedVariant?.priceV2?.amount) /
            Number(selectedVariant?.compareAtPriceV2?.amount)) *
            100
        ).toFixed(0);
        return percentOff;
      }
      return null;
    }, [hasSaleBadge, selectedVariant]);
    const {
      enabled,
      text: preOrderText,
      textColor,
      bgColor,
    } = settings?.product?.preOrder?.preOrderBadge || {};

    const {
      enabled: showSaleBadges,
      text: saleBadgeText,
      textColor: saleBadgeTextColor,
      bgColor: saleBadgeBgColor,
      borderColor: saleBadgeBorderColor,
      saleBadgeStyle: primary,
    } = settings?.product?.onSaleBadge || {};

    return (
      <Flex
        data-comp={Badges.displayName}
        sx={{
          ...theme.badgeWrapper,
          ...badgeWrapperStyle,
          width: '100%',
          justifyContent: 'space-between',
          px: 5,
          zIndex: isMobile ? 2 : 1,
        }}
      >
        <Flex>
          {showProductBadges && productBadge && (
            <Badge
              variant={`${variant !== 'inverse' ? 'primary' : 'secondary'}`}
              sx={{
                ...theme.badge(false),
                ...productBadgeStyle,
              }}
            >
              {productBadge}
            </Badge>
          )}

          {showFabricBadges && fabricBadge && (
            <Badge
              variant={`${variant !== 'inverse' ? 'secondary' : 'primary'}`}
              sx={{
                ...theme.badge(fabricBadge.toLowerCase()),
                ...fabricBadgeStyle,
              }}
            >
              {fabricBadge}
            </Badge>
          )}
          {isPreOrder && preOrderTag && enabled && (
            <Badge
              variant="preOrder"
              sx={{ ...theme.preOrder, color: textColor, bg: bgColor }}
            >
              {preOrderText || preOrderTag}
            </Badge>
          )}
        </Flex>

        {showSaleBadges && saleBadge && (
          <Badge
            sx={{
              ...theme.saleBadge,
              variant: `${primary ? 'text.base' : 'text.xs'}`,
              fontWeight: `${primary ? 'normal' : 'bold'}`,
              borderRadius: `${primary ? 0 : '20px'}`,
              color: saleBadgeTextColor,
              bg: saleBadgeBgColor,
              border: `0.5px solid ${saleBadgeBorderColor}`,
            }}
          >
            {saleBadgeText || `${saleBadge}% off`}
          </Badge>
        )}
      </Flex>
    );
  }
);

Badges.displayName = 'Badges';
