import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'buttons.plain',
      py: '0 !important',
      position: 'relative',
    };

    this.colorName = {
      borderRadius: '0px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'absolute',
      bg: 'gray.mediumLight',
      color: 'text',
      fontSize: 1,
      letterSpacing: 'loose',
      fontWeight: 'normal',
      transition: 'opacity .15s ease-in',
      opacity: 0,
      px: 4,
      left: '50%',
      width: 'max-content',
      transform: 'translateX(-50%)',
      top: 'calc(100% + 16px)',
      ml: 5,
    };
    this.value = ({ isSelected }) => ({
      cursor: 'pointer',
      border: (t) =>
        isSelected
          ? ` 0.5px solid ${t.colors.text}`
          : `1px solid ${t.colors.gray}`,
      borderRadius: '50%',
      bg: 'background',
      color: 'text',
    });
  })()
);
