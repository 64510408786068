import { useCallback } from 'react';
import { useStartFinishErrors } from '@backpackjs/storefront';

import { useDataLayerActions } from '@hooks';

export const useBackInStock = () => {
  const { sendSubscribeEvent } = useDataLayerActions();
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();

  const subscribeToBackInStock = useCallback(async ({ email, variantId }) => {
    try {
      if (!email) {
        return console.error('Missing required parameter: email');
      }
      if (!variantId) {
        return console.error('Missing required parameter: variant');
      }

      reset();
      setStarted(true);
      const url = `/api/back-in-stock`;
      const options = {
        method: 'POST',
        body: JSON.stringify({
          email,
          variant: variantId,
        }),
      };
      const response = await fetch(url, options);

      const data = await response.json();
      if (!response?.ok) {
        setErrors(['Error with subscribing to back in stock.']);
        console.error(`Response back from API not successful: ${data}`);
        return null;
      }
      setFinished(true);
      sendSubscribeEvent({ email });
      return data;
    } catch (error) {
      setErrors(['Error with subscribing to back in stock.']);
      console.error(`Error with subscribeToBackInStock: ${error}`);
    }
  }, []);

  return {
    subscribeToBackInStock,
    setErrors,
    reset,
    ...status,
  };
};
