import { useCallback } from 'react';

import { useSearchspring } from '@hooks';
import store, { useRecoilState } from '@store';

export const useSearchTrending = () => {
  const { requestSearchspring } = useSearchspring();
  const [trending, setTrending] = useRecoilState(store.searchTrending);

  // Get trending search suggestions from past 90 days from ss
  const getSearchTrending = useCallback(async () => {
    try {
      const data = await requestSearchspring({
        action: 'getSearchTrending',
      });

      setTrending(
        data?.trending?.queries?.length
          ? data.trending.queries.map(({ searchQuery }) => searchQuery)
          : []
      );
    } catch (error) {
      console.error(`Error with getSearchTrending: ${error.message}`);
      throw error;
    }
  }, []);

  return {
    trending,
    getSearchTrending,
  };
};

// Example data from api
// "trending": {
//   "queries": [
//     {
//         "popularity": 9973,
//         "searchQuery": "shirts"
//     },
//     {
//         "popularity": 7467,
//         "searchQuery": "sweaters"
//     }
//   ]
// }
