export const defaultTile1 = ({ align, button }) => ({
  text: {
    heading: 'New Releases',
    description: '',
    color: '',
  },
  alt: 'Tile image',
  image: {
    src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
  },
  button: {
    clickableTitle: false,
    hideButtons: false,
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      {
        cta: {
          text: 'Promos',
          url: '/collections/all',
        },
        style: 'primary',
      },
    ],
  },
  tileSettings: {
    imageRatio: 'square',
    imageRatioDt: 'square',
    overlay: 'none',
    textPosition: 'absolute',
    textAlign: align.value.left,
    ctaStyle: button.value.secondary,
  },
});

export const defaultTile2 = ({ align, button }) => ({
  text: {
    heading: 'New Releases',
    description: '',
    color: '',
  },
  alt: 'Tile image',
  image: {
    src: 'https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
  },
  button: {
    clickableTitle: false,
    hideButtons: false,
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      {
        cta: {
          text: 'Promos',
          url: '/collections/all',
        },
        style: 'primary',
      },
    ],
  },
  tileSettings: {
    imageRatio: 'square',
    imageRatioDt: 'square',
    overlay: 'none',
    textPosition: 'absolute',
    textAlign: align.value.left,
    ctaStyle: button.value.secondary,
  },
});
