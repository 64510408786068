const base = {
  variant: 'text.button',
  px: 7,
  py: ['12px', '15px'],
  borderRadius: '4px',
  cursor: 'pointer',
  minWidth: 180,
  textAlign: 'center',
  borderWidth: '1px',
  borderStyle: 'solid',
  transition: '0.3s',
  fontWeight: 'bold',
};

const secondary = {
  ...base,
  backgroundColor: 'background',
  borderColor: 'background',
  color: 'text',
  transition: '0.3s',
  '@media screen and (min-width: 480px)': {
    '&:hover': {
      backgroundColor: 'text',
      color: 'background',
    },
  },
};

export const buttons = {
  primary: {
    ...base,
    backgroundColor: 'text',
    borderColor: 'text',
    color: 'background',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'background',
        color: 'text',
      },
    },
  },
  gray: {
    ...base,
    backgroundColor: 'gray.light',
    color: 'text',
    borderColor: 'gray.light',
    fontWeight: 'normal',
  },
  inverse: secondary, // primary inverse
  secondary,
  white: {
    ...base,
    backgroundColor: '#FFFF',
    borderColor: 'white',
    color: 'text',
    fontWeight: 'normal',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'gray.light',
        color: 'text',
      },
    },
  },
  orange: {
    ...base,
    backgroundColor: 'orange',
    borderColor: 'orange',
    color: 'text',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'background',
        color: 'text',
        borderColor: 'orange',
      },
    },
  },
  yellow: {
    ...base,
    backgroundColor: 'yellow',
    borderColor: 'yellow',
    color: 'text',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'background',
        color: 'text',
        borderColor: 'yellow',
      },
    },
  },
  outline: {
    ...base,
    backgroundColor: 'transparent',
    borderColor: 'text',
    color: 'text',
    transition: '0.3s',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'text',
        color: 'background',
      },
    },
  },
  'outline-inverse': {
    ...base,
    backgroundColor: 'transparent',
    borderColor: 'background',
    color: 'background',
    transition: '0.3s',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        backgroundColor: 'background',
        borderColor: 'background',
        color: 'text',
      },
    },
  },
  link: {
    large: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontSize: 2, // 15px
      fontFamily: 'body',
      lineHeight: 1.1846666667,
      fontWeight: 600,
      color: 'text',
      textDecoration: 'underline',
      '@media screen and (min-width: 480px)': {
        '&:hover': {
          color: 'gray.dark',
        },
      },
    },
    small: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontSize: 1, // 12px
      fontFamily: 'body',
      lineHeight: 1.5,
      fontWeight: 600,
      color: 'text',
      textDecoration: 'underline',
      '@media screen and (min-width: 480px)': {
        '&:hover': {
          color: 'gray.dark',
        },
      },
    },
  },
  plain: {
    bg: 'transparent',
    border: 0,
    borderRadius: 0,
    color: 'text',
    cursor: 'pointer',
    fontFamily: 'body',
    m: 0,
    minWidth: 'unset',
    p: 0,
    position: 'relative',
    textDecoration: 'none',
  },

  withArrow: {
    variant: 'buttons.link.large',
    position: 'relative',
    textDecoration: 'none',
    maxWidth: '90%',
    ':before': {
      content: '""',
      position: 'absolute',
      left: 'calc(100% - 10px);',
      top: 0,
      ml: 5,
      height: '18px',
      width: '30px',
      backgroundImage: `url('/svgs/chevron/right.svg')`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      transition: 'all 0.2s ease-in-out',
      transform: 'translateX(0)',
    },
    '@media screen and (min-width: 480px)': {
      ':hover:before': {
        transform: 'translateX(2px)',
      },
    },
  },

  IGLink: {
    ...base,
    display: 'inline-flex!important',
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    color: 'text',
    transition: '0.3s',
    p: 0,
    ':before': {
      content: '""',
      width: '20px',
      height: '20px',
      mr: 6,
      display: 'block',
      background: 'url("/svgs/noprecache/social/instagram.svg")',
    },
  },
};
