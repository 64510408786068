import { sectionWithHeight, align, color } from '@settings/common';
import { heading, socialImages } from './Schemas';

export const Schema = () => {
  return {
    label: 'Social IG',
    key: 'social-ig',
    fields: [
      heading({ align }),
      {
        name: 'images',
        label: 'Images settings',
        component: 'group',
        fields: [socialImages({ color })],
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: 324,
            minHeight: 324,
          },
          desktop: {
            container: 'content',
            maxHeight: 874,
            minHeight: 874,
          },
        },
      },
    ],
  };
};
