export const featuredProductCarouselParams = {
  spaceBetween: 10,
  slidesPerView: 1.6,
  slidesOffsetBefore: 10,
  slidesOffsetAfter: 20,
  slidesPerGroup: 1,
  centeredSlides: false,
  centeredSlidesBounds: false,
  centerInsufficientSlides: false,
  // autoHeight: true,
  navigation: false,
  loop: true,
  breakpoints: {
    480: {
      spaceBetween: 10,
      slidesPerView: 1.6,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
    },
    600: {
      spaceBetween: 15,
      slidesPerView: 2.25,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
    },
    768: {
      spaceBetween: 15,
      slidesPerView: 2.25,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1024: {
      spaceBetween: 20,
      slidesPerView: 3.2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1280: {
      spaceBetween: 20,
      slidesPerView: 4.2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
  },
};
