import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Flex } from 'theme-ui';

import { themed } from './Price.theme';

export const Price = themed(
  ({
    theme: themes,
    discountAllocations,
    estimatedCost,
    inSidebar,
    isDiscounted,
    isOnSale,
    isSubscription,
    sellingPlanAllocation,
    discountDescription,
  }) => {
    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Flex data-comp={Price.displayName} sx={theme.price}>
        <>
          {/* The full price before any savings or discounts */}
          {isOnSale ? (
            <Paragraph sx={theme.price.strike}>
              {estimatedCost.compareAtPriceAmount.locale}
            </Paragraph>
          ) : null}

          {isDiscounted && !isOnSale && (
            <Paragraph sx={theme.price.strike}>
              {estimatedCost.subtotalAmount.locale}
            </Paragraph>
          )}

          {/* the total including savings and any applied discounts */}
          <Paragraph sx={theme.price.regular}>
            {estimatedCost.totalAmount.locale}
          </Paragraph>
        </>

        <Paragraph variant="text.xs">{discountDescription}</Paragraph>
      </Flex>
    );
  }
);

Price.displayName = 'Price';
Price.propTypes = {
  discountAllocations: PropTypes.array,
  estimatedCost: PropTypes.object,
  inSidebar: PropTypes.bool,
  isOnSale: PropTypes.bool,
  isSubscription: PropTypes.bool,
  locale: PropTypes.object,
  sellingPlanAllocation: PropTypes.object,
};
