import dynamic from 'next/dynamic';
import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ThreeTile.schema';
import { Slider, GridDisplay } from './Displays';

const Header = dynamic(
  () => import('./Content').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Footer = dynamic(
  () => import('./Content').then((module) => module.Footer),
  { ssr: false, suspense: false }
);

export const ThreeTile = withInView(
  ({ cms, inView }) => {
    const { section, header, tiles, tile } = cms;

    return (
      <Section section={section}>
        <Container data-comp={ThreeTile.displayName}>
          {inView && <Header header={header} section={section} />}

          {tiles?.length > 0 ? (
            tile?.display === 'stacked' ? (
              <GridDisplay tiles={tiles} tile={tile} inView={inView} />
            ) : (
              <Slider tiles={tiles} tile={tile} inView={inView} />
            )
          ) : null}

          {inView && <Footer header={header} section={section} />}
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

ThreeTile.displayName = 'ThreeTile';
ThreeTile.Schema = Schema;
