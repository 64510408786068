export const fits = {
  label: 'Fits',
  name: 'fits',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}',
  },
  defaultItem: {
    name: 'Slim',
    copy: '<p>Designed to be fitted around the chest and shoulders with a taper down the body through the waist.</p><p>On average, our customers say it fits small.</p>',
  },
  fields: [
    {
      label: 'name',
      name: 'name',
      component: 'text',
      description: 'Must match the text of the fit::tag.',
    },
    {
      label: 'copy',
      name: 'copy',
      component: 'html',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
  ],
};
