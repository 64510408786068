import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
    };

    this.header = {
      minHeight: ['35px', '45px', '60px'],
    };

    this.tab = {
      cursor: 'pointer',
      fontFamily: 'Fragment Mono !important',
      textTransform: 'uppercase',
      pb: '3px',
      cursor: 'pointer',
    };

    this.slider = {
      minHeight: ['200px'],
    };

    this.itemWrapper = {
      height: '100%',
      width: '100%',
      position: 'relative',
    };
    this.badge = {
      py: '2px',
      px: '10px',
      borderRadius: '2px',
      background: 'white',
      fontSize: ['10px', '11px'],
      fontFamily: 'Fragment Mono !important',
      position: 'absolute',
      left: ['15px', 25],
      top: ['15px', 25],
      zIndex: 5,
    };

    this.notify = {
      py: ['12px', '20px'],
      px: ['25px', '40px'],
      borderRadius: '4px',
      fontSize: ['11px', '20px'],
      fontFamily: 'Fragment Mono !important',
      position: 'absolute',
      border: '1px solid #F2F2F2',
      borderRadius: '4px',
      margin: 'auto',
      top: 'calc(50% - 60px)',
      zIndex: 5,
      bg: 'rgba(217, 217, 217, 0.9)',
      cursor: 'pointer',
      textAlign: 'center',
      maxWidth: ['80%', 'unset'],
    };

    this.date = {
      fontSize: ['12px', '20px'],
      fontFamily: 'Fragment Mono !important',
      position: 'absolute',
      left: ['15px', 25],
      top: ['45px', 60],
      zIndex: 100,
      textAlign: 'center',
    };

    this.day = {
      fontSize: ['20px', 40],
      mt: '8px',
      pt: '2px',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      lineHeight: ['30px', '48px'],
    };

    this.badgeWrapper = {
      top: '12px',
      height: 'fit-content',
    };

    this.mediaWrapper = {
      border: 0,
      boxSizing: 'border-box',
      display: 'flex',
      height: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      p: 0,
      position: 'relative',
      width: '100%',
    };

    this.media = {
      bottom: 0,
      left: 0,
      p: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
      willChange: 'opacity',
    };

    this.hoverMedia = {
      ...this.media,
      border: 0,
    };
  })()
);
