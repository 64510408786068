import { sectionWithHeight, button } from '@settings/common';

export const Schema = ({ page }) => {
  if (page?.handle !== '404') return null;

  return {
    label: '404 page section',
    key: 'fourZeroFourSection',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Looks like you’re lost',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'text',
        defaultValue: '404',
      },
      {
        label: 'Background image (mobile)',
        name: 'image',
        component: 'image',

        defaultValue: {
          src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/404mobile.jpg?v=1652969344',
        },
      },
      {
        name: 'imageDt',
        label: 'Background image(desktop)',
        component: 'image',

        defaultValue: {
          src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/404desktop.jpg?v=1652968629',
        },
      },
      {
        label: 'CTA Link',
        name: 'cta',
        component: 'link',
        defaultValue: {
          text: 'Shop best sellers',
          url: '/collections/all',
        },
      },
      {
        name: 'ctaStyle',
        label: 'CTA link style',
        component: 'select',
        options: button.options,
        defaultValue: button.options.primary,
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          color: '#000',
          desktop: {
            minHeight: 900
          },
          mobile: {
            minHeight: 750,
            maxHeight: null,
          },
        },
      },
    ],
  };
};
