import { useCallback, useState } from 'react';
import { Box, Button, Flex, Input, Label, Radio } from 'theme-ui';

import { Section, Markdown, Svg } from '@snippets';
import { useDataLayerActions } from '@hooks';
import { selectTheFirstNElementsFromArray, withInView } from '@utils';

import { themed } from './EmailSignup.theme';
import { Schema } from './EmailSignup.schema';

export const EmailSignup = withInView(
  themed(({ cms, theme }) => {
    const { sendSubscribeEvent } = useDataLayerActions();
    const { listId, section, text, inputPlaceholder, categorySubscription } =
      cms;
    const darkMode =
      section?.bgColor === '#000' || section?.bgColor === '#0C0C0C';
    const categories = selectTheFirstNElementsFromArray(
      categorySubscription,
      1,
      3
    );

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState('noSelected');

    const selectCategory = useCallback(
      ({ target: { name, checked } }) => {
        if (checked) {
          setCategory(name);
        }
      },
      [category]
    );
    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        if (!email || isLoading) return;
        setIsLoading(true);
        try {
          const response = await fetch('/api/klaviyo', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
              action: 'clientSubscribe',
              email,
              phone: null,
              source: window.location.href,
              acceptsMarketing: false,
              listId,
            }),
          });
          if (response.ok) {
            setMessage('Email submitted!');
            sendSubscribeEvent({ email });
          }
          const data = await response.json();
          if (!response.ok && data.errors) {
            setMessage(data?.data || data?.errors);
          }
          setIsLoading(false);
          setEmail('');
        } catch (error) {
          setIsLoading(false);
          setMessage('Please try again later');
          console.error(error);
        }
      },
      [email, isLoading, message, category]
    );

    const heading = text?.heading;
    const headingTextAlign = [
      heading?.headingAlignmentMb,
      heading?.headingAlignmentDt,
    ];
    const headingColor = [
      heading?.headingColorMb || 'currentColor',
      heading?.headingColorDt || 'currentColor',
    ];

    const subheading = text?.subheading;
    const subheadingTextAlign = [
      subheading?.subheadingAlignmentMb,
      subheading?.subheadingAlignmentDt,
    ];
    const subheadingColor = [
      subheading?.subheadingColorMb || 'currentColor',
      subheading?.subheadingColorDt || 'currentColor',
    ];

    return (
      <Section data-comp={EmailSignup.displayName} section={section}>
        <Box
          sx={{
            maxWidth: section?.contentWidth,
            mx: 'auto',
          }}
        >
          <Markdown
            text={heading?.headingText}
            textSx={{
              ...theme.heading,
              textAlign: headingTextAlign,
              color: darkMode ? 'white' : headingColor,
            }}
            sx={{
              mb: subheading?.subheadingText ? [8, 11] : 0,
            }}
          />

          {subheading?.subheadingText && (
            <Markdown
              text={subheading?.subheadingText}
              textSx={{
                ...theme.subheading,
                textAlign: subheadingTextAlign,
                color: darkMode ? 'white' : subheadingColor,
              }}
            />
          )}
        </Box>

        <Box
          data-comp="Email SignUp Form"
          as="form"
          email={email}
          onSubmit={handleSubmit}
          sx={{
            ...theme.wrapper,
            maxWidth: section?.signupWidth,
          }}
        >
          {categories && categories.length > 0 && (
            <Flex>
              {categories.map((item, index) => (
                <Label key={index} sx={theme.categories(darkMode)}>
                  <Radio
                    checked={category === item.category}
                    value={item.category}
                    onChange={selectCategory}
                    name={item.category}
                  />
                  {item.category}
                </Label>
              ))}
            </Flex>
          )}

          <Input
            placeholder={inputPlaceholder || 'Email'}
            type="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            sx={theme.input(darkMode)}
            value={email}
          />

          <Button sx={theme.button}>
            <Svg
              src="/svgs/arrow-right.svg#arrow-right"
              alt="Submit"
              viewBox="0 0 48 48"
              sx={theme.icon(darkMode)}
            />
          </Button>
          {!!message?.length && (
            <Box sx={theme.message(darkMode)}>{message}</Box>
          )}
        </Box>
      </Section>
    );
  }),
  { triggerOnce: true }
);

EmailSignup.displayName = 'EmailSignup';
EmailSignup.Schema = Schema;
