import { useEffect, useState, useRef, forwardRef } from 'react';

import { Flex } from 'theme-ui';

/*
  pointerPosition: controls Y axis of pointer position in percentage
  textPosition: controls placement of text to be either left or right of pointer
  textDistancePosition: controls distance between text and browser in pixels
*/

export const FeatureCallout = forwardRef(
  ({ index, numOfTiles, props }, ref) => {
    const { pointerPosition, text, textDistancePosition, textPosition } = {
      ...props,
    };
    const [textPositionValue, setTextPositionValue] = useState(null);
    const [lineSegmentWidth, setLineSegmentWidth] = useState(0);

    const pointerRef = useRef();
    const textRef = useRef();

    const getTextPosition = (positionWithinBrowser) => {
      const textRefWidth = textRef.current.getBoundingClientRect()?.width;
      const parentRefPosX =
        positionWithinBrowser === 'left'
          ? (ref?.current?.getBoundingClientRect().width || 0) +
            (ref?.current?.getBoundingClientRect().x || 0)
          : window.innerWidth - (ref?.current?.getBoundingClientRect().x || 0);

      if (positionWithinBrowser === 'left') {
        return parentRefPosX - textRefWidth - textDistancePosition;
      }
      return parentRefPosX - textRefWidth - textDistancePosition;
    };

    const getlineSegmentWidth = (positionWithinBrowser) => {
      const pointerRefPosX =
        (pointerRef?.current?.getBoundingClientRect().x || 0) +
        (positionWithinBrowser === 'left' ? 0 : 32);

      const textRefPosX =
        (textRef?.current?.getBoundingClientRect().x || 0) +
        (positionWithinBrowser === 'right'
          ? 0
          : textRef?.current?.getBoundingClientRect().width || 0);

      return Math.abs(pointerRefPosX - textRefPosX);
    };

    const setElementPositions = () => {
      setTextPositionValue(getTextPosition(textPosition));
      setTimeout(() => {
        setLineSegmentWidth(getlineSegmentWidth(textPosition));
      }, 125);
    };

    useEffect(() => {
      setElementPositions();
      window.addEventListener('resize', setElementPositions);
      return () => {
        window.removeEventListener('resize', setElementPositions);
      };
    }, [numOfTiles]);

    return (
      <>
        <Flex
          ref={pointerRef}
          variant="flex.row.center"
          sx={{
            position: 'absolute',
            left: 'calc(50% - 16px)',
            top: `calc(${pointerPosition || 100}% - 34px)`,
            fontSize: 1,
            fontWeight: 400,
            lineHeight: '1',
            width: '32px',
            height: '32px',
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '100%',
            boxShadow: '2px 4px 32px rgba(0, 0, 0, 0.1)',
            zIndex: '1',
            '::after': {
              content: '""',
              position: 'absolute',
              top: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '34px',
              left: textPosition === 'right' ? 32 : 'auto',
              right: textPosition === 'left' ? 32 : 'auto',
              width: lineSegmentWidth,
              borderTop: '2px dashed white',
            },
          }}
        >
          {index + 1}
        </Flex>
        <Flex
          ref={textRef}
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: `calc(${pointerPosition || 100}% - 34px)`,
            left:
              textPosition === 'right' && textPositionValue
                ? `${textPositionValue}px`
                : 'auto',
            right:
              textPosition === 'left' && textPositionValue
                ? `${textPositionValue}px`
                : 'auto',
            fontSize: 1,
            fontWeight: 400,
            lineHeight: '1',
            px: '10px',
            height: '34px',
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '99px',
            boxShadow: '2px 4px 32px rgba(0, 0, 0, 0.1)',
            zIndex: '1',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Flex>
      </>
    );
  }
);

FeatureCallout.displayName = 'FeatureCallout';
