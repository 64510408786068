import { useMemo } from 'react';
import { Flex, Paragraph, Button, Box } from 'theme-ui';

import { SizeGuide } from '@templates/product/Main/Details/Fit/SizeGuide/SizeGuide';
import { themed } from './Options.theme';

const WAIST_SIZES = ['28', '30', '31', '32', '33', '34', '36', '38'];
const INSEAM_SIZES = ['32', '34'];

export const OptionPants = themed(
  ({
    theme,
    activeSize = '',
    kitSizeAvailablity,
    setActiveSize,
    type = '',
    products,
  }) => {
    const sizeGuideType = useMemo(() => {
      const pantsProduct = products?.find(({ optionsMap }) => {
        return optionsMap?.Waist && optionsMap?.Inseam;
      });
      const typeTag = pantsProduct?.tags?.find((tag) =>
        tag.startsWith('size-guide::')
      );
      return typeTag?.split('::')[1]?.trim();
    }, [products]);

    const [activeWaistSize, activeInseamSize] = activeSize.split(' / ');

    return (
      <Box data-comp={OptionPants.displayName}>
        <Flex sx={theme.heading}>
          <Paragraph as="label" sx={theme.optionName}>
            {type} Waist
          </Paragraph>

          <Paragraph sx={theme.selectedOption}>{activeWaistSize}</Paragraph>

          <SizeGuide sx={theme.SizeGuide} isKit type={sizeGuideType} />
        </Flex>

        <Flex sx={theme.values}>
          {WAIST_SIZES.map((size) => {
            const newSize = `${size} / ${activeInseamSize}`;
            const isSelected = activeWaistSize === size;
            const isAvailable = kitSizeAvailablity[newSize];
            return (
              <Button
                aria-label={`Select pants waist size: ${size}`}
                variant="buttons.plain"
                sx={{
                  ...theme.value,
                  ...(isAvailable
                    ? isSelected
                      ? theme.value.available.selected
                      : theme.value.available.unselected
                    : isSelected
                    ? theme.value.unavailable.selected
                    : theme.value.unavailable.unselected),
                }}
                onClick={() => {
                  if (!isAvailable) return;
                  !isSelected ? setActiveSize(newSize) : null;
                }}
                key={size}
              >
                {size}
              </Button>
            );
          })}
        </Flex>

        <Flex sx={theme.heading}>
          <Paragraph as="label" sx={theme.optionName}>
            {type} Inseam
          </Paragraph>

          <Paragraph sx={theme.selectedOption}>{activeInseamSize}</Paragraph>
        </Flex>

        <Flex sx={theme.values}>
          {INSEAM_SIZES.map((size) => {
            const newSize = `${activeWaistSize} / ${size}`;
            const isSelected = activeInseamSize === size;
            const isAvailable = kitSizeAvailablity[newSize];
            return (
              <Button
                aria-label={`Select pants inseam size: ${size}`}
                variant="buttons.plain"
                sx={{
                  ...theme.value,
                  ...(isAvailable
                    ? isSelected
                      ? theme.value.available.selected
                      : theme.value.available.unselected
                    : isSelected
                    ? theme.value.unavailable.selected
                    : theme.value.unavailable.unselected),
                }}
                onClick={() => {
                  if (!isAvailable) return;
                  !isSelected ? setActiveSize(newSize) : null;
                }}
                key={size}
              >
                {size}
              </Button>
            );
          })}
        </Flex>
      </Box>
    );
  }
);

OptionPants.displayName = 'OptionPants';
