export const buttons = ({ button }) => ({
  label: 'Button settings',
  name: 'button',
  description: 'Buttons, clickable hero',
  component: 'group',
  fields: [
    {
      label: 'Clickable hero',
      description:
        'Allows entire hero to be clickable, using first button link as url',
      name: 'clickableHero',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Hide buttons',
      name: 'hideButtons',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'buttons',
      label: 'Buttons',
      component: 'group-list',
      description: 'Max of 2 buttons',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate: {
        maxItems: 2,
      },
      defaultItem: {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      fields: [
        {
          name: 'isAddToCart',
          label: 'Type of button',
          component: 'toggle',
          description: 'CTA or add to cart',
          toggleLabels: {
            true: 'Add to cart',
            false: 'cta',
          },
          defaultValue: false,
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
          description: 'Select a product to add to cart',
        },
        {
          name: 'buttonText',
          label: 'Button Text',
          component: 'text',
          description: 'Text to display on add to the cart button',
          defaultValue: 'Add to cart {price}',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
        {
          label: 'Color Override settings',
          name: 'colorOverride',
          component: 'group',
          fields: [
            {
              label: 'Enable Color Override',
              name: 'enable',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
              defaultValue: false,
            },
            {
              label: 'Background color',
              name: 'background',
              component: 'color',
            },
            {
              label: 'Text color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Hover Background color',
              name: 'backgroundHover',
              component: 'color',
            },
            {
              label: 'Text color',
              name: 'colorHover',
              component: 'color',
            },
          ],
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Shop Now',
            url: '/collections/all',
          },
          style: 'primary',
        },
      ],
    },
    {
      name: 'additionalText',
      label: 'Additional Text',
      component: 'text',
      description: 'Additional text to display below buttons',
    },
    {
      name: 'additionalTextColor',
      label: 'Additional Text Color',
      component: 'color',
    },
    {
      name: 'showLoginCTA',
      label: 'Show Login CTA',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'loginCTA',
      label: 'Login CTA',
      component: 'link',
      description: 'CTA for login',
      defaultValue: {
        text: 'Already a Member? Log in',
        url: '/account/login',
      },
    },
  ],
});
