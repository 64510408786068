import React, { useEffect } from 'react';
import { themed } from './YouTubeVideoPopup.theme';
import { Container, Flex } from 'theme-ui';

export const YouTubeVideoPopup = themed(({ theme, youtubeLink, closeModal }) => {
  const getYoutubeId = (url) => {
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url;
  }
  const youtubeId = getYoutubeId(youtubeLink);
  return (
    <Container
      sx={theme.container}
    >
      <Flex
        sx={theme.wrap}
      >
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}`}
          title="Video" htmltitle="Video modal window"
          width="100%"
          height="350px"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Flex>
    </Container>
  );
});
