import { Box, Spinner, Flex } from 'theme-ui';

// dynamic components loading component
export const loading = ({ minHeight } = { minHeight: 150 }) => (
  <Flex variant="flex.row.center" sx={{ minHeight, width: '100%' }}>
    <Box>
      <Spinner />
    </Box>
  </Flex>
);
