import { button } from '@settings/common';

export default {
  label: 'Rewards Settings',
  name: 'rewards',
  component: 'group',
  fields: [
    {
      label: 'My rewards Link',
      name: 'cta',
      component: 'link',
    },
    {
      name: 'ctaStyle',
      label: 'Link Style',
      component: 'select',
      options: button.options,
    },
  ],
  defaultValue: {
    cta: {
      text: '',
      url: '',
    },
    ctaStyle: button.value.primary,
  },
};
