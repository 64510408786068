import { create } from '@theme/create';

const PROMO_HEIGHT = ['34px', null, '40px'];

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.padded',
      zIndex: 31,
      alignItems: 'center',
      minHeight: PROMO_HEIGHT,
      maxHeight: PROMO_HEIGHT,
      '@media screen and (min-width: 480px)': {
        '&:hover': {
          '[data-comp="PromoBarClose"]': {
            opacity: 1,
          },
        },
      },
    };

    this.close = {
      position: 'absolute',
      cursor: 'pointer',
      right: ['16px', null, '24px'],
      width: '20px',
      opacity: 0,
      top: '50%',
      zIndex: 6,
      transform: 'translateY(-50%) scale(.9)',
      transformOrigin: 'center',
      transition: 'transform .4s ease-in',
      '@media screen and (min-width: 480px)': {
        '&:hover': {
          transform: 'translateY(-50%) scale(1)',
        },
      },
    };

    this.swiperButton = {
      fontSize: '12px',
      fontWeight: '700',
      color: 'white',
    };
    this.swiper = {
      width: '100%',
      '[data-comp="PromoBarMessage"]': {
        height: PROMO_HEIGHT,
        fontSize: 1, // for markdown
      },
      '@media screen and (min-width: 1025px)': {
        '[data-comp="PromoBarMessage"]': {
          height: PROMO_HEIGHT,
          fontSize: 2, // for markdown
        },
      },
      '.swiper-button-prev:after, .swiper-button-next:after': {
        ...this.swiperButton,
      },
    };

    this.message = {
      // wrapper
      variant: 'flex.row.center',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'inherit',
      maxHeight: 'inherit',
      width: '100%',
      p: {
        display: 'block',
        alignItems: 'center',
        m: 0,
      },
      // copy
      copy: {
        mr: 2,
        opacity: 1,
        transform: 'translateY(0%)',
        // html
        html: {
          variant: 'text.sm-base',
          a: {
            textDecoration: 'underline',
          },
        },
      },
    };
  })()
);
