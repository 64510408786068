import Script from 'next/script';

export function FueledNorthbeamScript() {
  return (
    <Script
      strategy="afterInteractive"
      async="true"
      src="https://j.northbeam.io/ota-sp/66c6c444-bc95-4308-a8ea-0c71e6481edd.js"
    />
  );
}