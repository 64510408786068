import { create } from '@theme/create'

export const themed = create(new function() {
  this.container = {
    position: 'relative',
    fontFamily: 'body',
  }
  this.video_wrap = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    '> div': {
      width: '100%',
      height: '100%',
    }
  }
  this.description = {
    mt: 8,
  }
});