export const account = {
  input: {
    variant: 'text.base',
    color: 'colors.black',
    padding: 0,
    paddingBottom: 4,
    mb: [9],
    border: 'none',
    borderBottom: (t) => `1px solid ${t.colors.black}`,
    borderRadius: 0,
    ':focus': {
      outline: 'none !important',
      borderBottom: (t) => `1px solid ${t.colors.gray.medium}`,
    },
  },
};
