import { button } from '@settings/common';

export const vipProductBanner = {
  label: 'VIP Banner',
  name: 'vipBanner',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enable Message',
      component: 'toggle',
      description:
        'Show a message to customers who are not VIP members or do not have a membership product in their cart.',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'showLogin',
      label: 'Show Login',
      component: 'toggle',
      description: 'Show a login link for vip customers . ',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'tags',
      component: 'tags',
      label: 'Active on tags',
      description: 'Show the message on products with these tags. ',
    },
    {
      label: 'Background Color',
      name: 'backgroundColor',
      component: 'color',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'color',
    },
    {
      label: 'Heading',
      description: 'Banner heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Join the VIP Club',
    },
    {
      label: 'Message',
      description: 'Banner message',
      name: 'message',
      component: 'textarea',
      defaultValue: 'This is a VIP-only product.',
    },
    {
      label: 'Link',
      description:
        'Optional hyperlinked text at the end of the message, e.g. "Shop Now"',
      name: 'link',
      component: 'link',
    },
    {
      name: 'style',
      label: 'Button style',
      component: 'select',
      options: button.options,
    },
  ],
  defaultValue: {
    enabled: true,
    tags: ['inveterate-tier:vip-membership'],
    backgroundColor: '#000',
    color: '#fff',
    heading: 'Join the VIP Club',
    message: 'This is a VIP-only product.',
    link: '',
    style: 'primary',
  },
};
