import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.link = {
      mb: 4,
      bg: '#FAFAFA',
      width: '100%',
      display: 'block',
      position: 'relative',
    };

    this.spinner = {
      width: '30px',
      color: 'lightGray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };

    this.wrapper = {
      border: 0,
      boxSizing: 'border-box',
      display: 'flex',
      height: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      p: 0,
      position: 'relative',
      width: '100%',
    };

    this.media = {
      bottom: 0,
      left: 0,
      p: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
      willChange: 'opacity',
    };

    this.hoverMedia = {
      ...this.media,
      border: 0,
    };
  })()
);
