import store, { useRecoilValue, useRecoilValueLoadable } from '@store';

const defaultProps = {
  perPage: 25,
  page: 1,
};

export const useYotpoSiteReviews = ({ perPage, ...params } = defaultProps) => {
  const yoptoActivePage = useRecoilValue(store.yoptoActivePage('siteReviews'));

  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoSitesReviews$({
      per_page: perPage || 25,
      page: yoptoActivePage || 1,
      ...params,
    })
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    ...(state === 'hasValue' && contents
      ? {
          ...contents,
        }
      : null),
    page: yoptoActivePage,
  };
};
