import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.headingContainer = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    };
    this.subheading = {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    };
    this.heading = {
      variant: 'text.h4',
      m: 0,
      textAlign: 'center',
      quotes: 'initial',
      maxWidth: ['335px', '725px'],
    };
    this.quote = {
      variant: 'text.md',
      m: 0,
      quotes: 'initial',
      lineHeight: '22px',
      maxWidth: ['335px', '725px'],
    };
  })()
);
