import { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Container, Heading } from 'theme-ui';

import { Section } from '@snippets';
import { useBreakpointValue } from '@hooks';
import { calcResponsiveHeight, withInView } from '@utils';

import { Schema } from './Hero.schema';
import { Slide } from './Slide';
import { Slider, ContentSlider } from './Slider';
import { themed } from './Hero.theme';

export const Hero = withInView(
  themed(({ theme, cms }) => {
    const { slider, section, slides } = cms;

    const contentPosition = useBreakpointValue([
      slider?.contentPosition,
      slider?.contentPositionDt,
    ]);
    const [swiper, setSwiper] = useState();
    const [contentSwiper, setContentSwiper] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const showContentSlider = contentPosition === 'relative';

    const mobileMaxHeight = section?.mobile?.maxHeight;
    const desktopMaxHeight = section?.desktop?.maxHeight;
    const mobileAspectRatio = section?.mobile?.aspectRatio;
    const desktopAspectRatio = section?.desktop?.aspectRatio;

    const height = useMemo(() => {
      return [
        !mobileAspectRatio || mobileAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: mobileMaxHeight,
              viewport: 768,
            })
          : 'auto',
        !desktopAspectRatio || desktopAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: desktopMaxHeight,
              viewport: 1280,
            })
          : 'auto',
      ];
    }, [
      mobileMaxHeight,
      desktopMaxHeight,
      mobileAspectRatio,
      desktopAspectRatio,
    ]);

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper || slides?.length < 2) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
        contentSwiper?.slideTo(swiper?.realIndex);
      });
    }, [swiper, contentSwiper, slides?.length]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper, contentSwiper, slides?.length]);

    const hasHeroHeading = slides?.some(
      (slide) => slide?.text?.heading || slide?.text?.subheadingText
    );

    return (
      <Section section={section}>
        {/* Regular slider for hero media and contained content */}
        <Container
          data-comp={Hero.displayName}
          sx={{
            ...theme.hero,
            height,
            ...((!mobileAspectRatio || mobileAspectRatio === 'none') &&
              (!desktopAspectRatio || desktopAspectRatio === 'none') && {
                minHeight: [
                  section?.mobile?.minHeight
                    ? `${section.mobile.minHeight}px`
                    : 'unset',
                  section?.desktop?.minHeight
                    ? `${section.desktop.minHeight}px`
                    : 'unset',
                ],
                maxHeight: [
                  section?.mobile?.maxHeight
                    ? `${section.mobile.maxHeight}px`
                    : 'unset',
                  section?.desktop?.maxHeight
                    ? `${section.desktop.maxHeight}px`
                    : 'unset',
                ],
              }),
            overflow: 'hidden',
          }}
        >
          {/* hidden heading for slides with embedded text */}
          {hasHeroHeading && slider?.hiddenHeading && (
            <Heading
              as="h1"
              role="heading"
              sx={{
                position: 'absolute',
                left: '-10000px',
                top: 'auto',
                width: '1px',
                height: '1px',
                overflow: 'hidden',
              }}
            >
              {slider.hiddenHeading}
            </Heading>
          )}

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: [
                !mobileAspectRatio || mobileAspectRatio === 'none' ? '100%' : 0,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? '100%'
                  : 0,
              ],
              pb: [
                !mobileAspectRatio || mobileAspectRatio === 'none'
                  ? 0
                  : mobileAspectRatio,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? 0
                  : desktopAspectRatio,
              ],
            }}
          >
            <Box sx={theme.innerWrapper}>
              {slides?.length > 1 ? (
                <Slider
                  slides={slides}
                  slider={slider}
                  swiper={swiper}
                  setSwiper={setSwiper}
                  showContentSlider={showContentSlider}
                  activeIndex={activeIndex}
                />
              ) : slides?.length > 0 ? (
                <Slide
                  slide={slides[0]}
                  showContentSlider={showContentSlider}
                  isActiveSlide
                  isFirstSlide
                />
              ) : null}
            </Box>
          </Box>
        </Container>

        {/* Slider for content if displayed below hero image */}
        <ContentSlider
          activeIndex={activeIndex}
          bgColor={section?.bgColor}
          contentSwiper={contentSwiper}
          setContentSwiper={setContentSwiper}
          showContentSlider={showContentSlider}
          slider={slider}
          slides={slides}
        />
      </Section>
    );
  }),
  { triggerOnce: true }
);

Hero.displayName = 'Hero';
Hero.Schema = Schema;
