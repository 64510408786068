import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      px: [8, 0],
    };

    this.sticky = {
      alignSelf: 'flex-start',
      justifySelf: 'flex-end',
      position: 'sticky',
      pt: 4,
      top: 'calc(var(--header-height) + var(--promoBar-height) + 20px)',
      width: '100%',

      wrapper: {
        variant: 'flex.column',
      },
    };

    (this.shipping = {
      variant: 'text.label.1',
      mt: 3,
      color: 'gray.dark',
    }),
      (this.description = {
        variant: 'text.sm',
        mt: 6,
      }),
      (this.ctas = {
        variant: 'flex.column.start',
      });

    this.accordions = {
      mt: 10,
    };

    this.accordion = {
      heading: {
        variant: 'text.label.2',
      },

      contents: {
        'p, ul, ol, li': {
          variant: 'text.sm',
        },
        'ul, ol': {
          pl: 0,
        },
        'p:first-of-type': {
          pt: 0,
          mt: 0,
        },
      },
    };

    this.looks = {
      variant: 'flex.column',
      mt: 11,

      heading: {
        variant: 'text.label.2',
      },

      products: {
        gridTemplateColumns: '1fr 1fr',
        columGap: '20px',
        mt: 8,
      },
    };
  })()
);
