import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrap = {
      backgroundColor: 'background',
      zIndex: '1',
    };
    this.content = {};
    this.heading = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      variant: 'text.sm',
      py: '10px',
      px: 10,
      fontWeight: 400,
      textAlign: 'center',
      img: {
        mr: '4px',
      },
    };
    this.progress = {
      bg: 'lightGray',
      color: 'seafoam',
      height: '4px',
      borderColor: 'black',
      borderRadius: 0,
    };
  })()
);
