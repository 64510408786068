import { icons, color } from '@settings/common';

const generateIcons = () => [
  {
    text: 'Buttery Soft',
    icon: icons.value.butterySoft,
  },
  {
    text: 'Tailored Fit',
    icon: icons.value.tailoredFit,
  },
  {
    text: 'Anti Pilling',
    icon: icons.value.antiPilling,
  },
];

export const Schema = () => {
  return {
    label: 'Product Education',
    key: 'productEducation',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Shop By Cut',
      },
      {
        label: 'Heading color',
        name: 'headingColor',
        component: 'select',
        options: color.options,
        defaultValue: color.value.black,
      },
      {
        label: 'Text Placement',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        name: 'location',
        options: [
          {
            value: 'top',
            label: 'Top',
          },
          {
            value: 'middle',
            label: 'Middle',
          },
          {
            value: 'bottom',
            label: 'Bottom',
          },
        ],
        defaultValue: 'bottom',
      },
      {
        name: 'showImageHeader',
        label: 'Hide / Show Image Heading',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Items',
        name: 'items',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            name: 'title',
            component: 'text',
            label: 'Item title',
            defaultValue: 'New Item',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            name: 'link',
            component: 'link',
            label: 'Link to collection',
            defaultValue: {
              link: '/about-us',
              text: 'Link Text',
            },
          },
          {
            label: 'Icons',
            name: 'icons',
            description:
              'Customize the icons and their descriptions \n ( max 4)',
            component: 'group-list',
            itemProps: {
              label: '{{item.text}}',
            },
            defaultItem: {
              text: 'Icon Description',
              icon: icons.value.antiPilling,
            },
            validate: {
              maxItems: 4,
            },
            // each icon
            fields: [
              {
                label: 'Icon',
                name: 'icon',
                component: 'select',
                description: 'Select the icon',
                options: icons.options,
                defaultValue: icons.value.preShrunk,
              },

              {
                label: 'Text',
                name: 'text',
                component: 'text',
                description: 'Enter the text',
                defaultValue: 'Pre-Shrunk',
              },
            ],
            defaultValue: generateIcons(),
          },
        ],
      },
    ],
  };
};
