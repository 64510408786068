import { useEffect } from 'react';
import { Box, Text, Grid, Heading, Paragraph, Flex } from 'theme-ui';
import spacetime from 'spacetime';

import { YotpoVotes } from '../YotpoVotes/YotpoVotes';

export const YotpoListQuestions = ({
  items = [],
  totalQuestions = 0,
  totalAnswers = 0,
  onMounted = () => { },
}) => {
  useEffect(() => { onMounted() }, []);

  return (
    <Box data-comp={YotpoListQuestions.displayName}>
      {totalQuestions ? (
        items.map((question, index) => {
          return (
            <Grid
              key={question.id + index}
              sx={{
                gridTemplateColumns: ['1fr', '180px 1fr'],
                columnGap: [0, '40px'],
                textAlign: 'left',
                width: '100%',
                pt: [9, 10, 11],
                pb: [10, 11, 12],
                // opacity: 0,
                borderBottom: (t) => `1px solid ${t.colors.beige}`,
              }}
            >
              {/* Asker column */}
              <Flex variant="flex.column">
                <Heading
                  variant="label2"
                  sx={{
                    color: 'lightGreen',
                  }}
                >
                  {question.asker.displayName}
                </Heading>

                <Paragraph
                  sx={{
                    mt: 8,
                    color: 'lightGreen',
                  }}
                >
                  {spacetime(question.createdAt).format('numeric-us')}
                </Paragraph>
              </Flex>

              {/* Question & Answer column */}
              <Flex variant="flex.column">
                {/* Question */}
                <Flex variant="flex.row.start.end" sx={{ pb: 2 }}>
                  <Paragraph
                    sx={{
                      fontFamily: 'Self Modern',
                      fontStyle: 'italic',
                      fontWeight: 'bolder',
                      // color: 'red',
                      fontSize: 4,
                      mr: 4,
                    }}
                  >
                    {index + 1}.
                  </Paragraph>

                  <Paragraph
                    sx={{
                      fontFamily: 'Self Modern',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                      fontSize: 4,
                    }}
                  >
                    {question.content}
                  </Paragraph>
                </Flex>

                {/* Answers */}
                {question?.sortedPublicAnswers?.length
                  ? question.sortedPublicAnswers.map((answer, index) => {
                    return (
                      <Flex
                        variant="flex.column"
                        key={answer.id + index}
                        sx={{
                          mt: 5,
                          mb: 7,
                          pl: 10,
                        }}
                      >
                        <Paragraph>{answer.content}</Paragraph>

                        <Paragraph
                          variant="label2"
                          sx={{
                            color: 'lightGreen',
                            fontSize: '10px !important',
                            mt: 4,
                            mb: 5,
                          }}
                        >
                          {answer.answerer.displayName}{' '}
                          {answer.storeOwnerComment ? '(Store Owner)' : ''}
                        </Paragraph>

                        <YotpoVotes
                          id={answer.id}
                          endpoint="answers"
                          votesUp={answer.votesUp}
                          votesDown={answer.votesUp}
                          alignedRight={false}
                        />
                      </Flex>
                    );
                  })
                  : null}
              </Flex>
            </Grid>
          );
        })
      ) : (
        <Flex data-comp="NoQuestionsFound" sx={{ pt: 10, pb: 16 }}>
          <Paragraph variant="lg">
            No questions found. Do you have a question?
          </Paragraph>
        </Flex>
      )}
    </Box>
  );
};

YotpoListQuestions.displayName = 'YotpoListQuestions';
