import { section, color, button, icons } from '@settings/common';

const getRandomId = () => {
  return String(Math.random()).replace('0.', '');
};

const generateIcons = () => [
  {
    id: getRandomId(),
    text: 'Buttery Soft',
    icon: icons.value.butterySoft,
  },
  {
    id: getRandomId(),
    text: 'Tailored Fit',
    icon: icons.value.tailoredFit,
  },
  {
    id: getRandomId(),
    text: 'Anti Pilling',
    icon: icons.value.antiPilling,
  },
];

export const Schema = ({ article, blog, page }) => {
  if (page?.resourceType === 'home_page' || blog || article) return null;

  return {
    label: 'Collection Subcategory',
    key: 'subcategory',
    fields: [
      {
        name: 'products',
        label: 'Products',
        component: 'group-list',
        itemProps: {
          label: '{{item.product.handle}}',
        },
        defaultItem: {
          product: { handle: 'pacific-blue-legacy-bomber' },
          button: {
            cta: {
              text: 'Shop Signature Polo',
              url: '/collections/all',
            },
            style: 'withArrow',
          },
          icons: generateIcons(),
        },
        fields: [
          {
            label: 'Item title',
            name: 'title',
            component: 'text',
            defaultValue: 'New Item',
          },
          {
            label: 'Text Items',
            name: 'icons',
            description: 'Customize the text above the links',
            component: 'group-list',
            itemProps: {
              label: '{{item.text}}',
            },
            defaultItem: {
              text: 'Text Description',
            },
            validate: {
              maxItems: 3,
            },
            fields: [
              {
                label: 'Text',
                name: 'text',
                component: 'text',
                description: 'Enter the text',
                defaultValue: 'Pre-Shrunk',
              },
            ],
            defaultValue: generateIcons(),
          },
          {
            name: 'button',
            label: 'Button',
            component: 'group',
            description: 'Set the Button link, text and style',
            itemProps: {
              label: '{{item.cta.text}}',
            },
            defaultItem: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },
            fields: [
              {
                name: 'cta',
                component: 'link',
                label: 'Button',
              },
              {
                name: 'style',
                label: 'Button style',
                component: 'select',
                options: button.options,
              },
            ],
            defaultValue: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },
          },
          {
            label: 'Select Image source',
            description:
              'Select the source of the image \n you can choose from the media library or search for a product',
            name: 'mediaType',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Product', value: 'product' },
            ],
            defaultValue: 'product',
          },
          {
            label: 'Image ',
            name: 'image',
            description: 'Select the image',
            component: 'image',
          },
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
        defaultValue: [
          {
            product: { handle: 'hoodie-classic-mini-logo' },
            title: 'Signature Polo',
            button: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },
            icons: generateIcons(),
          },
          {
            title: 'Coz Polos',
            product: { handle: 'pacific-blue-legacy-bomber' },
            button: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },

            icons: generateIcons(),
          },
          {
            product: { handle: 'nord-pine-hoodie-split-hem' },
            title: 'Split Hem',
            button: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },
            icons: generateIcons(),
          },
          {
            product: { handle: 'heather-green-1-4-zip' },
            title: 'Prestige Polos',
            button: {
              cta: {
                text: 'Shop Signature Polo',
                url: '/collections/all',
              },
              style: 'withArrow',
            },
            icons: generateIcons(),
          },
        ],
      },

      {
        label: 'Content settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'showTitle',
            label: 'Hide / Show title',
            description: 'Show title',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showIcons',
            label: 'Hide / Show icons',
            description:
              'This will also remove extra whitespace and hide text items',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'showButton',
            label: 'Hide / Show button',
            description: 'You can hide the button',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
        ],
        defaultValue: {
          showTitle: page?.resourceType !== 'page',
          showIcons: page?.resourceType !== 'page',
          showButton: true,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
