export const products = ({ color }) => ({
  name: 'products',
  label: 'Product list',
  component: 'group',
  itemProps: {
    label: '{{item.name}}',
  },
  fields: [
    {
      name: 'products',
      label: 'Products',
      component: 'group-list',
      itemProps: {
        label: '{{item.product.handle}}',
      },
      defaultItem: {
        product: { handle: 'black-crew-curve-hem' },
        showBadge: true,
        badgeStyle: 'primary',
      },
      fields: [
        {
          name: 'showBadge',
          label: 'Hide / Show badge',
          description: 'Show badge',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          name: 'badgeStyle',
          label: 'Badge style',
          description:
            'Change between primary and secondary styles for the badge',
          component: 'radio-group',
          direction: 'horizontal',
          options: [
            { label: 'Primary', value: 'primary' },
            { label: 'Secondary', value: 'inverse' },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
    },
    {
      name: 'showButton',
      label: 'Hide / Show button',
      description: 'Show button',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'cta',
      component: 'link',
      label: 'Button / Link',
    },
  ],
  defaultValue: {
    products: [
      {
        product: { handle: 'black-crew-curve-hem' },
        showBadge: true,
        badgeStyle: 'primary',
      },
      {
        product: { handle: 'hoodie-classic-mini-logo' },
        showBadge: true,
        badgeStyle: 'inverse',
      },
      {
        product: { handle: 'black-ao-jogger' },
        showBadge: true,
        badgeStyle: 'primary',
      },
      {
        product: { handle: 'heather-grey-hoodie-classic' },
        showBadge: true,
        badgeStyle: 'primary',
      },
      {
        product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
        showBadge: true,
        badgeStyle: 'primary',
      },
    ],
    cta: {
      text: 'Shop Now',
      url: '/collections/all',
    },
    showButton: true,
    buttonColor: '',
  },
});
