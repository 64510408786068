import { section, icons, text as textOptions } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Icon Row',
    key: 'icon-row',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Heading',
      },
      {
        name: 'description',
        label: 'Description',
        component: 'markdown',
        defaultValue: 'Description...',
      },
      {
        name: 'icons',
        label: 'Icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.text}}' || 'Default Title',
        },
        defaultItem: {
          text: '**Breathable**',
          icon: icons.value.breathable,
        },
        fields: [
          {
            label: 'Image',
            name: 'image',
            description:
              'Choose or upload image file. If no image src is present, section will render icons from list below.',
            component: 'image',
          },
          {
            name: 'icon',
            label: 'Icon',
            description:
              'If no image src chosen above, section will render iconst from this list.',
            component: 'select',
            options: icons.options,
          },
          {
            name: 'iconColor',
            label: 'Icon Color',
            component: 'color',
          },
          {
            name: 'iconSize',
            label: 'Icon Size',
            component: 'number',
            defaultValue: 24,
          },
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            component: 'select',
            name: 'headingSize',
            label: 'Text size (mobile)',
            options: [{ label: 'Default', value: '' }, ...textOptions.options],
          },
          {
            name: 'text',
            label: 'Text',
            component: 'markdown',
            defaultValue: 'Breathable',
          },
          {
            name: 'roundBorder',
            label: 'Round Border',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            default: false,
          },
          {
            name: 'borderColor',
            label: 'Border Color',
            component: 'color',
          },
          {
            name: 'borderWidth',
            label: 'Border Width',
            component: 'number',
            defaultValue: 1,
          },
        ],
        defaultValue: [
          {
            text: '**Breathable**',
            icon: icons.value.breathable,
          },
          {
            text: '**Water Resistant**',
            icon: icons.value.waterResistant,
          },
          {
            text: '**Buttery Soft**',
            icon: icons.value.butterySoft,
          },
        ],
      },
      section,
    ],
  };
};
