import { useMemo } from 'react';
import { Box, Container, Heading } from 'theme-ui';

import { Section } from '@snippets';
import { calcResponsiveHeight, withInView } from '@utils';

import { Schema } from './ParallaxHero.schema';
import { Hero } from './Hero';
import { themed } from './ParallaxHero.theme';

export const ParallaxHero = withInView(
  themed(({ theme, cms }) => {
    const { section } = cms;

    const hero = {
      button: cms?.button,
      content: cms?.content,
      media: cms?.media,
      text: cms?.text,
    };

    const mobileMaxHeight = section?.mobile?.maxHeight;
    const desktopMaxHeight = section?.desktop?.maxHeight;
    const mobileAspectRatio = section?.mobile?.aspectRatio;
    const desktopAspectRatio = section?.desktop?.aspectRatio;

    const height = useMemo(() => {
      return [
        !mobileAspectRatio || mobileAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: mobileMaxHeight,
              viewport: 768,
            })
          : 'auto',
        !desktopAspectRatio || desktopAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: desktopMaxHeight,
              viewport: 1280,
            })
          : 'auto',
      ];
    }, [
      mobileMaxHeight,
      desktopMaxHeight,
      mobileAspectRatio,
      desktopAspectRatio,
    ]);

    const hasHeroHeading =
      hero?.text?.heading?.headingText ||
      hero?.text?.subheading?.subheadingText;

    return (
      <Section section={section}>
        {/* Regular slider for hero media and contained content */}
        <Container
          data-comp={ParallaxHero.displayName}
          sx={{
            ...theme.hero,
            height,
            ...((!mobileAspectRatio || mobileAspectRatio === 'none') &&
              (!desktopAspectRatio || desktopAspectRatio === 'none') && {
                minHeight: [
                  section?.mobile?.minHeight
                    ? `${section.mobile.minHeight}px`
                    : 'unset',
                  section?.desktop?.minHeight
                    ? `${section.desktop.minHeight}px`
                    : 'unset',
                ],
                maxHeight: [
                  section?.mobile?.maxHeight
                    ? `${section.mobile.maxHeight}px`
                    : 'unset',
                  section?.desktop?.maxHeight
                    ? `${section.desktop.maxHeight}px`
                    : 'unset',
                ],
              }),
            overflow: 'hidden',
          }}
        >
          {/* hidden heading for slides with embedded text */}
          {hasHeroHeading && (
            <Heading
              as="h1"
              role="heading"
              sx={{
                position: 'absolute',
                left: '-10000px',
                top: 'auto',
                width: '1px',
                height: '1px',
                overflow: 'hidden',
              }}
            >
              {hero?.text?.heading?.headingText ||
                hero?.text?.subheading?.subheadingText}
            </Heading>
          )}

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: [
                !mobileAspectRatio || mobileAspectRatio === 'none' ? '100%' : 0,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? '100%'
                  : 0,
              ],
              pb: [
                !mobileAspectRatio || mobileAspectRatio === 'none'
                  ? 0
                  : mobileAspectRatio,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? 0
                  : desktopAspectRatio,
              ],
            }}
          >
            <Box sx={theme.innerWrapper}>
              <Hero hero={hero} />
            </Box>
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ParallaxHero.displayName = 'ParallaxHero';
ParallaxHero.Schema = Schema;
