import { useEffect } from 'react';
import Script from 'next/script';

const transformProduct = (product = {}) => ({
  item_id: product?.product_id,
  item_name: product?.name,
  item_brand: product?.brand,
  item_category: product?.category,
  item_variant: product?.variant,
  price: product?.price,
  image_url: product?.image,
  quantity: product?.quantity || 1,
});

export function FueledApplovinScript({ withKey: applovinEventKey }) {
  useEffect(() => {
    if (!window.axon) return null;

    const pageVisitListener = () => {
      window.axon('track', 'page_view');
    };

    const viewItemListener = (event) => {
      const items = (event.detail.ecommerce.detail.products || []).map(
        transformProduct
      );

      window.axon('track', 'view_item', {
        value: items[0]?.price,
        currency: event.detail.ecommerce.currency_code,
        items,
      });
    };

    const addToCartListener = (event) => {
      const items = (event.detail.ecommerce.add.products || []).map(
        transformProduct
      );
      const value = event.detail.ecommerce.add.products.reduce(
        (total, product) =>
          total + product.price * Number(product.quantity || 1),
        0
      );

      window.axon('track', 'add_to_cart', {
        value,
        items,
        currency: event.detail.ecommerce.currency_code,
      });
    };

    window.addEventListener('dl_route_update', pageVisitListener);
    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);

    return () => {
      window.removeEventListener('dl_route_update', pageVisitListener);
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !(function (e, r) {
            const t = [
              'https://s.axon.ai/pixel.js',
              'https://c.albss.com/p/l/loader.iife.js',
            ];
            if (!e.axon) {
              var a = (e.axon = function () {
                a.performOperation
                  ? a.performOperation.apply(a, arguments)
                  : a.operationQueue.push(arguments);
              });
              (a.operationQueue = []), (a.ts = Date.now()), (a.eventKey = '${applovinEventKey}');
              for (
                let n = r.getElementsByTagName('script')[0], o = 0;
                o < t.length;
                o++
              ) {
                const i = r.createElement('script');
                (i.async = !0), (i.src = t[o]), n.parentNode.insertBefore(i, n);
              }
            }
          })(window, document);

          axon('init');`,
      }}
    />
  );
}
