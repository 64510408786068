import {
  button,
  color,
  layout,
  margin,
  padding,
  position,
} from '@settings/common';

const fields = [];

const mobileFields = [
  {
    label: 'Container width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const mobileDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const desktopFields = [
  {
    label: 'Container width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const desktopDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const mobile = {
  label: 'Mobile settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
  defaultValue: mobileDefaultValue,
};

const desktop = {
  label: 'Desktop settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
  defaultValue: desktopDefaultValue,
};

const section = {
  label: 'Section settings',
  name: 'section',
  description:
    'Background color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, desktop, mobile],
};

const text = ({ color }) => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      label: 'Heading Font Family',
      name: 'headingFontFamily',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Gotham', value: 'default' },
        { label: 'Quarto', value: 'quarto' },
      ],
      defaultValue: 'default',
    },
    {
      label: 'Heading Font Weight',
      name: 'headingFontWeight',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Bold', value: 'default' },
        { label: 'Light', value: 'light' },
      ],
      defaultValue: 'Bold',
    },
    {
      label: 'Heading Font Size (Desktop)',
      name: 'headingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'hero',
    },
    {
      label: 'Heading Font Size (Mobile)',
      name: 'headingFontSizeMobile',
      component: 'select',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'image',
    },
    {
      name: 'headingColor',
      label: 'Heading Color (Desktop)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      name: 'headingColorMob',
      label: 'Heading Color (Mobile)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      label: 'Heading Margin Bottom (Desktop)',
      name: 'headingMarginBottomDt',
      component: 'number',
    },
    {
      label: 'Heading Margin Bottom (Mobile)',
      name: 'headingMarginBottomMob',
      component: 'number',
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Superheading Font Size (Desktop)',
      name: 'superheadingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h6',
    },
    {
      label: 'Superheading Font Size (Mobile)',
      name: 'superheadingFontSizeMobile',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h6',
    },
    {
      name: 'subheading',
      component: 'markdown',
      label: 'Subheading',
    },
    {
      label: 'Subheading Font Size (Desktop)',
      name: 'subheadingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h4',
    },
    {
      label: 'Subheading Font Size (Mobile)',
      name: 'subheadingFontSizeMobile',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h4',
    },
    {
      name: 'color',
      label: 'Subheading Color (Desktop)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      name: 'colorMob',
      label: 'Subheading Color (Mobile)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
  ],
  defaultValue: {
    heading: '# Welcome to Storefront',
    headingFontSize: 'big',
    headingFontSizeMobile: 'h1',
    headingMarginBottomDt: 20,
    headingMarginBottomMob: 8,
    superheading: 'Backpack Storefronts',
    subheading: 'Manage content directly within the customizer.',
    subheadingFontSize: 'big',
    color: '',
    colorMob: '',
  },
});

export const Schema = () => {
  return {
    label: 'Klaviyo Subscribe',
    key: 'klaviyo-subscribe',
    fields: [
      {
        label: 'List id',
        name: 'listId',
        component: 'text',
        defaultValue: '',
      },
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, image position',
        component: 'group',
        fields: [
          {
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
            label: 'Alt',
          },
          {
            name: 'image',
            label: 'Image (desktop)',
            component: 'image',
          },
        ],
        defaultValue: {
          alt: 'Background image',
          image: {
            src: '',
          },
        },
      },
      text({ color }),
      {
        name: 'form',
        label: 'Form Settings',
        component: 'group',
        fields: [
          {
            label: 'Button Text',
            name: 'buttonText',
            component: 'text',
            defaultValue: 'Subscribe',
          },
          {
            name: 'buttonStyle',
            label: 'Button Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Capture Phone Number',
            name: 'capturePhoneNumber',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Phone Number Is Required',
            name: 'phoneNumberIsRequired',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Consent Is Required',
            name: 'consentIsRequired',
            component: 'toggle',
            description:
              'Enables consent checkbox to be required to submit form',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Consent Text',
            name: 'consentText',
            component: 'markdown',
            defaultValue:
              'By providing your phone number, you agree to receive recurring automated marketing text messages...',
          },
          {
            label: 'Consent Text Checkbox',
            name: 'consentTextCheckbox',
            component: 'text',
            defaultValue: 'I agree with all terms and conditions.',
          },

          {
            label: 'Consent Text Font Size ',
            name: 'consentTextFontSize',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
              { label: 'Body', value: 'body' },
              { label: 'SM', value: 'sm' },
              { label: 'LG', value: 'lg' },
              { label: 'Caption', value: 'caption' },
              { label: 'Label', value: 'label' },
              { label: 'Text Link', value: 'textLink' },
              { label: 'Base', value: 'base' },
            ],
            defaultValue: 'base',
          },
          {
            name: 'consentColor',
            label: 'Consent Color',
            component: 'color',
            colors: color.list,
          },
          {
            name: 'successMessage',
            label: 'Success Message',
            component: 'markdown',
            defaultValue: 'Submission sent!',
          },
          {
            label: 'Success Text Font Size ',
            name: 'successTextFontSize',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
              { label: 'Body', value: 'body' },
              { label: 'SM', value: 'sm' },
              { label: 'LG', value: 'lg' },
              { label: 'Caption', value: 'caption' },
              { label: 'Label', value: 'label' },
              { label: 'Text Link', value: 'textLink' },
              { label: 'Base', value: 'base' },
            ],
            defaultValue: 'base',
          },
          {
            name: 'successColor',
            label: 'Success message Color',
            component: 'color',
            colors: color.list,
          },
        ],
      },
      section,
    ],
  };
};
