import { useMemo } from 'react';
import { Box, Button } from 'theme-ui';
import startCase from 'lodash.startcase';

import { themed } from './Swatch.theme';

export const Swatch = themed(
  ({
    theme,
    color,
    colorName,
    isWhite,
    handleClick,
    size = 8,
    showName = true,
    isSelected = false,
  }) => {
    const titledColorName = useMemo(() => startCase(colorName), [colorName]);

    return color ? (
      <Button
        onClick={() => handleClick(colorName)}
        sx={{
          ...theme.wrapper,
          width: size,
          height: size,
          '@media screen and (min-width: 480px)': {
            ':hover': {
              '[data-comp="color-swatch-name"]': {
                opacity: [0, 0, 0, 1],
              },
              '@media screen and (min-width: 480px)': {
                '[data-comp=SwatchInnerCircle]': {
                  r: 10,
                },
              },
            },
          },
        }}
      >
        <Box
          as="svg"
          width={size}
          height={size}
          viewBox="0 0 28 28"
          fill="none"
          sx={theme.value({ isSelected })}
        >
          <Box
            id="outer"
            as="circle"
            cx="14"
            cy="14"
            r="13.5"
            stroke={isWhite ? '#B4B4B4' : color}
          />
          <Box
            id="inner"
            data-comp="SwatchInnerCircle"
            as="circle"
            cx="14"
            cy="14"
            r={isSelected ? '11' : '13.5'}
            fill={color}
            stroke={isWhite ? '#B4B4B4' : color}
          />
        </Box>
        {showName && (
          <Box data-comp="color-swatch-name" sx={theme.colorName}>
            {titledColorName}
          </Box>
        )}
      </Button>
    ) : null;
  }
);

Swatch.displayName = 'Swatch';
