import { store } from '@backpackjs/storefront';

import Cart from './Cart';
import Cms from './Cms';
import Collection from './Collection';
import Global from './Global';
import Menu from './Menu';
import Search from './Search';
import SelectedSellingPlan from './SelectedSellingPlan';
import SelectedVariant from './SelectedVariant';
import Yotpo from './Yotpo';

export { useGotoRecoilSnapshot } from 'recoil';

// share storefront recoil within the project
// export const useGotoRecoilSnapshot = store.recoil.useGotoRecoilSnapshot;
export const { useRecoilState } = store.recoil;
export const { useRecoilStateLoadable } = store.recoil;
export const { useSetRecoilState } = store.recoil;
export const { useResetRecoilState } = store.recoil;
export const { useRecoilValue } = store.recoil;
export const { useRecoilValueLoadable } = store.recoil;
export const { useRecoilSnapshot } = store.recoil;
export const { useRecoilCallback } = store.recoil;
export const { atom } = store.recoil;
export const { atomFamily } = store.recoil;
export const { selector } = store.recoil;
export const { selectorFamily } = store.recoil;

// eslint-disable-next-line import/no-default-export
export default {
  ...Cart,
  ...Cms,
  ...Collection,
  ...Global,
  ...Menu,
  ...Search,
  ...SelectedSellingPlan,
  ...SelectedVariant,
  ...Yotpo,
};
