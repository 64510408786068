import { useRef, useEffect } from 'react';

export const useSetPromoBarHeightCssVar = (inView, entry) => {
  const heightVarSet = useRef(false);

  const setPromoBarHeightVar = () => {
    if (heightVarSet.current) return;
    if (!entry) {
      document.documentElement.style.setProperty('--promoBar-height', '0px');
      return;
    }
    if (!inView) return;

    const { target } = entry;
    const promoBarHeightPx = inView
      ? `${target.getBoundingClientRect().height}px`
      : `0px`;

    document.documentElement.style.setProperty(
      '--promoBar-height',
      promoBarHeightPx
    );
    heightVarSet.current = true;
  };

  useEffect(() => { setPromoBarHeightVar() }, [inView]);
};
