import { useEffect, useState } from 'react';
import { Section } from '@snippets';
import Script from 'next/script';
import { useRouter } from '@backpackjs/storefront';
import { Box, Button, Flex, Field, Input, Paragraph } from 'theme-ui';
import { Schema } from './NarvarTracking.schema';

export const NarvarTracking = ({ cms }) => {
  const { section, heading, subHeading, fieldLabel, buttonText } = cms;

  const { query } = useRouter();

  const [orderNumber, setOrderNumber] = useState(null);

  const orderHandler = async (event) => {
    event.preventDefault();
    const { _orderNumber } = event.target;

    await setOrderNumber(_orderNumber.value);
  };

  useEffect(() => {
    if (!orderNumber) return;
    try {
      window.narvar('dynamicTrack', {
        localeLanguage: 'en', // Put locale language code here
        localeCountry: 'US', // Put locale country code here
        // trackingNumbers: query?.tracking_numbers || trackingNumber, // Put tracking number here
        trackingNumbers: '', // Put tracking number here
        // carrier: query?.carrier || 'generic', // Put carrier moniker here
        carrier: '', // Put carrier moniker here
        orderNumbers: query?.order_numbers || orderNumber, // Put order number here
        originZip: '', // Put origin zip here
        destinationZip: '', // Put destination zip here
        originCountry: '', // Put origin country code here
        destinationCountry: '', // Put destination country code here
        service: '', // Put service code here
        orderDate: '', // Put order date [YYYY-MM-DD] here
        shipDate: '', // Put ship date [YYYY-MM-DD] here
        promiseDate: '', // Put promise date [YYYY-MM-DD] here
        retailerDcId: '', // Put retailer distribution center ID here
        productCategory: '', // Put product category here
      });
    } catch (err) {
      console.error(err);
    }
  }, [orderNumber]);

  return (
    <Section section={section}>
      {!orderNumber && (
        <Flex
          data-comp="TrackingForm"
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            py: '2.5rem',
            maxWidth: '500px',
            bg: 'gray.light',
            mx: 'auto',
          }}
        >
          <Box sx={{ width: '80%' }}>
            {/* Heading */}
            {heading && <Paragraph variant="text.h3">{heading}</Paragraph>}

            {/* Sub heading */}
            {subHeading && (
              <Paragraph
                sx={{
                  mt: 8,
                }}
              >
                {subHeading}
              </Paragraph>
            )}
          </Box>

          {/* Form */}
          <Flex
            variant="flex.column"
            as="form"
            onSubmit={orderHandler}
            sx={{
              width: '80%',
              mt: 14,
              mb: 12,
            }}
          >
            <Box
              sx={{
                mb: 12,
              }}
            >
              <Field
                variant="account.input"
                name="_orderNumber"
                label={fieldLabel}
                defaultValue={orderNumber}
                required
                as={Input}
              />
            </Box>

            <Button
              variant="primary"
              sx={{
                minWidth: '100%',
              }}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      )}

      {orderNumber && <div data-narvar-feature="dynamicTrack" />}
      <Script
        src={`${process.env.NEXT_PUBLIC_NARVAR_TRACKING_SCRIPT}`}
        type="text/javascript"
        strategy="afterInteractive"
      />
    </Section>
  );
};

NarvarTracking.displayName = 'NarvarTracking';
NarvarTracking.Schema = Schema;
