import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
      },
      '.swiper-container, .swiper-wrapper, .swiper-slide': {
        height: 'auto !important',
        width: '100%',
      },
    };

    this.grid = {
      display: 'grid',
      gridGap: [6, 10],
      gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(4, 1fr)'],
    };

    this.portrait = {
      width: '100%',
      ':before': {
        content: '""',
        pb: `${(1 / 0.75) * 100}%`,
        float: 'left',
      },
    };

    this.square = {
      width: '100%',
      ':before': {
        content: '""',
        pb: '100%',
        float: 'left',
      },
    };
  })()
);
