import React from 'react';
import { Box, Flex, Grid, Paragraph } from 'theme-ui';
import { themed } from './AmbassadorHow.theme';
import { Schema } from './AmbassadorHow.schema';
import { Section, Picture, Link, Svg } from '@snippets';
import { withInView } from '@utils';

export const AmbassadorHow = withInView(
  themed(({ theme, cms }) => {
    const {
      heading,
      howItems: { items },
      section,
      instagramLink,
      facebookLink,
    } = cms;

    return (
      <Section section={section}>
        <Box sx={theme.wrapper}>
          <Paragraph sx={theme.title}>{heading}</Paragraph>
          <Grid as="ul" sx={theme.container}>
            {items?.map(({ image, heading, text }, index) => (
              <Box as="li" key={heading} sx={theme.item}>
                <Picture
                  images={[
                    {
                      src: image,
                      ratio: 1,
                    },
                  ]}
                  alt={heading}
                  sx={theme.image}
                />
                <Box sx={theme.itemHeading}>{heading}</Box>
                <Box sx={theme.itemText}>{text}</Box>
                {index === 2 && (
                  <Flex>
                    <Link href={instagramLink} sx={theme.iconWrap}>
                      <Svg
                        src="/svgs/noprecache/social/instagram.svg#instagram"
                        alt="social"
                        viewBox="0 0 26 26"
                        sx={theme.icon}
                      />
                    </Link>
                    <Link href={facebookLink} sx={theme.iconWrap}>
                      <Svg
                        src="/svgs/noprecache/social/facebook.svg#facebook"
                        alt="social"
                        viewBox="0 0 26 26"
                        sx={theme.icon}
                      />
                    </Link>
                  </Flex>
                )}
              </Box>
            ))}
          </Grid>
        </Box>
      </Section>
    );
  })
);

AmbassadorHow.displayName = 'AmbassadorHow';
AmbassadorHow.Schema = Schema;
