import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useRouter } from '@backpackjs/storefront';

import { useBreakpointValue } from '@hooks';
import { Content } from './Content';

import { themed } from './Slide.theme';

const Video = dynamic(() => import('./Video').then((module) => module.Video), {
  ssr: false,
  suspense: false,
});
const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const Slide = themed(
  ({
    theme,
    slide,
    isSlider,
    showContentSlider,
    visible,
    isActiveSlide,
    isFirstSlide,
  }) => {
    const router = useRouter();
    const { button, media } = slide;
    const cta = button?.buttons?.[0]?.cta;
    const clickable = button?.clickableHero && cta?.url;

    const mediaType = useBreakpointValue([
      media?.mediaTypeMb,
      media?.mediaTypeDt,
    ]);

    const [loaded, setLoaded] = useState(isFirstSlide);

    useEffect(() => {
      if (loaded) return;
      if (isActiveSlide) setLoaded(true);
    }, [isActiveSlide]);

    return (
      <Box
        data-comp={Slide.displayName}
        onClick={() => (clickable ? router.push(cta?.url) : null)}
        tabIndex={clickable && button?.hideButtons ? '0' : '-1'}
        aria-label={clickable && button?.hideButtons ? cta?.text : ''}
        sx={{
          ...theme.slide,
          cursor: clickable ? 'pointer' : isSlider ? 'grab' : 'auto',
        }}
      >
        {loaded &&
          (mediaType === 'image' ? (
            <Image slide={slide} isFirstSlide={isFirstSlide} />
          ) : (
            <Video video={media?.video} isActiveSlide={isActiveSlide} />
          ))}

        {!showContentSlider && <Content slide={slide} visible={visible} />}
      </Box>
    );
  }
);

Slide.displayName = 'Slide';
