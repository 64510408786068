import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      bg: 'ghostwhite',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'body',
    };

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pointerEvents: 'none',
      position: 'relative',
      width: '100%',
    };

    this.content = {
      px: [12, 15],
      py: [15, 17],
      pointerEvents: 'auto',
      '@media only screen and (max-width: 479px)': {
        px: 10,
      },
    };

    this.superheading = {
      fontFamily: 'body',
      letterSpacing: '2px',
      pb: 3,
      textUnderlineOffset: '0.4em',
    };

    this.heading = {
      fontFamily: 'heading',
      m: 0,
    };
  })()
);
