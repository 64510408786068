import { Flex, Heading } from 'theme-ui';

import { Svg } from '@snippets';

export const IconWithDescription = ({ icon, text, viewBox, theme }) => {
  const svgName = icon?.split('#').pop();

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        ...theme.flex,
      }}
    >
      {icon !== 'none' && (
        <Svg
          alt={text}
          src={`/svgs/noprecache/fabric/${svgName}.svg#${svgName}`}
          viewBox={viewBox || '0 0 360 360'}
          sx={{ ...theme.icon }}
        />
      )}
      <Flex>{text && <Heading sx={{ ...theme.text }}>{text}</Heading>}</Flex>
    </Flex>
  );
};

IconWithDescription.displayName = 'IconWithDescription';
