import { Grid, Flex, Box, Paragraph } from 'theme-ui';

import { Accordion, Accordions, ProductItem, Markdown, Svg } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './ProductMeta.schema';
import { themed } from './ProductMeta.theme';

// This component's data is rendered by the Product/Main/Details component.
export const ProductMeta = withInView(
  themed(({ cms, theme }) => {
    const { details, looks, fabric } = cms;
    const { icons } = fabric;

    return (
      <Box data-comp={ProductMeta.displayName}>
        {/* Details accordions */}
        {details?.groups?.length ? (
          <Accordions
            textColor="black"
            borderColor="mediumLight"
            iconColor="black"
            minHeight="36px"
            sx={theme.accordions}
          >
            {details.groups.map((group, index) => {
              return (
                <Accordion
                  theme="light"
                  key={index}
                  autoOpen={group.autoOpen}
                  oneOpenAtATime={false}
                >
                  <Accordion.Header>
                    <Paragraph sx={theme.accordion.heading}>
                      {group.heading}
                    </Paragraph>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Markdown
                      key={`Content${index}`}
                      text={group.contents}
                      sx={theme.accordion.contents}
                    />
                  </Accordion.Body>
                </Accordion>
              );
            })}
          </Accordions>
        ) : null}

        {/* Fabric icons */}
        {icons?.length ? (
          <Grid
            sx={{
              ...theme.fabric.grid,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {icons.map((icon, index) => {
              const [label, value] = icon.split('|');

              return (
                <Flex
                  key={index}
                  sx={{ ...theme.fabric.item, minWidth: ['100px', '170px'] }}
                >
                  <Svg
                    src={`/svgs/noprecache/fabric/${value}.svg#${value}`}
                    alt={label}
                    viewBox="0 0 360 360"
                    sx={theme.fabric.icon}
                  />

                  <Paragraph variant="text.sm" sx={theme.fabric.label}>
                    {label}
                  </Paragraph>
                </Flex>
              );
            })}
          </Grid>
        ) : null}

        {/* Complete The Look products */}
        {looks?.products?.length ? (
          <Flex sx={theme.looks}>
            <Paragraph sx={theme.looks.heading}>
              {looks?.heading || 'Complete the Look'}
            </Paragraph>

            <Grid sx={theme.looks.products}>
              {looks.products.slice(0, 2).map((look, index) => {
                return (
                  <ProductItem
                    key={look.product.handle}
                    product={look.product}
                    index={index}
                  />
                );
              })}
            </Grid>
          </Flex>
        ) : null}
      </Box>
    );
  }),
  { triggerOnce: true }
);

ProductMeta.displayName = 'ProductMeta';
ProductMeta.Schema = Schema;
