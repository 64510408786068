import { useEffect, useMemo, Children, cloneElement, useState } from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';

import { Header } from './Header';
import { Body } from './Body';
import { themed } from './Accordions.theme';

export const Accordion = themed(
  ({
    theme,
    borderColor,
    children,
    iconColor,
    index,
    isOpen,
    maxHeight,
    minHeight,
    oneOpenAtATime,
    setOpenIndex,
    textColor,
    autoOpen,
    setAutoOpenTier,
    ...props
  }) => {
    const [localOpen, setLocalOpen] = useState(false);
    useEffect(() => {
      if (autoOpen === true) {
        setLocalOpen(true);
      }
    }, [autoOpen]);
    const accordionIsOpen = oneOpenAtATime ? isOpen : localOpen;

    const AccordionContent = useMemo(() => {
      let Header = null;
      let Body = null;

      Children.forEach(children, (child) => {
        const displayName =
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
          child?.type?.displayName;

        switch (displayName) {
          case 'Header':
            Header = cloneElement(child, {
              iconColor,
              index,
              isOpen: accordionIsOpen,
              minHeight,
              onClick: () => {
                oneOpenAtATime
                  ? setOpenIndex(isOpen ? null : index)
                  : setLocalOpen(!localOpen);

                if (setAutoOpenTier) {
                  setAutoOpenTier(false);
                }
              },
              textColor,
              ...child.props,
            });
            break;

          case 'Body':
            Body = cloneElement(child, {
              index,
              isOpen: accordionIsOpen,
              ...child.props,
            });
            break;

          default:
            break;
        }
      });

      return (
        <>
          {Header}
          {Body}
        </>
      );
    }, [
      Children.count(children),
      children,
      iconColor,
      index,
      isOpen,
      minHeight,
      setOpenIndex,
      oneOpenAtATime,
      localOpen,
    ]);

    return (
      <Box
        data-comp={Accordion.displayName}
        role="tablist"
        {...props}
        sx={{
          ...theme.accordion,
          borderColor,
          maxHeight:
            (!oneOpenAtATime && localOpen) || (oneOpenAtATime && isOpen)
              ? maxHeight
              : minHeight,
          ...props.sx,
        }}
      >
        {AccordionContent}
      </Box>
    );
  }
);

Accordion.displayName = 'Accordion';
Accordion.Header = Header;
Accordion.Body = Body;

Accordion.propTypes = {
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  index: PropTypes.number,
  isOpen: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oneOpenAtATime: PropTypes.bool,
  setOpenIndex: PropTypes.func,
  textColor: PropTypes.string,
};
