import { useEffect, useRef, useState } from 'react';
import { withInView } from '@utils';
import { Section, Picture, Svg } from '@snippets';

import { Button, Container } from 'theme-ui';
import { Schema } from './AmbassadorVideo.schema';
import { themed } from './AmbassadorVideo.theme';

export const AmbassadorVideo = withInView(
  themed(({ theme, cms }) => {
    const { videoScr, button, overlay, section } = cms;

    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const handlePlay = () => {
      setIsPlaying((playing) => !playing);
    };

    const handlePlaying = isPlaying
      ? videoRef.current.pause()
      : videoRef.current.play();

    useEffect(() => {
      handlePlaying();
    }, [isPlaying]);
    return (
      <Section section={section}>
        <Container sx={theme.wrapper}>
          {!isPlaying && (
            <>
              <Picture
                images={[
                  {
                    src: overlay,
                    ratio: 1,
                  },
                ]}
                sx={theme.overlay}
              />
              <Button sx={theme.playBtn} onClick={handlePlay}>
                <Svg
                  src="/svgs/play_round.svg"
                  alt="play"
                  viewBox="0 0 16 16"
                  sx={theme.icon}
                />
                {button}
              </Button>
            </>
          )}

          <video
            ref={videoRef}
            title="ambassador video"
            style={{
              ...theme.video,
              opacity: isPlaying ? 1 : 0,
            }}
            muted
          >
            <source src={videoScr} />
            Your browser does not support the <code>video</code> element.
          </video>

          {/* <VideoPlayer */}
          {/*  styles={{...theme.video}} */}
          {/*  source={videoScr} */}
          {/*  title={'video'} */}
          {/*  autoPlay={false} */}
          {/*  showPlayButton */}
          {/* /> */}
        </Container>
      </Section>
    );
  })
);

AmbassadorVideo.displayName = 'AmbassadorVideo';
AmbassadorVideo.Schema = Schema;
