import { Flex, Box, Text } from 'theme-ui';

import { Link, Svg } from '@snippets';

export const Links = ({ link }) => (
  <Flex>
    <Link
      variant="buttons.withArrow"
      href={link?.cta.url}
      sx={{
        mt: '5px',
        display: 'inline-block',
        position: 'relative',
        maxWidth: '85%',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <Text
        sx={{
          fontSize: ['12px', '14px'],
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: 'relative',
        }}
      >
        {link?.cta.text}
        <Svg
          alt="chevron"
          viewBox="0 0 48 48"
          src="/svgs/chevron/right.svg#right"
          sx={{
            width: ['10px', '12px'],
            position: 'relative',
            top: '0',
            ml: 2,
          }}
        />
      </Text>
    </Link>
  </Flex>
);

{
  /* <Flex
    sx={{
      marginTop: 'auto',
    }}
  >
    <Link
      variant={`buttons.${link.style}`}
      href={link?.cta.url}
      sx={{
        mt: '5px',
        display: 'flex',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <Text
        sx={{
          fontSize: ['0.6rem', '0.8rem', '0.9rem'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {link?.cta.text}
      </Text>
      <Svg
        alt="chevron"
        viewBox="0 0 48 48"
        src="/svgs/chevron/right.svg#right"
        sx={{ width: ['10px', '12px'], ml: '10px' }}
      />
    </Link>
  </Flex> */
}
