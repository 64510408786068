import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      boxShadow: 'left',
      minHeight: `var(--viewport-height)`,
      position: 'fixed',
      right: 0,
      top: 0,
      transition: 'transform .3s ease-in-out',
      width: ['100%', '617px'],
      willChange: 'transform',
      zIndex: 50,
      fontFamily: 'body',

      closed: {
        transform: 'translateX(100%)',
      },
      open: {
        transform: 'translateX(0)',
      },

      scroll: {
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    };

    // dynamic drawer wrapper
    this.inner = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'var(--viewport-height)',

      scroll: {
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    };
  })()
);
