import { useEffect, useState } from 'react';
import { Flex } from 'theme-ui';
import { Markdown, Picture } from '@snippets';
import { useBreakpointValue } from '@hooks';
import { convertAlignToFlex } from '@utils/common';
import { text as textBase } from '@theme/theme.text';

export const TextSection = ({ text, globalAlign, theme }) => {
  const [logoImageRatio, setLogoImageRatio] = useState(null);
  const [logoImageRatioDt, setLogoImageRatioDt] = useState(null);

  const logo = text?.logoSettings;
  const logoAlignMb = convertAlignToFlex(logo?.logoAlignmentMb);
  const logoAlignDt = convertAlignToFlex(logo?.logoAlignmentDt);

  const heading = text?.heading;
  const headingText = heading?.headingText;
  const headingTextAlign = useBreakpointValue([
    heading?.headingAlignmentMb,
    heading?.headingAlignmentDt,
  ]);
  const headingSize = useBreakpointValue([
    heading?.headingSizeMb,
    heading?.headingSizeDt,
  ]);
  const headingColor = useBreakpointValue([
    heading?.headingColorMb || 'currentColor',
    heading?.headingColorDt || 'currentColor',
  ]);

  const subheading = text?.subheading;
  const subheadingText = subheading?.subheadingText;
  const subheadingTextAlign = useBreakpointValue([
    subheading?.subheadingAlignmentMb,
    subheading?.subheadingAlignmentDt,
  ]);
  const subheadingColor = useBreakpointValue([
    subheading?.subheadingColorMb || 'currentColor',
    subheading?.subheadingColorDt || 'currentColor',
  ]);
  const subheadingPlacement = subheading?.subheadingPlacement || 'below-logo';

  const superheading = text?.superheading;
  const superheadingText = superheading?.superheadingText;
  const superheadingTextAlign = useBreakpointValue([
    superheading?.superheadingAlignmentMb,
    superheading?.superheadingAlignmentDt,
  ]);
  const superheadingColor = useBreakpointValue([
    superheading?.superheadingColorMb || 'currentColor',
    superheading?.superheadingColorDt || 'currentColor',
  ]);

  const globalAlignText = globalAlign?.textAlign;

  // temporary solution until v4-v5 customizer passes ratio, height, and image through cms
  useEffect(() => {
    if (logo?.logoImage) {
      const url = logo.logoImage.src;
      const img = new Image();
      img.src = url;
      img.onload = function () {
        setLogoImageRatio(img.width / img.height);
      };
    }
  }, [logo?.logoImage]);

  useEffect(() => {
    if (logo?.logoImageDt) {
      const url = logo.logoImageDt.src;
      const img = new Image();
      img.src = url;
      img.onload = function () {
        setLogoImageRatioDt(img.width / img.height);
      };
    }
  }, [logo?.logoImageDt]);

  return (
    <>
      {subheadingPlacement === 'above-logo' && subheadingText && (
        <Markdown
          text={subheadingText}
          textSx={{
            ...theme.subheading,
            textAlign: globalAlign?.active
              ? globalAlignText
              : subheadingTextAlign,
            color: subheadingColor,
          }}
          sx={{
            mb: [8, 11],
          }}
        />
      )}

      {logo?.showLogo &&
        logo?.logoImage?.src &&
        logo?.logoImageDt?.src &&
        logoImageRatio &&
        logoImageRatioDt && (
          <Flex
            sx={{
              width: '100%',
              justifyContent: [logoAlignMb, logoAlignDt],
              mb: superheadingText || headingText ? 8 : 0,
            }}
          >
            <Picture
              images={[
                {
                  src: logo?.logoImage?.src,
                  ratio: logoImageRatio || 1,
                },
                {
                  src: logo?.logoImageDt?.src,
                  ratio: logoImageRatioDt || 1,
                },
              ]}
              alt="logo"
              sx={{
                width: [`${logo?.logoWidthMb}%`, `${logo?.logoWidthDt}%`],
              }}
            />
          </Flex>
        )}

      {superheadingText && (
        <Markdown
          text={superheadingText}
          textSx={{
            ...theme.superheading,
            textAlign: globalAlign?.active
              ? globalAlignText
              : superheadingTextAlign,
            color: superheadingColor,
          }}
          sx={{
            mb: 8,
          }}
        />
      )}

      <Markdown
        text={headingText}
        textSx={{
          ...theme.heading,
          ...textBase[headingSize],
          textAlign: globalAlign?.active ? globalAlignText : headingTextAlign,
          color: headingColor,
        }}
        sx={{
          mb:
            subheadingPlacement === 'below-logo' && subheadingText
              ? [8, 11]
              : 0,
        }}
      />

      {subheadingPlacement === 'below-logo' && subheadingText && (
        <Markdown
          text={subheadingText}
          textSx={{
            ...theme.subheading,
            textAlign: globalAlign?.active
              ? globalAlignText
              : subheadingTextAlign,
            color: subheadingColor,
          }}
        />
      )}
    </>
  );
};

TextSection.displayName = 'TextSection';
