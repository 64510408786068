import { color, align, sectionWithoutFields } from '@settings/common';
import { header, media, products } from './schemas';

export const Schema = ({ blog, article, page }) => {
  if (page?.resourceType === 'home_page' || blog || article) return null;
  return {
    label: 'Super Collection',
    key: 'super-collection',
    fields: [
      header({ align }),
      media(),
      products({ color }),
      {
        ...sectionWithoutFields,
        defaultValue: {
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};
