/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Box, Button, Flex, Spinner, Paragraph } from 'theme-ui';
import { useCountry, useCountries } from '@backpackjs/storefront';

import store, { useRecoilState } from '@store';
import { Svg } from '@snippets';

import { countries as missingCountries } from '@utils';
import { themed } from './CountrySelector.theme';

export const CountrySelector = themed(({ theme, handleOnChange, ...props }) => {
  const dropdownRef = useRef(null);
  const countries = useCountries();
  const { country, updateCountry, ...updateStatus } = useCountry();
  const haveCountries = countries?.length > 0;

  const [currentCountry, setCurrentCountry] = useRecoilState(store.country);
  const [selectedCountry, setSelectedCountry] = useState(currentCountry);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // TODO: some countries are not being returned by the useCountries hook. This is a temporary solution.
  const fullCountries = useMemo(() => {
    if (!countries?.length) return null;
    return [...missingCountries, ...countries].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [countries?.length, missingCountries]);

  const handleCountrySelect = useCallback(
    async (selected) => {
      try {
        if (!selected?.name || isUpdating) return;
        setIsUpdating(true);
        setIsOpen(false);

        await updateCountry({
          country: selected,
        });

        setCurrentCountry(selected);
        setSelectedCountry(selected);

        if (typeof handleOnChange === 'function') {
          handleOnChange();
        }
        setIsUpdating(false);
      } catch (error) {
        console.error(error.message);
        setIsUpdating(false);
      }
    },
    [isUpdating]
  );

  useEffect(() => {
    if (country?.name !== currentCountry?.name) {
      setCurrentCountry(country);
    }
    if (country?.name !== selectedCountry?.name) {
      setSelectedCountry(country);
    }
  }, [country]);

  useEffect(() => {
    const closeOnClickOutside = (event) => {
      if (!dropdownRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mouseup', closeOnClickOutside);
    return () => document.removeEventListener('mouseup', closeOnClickOutside);
  }, []);

  // TODO: if auto geolocation is fixed this will update the country

  // const updateSelectedCountry = useCallback(
  //   async (selected) => {
  //     const _selectedCountry = fullCountries?.find(
  //       (_country) => _country.isoCode === selected.isoCode
  //     );

  //     await updateCountry({
  //       country: selected,
  //     });

  //     if (_selectedCountry?.name) {
  //       setCurrentCountry(_selectedCountry);
  //       setSelectedCountry(_selectedCountry);
  //     }
  //   },
  //   [fullCountries?.length]
  // );

  // useEffect(() => {
  //   if (!currentCountry?.name && currentCountry?.isoCode) {
  //     updateSelectedCountry(currentCountry);
  //   } else {
  //     setCurrentCountry(country);
  //   }
  // }, [currentCountry, haveCountries, country, selectedCountry]);

  if (!currentCountry?.name && !selectedCountry?.name) return null;

  if (!countries || countries?.length < 2) return null;

  return haveCountries && fullCountries ? (
    <Box ref={dropdownRef} sx={theme.dropdown}>
      <Button
        variant="buttons.plain"
        aria-label={`Open country selector dropdown. Current country selected is ${selectedCountry?.name}`}
        sx={{
          position: 'relative',
          height: '32px',
          border: '1px solid white',
          ...theme.item,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Svg
          alt={selectedCountry?.name}
          viewBox="0 85.333 512 341.333"
          src={`/svgs/noprecache/flags/${selectedCountry?.isoCode}.svg`}
          sx={theme.item.icon}
        />

        <Paragraph sx={theme.item.name}>{selectedCountry?.name}</Paragraph>

        <Svg
          alt="Select a s"
          viewBox="0 0 48 48"
          src={
            isOpen ? '/svgs/chevron/up.svg#up' : '/svgs/chevron/down.svg#down'
          }
          sx={theme.item.toggle}
        />

        {isUpdating && (
          <Box sx={theme.updatingBox}>
            <Spinner sx={theme.updatingSpinner} />
          </Box>
        )}
      </Button>

      <Flex
        sx={{
          ...theme.optionsWrapper,
          display: isOpen ? 'flex' : 'none',
        }}
      >
        <Box role="listbox" sx={theme.scroll}>
          {isOpen &&
            fullCountries.map((_country) => (
              <Button
                key={_country.name}
                role="option"
                aria-selected={_country.name === selectedCountry?.name}
                aria-label={_country.name}
                onClick={() => handleCountrySelect(_country)}
                sx={theme.option}
              >
                <Flex sx={theme.item}>
                  <Svg
                    alt={_country?.name}
                    viewBox="0 85.333 512 341.333"
                    src={`/svgs/noprecache/flags/${_country.isoCode}.svg`}
                    sx={theme.item.icon}
                  />

                  <Paragraph sx={theme.item.name}>{_country?.name}</Paragraph>
                </Flex>
              </Button>
            ))}
        </Box>
      </Flex>
    </Box>
  ) : (
    <Spinner height="20px" />
  );
});

CountrySelector.displayName = 'CountrySelector';
