// menu
export default {
  label: 'Account Menu Settings',
  name: 'menu',
  component: 'group',
  fields: [
    {
      name: 'items',
      label: 'Menu items',
      component: 'group-list',
      itemProps: {
        label: '{{item.text}}',
      },
      fields: [
        {
          name: 'text',
          label: 'Text',
          component: 'text',
        },
        {
          name: 'url',
          label: 'URL',
          component: 'text',
        },
        {
          label: 'Open in new tab',
          name: 'newTab',
          component: 'toggle',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
        },
        {
          label: 'Recharge link',
          name: 'rechargeLink',
          component: 'toggle',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
        },
      ],
    },
    {
      label: 'Sign Out',
      name: 'withSignOut',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      label: 'Need Help link',
      name: 'helpLink',
      component: 'link',
    },
  ],
  defaultValue: {
    items: [
      {
        text: 'Order History',
        url: '/account/orders',
        rechargeLink: false,
        newTab: false,
      },
      {
        text: 'Edit Profile',
        url: '/account/edit-profile',
        rechargeLink: false,
        newTab: false,
      },
      {
        text: 'Address Book',
        url: '/account/address-book',
        rechargeLink: false,
        newTab: false,
      },
      {
        text: 'Payment Methods',
        url: '/account/payment-methods',
        rechargeLink: false,
        newTab: false,
      },
      {
        text: 'Favorites',
        url: '/account/favorites',
        rechargeLink: false,
        newTab: false,
      },
      {
        text: 'Membership',
        url: '#',
        rechargeLink: true,
        newTab: true,
      },
      {
        text: 'Rewards',
        url: '/pages/rewards',
        rechargeLink: false,
        newTab: false,
      },
    ],
    withSignOut: true,
    helpLink: {
      url: 'https://cutsclothing.kustomer.help/contact/contact-us-HkIWrZkZ_',
      text: 'Contact Us',
      isExternal: true,
      newTab: true,
    },
  },
};
