import { forwardRef } from 'react';
import {
  Flex,
  Input,
  Label,
  Text,
  Checkbox,
  Radio,
  Select,
  Paragraph,
  Box,
  Textarea,
} from 'theme-ui';
import { pascalCase } from 'change-case';
import { CountryField } from './CountryField';

export const FormField = forwardRef(({ field }, ref) => {
  const {
    _template: type,
    column,
    direction,
    halfWidth,
    hideLabel,
    infoMessage,
    label,
    labelPosition,
    link,
    name,
    options,
    placeholder,
    required,
  } = field;

  const isTextarea = type === 'textArea';
  const isRadio = type === 'radio';
  const isSelect = type === 'select';
  const isCountry = type === 'country';

  // Non inputs
  const isLabel = type === 'label';
  const hasLabel = !isLabel;

  // Uses <checkbox>
  const isCheckbox = type === 'checkbox';
  const isMultiCheckbox = type === 'multipleCheckbox';
  const isCheckboxType = isCheckbox || isMultiCheckbox;

  // Uses <input type="file">
  const isFile = type === 'file';
  const isFileType = isFile;

  // Uses <input>
  const isText = type === 'text';
  const isEmail = type === 'email';
  const isPhone = type === 'phone';
  const isDate = type === 'date';
  const isNumber = type === 'number';
  const isUrl = type === 'url';
  const isTime = type === 'time';
  const isAddress = type === 'address';
  const isInput =
    isText ||
    isEmail ||
    isPhone ||
    isDate ||
    isNumber ||
    isUrl ||
    isTime ||
    isAddress;

  const isNotFullWidth = isCheckbox || isRadio || isFileType || isCheckboxType;

  return (
    <Flex
      data-comp={FormField.displayName}
      key={name}
      sx={{
        flexDirection: 'column',
        width: '100%',
        gridColumn: halfWidth ? column : `1 / span 2`,
        alignItems: isNotFullWidth ? 'flex-start' : 'stretch',
        position: 'relative',
        label: {
          fontSize: 0,
          textTransform: 'uppercase',
          fontWeight: 700,
          letterSpacing: '0.44px',
          mb: 0,
          ...(!isCheckbox &&
            labelPosition !== 'top' &&
            !isRadio && {
              '.FormField-Label': {
                position: 'absolute',
                top: 1,
                left: '17px',
              },
            }),
        },
        input: {
          fontSize: 3,
          px: 8,
          pt: 9,
          pb: 2,
          borderColor: 'mediumGray',
          borderRadius: '4px',
        },
        'select, textarea': {
          fontSize: 3,
          px: 8,
          pt: 9,
          pb: 2,
          borderRadius: '4px',
          borderColor: 'mediumGray',
        },
      }}
    >
      <Label
        htmlFor={name}
        sx={{
          width: isNotFullWidth ? 'auto' : '100%',
          display: 'flex',
          flexDirection: isCheckbox ? 'row' : 'column-reverse',
          py: isCheckbox || isLabel ? 2 : 0,
          columnGap: 2,
          rowGap: 3,
          fontWeight: 400,
          justifySelf:
            isCheckbox || isRadio || isFileType ? 'flex-start' : 'stretch',
        }}
      >
        {isLabel && label}

        {isFileType && (
          <Input
            data-comp={pascalCase(type)}
            id={name}
            name={name}
            ref={ref?.current[name]}
            required={required}
            type="file"
            multiple
          />
        )}

        {isInput && (
          <Input
            data-comp={pascalCase(type)}
            id={name}
            name={name}
            placeholder={placeholder}
            ref={ref?.current[name]}
            required={required}
            type={isPhone ? 'tel' : type}
            sx={{
              mb: 0,
              '&[type="date"]::-webkit-calendar-picker-indicator, &[type="time"]::-webkit-calendar-picker-indicator':
                {
                  width: '14px',
                  height: '14px',
                  position: 'relative',
                  top: '-6px',
                  right: '-5px',
                },
            }}
          />
        )}

        {isTextarea && (
          <Textarea
            data-comp={pascalCase(type)}
            id={name}
            name={name}
            placeholder={placeholder}
            ref={ref?.current[name]}
            required={required}
            sx={{ resize: 'none', p: 3 }}
            type="textarea"
          />
        )}

        {isCheckbox && (
          <Checkbox
            data-comp={pascalCase(type)}
            id={name}
            name={name}
            ref={ref?.current[name]}
            required={required}
          />
        )}

        {isMultiCheckbox && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: direction === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {options?.map((value) => (
              <Label htmlFor={`${name}.${value}`} key={value}>
                <Checkbox
                  id={`${name}.${value}`}
                  name={value}
                  ref={ref?.current[`${name}.${value}`]}
                />
                {value}
              </Label>
            ))}
          </Flex>
        )}

        {isRadio && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: direction === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            {options?.map((value) => (
              <Label htmlFor={`${name}.${value}`}>
                <Radio
                  id={`${name}.${value}`}
                  name={name}
                  ref={ref?.current[`${name}.${value}`]}
                  value={value}
                />
                <Text>{value}</Text>
              </Label>
            ))}
          </Flex>
        )}

        {isSelect && (
          <Select
            data-comp={pascalCase(type)}
            id={name}
            name={name}
            ref={ref?.current[name]}
            required={required}
            arrow={
              <Box
                as="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="currentcolor"
                sx={{
                  ml: '-25px',
                  alignSelf: 'center',
                  pointerEvents: 'none',
                }}
              >
                <path
                  d="M8.8 1.2L7.6 0L4.4 3.2L1.2 0L0 1.2L4.4 5.6L8.8 1.2Z"
                  fill="#282828"
                />
              </Box>
            }
          >
            {['', ...(options || [])].map((value, index) => (
              <option
                disabled={index === 0}
                key={`${name}.${index}}`}
                selected={index === 0}
                value={value}
              >
                {value || placeholder}
              </option>
            ))}
          </Select>
        )}

        {isCountry && (
          <CountryField
            name={name}
            placeholder={placeholder}
            ref={ref?.current[name]}
            required={required}
          />
        )}

        {hasLabel && !hideLabel && (
          <Text
            className={`${FormField.displayName}-Label`}
            dangerouslySetInnerHTML={{
              __html: `${label}${
                link?.text
                  ? `&nbsp;<a href="${link.url}" onclick="event.preventDefault" target="_blank" style"position:inline;">${link.text}</a>`
                  : ''
              }${required ? ' *' : ''}`,
            }}
            sx={{ a: { textDecoration: 'underline' } }}
          />
        )}
      </Label>

      {infoMessage && (
        <Paragraph
          sx={{
            variant: 'text.sm',
            color: 'mediumDarkGray',
            mt: 2,
          }}
        >
          {infoMessage}
        </Paragraph>
      )}
    </Flex>
  );
});

FormField.displayName = 'FormField';
