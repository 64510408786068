import { useState, useMemo, useCallback } from 'react';
import { Flex } from 'theme-ui';

import { Countdown } from '@snippets';

export function Timer({ dateTimes, status, setStatus }) {
  const { startDate, startTime, endDate, endTime } = dateTimes || {};

  if (!startDate || !startTime || !endDate || !endTime) return null;

  const [message, setMessage] = useState('');

  const getFormattedDate = useCallback((date, time) => {
    const gmtOffset =
      new Date(date.replace('T00', 'T07')).getTimezoneOffset() / 60;
    const baseTimeInMs = new Date(
      date.replace('T00', `T0${gmtOffset}`)
    ).getTime();
    const addedTimeInMin =
      Math.floor(time) * 60 + parseFloat(time.toFixed(2).split('.')[1]);
    const finalTimeInMs = baseTimeInMs + addedTimeInMin * 60 * 1000;

    return new Date(finalTimeInMs);
  }, []);

  const formattedDates = useMemo(() => {
    return {
      start: getFormattedDate(startDate, startTime),
      end: getFormattedDate(endDate, endTime),
    };
  }, [startDate, startTime, endDate, endTime]);

  return (
    <Flex data-comp={Timer.displayName} variant="flex.row.start.center">
      {status ? `${message}${' '}` : ''}
      <Countdown
        startTime={formattedDates.start}
        endTime={formattedDates.end}
        status={status}
        style={{
          fontSize: '10px',
          letterSpacing: '.08em',
          display: 'inline',
          ml: 2,
        }}
        actions={{
          setEnded: () => {
            setStatus('ended');
            setMessage('Expired');
          },
          setAwaiting: () => {
            setStatus('awaiting');
            setMessage('Live in');
          },
          setLive: () => {
            setStatus('live');
            setMessage('Expires in');
          },
        }}
      />
    </Flex>
  );
}

Timer.displayName = 'Timer';
