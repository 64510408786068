export const header = ({ align }) => ({
  label: 'Content settings',
  name: 'content',
  description: 'Heading, text align, button style',
  component: 'group',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'textAlign',
      description: "Doesn't apply on desktop when group list is inline",
      label: 'Heading alignment',
      options: align.options,
    },
  ],
  defaultValue: {
    heading: 'Shop Best Sellers',
    textAlign: align.value.left,
  },
});
