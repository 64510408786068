import { Box } from 'theme-ui';

import { Option } from './Option';
import { themed } from './Options.theme';

export const Options = themed(
  ({
    theme,
    activeSizesByType,
    setActiveSizesByType,
    sizeAvailsByType,
    products,
    ...props
  }) => {
    return activeSizesByType ? (
      <Box
        data-comp={Options.displayName}
        sx={{
          width: '100%',
          borderBottom: (t) => `1px solid ${t.colors.gray.mediumLight}`,
          pb: 8,
        }}
        {...props}
      >
        {Object.entries(activeSizesByType).map(([type, size], index) => (
          <Option
            key={index}
            type={type}
            activeSize={size}
            kitSizeAvailablity={sizeAvailsByType[type]}
            products={products}
            setActiveSize={(newSize) => {
              setActiveSizesByType({
                ...activeSizesByType,
                [type]: newSize,
              });
            }}
          />
        ))}
      </Box>
    ) : null;
  }
);

Options.displayName = 'Options';
