import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      flexDirection: 'column',
    };
  })()
);
