import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sizeGuide = {
      variant: 'text.sm',
      textDecoration: 'underline',
      cursor: 'pointer',
      ml: 'auto',
    };

    this.tagMissing = {
      variant: 'text.xs',
      color: 'gray.dark',
      ml: 'auto',
    };

    this.settingMissing = {
      ...this.tagMissing,
    };
  })()
);
