import {
  position,
  sectionWithHeight,
  color,
  align,
  button,
  padding,
} from '@settings/common';
import { text, buttons, content, media } from './Schemas';

export const Schema = () => {
  return {
    label: 'Parallax Hero',
    key: 'parallax-hero',
    fields: [
      media({ position, color }),
      text({ color, align }),
      buttons({ button }),
      content({ position, align, padding }),
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: 324,
            minHeight: 324,
          },
          desktop: {
            maxHeight: 600,
            minHeight: 600,
          },
        },
      },
    ],
  };
};
