import { Picture } from '@snippets/Picture';
import { useMemo } from 'react';
import { Flex, Heading, Paragraph, Spinner } from 'theme-ui';

export const ProductItem = ({ productItem }) => {
  const title = useMemo(() => {
    const fullTitle = productItem?.title;
    const splitIndex = fullTitle?.indexOf('|') || -1;
    return {
      product:
        splitIndex >= 0 ? fullTitle?.slice(0, splitIndex).trim() : fullTitle,
      variant: splitIndex >= 0 ? fullTitle?.slice(splitIndex + 1).trim() : '',
    };
  }, [productItem?.title]);

  return (
    <Flex
      data-comp={ProductItem.displayName}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: '122px',
        px: 14,
        gap: 8,
      }}
    >
      <Picture
        alt={productItem?.title}
        data-comp="UpsellSliderItemPicture"
        images={[
          {
            src: productItem?.images?.[0]?.src,
            width: 400,
            ratio: 3 / 4,
          },
        ]}
        sx={{
          width: ['100px', '140px'],
          alignItems: 'center',
          mr: 8,
        }}
      >
        {!productItem?.images?.[0]?.src && (
          <Spinner
            sx={{
              spinner: {
                width: '16px',
                color: 'gray.light',
                m: 'auto',
              },
            }}
          />
        )}
      </Picture>
      <Flex
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          width: '80%',
        }}
      >
        <Paragraph
          sx={{
            variant: 'text.label.1',
            color: 'gray.dark',
            minHeight: '18px',
            textTransform: 'uppercase',
          }}
        >
          {productItem?.variants?.[0]?.title}
        </Paragraph>
        <Heading
          data-comp="UpsellSliderItemHeading"
          as="h6"
          sx={{
            variant: 'text.base',
            fontWeight: '500',
            minHeight: '18px',
            color: 'black',
            my: 4,
          }}
        >
          {title?.product}
        </Heading>
        <Flex
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: [8, 2],
          }}
        />
      </Flex>
    </Flex>
  );
};
