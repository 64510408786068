import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      variant: 'text.h1',
      m: 0,
    };

    this.subheading = {
      variant: 'text.lg',
    };

    this.wrapper = {
      position: 'relative',
      width: '100%',
      mx: 'auto',
      mt: [12, 14],
    };

    this.input = (darkMode) => ({
      variant: 'text.base',
      fontSize: 3,
      py: 5,
      pl: 0,
      pr: 13,
      borderRadius: 0,
      m: 0,
      bg: 'transparent',
      color: darkMode ? 'white' : 'black',
      border: 'none',
      borderBottom: `1px solid ${darkMode ? 'white' : 'black'}`,
      '::placeholder': {
        color: darkMode ? 'white' : 'black',
        variant: 'text.base',
      },
      ':focus': {
        boxShadow: 'none',
      },
      '@media screen and (min-width: 480px)': {
        ':hover': {
          boxShadow: 'none',
        },
      },
    });

    this.button = {
      variant: 'buttons.plain',
      position: 'absolute',
      right: 0,
      bottom: '1.75rem',
      height: '50%',
      minWidth: 'unset',
      '@media screen and (min-width: 480px)': {
        ':hover': {
          background: 'none',
        },
      },
    };

    this.icon = (darkMode) => ({
      width: '24px',
      color: darkMode ? 'white' : 'black',
    });

    this.categories = (darkMode) => ({
      alignItems: 'center',
      mr: 12,
      color: darkMode ? 'white' : 'black',
      '& div *': {
        color: darkMode ? 'white' : 'black',
        ':focus': {
          visibility: 'hidden',
        },
      },
    });

    this.message = (darkMode) => ({
      color: darkMode ? 'white' : 'black',
      mt: '12px',
    });
  })()
);
