export const swiperDefaultParams = {
  spaceBetween: 0,
  slidesPerView: 1.1,
  slidesOffsetBefore: 10,
  slidesOffsetAfter: 0,
  slidesPerGroup: 1,
  centeredSlides: false,
  centeredSlidesBounds: false,
  centerInsufficientSlides: false,
  autoHeight: false,
  navigation: false,
  loop: true,
  threshold: 25,
  breakpoints: {
    480: {
      spaceBetween: 0,
      slidesPerView: 1.1,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
    },
    600: {
      spaceBetween: 0,
      slidesPerView: 1.1,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
    },
    768: {
      spaceBetween: 0,
      slidesPerView: 2.1,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1024: {
      spaceBetween: 0,
      slidesPerView: 3.2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1280: {
      spaceBetween: 0,
      slidesPerView: 3.2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1600: {
      loop: false,
      spaceBetween: 0,
      slidesPerView: 'auto',
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
  },
};
