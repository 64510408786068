import { store } from '@backpackjs/storefront';
import equal from 'fast-deep-equal';
import effects from './effects';

// tracks the combined selected options e.g { size: 'sm', color: 'red' }
const selectedOptions = store.recoil.atomFamily({
  key: 'product/selectedOptions',
  default: null,
  effects_UNSTABLE: [effects.logStateChange('selectedOptions')],
});

const selectedVariant = store.recoil.atomFamily({
  key: 'product/selectedVariant',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('selectedVariant'),
    // effects.onSetUpdateVariantUrlParam(),
    effects.onChange({
      key: 'selectedVariant',
      topic: 'VARIANT_UPDATE',
      actions: ['publishGtmEvent', 'publishBusEvent'],
    }),
  ],
});

// return only the medias for the selected color
const selectedMedia$ = store.recoil.selector({
  key: 'product/selectedMedia',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return [];

    const _selectedOptions = get(selectedOptions(_product.handle));

    if (!_selectedOptions) return [];

    try {
      const { color } = _selectedOptions;
      const selectedMedia = color
        ? _product.media.filter(({ product }) => {
            return product.tags.find((tag) =>
              tag.match(new RegExp(`color::${color}`, 'gi'))
            )?.length;
          })
        : _product.media;
      return selectedMedia;
    } catch (error) {
      return _product.media;
    }
  },
});

// parse all key::value tags from selectedVariant and its parent product
const selectedTags$ = store.recoil.selector({
  key: 'product/selectedTags',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return null;

    return _product?.tags?.reduce(parseTags, {}) || {};
  },
});

const groupingTags$ = store.recoil.selector({
  key: 'product/groupingTags$',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return null;

    return _product.variants.reduce((tags, variant) => {
      const variantTags = variant?.product?.tags?.reduce(parseTags, {});
      return Object.keys(variantTags).reduce(
        (_tags, vTagKey) => {
          if (_tags[vTagKey]) {
            _tags[vTagKey] = [
              ...new Set([..._tags[vTagKey], variantTags[vTagKey]]),
            ];
          } else {
            _tags[vTagKey] = [variantTags[vTagKey]];
          }
          return _tags;
        },
        { ...tags }
      );
    }, {});
  },
});

const productMeta = store.recoil.selector({
  key: 'product/productMeta$',
  get: ({ get, set }, meta) => {
    return meta;
  },
});

// merges a newly selected option with the existing selectedOptions
// newOption: should be an object e.g { color: 'blue' }
const setSelectedOptions$ = store.recoil.selectorFamily({
  key: 'product/setSelectedOptions$',
  set:
    (handle) =>
    ({ get, set }, newOptions) => {
      const _product = get(store.state.product);

      // navigating to a page other than PDP
      if ((!_product, !newOptions, !handle, !_product?.variants.length)) {
        return;
      }

      // have option update both
      const _selectedOptions = get(selectedOptions(handle));
      const updatedSelectedOptions = { ..._selectedOptions, ...newOptions };

      // update selected options for this product
      set(selectedOptions(handle), updatedSelectedOptions);
      const _selectedVariant = getVariantFromOptions(
        updatedSelectedOptions,
        _product?.variants
      );

      if (_selectedVariant) {
        set(selectedVariant(handle), _selectedVariant);
      }
    },
});

// determines wether a set of options e.g selectedOptions + a passedOption
// returns a variant that's available for sale
const optionIsAvailable$ = store.recoil.selectorFamily({
  key: 'product/optionIsAvailable$',
  get:
    (queriedOption) =>
    ({ get }) => {
      const _product = get(store.state.product);
      if (!_product) return false;

      const _selectedOptions = get(selectedOptions(_product.handle));

      const queriedOptions = { ..._selectedOptions, ...queriedOption };
      const _selectedVariant = getVariantFromOptions(
        queriedOptions,
        _product.variants
      );
      return _selectedVariant ? _selectedVariant.availableForSale : false;
    },
});

const isSoldOut$ = store.recoil.selector({
  key: 'product/isSoldOut$',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return false;

    const _selectedVariant = get(selectedVariant(_product.handle));

    return _selectedVariant
      ? _selectedVariant.inventoryQuantity <= 0 &&
          _selectedVariant.inventoryPolicy !== 'CONTINUE'
      : false;
  },
});

const isPreOrder$ = store.recoil.selector({
  key: 'product/isPreOrder$',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return false;

    const _selectedVariant = get(selectedVariant(_product.handle));

    return _selectedVariant
      ? _selectedVariant.inventoryQuantity <= 0 &&
          _selectedVariant.inventoryPolicy === 'CONTINUE'
      : false;
  },
});

const fit = (initialValue) =>
  store.recoil.atom({
    key: 'product/fit',
    default: initialValue,
  });

const fitDrawer = store.recoil.atom({
  key: 'product/fitDrawer',
  default: false,
});

/* Utilities */
const getVariantFromOptions = (selectedOptions, variants) => {
  return variants?.find(({ selectedOptionsMap }) => {
    return equal(selectedOptions, selectedOptionsMap);
  });
};

const parseTags = (tags, tag) => {
  try {
    const [key, value] = tag.split('::');
    tags[key] = value || true;

    /*
      • adds fit tag by default if fits:: is only provided
      • Reason: client request to only use fits:: tag as source of truth
        instead of having to add another fit:: to be used as the selected fit
      • we grab the selected fit tag by grabbing the first value by default
    */

    if (key === 'fits') {
      const fitValue = value?.split('||')?.[0]?.split('//')?.[0]; // Signature-Fit//black-crew-curve-hem||Classic-Fit//black-universal-crew-curve-hem -> Signature-Fit
      if (fitValue) {
        tags.fit = fitValue;
      }
    }

    return tags;
  } catch (e) {
    return tags;
  }
};

export default {
  selectedOptions,
  selectedVariant,
  selectedMedia$,
  selectedTags$,
  groupingTags$,
  setSelectedOptions$,
  optionIsAvailable$,

  // fit toggles
  fit,
  fitDrawer,

  // selectors
  isPreOrder$,
  isSoldOut$,
  productMeta,
};
