import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './CollectionSubCategory.schema';
import { themed } from './CollectionSubCategory.theme';

const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const CollectionSubCategory = withInView(
  themed(({ theme, cms, inView }) => {
    const { content, section, products } = cms;

    return (
      <Section section={section}>
        <Container
          data-comp={CollectionSubCategory.displayName}
          sx={theme.wrapper}
        >
          <Box sx={theme.slider}>
            {inView && <Slider products={products} settings={content} />}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

CollectionSubCategory.displayName = 'CollectionSubCategory';
CollectionSubCategory.Schema = Schema;
