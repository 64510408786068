import { Box, Container, Flex, Heading } from 'theme-ui';
import React from 'react';
import { themed } from './SectionFourZeroFour.theme';
import { Schema } from './SectionFourZeroFour.schema';
import { Section, Link, Picture } from '@snippets';
import { withInView } from '@utils';

export const SectionFourZeroFour = withInView(
  themed(({ theme, cms }) => {
    const { section, image, imageDt, heading, subheading, cta, ctaStyle } = cms;
    return (
      <Section section={section}>
        <Container
          sx={{
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
            ...theme.container,
          }}
        >
          <Picture
            alt="404 page background"
            images={[
              {
                src: image?.src,
                width: 512,
                ratio: 1,
              },
              {
                src: image?.src,
                width: 768,
                ratio: 1.2,
              },
              {
                src: imageDt?.src,
                width: 1024,
                ratio: 1.2,
              },
            ]}
            sx={theme.picture}
            imageSx={theme.image}
          />
          <Flex sx={theme.info}>
            <Heading variant="text.h1" sx={theme.heading}>
              {heading}
            </Heading>
            <Box sx={theme.subheading}>{subheading}</Box>
            <Link
              href={cta.url}
              variant={`buttons.${ctaStyle || 'primary'}`}
              sx={theme.link}
            >
              {cta.text}
            </Link>
          </Flex>
        </Container>
      </Section>
    );
  })
);

SectionFourZeroFour.displayName = 'SectionFourZeroFour';
SectionFourZeroFour.Schema = Schema;
