import { image } from './image.schema';
import { video } from './video.schema';

export const media = ({ position, color }) => ({
  label: 'Media settings',
  name: 'media',
  description:
    'Select Media Type (image - video), position, placement, aspect ratio',
  component: 'group',
  fields: [
    image({ position, color }),
    video({ color }),
    {
      label: 'Select Media Type (mobile)',
      description:
        "Allows select between image or video for mobile. If video is selected, you will need to set this section's background color to transparent while all other sections on the page will need to have any valid background color",
      name: 'mediaTypeMb',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'image',
    },
    {
      label: 'Select Media Type (desktop)',
      description:
        "Allows select between image or video for desktop. If video is selected, you will need to set this section's background color to transparent while all other sections on the page will need to have any valid background color",
      name: 'mediaTypeDt',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'video',
    },
  ],
});
