import { useEffect, useCallback } from 'react';
import store, { useRecoilValueLoadable } from '@store';

import { useProduct } from '@backpackjs/storefront';

const defaultProps = {
  legacyResourceId: null,
  perPage: 5,
  page: 1,
};

export const useYotpoProductReviews = ({
  legacyResourceId: passedId,
  perPage,
  page,
} = defaultProps) => {
  const product = useProduct();
  const legacyResourceId = passedId || product?.legacyResourceId;

  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoProductReviews$({
      legacyResourceId,
      per_page: perPage || 5,
      page: page || 1,
      sort: 'date',
      direction: 'desc',
    })
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    ...(state === 'hasValue' && contents
      ? {
        ...contents,
        // fix totalReviews & totalReview inconsistency between yotpo apis
        bottomline: {
          ...contents.bottomline,
          totalReviews: contents.bottomline.totalReview,
        },
      }
      : null),
    page,
  };
};
