import { useEffect, useCallback, useMemo, useState } from 'react';
import store, {
  useRecoilValue,
  useRecoilCallback,
  useGotoRecoilSnapshot,
} from '@store';

import {
  useCartAddItem,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';

export const useAddToCart = ({ selectedVariant, product }) => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const selectedPlan = useRecoilValue(store.selectedPlan(selectedVariant?.id));

  const [isAddStarted, setIsAddStarted] = useState(false);
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withQuantity: true,
  });

  const id = useMemo(() => {
    return Object.keys({ ...inventory?.variants })[0]?.startsWith('gid://')
      ? selectedVariant?.id
      : selectedVariant?.storefrontId;
  }, [inventory?.variants, selectedVariant?.id]);

  // use live inventory data
  const variantInventory = inventory ? inventory?.variants?.[id] : null;

  const isDigitalProduct = product?.productType === 'Gift Cards';

  const isSoldOut =
    inventoryStatus.success &&
    (!variantInventory ||
      (variantInventory.quantityAvailable <= 0 && !isDigitalProduct));

  const isPreOrder =
    isSoldOut && selectedVariant?.inventoryPolicy === 'CONTINUE';

  const toggleCartSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, true))
        .map(({ set }) => set(store.sidebar, 'cart'));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  // add oneTime and subs
  const addToCart = useCallback(
    async (quantity = 1) => {
      if (!selectedVariant || isSoldOut) return;
      setIsAddStarted(true);
      const item = {
        merchandiseId: selectedVariant.storefrontId,
        quantity,
      };


      // if selling plan is selected we the subscription
      if (selectedPlan) {
        item.sellingPlanId = selectedPlan.id;
      }

      const cart = await cartAddItem(item);
      setIsAddStarted(false);
      if (cart) {
        toggleCartSidebar();
      }
    },
    [selectedVariant?.id, isSoldOut, selectedPlan?.id]
  );

  const addItemStatusByProduct = useMemo(() => {
    return isAddStarted
      ? addItemStatus
      : {
        ...addItemStatus,
        started: false,
        success: false,
        finished: false,
      };
  }, [addItemStatus, isAddStarted]);

  useEffect(() => {
    return () => {
      setIsAddStarted(false);
    };
  }, []);

  return [
    {
      isPreOrder,
      isSoldOut,
      selectedPlan,
      status: {
        addItem: addItemStatusByProduct,
        inventory,
        inventoryStatus,
      },
    },
    {
      addToCart,
      toggleCartSidebar,
    },
  ];
};
