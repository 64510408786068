import store, { useRecoilValueLoadable } from '@store';

const defaultProps = {
  name: null,
  perPage: 100,
  page: 1,
};

export const useYotpoUgcAlbumByName = ({
  name,
  perPage,
  page,
} = defaultProps) => {
  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoUgcAlbumByName$({
      name,
      per_page: perPage || defaultProps.perPage,
      page: page || defaultProps.page,
    })
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    ...(state === 'hasValue' && contents
      ? {
          ...contents,
        }
      : null),
    page,
  };
};
