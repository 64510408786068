import dynamic from 'next/dynamic';
import { Flex } from 'theme-ui';

import { themed } from './Media.theme';

const Image = dynamic(() => import('../Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});
const Video = dynamic(() => import('../Video').then((module) => module.Video), {
  ssr: false,
  suspense: false,
});

export const Media = themed(({ theme, media, children }) => {
  const { mediaType, image } = media;
  if (!media) return null;

  return (
    <Flex sx={theme.media}>
      {mediaType === 'image' ? (
        <Image image={image} />
      ) : (
        <Video video={media} poster={image} />
      )}
      {children}
    </Flex>
  );
});

Media.displayName = 'Media';
