import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mt: 8,
      width: '100%',
    };

    // todo: nested variants should really be button variants that modify primary
    // so that they can be used through a project and not just here
    this.button = {
      variant: 'primary',
      width: '100%',
      mr: 'initial',
      mt: 2,
      mb: 12,
      outline: 'none',

      available: {
        cursor: 'pointer',

        oneTime: {
          bg: 'black',
        },

        subscribe: {
          border: (t) => `1px solid ${t.colors.black}`,
          bg: 'black',
          '@media screen and (min-width: 480px)': {
            '&:hover': {
              color: 'black',
            },
          },
        },
      },

      unavailable: {
        cursor: 'default',
        oneTime: {
          bg: 'gray.medium',
          border: (t) => `1px solid ${t.colors.gray.medium}`,
          '@media screen and (min-width: 480px)': {
            '&:hover': {
              bg: 'gray.medium',
              color: 'background',
            },
          },
        },

        subscribe: {
          bg: 'gray.medium',
          border: (t) => `1px solid ${t.colors.gray.medium}`,
          '@media screen and (min-width: 480px)': {
            '&:hover': {
              bg: 'gray.medium',
              color: 'background',
            },
          },
        },
      },
    };
  })()
);
