import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'grid',
      width: '100%',
      columnGap: [4, 4, null, 8],
      rowGap: [1, 2, null, 4],
      gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(4, 1fr)'],
      mb: [4, 8],
    };

    this.collection = {
      gridArea: ' 1 / 1 / 1 / 1',
      display: 'flex',
      flexDirection: 'column',
    };

    this.cta = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '48px',
      position: 'absolute',
      bottom: 8,
      right: 8,
      minWidth: 'auto',
    };
  })()
);
