import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    };

    this.swiper = {
      mx: 'auto',
      width: '100%',
      height: '100%',
    };

    this.button = {
      mt: [12, 14],
    };
  })()
);
