import { Grid } from 'theme-ui';

import { YotpoListReviewItemInfo } from './YotpoListReviewItemInfo';
import { YotpoListReviewItemUser } from './YotpoListReviewItemUser';

// each review list row
export const YotpoListReviewItem = ({ index, ...review }) => {
  if (!review) return null;

  return (
    <Grid
      data-comp={`${YotpoListReviewItem.displayName}`}
      sx={{
        gridTemplateColumns: ['1fr', '180px 1fr'],
        columnGap: [0, '40px'],
        textAlign: 'left',
        width: '100%',
        pt: [9, 10, 11],
        pb: [10, 11, 12],
        opacity: 0,
        borderBottom: (t) => `1px solid ${t.colors.beige}`,
      }}
    >
      {/* user column */}
      <YotpoListReviewItemUser index={index} {...review} />

      {/* info column */}
      <YotpoListReviewItemInfo index={index} {...review} />
    </Grid>
  );
};

YotpoListReviewItem.displayName = 'YotpoListReviewItem';
