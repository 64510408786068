import { Box, Grid } from 'theme-ui';

import { Ctas, CtaMenu } from './Ctas';
import { Logo } from './Logo';
import { Nav } from './Nav';
import { themed } from './Menu.theme';

export const Menu = themed(({ theme, transparentBGEnabled, ...props }) => {
  return (
    <Grid data-comp={Menu.displayName} sx={theme.wrapper(transparentBGEnabled)}>
      {/* 🍔 🔍  — mobile only */}
      <Box sx={theme.toggle}>
        <CtaMenu transparentBGEnabled={transparentBGEnabled} />
      </Box>

      {/* Top level nav */}
      <Nav transparentBGEnabled={transparentBGEnabled} />

      <Logo transparentBGEnabled={transparentBGEnabled} />

      {/* 🔍 👩‍💻  🛒 */}
      <Ctas transparentBGEnabled={transparentBGEnabled} />
    </Grid>
  );
});

Menu.displayName = 'Menu';
