export const userLocationModal = {
  label: 'User Location modal',
  name: 'userLocationModal',
  component: 'group',
  fields: [
    {
      label: 'Modal heading',
      name: 'heading',
      component: 'text',
      defaultValue: "It looks like you're not in the United States.",
    },
    {
      label: 'Modal subheading',
      name: 'subheading',
      component: 'textarea',
      defaultValue:
        'If you are shipping to the US, you will need to shop from our US Store by selecting the country',
    },
    {
      name: 'locations',
      component: 'tags',
      label: 'Available Location',
      description:
        "If tag is US the modal won't show up if the person is in the US. If empty, it will be available to all. Press enter to set. (Use Country ISO Code. IE: US)",
    },
    {
      label: 'Enable Country Selector',
      name: 'countrySelector',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'hide',
      },
      defaultValue: true,
      description:
        'Show / hide  country selector dropdown. Refresh is required',
    },
  ],
  defaultValue: {
    heading: "It looks like you're not in the United States.",
    subheading:
      'If you are shipping to the US, you will need to shop from our US Store by clicking the flag',
  },
};
