import { section, color } from '@settings/common';

const faqItemsArr = [
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
  {
    question: 'question question question question question question question?',
    answer: `Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes,
     nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor 
    mauris condimentum nibh, ut fermentum massa justo. Cum sociis natoque penatibus et magnis dis parturient montes.`,
  },
];

const FAQItems = () => ({
  label: 'FAQ items',
  name: 'faqItems',
  component: 'group-list',
  itemProps: {
    label: '{{item.question}}',
  },
  defaultItem: {
    question: 'question?',
    answer: 'answer',
  },
  fields: [
    {
      label: 'Question',
      name: 'question',
      component: 'text',
    },
    {
      label: 'Answer',
      name: 'answer',
      component: 'markdown',
    },
  ],
  defaultValue: [...faqItemsArr],
});
export const Schema = () => {
  return {
    label: 'FAQ Section',
    key: 'FAQSection',
    fields: [
      {
        label: 'Section title',
        name: 'title',
        component: 'text',
        defaultValue: 'Frequently Asked Questions',
      },
      {
        label: 'Items to show',
        name: 'itemsToShow',
        component: 'number',
        defaultValue: 5,
      },
      FAQItems(),
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
