import { useMemo, Children, cloneElement } from 'react';
import { useLocalizedCartTotals } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { formatAmountItem } from './formatAmountItem';

export const LocaleCartTotals = ({ cart, children, ...props }) => {
  if (!cart) {
    return null;
  }

  const { localized } = useLocalizedCartTotals({ cart: { ...cart } });

  const Components = useMemo(() => {
    let PriceComp = null;
    let LoaderComp = null;
    let ErrorComp = null;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Locale.Price': {
          const estimatedCost = localized?.estimatedCost;
          const isDiscounted =
            estimatedCost?.discountAmount?.amount > 0 || false;
          const isOnSale =
            estimatedCost?.totalSavingsAmount?.amount > 0 || false;

          PriceComp = localized
            ? cloneElement(child, {
              key: 'Locale.Price',
              estimatedCost: {
                ...estimatedCost,
                discountAmount: formatAmountItem(
                  estimatedCost?.discountAmount
                ),
                subtotalAmount: formatAmountItem(
                  estimatedCost?.subtotalAmount
                ),
                totalAmount: formatAmountItem(estimatedCost?.totalAmount),
                totalCompareAtAmount: formatAmountItem(
                  estimatedCost?.totalCompareAtAmount
                ),
                totalDiscountAmount: formatAmountItem(
                  estimatedCost?.totalDiscountAmount
                ),
                totalSavingsAmount: formatAmountItem(
                  estimatedCost?.totalSavingsAmount
                ),
                totalDutyAmount: formatAmountItem(
                  estimatedCost?.totalDutyAmount
                ),
                totalTaxAmount: formatAmountItem(
                  estimatedCost?.totalTaxAmount
                ),
              },
              discountAllocations: localized?.discountAllocations,
              locale: localized.locale,
              isDiscounted,
              isOnSale,
              ...child.props,
            })
            : null;
          break;
        }

        case 'Locale.Error':
          ErrorComp = null;
          break;

        case 'Locale.Loader':
          LoaderComp = null;
          break;

        default:
          break;
      }
    });

    // active components
    return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
  }, [
    localized?.estimatedCost?.subtotalAmount?.amount,
    localized?.discountAllocations?.length,
  ]);

  return (
    <Box
      data-comp={LocaleCartTotals.displayName}
      {...props}
      sx={{ ...props.sx }}
    >
      {Components.map((Component) => Component)}
    </Box>
  );
};

LocaleCartTotals.displayName = 'Locale.CartTotals';
