import { Box, Paragraph, Progress } from 'theme-ui';
import { useSettings, useCart } from '@backpackjs/storefront';

import { Svg } from '@snippets/Svg';
import store, { useRecoilValue } from '@store';

import { themed } from './FreeShippingProgress.theme';

export const FreeShippingProgress = themed(({ theme }) => {
  const adjustedCartTotal = useRecoilValue(store.cartTotal);
  const settings = useSettings();
  const { freeShippingProgress } = { ...settings?.cart };
  const cart = useCart();
  if (!cart || !freeShippingProgress?.progressEnabled) return null;
  const isComplete = adjustedCartTotal >= Number(freeShippingProgress?.value);
  return (
    <Box sx={theme.wrap}>
      <Paragraph sx={theme.heading}>
        {isComplete ? (
          <>
            <Svg
              src="/svgs/noprecache/svg/truck.svg"
              alt="truck"
              viewBox="0 0 20 20"
            />
            {freeShippingProgress.complete}
          </>
        ) : (
          freeShippingProgress.heading
        )}
      </Paragraph>
      <Progress
        max={freeShippingProgress.value}
        value={cart.estimatedCost.subtotalAmount.amount}
        sx={theme.progress}
      />
    </Box>
  );
});
