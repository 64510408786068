import { layout } from '@theme/theme.layout';

// Converts text align value to flex
const convertAlignToFlex = (alignment) => {
  return alignment
    ? alignment === 'left'
      ? 'flex-start'
      : alignment === 'center'
      ? 'center'
      : 'flex-end'
    : 'center';
};

// Parses flex position value (e.g. "flex-start center") into two values
const parseContentPosition = (position) => {
  return position
    ? position.split(' ').reduce((obj, pos, index) => {
        index === 0 ? (obj.alignItems = pos) : (obj.justifyContent = pos);
        return obj;
      }, {})
    : {};
};

// Adds horizontal padding to a specific element when parent section is full bleed
const pxWithFullBleed = (section) => {
  const isFullBleedMb = section?.mobile?.container === 'container';
  const isFullBleedDt = section?.desktop?.container === 'container';

  return {
    px: [
      isFullBleedMb ? layout.padded?.px?.[0] || 0 : 0,
      isFullBleedDt ? layout.padded?.px?.[1] || 0 : 0,
      isFullBleedDt ? layout.padded?.px?.[2] || null : 0,
      isFullBleedDt ? layout.padded?.px?.[3] || null : 0,
    ],
  };
};

const calcResponsiveHeight = ({ maxHeight, viewport }) => {
  if (typeof maxHeight !== 'number' || typeof viewport !== 'number') {
    console.error('maxHeight and viewport must be integers');
    return;
  }
  return `${maxHeight / (viewport / 100)}vw`;
};

export {
  convertAlignToFlex,
  parseContentPosition,
  pxWithFullBleed,
  calcResponsiveHeight,
};
