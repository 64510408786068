import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.wrapper = {
      // maxheight: 628,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    };
  })()
);
