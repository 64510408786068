import { Flex, Heading } from 'theme-ui';

import { themed } from './TestimonialSlide.theme';

export const TestimonialSlide = themed(({ theme, text, visible, color }) => {
  return (
    <Flex
      sx={{
        ...theme.headingContainer,
        visibility: visible ? 'visible' : 'hidden',
        color,
      }}
    >
      <Heading
        as={'h4'}
        sx={{
          ...theme.heading,
        }}
      >
        <q>{text}</q>
      </Heading>
    </Flex>
  );
});
