import { create } from '@theme/create';
import { hexToRgbA } from '@utils';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridColumnGap: '0px',
      gridRowGap: 0,
    };
    this.heading = {
      variant: 'text.h3',
    };

    this.overlay = (overlayActive, overlayColor, overlayOpacity) => ({
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: ' 100%',
      width: '100%',
      opacity: 0,
      transition: 'opacity .3s ease',
      background: overlayActive
        ? hexToRgbA(overlayColor, overlayOpacity / 100)
        : 'transparent',
      '@media screen and (min-width: 480px)': {
        ':hover': {
          opacity: 1,
        },
      },
    });

    this.icon = (color) => ({
      width: 50,
      position: 'absolute',
      top: '50%',
      left: ' 50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      color,
    });
  })()
);
