import { Box } from 'theme-ui';

import { Svg } from '@snippets';
import { useModal } from '@hooks';

import { themed } from './Modal.theme';

export const Modal = themed(({ theme, ...props }) => {
  const [{ modal, modalProps }, { closeModal }] = useModal();

  return modal ? (
    <Box
      as="aside"
      data-comp={Modal.displayName}
      {...props}
      {...modalProps}
      sx={{
        ...theme.wrapper,
        ...props.sx,
        ...modalProps?.sx,
      }}
    >
      {/* X close modal */}
      <Box
        data-comp={`${Modal.displayName}Close`}
        sx={theme.close}
        onClick={closeModal}
        role="button"
      >
        <Svg
          alt="Close modal"
          src="/svgs/close.svg#close"
          viewBox="0 0 48 48"
          sx={theme.icon}
        />
      </Box>

      <Box data-comp={`${Modal.displayName}Content`} sx={theme.content}>
        {modal}
      </Box>
    </Box>
  ) : null;
});

Modal.displayName = 'Modal';
