const heading = ({ color, align }) => ({
  label: 'Heading settings',
  name: 'heading',
  description: 'Heading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'headingText',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      name: 'headingAlignmentMb',
      label: 'Heading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'headingAlignmentDt',
      label: 'Heading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    headingText: '# Welcome to Storefront **Backpack**',
    headingAlignmentMb: 'center',
    headingColorMb: '',
    headingAlignmentDt: 'center',
    headingColorDt: '',
  },
});

const superheading = ({ color, align }) => ({
  label: 'SuperHeading settings',
  name: 'superheading',
  description: 'SuperHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'superheadingText',
      component: 'markdown',
      label: 'SuperHeading',
      description: 'Heading above the main heading.',
    },
    {
      name: 'superheadingAlignmentMb',
      label: 'SuperHeading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'superheadingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'superheadingAlignmentDt',
      label: 'SuperHeading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'superheadingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    superheadingText: ' Backpack Storefronts',
    superheadingAlignmentMb: 'center',
    superheadingColorMb: '',
    superheadingAlignmentDt: 'center',
    superheadingColorDt: '',
  },
});

const subheading = ({ color, align }) => ({
  label: 'SubHeading settings',
  name: 'subheading',
  description: 'SubHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'subheadingPlacement',
      label: 'Subheading Placement',
      description: 'Control subheading placement above or below header & logo',
      component: 'radio-group',
      direction: 'vertical',
      variant: 'radio',
      options: [
        { label: 'Above Header & Logo', value: 'above-logo' },
        { label: 'Below Header & Logo', value: 'below-logo' },
      ],
    },
    {
      name: 'subheadingText',
      component: 'markdown',
      label: 'SubHeading',
    },
    {
      name: 'subheadingAlignmentMb',
      label: 'subheading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'subheadingAlignmentDt',
      label: 'subheading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    subheadingPlacement: 'below-logo',
    subheadingText: 'Manage content directly within the customizer.',
    subheadingAlignmentMb: 'center',
    subheadingColorDt: '',
    subheadingAlignmentDt: 'center',
    subheadingColorMb: '',
  },
});

const logoSettings = ({ align }) => ({
  label: 'Logo settings',
  name: 'logoSettings',
  description: 'Show Logo, Logo Image, Logo Width, Logo Alignment',
  component: 'group',
  fields: [
    {
      name: 'showLogo',
      label: 'Hide / Show logo image',
      description: 'Show logo image in the hero section',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'logoImage',
      label: 'Logo (mobile)',
      component: 'image',
      description: 'Select a logo to be displayed in the hero section',

      defaultValue: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/Cuts_TertiaryLogo_White_copy.png?v=1666978334',
      },
    },
    {
      name: 'logoWidthMb',
      component: 'number',
      label: 'Image Width (mobile)',
      description: 'set the logo width in percentage',
      defaultValue: 25,
    },
    {
      name: 'logoAlignmentMb',
      label: 'Logo Alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
      defaultValue: 'center',
    },
    {
      name: 'logoImageDt',
      label: 'Logo (desktop)',
      component: 'image',
      description: 'Select a logo to be displayed in the hero section',

      defaultValue: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/Cuts_TertiaryLogo_White_copy.png?v=1666978334',
      },
    },
    {
      name: 'logoWidthDt',
      component: 'number',
      label: 'Logo Width (desktop)',
      description: 'set the logo width in percentage',
      defaultValue: 25,
    },
    {
      name: 'logoAlignmentDt',
      label: 'Logo Alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
      defaultValue: 'center',
    },
  ],
});

export const text = ({ color, align }) => ({
  label: 'Text settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    superheading({ color, align }),
    heading({ color, align }),
    subheading({ color, align }),
    logoSettings({ align }),
  ],
});
