import atob from 'atob';
/**
 *
 * @param {string} value
 */
export const isEncoded = (value) => {
  const regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return regex.test(value);
};

/**
 *
 * @param {string} id
 */
export const getValidId = (id) => {
  return isEncoded(id) ? atob(id).split('/').pop() : id.split('/').pop();
};
