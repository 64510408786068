import { Flex, Paragraph, Button, Box } from 'theme-ui';

import { SizeGuide } from '@templates/product/Main/Details/Fit/SizeGuide/SizeGuide';
import { OptionPants } from './OptionPants';
import { themed } from './Options.theme';

const SIZE_MAP = {
  XS: 'X-Small',
  S: 'Small',
  M: 'Medium',
  L: 'Large',
  XL: 'X-Large',
  XXL: 'XX-Large',
  'S / M': 'Small / Medium',
  'L / XL': 'Large / X-Large',
  'O / S': 'One Size',
};

export const Option = themed(
  ({
    theme,
    activeSize,
    kitSizeAvailablity,
    setActiveSize,
    type = '',
    products,
    ...props
  }) => {
    return kitSizeAvailablity ? (
      <Box
        data-comp={`${Option.displayName}-${type}`}
        {...props}
        sx={{
          ...theme.option,
          ...props.sx,
        }}
      >
        {type === 'Pants' && (
          <OptionPants
            activeSize={activeSize}
            kitSizeAvailablity={kitSizeAvailablity}
            setActiveSize={setActiveSize}
            type={type}
            products={products}
          />
        )}

        {type !== 'Pants' && (
          <>
            <Flex sx={theme.heading}>
              <Paragraph as="label" sx={theme.optionName}>
                {type} Size
              </Paragraph>

              <Paragraph sx={theme.selectedOption}>
                {SIZE_MAP[activeSize]}
              </Paragraph>

              <SizeGuide
                sx={theme.SizeGuide}
                isKit
                type={type}
                activeSize={activeSize}
              />
            </Flex>

            <Flex sx={theme.values}>
              {Object.keys(kitSizeAvailablity).map((size) => {
                const isSelected = activeSize === size;
                const isAvailable = kitSizeAvailablity[size];
                return (
                  <Button
                    aria-label={`Select size: ${SIZE_MAP[size]}`}
                    variant="buttons.plain"
                    sx={{
                      ...theme.value,
                      ...(isAvailable
                        ? isSelected
                          ? theme.value.available.selected
                          : theme.value.available.unselected
                        : isSelected
                        ? theme.value.unavailable.selected
                        : theme.value.unavailable.unselected),
                    }}
                    onClick={() => {
                      if (!isAvailable) return;
                      !isSelected ? setActiveSize(size) : null;
                    }}
                    key={size}
                  >
                    {size}
                  </Button>
                );
              })}
            </Flex>
          </>
        )}
      </Box>
    ) : null;
  }
);

Option.displayName = 'Option';
