import { create } from '@theme/create';

const ITEM_HEIGHT = '32px';
const MIN_WIDTH = [0, '200px'];
const COUNTRIES_ITEM_HEIGHT = '32px';
const COUNTRIES_MIN_WIDTH = ['40px', null, '200px'];

const wrapper = {
  minWidth: MIN_WIDTH,
  position: 'relative',
  zIndex: 1,
};

const drawer = {
  bg: 'white',
  border: '1px solid',
  borderColor: 'text',
  flexDirection: 'column',
  height: 'auto',
  left: 0,
  maxHeight: '100%',
  minHeight: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  transition: 'max-height 0.5s ease-in-out',
  width: '100%',
};

const scroll = {
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  webkitOverflowScrolling: 'touch',
  height: 'auto',
  maxHeight: '100%',
  mt: ITEM_HEIGHT,
  overflowY: 'auto',
  position: 'relative',
  width: '100%',
};

const item = {
  alignItems: 'center',
  bg: 'white',
  color: 'text',
  display: 'flex',
  flexDirection: 'row',
  height: ITEM_HEIGHT,
  justifyContent: 'space-between',
  lineHeight: ITEM_HEIGHT,
  overflow: 'hidden',
  px: 0,
  py: 0,
  textAlign: 'left',
  textOverflow: 'ellipsis',
  transition: 'background-color 0.1s ease-in-out',
  whiteSpace: 'nowrap',
  width: '100%',
  zIndex: 1,
};

const selected = {
  ...item,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'text',
  borderRadius: '0px',
};

const option = {
  ...item,
  border: 'none',
  '&:first-of-type': {
    mt: 4,
  },
  '&:last-of-type': {
    mb: 4,
  },
};

export const themed = create(
  new (function () {
    this.default = {
      wrapper,
      drawer,
      scroll,
      selected, // selected option
      option, // each dropdown option
    };

    this.countries = {
      wrapper: {
        ...wrapper,
        minWidth: COUNTRIES_MIN_WIDTH,
      },

      // Drawer wrapper
      drawer,

      // defining a px maxHeight enables scrolling
      scroll: {
        ...scroll,
        mt: COUNTRIES_ITEM_HEIGHT,
      },

      // top selected item
      selected: {
        ...selected,
        borderWidth: ['0px', '1px'],
        height: COUNTRIES_ITEM_HEIGHT,
        lineHeight: COUNTRIES_ITEM_HEIGHT,
      },

      // each dropdown option
      option: {
        ...option,
        height: COUNTRIES_ITEM_HEIGHT,
        lineHeight: COUNTRIES_ITEM_HEIGHT,
        '&:first-of-type': {
          mt: 4,
        },
        '&:last-of-type': {
          mb: 4,
        },
      },
    };

    this.subscription = {
      wrapper,
      drawer,
      scroll,
      selected,
      option: {
        ...option,
        '@media screen and (min-width: 480px)': {
          '&:hover': {
            bg: 'lightGray',
          },
        },
      },
    };

    this.stores = {
      wrapper: {
        ...wrapper,
        maxWidth: ['100%', '200px'],
      },
      drawer: {
        ...drawer,
        bg: 'black',
        borderColor: 'white',
      },
      scroll,
      selected: {
        ...selected,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'white',
        borderRadius: '0px',
      },
      option: {
        ...option,
        borderStyle: 'none',
        borderRadius: '0px',
        '&:first-of-type': {
          mt: 0,
        },
        '&:last-of-type': {
          mb: 0,
        },
      },
    };
  })()
);
