
export const useDate = ({ startDate, startTime, endDate, endTime }) => {

  if (!startDate || !startTime || !endDate || !endTime) {
    return null
  }

  const getFormattedDate = (date, time) => {
    const gmtOffset =
      new Date(date.replace('T00', 'T07')).getTimezoneOffset() / 60;
    const baseTimeInMs = new Date(
      date.replace('T00', `T0${gmtOffset}`)
    ).getTime();
    const addedTimeInMin =
      Math.floor(time) * 60 + parseFloat(time.toFixed(2).split('.')[1]);
    const finalTimeInMs = baseTimeInMs + addedTimeInMin * 60 * 1000;

    return new Date(finalTimeInMs);
  };

  const formattedDates =
  {
    start: getFormattedDate(startDate, startTime),
    end: getFormattedDate(endDate, endTime),
  };


  return formattedDates;
};
