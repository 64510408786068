import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      fontFamily: 'body',
      pt: '25px',
      pb: '30px',
      px: ['15px', '25px'],
      maxWidth: '100%',
      ml: 'auto',
      mr: 'auto',
      '@media (min-width: 1441px)': {
        px: 15,
      },
    };
    this.heading = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      py: '50px',
      px: '20px',
      zIndex: 5,
      fontWeight: 'bold',
      fontSize: '40px',
      width: '100%',
      display: ['none', 'flex'],
    };
    this.list = {
      display: 'grid',
      gridTemplateColumns: ['repeat(3, 1fr)', 'repeat(6, 1fr)'],
      gridGap: [0, 8],
      gridRowGap: [4, 0],
      px: [10, 11, 11, 12],
      maxWidth: '100%',
      margin: '0 auto',
    };
    this.swiper = {
      width: '100%',
      '& .swiper-wrapper': {},
      '& .swiper-slide': {
        height: 'auto',
        '@media (min-width: 1600px)': {
          maxWidth: '300px',
        },
      },
    };
    this.item = {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        left: ['5px', 10],
        right: ['5px', 10],
        top: 0,
        bottom: 0,
        border: '1px solid',
        borderColor: 'gray.light',
        transition: 'border-color .3s',
      },
      '@media screen and (min-width: 480px)': {
        '&:hover:after': {
          borderColor: 'gray.dark',
        },
      },
      '&:nth-child(n+4)': {
        flexDirection: 'column-reverse',
      },
    };
    this.name = {
      fontSize: 1,
      fontWeight: 700,
      py: 10,
    };
    this.image = {
      position: 'relative',
      maxHeight: ['70px', '130px'],
      zIndex: 3,
      width: '100%',
      height: '100%',
      maxHeight: 'unset',
      display: ['none', 'block'],
    };
    this.imageSx = {
      objectFit: 'cover',
      objectPosition: 'center bottom',
    };
    this.bullets = {
      display: ['block', 'none'],
      span: {
        backgroundColor: 'gray.medium',
        opacity: 1,
      },
      '& .swiper-pagination-bullet-active': {
        width: '12px',
        height: '12px',
        position: 'relative',
        top: '2px',
      },
      cursor: 'pointer',
      '&&&': {
        bottom: '-25px',
      },
    };
  })()
);
