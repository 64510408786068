import { useCallback, useState } from 'react';
import { Box, Button, Flex, Input, Label, Radio } from 'theme-ui';

import { Svg } from '@snippets';
import { useDataLayerActions } from '@hooks';

import { themed } from './NewsletterSignUp.theme';

export const NewsletterSignUp = themed(
  ({ categories, placeholder, variant, theme, ...props }) => {
    const { sendSubscribeEvent } = useDataLayerActions();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState('noSelected');

    const selectCategory = useCallback(
      ({ target: { name, checked } }) => {
        if (checked) {
          setCategory(name);
        }
      },
      [category]
    );
    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        if (!email || isLoading) return;
        setIsLoading(true);
        try {
          const response = await fetch('/api/klaviyo', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
              action: 'clientSubscribe',
              email,
              phone: null,
              source: window.location.href,
              acceptsMarketing: false,
              listId: process.env.NEXT_PUBLIC_KLAVIYO_NEWS_LETTER,
            }),
          });

          if (response.ok) {
            setMessage('Email submitted!');
            sendSubscribeEvent({ email });
          }
          const data = await response.json();

          if (!response.ok && data.errors) {
            setMessage(data?.data || data?.errors);
          }
          setIsLoading(false);
          setEmail('');
        } catch (error) {
          setIsLoading(false);
          setMessage('Please try again later');
          console.error(error);
        }
      },
      [email, isLoading, message, category]
    );

    return (
      <>
        <Box
          data-comp="NewsletterSignUp"
          as="form"
          email={email}
          onSubmit={handleSubmit}
          {...props}
          sx={{
            ...theme.wrapper,
            ...props.sx,
          }}
        >
          {categories && categories.length > 0 && (
            <Flex>
              {categories.map((item, index) => (
                <Label key={index} sx={theme.categories(variant)}>
                  <Radio
                    checked={category === item.category}
                    value={item.category}
                    onChange={selectCategory}
                    name={item.category}
                  />
                  {item.category}
                </Label>
              ))}
            </Flex>
          )}

          <Input
            placeholder={placeholder || 'Email'}
            type="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            sx={theme.input(variant)}
            value={email}
          />

          <Button sx={theme.button}>
            <Svg
              src="/svgs/arrow-right.svg#arrow-right"
              alt="Submit"
              viewBox="0 0 48 48"
              sx={theme.icon(variant)}
            />
          </Button>
        </Box>
        {!!message?.length && <Box sx={theme.message(variant)}>{message}</Box>}
      </>
    );
  }
);

NewsletterSignUp.displayName = 'NewsletterSignUp';
