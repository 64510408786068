import React from 'react';
import { Box, Grid, Paragraph } from 'theme-ui';
import { themed } from './MembershipHow.theme';
import { Schema } from './MembershipHow.schema';
import { Section, Picture } from '@snippets';
import { withInView } from '@utils';

export const MembershipHow = withInView(
  themed(({ theme, cms }) => {
    const {
      heading,
      howItems: { items },
      section,
    } = cms;

    return (
      <Section section={section}>
        <Box sx={theme.wrapper}>
          <Paragraph sx={theme.title}>{heading}</Paragraph>
          <Grid as="ul" sx={theme.container}>
            {items?.map(({ image, heading, text }, index) => (
              <Box as="li" key={heading} sx={theme.item}>
                <Picture
                  images={[
                    {
                      src: image,
                      ratio: 1,
                    },
                  ]}
                  alt={heading}
                  sx={theme.image}
                />
                <Box sx={theme.itemText}>{text}</Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Section>
    );
  })
);

MembershipHow.displayName = 'MembershipHow';
MembershipHow.Schema = Schema;
