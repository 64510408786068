import { color } from '@settings/common';

export const preOrder = {
  label: 'Pre Order',
  name: 'preOrder',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enable Pre Order',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Pre Order Disclaimer',
      name: 'textDisclaimer',
      component: 'textarea',
      description:
        'This message will be displayed on the product page when the product is available for pre-order. {date} will be replaced with the estimated ship date, Ship-date:: tag',
      defaultValue:
        'This product is available for pre-order and will ship the week of {date}. All Pre-Orders will capture full payment at checkout.',
    },
    {
      name: 'enableDisclaimer',
      label: 'Enabled  Pre Order Disclaimer',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'preOrderCta',
      label: 'Pre Order CTA',
      component: 'group',
      fields: [
        {
          name: 'text',
          label: 'CTA Text',
          component: 'text',
          defaultValue: 'Pre Order',
        },
        {
          name: 'textColor',
          label: 'Text Color',
          component: 'color',
        },
        {
          name: 'bgColor',
          label: 'Background Color',
          component: 'color',
        },
        {
          name: 'bgColorHover',
          label: 'Background Color on Hover',
          component: 'color',
        },
      ],
    },
    {
      label: 'Pre Order Badge',
      name: 'preOrderBadge',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          name: 'text',
          label: 'Pre Order Badge Text',
          component: 'text',
          defaultValue: 'Pre Order',
        },
        {
          name: 'textColor',
          label: 'Text Color',
          component: 'color',
        },
        {
          name: 'bgColor',
          label: 'Background Color',
          component: 'color',
        },
      ],
    },
  ],
};
