import { Button, Box, Flex, Label } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './View.theme';

export const View = themed(
  ({ theme, isDesktop, viewPerRow, setViewPerRow }) => {
    return (
      <Flex variant="flex.row.between.center" sx={theme.wrapper}>
        <Label sx={theme.label}>View</Label>
        {isDesktop ? (
          <Box>
            <Button
              onClick={() => {
                setViewPerRow(3);
              }}
              variant="plain"
              sx={{
                ...theme.button,
                width: '30px',
                opacity: viewPerRow === 3 ? '1' : '0.5',
              }}
            >
              <Svg
                viewBox="0 0 20 12"
                alt={'View 3 Per Row'}
                src="/svgs/6-dots.svg#6-dots"
              />
            </Button>
            <Button
              onClick={() => {
                setViewPerRow(5);
              }}
              variant="plain"
              sx={{
                ...theme.button,
                width: '46px',
                opacity: viewPerRow === 5 ? '1' : '0.5',
              }}
            >
              <Svg
                viewBox="0 0 36 12"
                alt={'View 5 Per Row'}
                src="/svgs/10-dots.svg#10-dots"
              />
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              onClick={() => {
                setViewPerRow(1);
              }}
              variant="plain"
              sx={{
                ...theme.button,
                width: '14px',
                opacity: viewPerRow === 1 ? '1' : '0.5',
              }}
            >
              <Svg
                viewBox="0 0 4 11"
                alt={'View 1 Per Row'}
                src="/svgs/2-dots.svg#2-dots"
              />
            </Button>
            <Button
              onClick={() => {
                setViewPerRow(2);
              }}
              variant="plain"
              sx={{
                ...theme.button,
                width: '20px',
                opacity: viewPerRow === 2 ? '1' : '0.5',
              }}
            >
              <Svg
                viewBox="0 0 10 11"
                alt={'View 2 Per Row'}
                src="/svgs/4-dots.svg#4-dots"
              />
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
);
