import { socialIcons } from './SocialIcons.schema';

export const socialImages = ({ color }) => ({
  name: 'socialImages',
  label: 'Social Images',
  component: 'group-list',
  description: 'Max of 8 images',
  itemProps: {
    label: '{{item.alt}}',
  },
  validate: {
    maxItems: 8,
  },
  defaultItem: {
    cta: {
      text: 'Men',
      url: '/collections/all',
      newTab: true,
    },
    overlay: true,
    opacity: 50,
    icon: true,
    alt: `Half media image`,
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
    },
  },
  fields: [
    {
      name: 'overlay',
      label: 'Hide / Show overlay',
      description: 'Activate overlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      defaultValue: 50,
    },

    {
      name: 'icon',
      label: 'Hide / Show icon',
      description: 'Activate icon',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    socialIcons(),
    {
      component: 'select',
      name: 'iconColor',
      label: 'Select the icon color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'alt',
      label: 'Alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      name: 'cta',
      component: 'link',
      label: 'Link',
    },
  ],
  defaultValue: [
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
    {
      cta: {
        text: 'Men',
        url: '/collections/all',
        newTab: true,
      },
      alt: `Half media image`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
      },
      overlay: true,
      opacity: 50,
      icon: true,
      iconImage: 'instagram',
      iconColor: color.value.white,
      overlayColor: color.value.white,
    },
  ],
});
