import { useEffect } from 'react';
import { useCustomer } from '@backpackjs/storefront';

export const useGlobalCustomer = () => {
  const customer = useCustomer();

  useEffect(() => {
    window.ShopifyCustomer = {};
    if (customer) {
      window.ShopifyCustomer = customer;
    }
  }, [customer]);
};
