import { useEffect } from 'react';
import { withInView } from '@utils';
import { Section } from '@snippets';
import { Box, Container } from 'theme-ui';
import { Schema } from './YouTubeEmbedVideo.schema';
import { themed } from './YouTubeEmbedVideo.theme';

let player;
export const YouTubeEmbedVideo = withInView(
  themed(({ theme, cms, inView }) => {
    const { youtubeId, description, section } = cms;
    const loadVideo = () => {
      player = new window.YT.Player(`youtube-player-${youtubeId}`, {
        height: '100%',
        width: '100%',
        videoId: youtubeId,
      });
    };

    useEffect(() => {
      if (!inView) return;
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        window.onYouTubeIframeAPIReady = loadVideo;
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        loadVideo();
      }
    }, [inView]);

    return (
      <Section section={section}>
        {inView && (
          <Container
            sx={{
              ...theme.container,
            }}
            data-comp={YouTubeEmbedVideo.displayName}
          >
            <Box
              sx={{
                position: 'relative',
                minHeight: [
                  `${section?.mobile?.minHeight}px`,
                  `${section?.desktop?.minHeight}px`,
                ],
                maxHeight: [
                  `${section?.mobile?.maxHeight}px`,
                  `${section?.desktop?.maxHeight}px`,
                ],
              }}
            >
              <Box sx={theme.video_wrap}>
                <div id={`youtube-player-${youtubeId}`} />
              </Box>
            </Box>

            <Box sx={theme.description}>{description}</Box>
          </Container>
        )}
      </Section>
    );
  }),
  { triggerOnce: true }
);

YouTubeEmbedVideo.displayName = 'YouTubeEmbedVideo';
YouTubeEmbedVideo.Schema = Schema;
