import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      height: '20px',
      // minHeight: '40px' // prevent v-jump while loading
    };

    this.loader = {
      height: 8,
      color: 'gray.light',
    };

    this.price = {
      lineThrough: {
        variant: 'text.base',
        color: 'gray.medium',
        textDecoration: 'line-through',
        mr: 4,
      },
      regularRed: {
        variant: 'text.base',
        color: 'red',
      },

      regular: {
        variant: 'text.base',
      },
    };
  })()
);
