import account from './account';
import analytics from './analytics';
import cart from './cart';
import footer from './footer';
import header from './header';
import product from './product';
import collection from './collection';
import superCollection from './superCollection';
import search from './search';
import social from './social';
import blog from './blog';

export default [
  account,
  analytics,
  cart,
  footer,
  header,
  product,
  collection,
  superCollection,
  search,
  social,
  blog,
];
