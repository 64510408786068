export default {
  label: 'Blog settings',
  name: 'blog',
  component: 'group',
  fields: [
    {
      label: 'Blog navigation links',
      name: 'blogNav',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: {
        name: 'Blog Category',
      },
      fields: [
        {
          label: 'Blog category name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Blog nav link',
          name: 'navLink',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          name: 'All blog',
          navLink: {
            text: 'All blog',
            url: '/blogs/blog',
          },
        },
        {
          name: 'Collections',
          navLink: {
            text: 'Collections',
            url: '/blogs/collections',
          },
        },
        {
          name: 'Friday Projects',
          navLink: {
            text: 'Friday Projects',
            url: '/blogs/friday-projects',
          },
        },
        {
          name: 'Content',
          navLink: {
            text: 'Content',
            url: '/blogs/content',
          },
        },
      ],
    },
  ],
};
