import { Paragraph } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { useSearch } from '../../hooks';
import { themed } from './Empty.theme';

export const Empty = themed(({ theme, ...props }) => {
  const term = useRecoilValue(store.term);
  const [{ searchResults, totalResults }] = useSearch();

  const noResultsFound = term && !searchResults?.length && !totalResults;

  return noResultsFound ? (
    <Paragraph data-comp={Empty.displayName} sx={theme.text}>
      Nothing found, please try a different search
    </Paragraph>
  ) : null; // have results
});

Empty.displayName = 'Empty';
