import { Box, Container, Heading } from 'theme-ui';

import { Link, Picture, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Social.schema';
import { Header } from './Header';
import { SocialImages } from './Pictures/SocialImages';

export const Social = withInView(
  ({ cms, inView }) => {
    const { section, header } = cms;
    const {
      images: { socialImages: images },
    } = cms;

    return (
      <Section section={section}>
        <Container data-comp={Social.displayName} sx={{}}>
          <Header header={header} section={section} />
          <SocialImages images={images} />
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

Social.displayName = 'Social';
Social.Schema = Schema;
