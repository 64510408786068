import { Box } from 'theme-ui';

import { Link, Picture, Svg } from '@snippets';

import { themed } from './SocialImages.theme';

export const SocialImages = themed(({ theme, images }) => {
  return (
    <Box sx={theme.wrapper}>
      {images
        .slice(0, 8)
        .map(
          (
            {
              cta,
              image,
              alt,
              icon,
              overlay,
              iconImage,
              opacity,
              overlayColor,
              iconColor,
            },
            index
          ) => (
            <Link
              key={index}
              href={cta?.url}
              sx={{
                position: 'relative',
              }}
            >
              <Picture
                data-comp={SocialImages.displayName}
                alt={alt}
                images={[
                  {
                    src: image?.src,
                    width: 350,
                    ratio: 1 / 1,
                  },
                ]}
              />
              {
                <Box sx={theme.overlay(overlay, overlayColor, opacity)}>
                  {icon && (
                    <Svg
                      alt={alt}
                      src={`/svgs/noprecache/social/${iconImage}.svg#${iconImage}`}
                      draggable={false}
                      viewBox="0 0 24 24"
                      sx={theme.icon(iconColor)}
                    />
                  )}
                </Box>
              }
            </Link>
          )
        )}
    </Box>
  );
});

SocialImages.displayName = 'SocialImages';
