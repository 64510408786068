export const fontSizes = [
  /*
    DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  '10px', // 0
  '12px', // 1
  '15px', // 2 --- base/body ----
  '16px', // 3
  '18px', // 4
  '20px', // 5
  '24px', // 6
  '28px', // 7
  '36px', // 8
  '48px', // 9
  '56px', // 10
  /* DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER */
];
