import { forwardRef, useEffect } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export const PhoneField = forwardRef(({ required, setPhoneError }, ref) => {
  const updateState = (isValid, number, country) => {
    if (!isValid) {
      setPhoneError('Phone number is invalid');
      ref.current.value = '';
    } else {
      setPhoneError(null);
    }
    ref.current.value = `+${country.dialCode}${number}`;
  };
  useEffect(() => {
    const inputElement = document.getElementById('phone');
    const labelElement = document.getElementById('phone-label');

    if (inputElement && !labelElement) {
      const label = document.createElement('label');
      label.htmlFor = 'phone';
      label.id = 'phone-label';
      label.className = 'intl-tel-input-label';
      label.textContent = `Phone number ${required ? '' : '(optional)'}`;
      inputElement.parentNode.insertBefore(label, inputElement);
    }
    return () => {
      const label = document.querySelector('.intl-tel-input-label');
      if (label) {
        label.remove();
      }
    };
  }, []);

  return (
    <IntlTelInput
      fieldId="phone"
      inputClassName="intlTelInputClass"
      autoPlaceholder={false}
      variant="account.input"
      onPhoneNumberBlur={(isValid, number, country) => {
        updateState(isValid, number, country);
      }}
      onPhoneNumberChange={(isValid, number, country) => {
        updateState(isValid, number, country);
      }}
      onSelectFlag={(number, country, _, isValid) => {
        updateState(isValid, number, country);
      }}
      placeholder="3239169023"
      ref={ref}
      separateDialCode
      telInputProps={{
        required,
      }}
    />
  );
});

PhoneField.displayName = 'PhoneField';
