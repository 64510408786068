import { Fragment, useState, useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

import { useKustomerChat } from '@hooks';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { themed } from './Sidebar.theme';

export const Sidebar = themed(
  ({
    theme,
    open,
    isOverFlowing,
    setIsOverFlowing,
    isMobile,
    children,
    ...props
  }) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);

    useKustomerChat();

    const SidebarSlots = useMemo(() => {
      let Header = null;
      let Content = null;
      let Footer = null;

      const _footerHeight = footerHeight ? `${footerHeight}px` : '0px';
      const _headerHeight = headerHeight ? `${headerHeight}px` : '0px';

      const scrollHeight = `calc(var(--viewport-height) - ${_headerHeight} - ${_footerHeight})`;

      Children.forEach(children, (child) => {
        const displayName =
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
          child?.type?.displayName;

        switch (displayName) {
          case 'Header':
            Header = cloneElement(child, {
              key: 'Header',
              open,
              setHeight: setHeaderHeight,
              ...props,
              ...child.props,
            });
            break;

          case 'Content':
            Content = cloneElement(child, {
              key: 'Content',
              open,
              isOverFlowing,
              setIsOverFlowing,
              isMobile,
              ...props,
              ...child.props,
            });
            break;

          case 'Footer':
            Footer = cloneElement(child, {
              key: 'Footer',
              open,
              setHeight: setFooterHeight,
              isOverFlowing,
              isMobile,
              ...props,
              ...child.props,
            });
            break;

          default:
            break;
        }
      });

      return (
        <>
          {Header}
          <Box
            data-comp={`${Sidebar.displayName}Scroll`}
            sx={{
              ...theme.inner.scroll,
              height: scrollHeight,
            }}
          >
            {Content}
          </Box>
          {Footer}
        </>
      );
    }, [
      children,
      open,
      footerHeight,
      headerHeight,
      isOverFlowing,
      isMobile,
      setIsOverFlowing,
    ]);

    return (
      <Box data-comp={Sidebar.displayName} sx={theme.inner}>
        {SidebarSlots}
      </Box>
    );
  }
);

Sidebar.displayName = 'Sidebar';

Sidebar.Header = Header;
Sidebar.Content = Content;
Sidebar.Footer = Footer;
