export const media = () => ({
  label: 'Media settings',
  name: 'media',
  description: 'Select Media Type (image - video), ',
  component: 'group',
  fields: [
    {
      label: 'Description',
      name: 'description',
      description: 'Media description',
      component: 'markdown',
    },
    {
      label: 'Select Media Type',
      description: 'Allows select between image or video',
      name: 'mediaType',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'image',
    },
    {
      label: 'Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      name: 'image',
      label: 'Image ',
      component: 'image',

    },
    {
      label: 'Enable Autoplay',
      name: 'autoplay',
      description:
        'Enable autoplay for video, you need to refresh page after change',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Title',
      name: 'title',
      component: 'text',
      description: 'Title of the video',
    },
    {
      name: 'video',
      label: 'Video',
      component: 'text',
      description:
        'Select between a local video or a link to a video (.mp4) \n if you want to use a local video, upload it and let the url here empty',
    },
    {
      name: 'localVideo',
      label: 'Upload Video',
      component: 'image',

    },
  ],
  defaultValue: {
    description:
      'A blend of formal and casual styling, this classic mid-weight polo is crafted with modern details delivering sophistication you can’t beat. \n \n [**Shop Prestige Polo**](/collections/shop)',
    mediaType: 'image',
    alt: 'sweaters',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/sweaters-layers-tile.jpg?v=1651520745',
    },
    autoplay: true,
    title: 'lifeStyle',
    video:
      'https://cdn.shopify.com/s/files/1/1368/3463/files/PDP_LIFESTYLE_AOShort_16x9_01.mp4?v=1650908130',
    localVideo: '',
  },
});
