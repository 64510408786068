const heading = {
  fontFamily: 'heading',
};

const body = {
  fontFamily: 'body',
};

const label = {
  ...body,
  textTransform: 'uppercase',
};

const link = {
  ...body,
  cursor: 'pointer',
  textDecoration: 'underline',
};

const navigation = {
  ...body,
  cursor: 'pointer',
};

const h1 = {
  ...heading,
  fontSize: [9, null, 10], // [48px, 48px, 56px]
  fontWeight: 900,
  lineHeight: [1, null, 1.1428571429],
};

export const text = {
  // HEADINGS
  h1,
  'h1.caps': {
    ...h1,
    textTransform: 'uppercase',
  },
  h2: {
    ...heading,
    fontWeight: 700,
    lineHeight: [1.1111111111, 1.3333333333],
    fontSize: [8, null, 9], // [36px, 36px, 48px]
  },
  h3: {
    ...heading,
    fontWeight: 700,
    lineHeight: [1.3333333333, 1.1666666667],
    fontSize: [6, null, 8], // [24px, 24px, 36px]
  },
  h4: {
    ...heading,
    fontWeight: 700,
    fontSize: [5, null, 6], // [20px, 20px, 24px]
    lineHeight: [1.4, 1.3333333333],
  },
  h5: {
    ...heading,
    fontWeight: 700,
    fontSize: [3, null, 4], // [16px, 16px, 18px]
    lineHeight: [1.375, 1.3333333333],
  },
  h6: {
    ...heading,
    fontWeight: 700,
    letterSpacing: '10%',
    lineHeight: 1.8,
    fontSize: 0, // [10px]
    textTransform: 'uppercase',
  },

  // BODY
  xs: {
    ...body,
    fontWeight: 400,
    lineHeight: 1.4666666667,
    fontSize: 0, // 10px
  },
  'xs-sm': {
    ...body,
    fontWeight: 400,
    fontSize: [0, 1], // 12px -> // 14px
  },
  sm: {
    ...body,
    fontWeight: 400,
    fontSize: 1, // 12px
  },
  'sm-base': {
    ...body,
    fontWeight: 400,
    fontSize: [1, 2], // 12px -> 15px
  },
  base: {
    ...body,
    fontWeight: 400,
    lineHeight: 1.4666666667,
    fontSize: 2, // 15px
  },
  'base-lg': {
    ...body,
    fontSize: [2, 4], // 15px -> 18px
  },
  lg: {
    ...body,
    lineHeight: [1.3333333333, 1.4444444444],
    fontWeight: 400,
    fontSize: 4, // 18px
  },

  // LABELS
  label: {
    ...label,
    1: {
      ...label,
      lineHeight: 1,
      fontWeight: 300,
      color: 'black',
      fontSize: '11px',
    },
    2: {
      ...label,
      lineHeight: 1.1845454545,
      fontWeight: 700,
      fontSize: '11px',
    },
    3: {
      ...label,
      color: 'gray.dark',
      fontWeight: 400,
      lineHeight: 1.1758333333,
      letterSpacing: '1.5px',
      fontSize: 1, // 12px
    },
  },

  // OTHERS
  badge: {
    fontSize: 0,
    fontWeight: 700,
    lineHeight: 1.185,
    textTransform: 'uppercase',
  },

  field: {
    fontSize: 3, // 16px
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: '1%',
  },

  link: {
    ...link,
    text: {
      ...link,
      fontSize: 1,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    small: {
      ...link,
      fontWeight: 400,
      fontSize: 0,
      lineHeight: 1.6,
    },
    main: {
      ...link,
      fontSize: 2,
      fontWeight: 700,
      lineHeight: 1.1846666667,
    },
  },

  navMedia: {
    ...heading,
    fontSize: [7, 8], // 28px -> 36px
    lineHeight: [1.1428571429, 1.1666666667],
    fontWeight: 900,
  },

  // figma: navigation
  nav: {
    ...navigation,
    fontSize: 2, // 15px
    lineHeight: 1.1846666667,
    letterSpacing: '0.25px',
    fontWeight: 700,
  },
  navAux: {
    ...navigation,
    fontSize: 2, // 16px
    cursor: 'pointer',
  },
  navList: {
    ...navigation,
    fontSize: '14px', // 14px
    fontWeight: 400,
    mb: 4,
    cursor: 'pointer',
    lineHeight: 1.4285714286,
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  navSubItem: {
    ...label,
    fontSize: 3, // 16px
    mt: [2, null, 4],
    cursor: 'pointer',
    textTransform: 'initial',
    '@media screen and (min-width: 480px)': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  button: {
    fontSize: 2, // 15px
    fontWeight: 400,
  },
  searchHeading: {
    variant: 'text.label.2',
    position: 'sticky',
    top: ['-32px', '56px'],
    zIndex: 2,
    pb: 4,
    mb: 5,
    bg: 'background',
  },
};
