import { Badge as BadgeUI, Flex } from 'theme-ui';

import { Timer } from './Timer';
import { themed } from './KitItem.theme';

export const Badge = themed(({ theme, dateTimes, status, setStatus }) => {
  const expired = status === 'ended';

  return (
    <Flex data-comp={Badge.displayName} sx={theme.badgeWrapper}>
      <BadgeUI
        variant="primary"
        sx={{
          ...theme.badge,
          bg: expired ? 'gray.dark' : 'text',
          borderColor: expired ? 'gray.dark' : 'text',
        }}
      >
        <Timer dateTimes={dateTimes} status={status} setStatus={setStatus} />
      </BadgeUI>
    </Flex>
  );
});

Badge.displayName = 'Badge';
