import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      my: 4,
      p: 2,
    };

    this.title = {
      variant: 'text.label.1',
      my: 4,
    };
    this.link = {
      my: 2,
      variant: 'text.navList',
    };
  })()
);
