import { Link } from '@snippets';
import { Box, Text } from 'theme-ui';
import { themed } from './MenuItems.theme';

export const MenuItems = themed(({ theme, links }) => (
  <Box as="ul" sx={{ ...theme.wrapper }}>
    {links.map(({ group, links }, index) => (
      <Box as="li" key={`${group}-${index}`}>
        <Box as="ul" sx={theme.wrapper}>
          <Text as="p" sx={theme.title}>
            {group}
          </Text>
          {links.map(({ link }, index) => (
            <Box as="li" key={`${link?.url}-${index}`}>
              <Link
                animate
                href={link?.url}
                title={link?.text}
                sx={theme?.link}
              >
                {link?.text}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </Box>
));
