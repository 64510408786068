import { useCallback, useEffect, useMemo, useState } from 'react';
import { Text } from 'theme-ui';

export const Countdown = ({
  disableKit = false,
  startTime = '2021-08-18T00:00-07:00',
  endTime,
  style = {},
  status,
  actions = {
    setEnded: () => { },
    setAwaiting: () => { },
    setLive: () => { },
  },
  ...props
}) => {
  const [counter, setCounter] = useState(null);

  const times = useMemo(
    () => ({
      start: new Date(startTime).getTime(),
      end: new Date(endTime).getTime(),
    }),
    [startTime, endTime]
  );

  const convertMstoTimer = (ms) => {
    const convertUnit = (v) => (v < 10 ? '0' + v : v);
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const hrMinSec =
      convertUnit(hours) +
      ':' +
      convertUnit(minutes) +
      ':' +
      convertUnit(seconds);
    return days ? convertUnit(days) + ':' + hrMinSec : hrMinSec;
  };

  const checkTimerStatus = useCallback(
    (_status) => {
      const now = Date.now();
      if (now - times.end >= 0 || disableKit) {
        setCounter(null);
        // if (_status === 'ended') return;
        actions.setEnded();
      } else if (now - times.start < 0) {
        setCounter(convertMstoTimer(times.start - now));
        // if (_status === 'awaiting') return;
        actions.setAwaiting();
      } else {
        setCounter(convertMstoTimer(times.end - now));
        // if (_status === 'live') return;
        actions.setLive();
      }
    },
    [times, disableKit]
  );

  useEffect(() => {
    const interval = () => {
      checkTimerStatus(status);
    };
    if (status === 'ended' || !times.start || !times.end)
      return () => clearInterval(interval);
    checkTimerStatus(status);
    setInterval(interval, 1000);
    return () => clearInterval(interval);
  }, [times, status]);

  return (
    <Text
      sx={{
        fontWeight: 700,
        fontSize: '15px',
        display: 'inline',
        ...style,
      }}
      {...props}
    >
      {counter}
    </Text>
  );
};
