import { forwardRef } from 'react';

import { Svg } from '@snippets';

export const YotpoStarsIcon = forwardRef(
  (
    {
      averageScore,
      emptyColor,
      fullColor,
      hoveredIndex,
      index,
      isSelectable,
      onClickedStar,
      setHoveredIndex,
      totalReviews,
      width,
      ...props
    },
    ref
  ) => {
    if (typeof averageScore === 'undefined') return null;

    const scoreMinusIndex = averageScore - (index + 1);
    const isEmpty = scoreMinusIndex <= -1;
    const isHalf = !isEmpty && scoreMinusIndex < -0.5;
    const inHoverRange = index <= hoveredIndex;
    const starIndex = index + 1;

    const starSvgSrc =
      totalReviews === 0
        ? '/svgs/yotpo/star-empty.svg#star-empty'
        : isEmpty
        ? '/svgs/yotpo/star-full.svg#star-full' // for empty as just border -> '/svgs/noprecache/svg/star-empty.svg#star-empty'
        : isHalf
        ? '/svgs/yotpo/star-half-empty.svg#star-half-empty'
        : '/svgs/yotpo/star-full.svg#star-full'; // isFull

    return (
      <Svg
        alt={`${starIndex} ${starIndex === 1 ? 'star' : 'stars'}`}
        viewBox="0 0 24 24"
        key={index}
        width={width}
        src={starSvgSrc}
        onClick={() => onClickedStar(index + 1)}
        onMouseEnter={() => {
          setHoveredIndex(index);
        }}
        onMouseLeave={() => {
          setHoveredIndex(0);
        }}
        sx={{
          width: '10px',
          color: inHoverRange ? fullColor : isEmpty ? emptyColor : fullColor,
          cursor: isSelectable ? 'pointer' : 'initial',
          '@media screen and (min-width: 480px)': {
            '&:hover': {
              color: isSelectable ? fullColor : null,
            },
          },
        }}
      />
    );
  }
);

YotpoStarsIcon.displayName = 'YotpoStarsIcon';
