import { useMemo, useState, useEffect, Fragment } from 'react';
import { Box, Flex, Grid } from 'theme-ui';

import { Link } from '@snippets';
import { useBreakpointValue } from '@hooks';
import { convertAlignToFlex, parseContentPosition } from '@utils/common';
import { buttons } from '@theme/theme.buttons';

import { padding } from '@settings/common';

import { space } from '@theme/theme.space';

import { AddProduct } from '@sections/HalfMediaVIP/Content/AddProduct';
import { themed } from './Slide.theme';
import { TextSection } from './TextSection';

export const Content = themed(
  ({ theme, index, isBelowHero, slide, visible = true, ...props }) => {
    const { text, button, content } = slide;

    const [mounted, setMounted] = useState(false);

    const textAlign = useBreakpointValue([
      content?.alignment,
      content?.alignmentDt,
    ]);
    // Mobile content position
    const posMb = useMemo(
      () => parseContentPosition(content?.position),
      [content?.position]
    );
    const pyMb = padding?.value?.[content?.pyMb];
    const pxMb = padding?.value?.[content?.pxMb];

    // Desktop content position
    const posDt = useMemo(
      () => parseContentPosition(content?.positionDt),
      [content?.positionDt]
    );
    const pyDt = padding?.value?.[content?.pyDt];
    const pxDt = padding?.value?.[content?.pxDt];

    useEffect(() => {
      setMounted(true);
    }, []);

    return (
      <Flex
        data-comp={Content.displayName}
        sx={{
          ...theme.contentWrapper,
          alignItems: [posMb.alignItems, posDt.alignItems],
          justifyContent: [posMb.justifyContent, posDt.justifyContent],
          ...props.sx,
          visibility: visible ? 'visible' : 'hidden',
        }}
      >
        <Box
          sx={{
            ...theme.content,
            width: '100%',
            maxWidth: content?.maxWidth
              ? [
                  content.maxWidth + space[12] * 2,
                  content.maxWidth + space[15] * 2,
                ]
              : 'none',
            py: [
              pyMb?.[0] || 0,
              pyDt?.[1] || 0,
              pyDt?.[2] || null,
              pyDt?.[3] || null,
            ],
            px: [
              pxMb?.[0] || 0,
              pxDt?.[1] || 0,
              pxDt?.[2] || null,
              pxDt?.[3] || null,
            ],
            position: 'relative',
            top: [
              content?.yRelativePositionMb
                ? `${content?.yRelativePositionMb}px`
                : 'auto',
              content?.yRelativePositionDt
                ? `${content?.yRelativePositionDt}px`
                : 'auto',
            ],
            left: [
              content?.xRelativePositionMb
                ? `${content?.xRelativePositionMb}px`
                : 'auto',
              content?.xRelativePositionDt
                ? `${content?.xRelativePositionDt}px`
                : 'auto',
            ],
          }}
        >
          {mounted && (
            <>
              <TextSection
                text={text}
                theme={theme}
                globalAlign={{
                  active: content?.globalAlignment,
                  textAlign,
                }}
              />

              {!button?.hideButtons && button?.buttons?.length > 0 && (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                  }}
                >
                  <Grid
                    sx={{
                      ...theme.buttons,
                      justifyContent: convertAlignToFlex(textAlign),
                      '@media only screen and (max-width: 479px)': {
                        alignItems: convertAlignToFlex(textAlign),
                      },
                    }}
                  >
                    {button?.buttons?.slice(0, 2).map((item, idx) => {
                      const { colorOverride, cta, style } = { ...item };
                      const {
                        enable,
                        background,
                        backgroundHover,
                        color,
                        colorHover,
                      } = { ...colorOverride };

                      return (
                        <Fragment key={idx}>
                          {item?.isAddToCart ? (
                            <Flex>
                              <AddProduct
                                product={item?.product}
                                buttonText={item?.buttonText}
                                buttonStyle={buttons[style]}
                              />
                            </Flex>
                          ) : (
                            <Box>
                              <Link
                                key={idx}
                                variant={`buttons.${style}`}
                                href={cta?.url}
                                newTab={cta?.newTab}
                                sx={{
                                  ...theme.button,
                                  ...(enable && {
                                    borderColor: background,
                                    backgroundColor: background,
                                    color,
                                    '@media screen and (min-width: 480px)': {
                                      '&:hover': {
                                        borderColor: backgroundHover,
                                        backgroundColor: backgroundHover,
                                        color: colorHover,
                                      },
                                    },
                                  }),
                                }}
                              >
                                {cta?.text}
                              </Link>
                            </Box>
                          )}
                        </Fragment>
                      );
                    })}
                  </Grid>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      mt: [4, 8],
                      gap: 4,
                    }}
                  >
                    {button?.additionalText && (
                      <Box
                        variant="text.sm"
                        sx={{
                          ...theme.additionalText,
                          textAlign,

                          color: button?.additionalTextColor,
                        }}
                      >
                        {button?.additionalText}
                      </Box>
                    )}
                    {button?.showLoginCTA && (
                      <Link
                        variant="buttons.link.small"
                        href={button?.loginCTA.url}
                        sx={{
                          color: button?.additionalTextColor,
                        }}
                      >
                        {button?.loginCTA.text}
                      </Link>
                    )}
                  </Flex>
                </Flex>
              )}
            </>
          )}
        </Box>
      </Flex>
    );
  }
);

Content.displayName = 'Content';
