import { align, color, layout, margin, padding } from '@settings/common';

// text settings
const heading = {
  label: 'Heading Settings',
  name: 'heading',
  description: 'Heading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'headingText',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      name: 'headingAlignmentMb',
      label: 'Heading Alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorMb',
      label: 'Text Color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'headingAlignmentDt',
      label: 'Heading Alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorDt',
      label: 'Text Color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    headingText: '# Heading',
    headingAlignmentMb: 'center',
    headingColorMb: '',
    headingAlignmentDt: 'center',
    headingColorDt: '',
  },
};

const subheading = {
  label: 'SubHeading Settings',
  name: 'subheading',
  description: 'SubHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'subheadingText',
      component: 'markdown',
      label: 'SubHeading',
      description: 'This heading is below the main heading.',
    },
    {
      name: 'subheadingAlignmentMb',
      label: 'Subheading Alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorMb',
      label: 'Text Color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'subheadingAlignmentDt',
      label: 'Subheading Alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorDt',
      label: 'Text Color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    subheadingText: '**Subheading**',
    subheadingAlignmentMb: 'center',
    subheadingColorDt: '',
    subheadingAlignmentDt: 'center',
    subheadingColorMb: '',
  },
};

export const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, Subheading, Color',
  component: 'group',
  fields: [heading, subheading],
};

// section settings
const fields = [
  {
    name: 'bgColor',
    label: 'Background Color',
    component: 'select',
    options: color.options,
  },
  {
    name: 'contentWidth',
    component: 'number',
    label: 'Max Content Pixel Width',
  },
  {
    name: 'signupWidth',
    component: 'number',
    label: 'Max Signup Form Pixel Width',
  },
];

const mobileFields = [
  {
    label: 'Container Width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const desktopFields = [
  {
    label: 'Container Width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const mobile = {
  label: 'Mobile Settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
};

const desktop = {
  label: 'Desktop Settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
};

const section = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, desktop, mobile],
};

export const Schema = () => {
  return {
    label: 'Email Signup',
    key: 'email-signup',
    fields: [
      text,
      {
        label: 'Klaviyo Email Signup List Id',
        name: 'listId',
        component: 'text',
        defaultValue: 'JMe9C2',
      },
      {
        label: 'Newsletter Category Subscription',
        name: 'categorySubscription',
        description: 'Categories to subscribe to (max. 3)',
        component: 'group-list',
        itemProps: {
          label: '{{item.category}}',
        },
        defaultItem: {
          category: 'Category',
        },
        fields: [
          {
            label: 'Category',
            name: 'category',
            component: 'text',
          },
        ],
        defaultValue: [
          {
            category: 'Men',
          },
          {
            category: 'Women',
          },
          {
            category: 'Both',
          },
        ],
      },
      {
        label: 'Input Placeholder',
        name: 'inputPlaceholder',
        component: 'text',
        defaultValue: 'Your email address',
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          contentWidth: '600px',
          signupWidth: '400px',
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
