import { useState, useCallback, useEffect } from 'react';
import { Grid, Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';

import { Picture } from '@snippets';

import { themed } from './Media.theme';

export const Media = themed(({ theme, medias }) => {
  const [swiper, setSwiper] = useState(null);

  // init slide change
  useEffect(() => {
    if (swiper) {
      try {
        swiper.on('slideChange', (swiper) => {
          if (!swiper) return;
          swiper.allowTouchMove = true;
          swiper.update();
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [swiper, swiper?.initialized]);

  // on color swatch change go back to first slide
  useEffect(() => {
    if (swiper && swiper.initialized) {
      swiper.slideToLoop(0, 0);
    }
  }, [swiper]);

  return (
    <Grid
      data-comp={Media.displayName}
      sx={{
        ...theme.medias,
        ...theme.medias.sticky,
      }}
    >
      <Grid
        sx={{
          display: ['none', null, 'grid'],
          gridTemplateColumns: [null, null, '1fr', '1fr 1fr'],
          gap: '12px',
        }}
      >
        {medias?.map((media, index) => {
          return (
            <Box
              key={index}
              sx={{
                position: 'relative',
              }}
            >
              <Picture
                alt={media?.alt || 'Product image'}
                aboveTheFold
                images={[
                  {
                    src: media?.image?.src,
                    width: 768,
                    ratio: media?.image?.ratio || 0.75,
                  },
                ]}
                sx={theme.slide}
              />
            </Box>
          );
        })}
      </Grid>

      {/* Carousel */}
      <Swiper
        data-comp="MediaSwiper"
        init={false}
        effect="fade"
        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
        preloadImages={false}
        fadeEffect={{ crossFade: true }}
        onSwiper={setSwiper}
        slidesPerView={1}
        freeMode
        loop
        loopedSlides={2}
        initialSlide={0}
        threshold={25}
        sx={theme.swiper}
      >
        {/* Ech large media */}
        {medias?.map((media, index) => {
          return (
            <SwiperSlide key={index}>
              <Picture
                alt={media?.alt || 'Product image'}
                aboveTheFold
                images={[
                  {
                    src: media?.image?.src,
                    width: 768,
                    ratio: media?.image?.ratio || 0.75,
                  },
                ]}
                sx={theme.slide}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Grid>
  );
});

Media.displayName = 'Media';
