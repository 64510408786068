export const stickyCTA = {
  label: 'Sticky CTA',
  name: 'stickyCTA',
  component: 'group',
  fields: [
    {
      name: 'enable',
      label: 'Enable Sticky CTA',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      default: false,
    },
    {
      name: 'products',
      label: 'Products',
      description: 'Disables Sticky CTA per product',
      component: 'group-list',
      itemProps: (item) => {
        return {
          label: item?.product?.handle || 'Search For Product',
        };
      },
      fields: [
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
      defaultValue: [],
    },
  ],
};
