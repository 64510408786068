import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      p: 8,
      borderTop: (t) => `1px solid ${t.colors.gray.mediumLight}`,
    };
  })()
);
