import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.options = {
      variant: 'flex.column',
      width: '100%',
    };

    this.option = {
      mt: 0,
      width: '100%',
    };

    this.heading = {
      mb: 2,
      variant: 'flex.row.start.center',
      width: '100%',
      minHeight: '22px',
    };

    this.optionName = {
      variant: 'text.label.2',
    };

    this.selectedOption = {
      variant: 'text.sm',
      ml: 4,
      textTransform: 'none',
    };

    this.values = {
      variant: 'flex.row.start',
      flexWrap: 'wrap',
      flexBasis: '100%',
      mr: 'auto',
      // mb: 6
    };

    this.value = {
      mb: 6,
      mr: 6,
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12px',
      borderRadius: '4px',
      letterSpacing: '0.1px',
      fontWeight: 400,
      minWidth: '40px',
      height: '40px',
      lineHeight: '40px',
      position: 'relative',

      available: {
        selected: {
          border: (t) => `1px solid ${t.colors.black}`,
          bg: 'gray.light',
          color: 'text',
        },
        unselected: {
          border: (t) => `1px solid ${t.colors.gray.light}`,
          bg: 'gray.light',
          color: 'text',
        },
      },

      unavailable: {
        selected: {
          border: (t) => `1px solid ${t.colors.gray.medium}`,
          bg: 'lightGray',
          color: 'white',
          cursor: 'default',
        },
        unselected: {
          border: (t) => `1px solid ${t.colors.lightGray}`,
          bg: 'lightGray',
          color: 'white',
          cursor: 'default',
        },
      },
    };
  })()
);
