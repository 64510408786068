import { Spinner } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(({ theme, product, fullProduct }) => {
  const defaultImage = fullProduct
    ? fullProduct?.images?.length
      ? fullProduct?.images?.[0]
      : fullProduct?.variants?.image
    : null;

  const hoverImage = fullProduct?.images?.[1] || null;

  const imageSrc =
    product?.mediaType === 'image'
      ? product?.image?.src
      : defaultImage?.originalSrc;
  const hoverImageSrc =
    product?.mediaType === 'image'
      ? product?.image?.src
      : hoverImage?.originalSrc;
  const showSpinner =
    product?.mediaType === 'image'
      ? !product?.image?.src
      : !defaultImage?.originalSrc;

  const imageRatio =
    product?.mediaType === 'image'
      ? product?.image?.ratio
      : defaultImage?.ratio;
  const hoverImageRatio =
    product?.mediaType === 'image' ? product?.image?.ratio : hoverImage?.ratio;

  return (
    <Link
      data-comp={Image.displayName}
      href={product.button.cta.url}
      sx={theme.image}
    >
      <Picture
        alt={fullProduct?.title}
        images={[
          {
            src: imageSrc,
            width: 350,
            ratio: imageRatio || 3 / 4,
          },
        ]}
        hoverImages={[
          {
            src: hoverImageSrc,
            width: 350,
            ratio: hoverImageRatio || 3 / 4,
          },
        ]}
        imageSx={{
          width: '100%',
          margin: '0 auto',
        }}
      >
        {showSpinner && <Spinner sx={theme.spinner} />}
      </Picture>
    </Link>
  );
});

Image.displayName = 'Image';
