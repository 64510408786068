import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, Heading, Flex, Grid } from 'theme-ui';

import { withInView } from '@utils';
import { Markdown, Section } from '@snippets';

import { ComparisonTile } from './ComparisonTile';
import { Schema } from './Comparison.schema';

export const Comparison = withInView(({ cms, withInView }) => {
  const { section, heading, subheading, tiles } = cms;

  return (
    <Section section={section}>
      <Container data-comp={Comparison.displayName}>
        <Flex variant="flex.column.center.center">
          <Heading
            variant="text.h3"
            sx={{
              mb: 8,
            }}
          >
            {heading}
          </Heading>
          {subheading && (
            <Markdown
              text={subheading}
              sx={{
                textAlign: 'center',
                px: '20px',
                p: {
                  fontSize: [2, 4],
                },
              }}
            />
          )}
        </Flex>
        <Grid
          sx={{
            gridTemplateColumns: `repeat(${
              tiles?.slice(0, 4).length || 1
            }, minmax(0, 1fr))`,
            gridGap: 0,
            mx: 'auto',
            position: 'relative',
            display: ['none', null, 'grid'],
          }}
        >
          {tiles?.length > 0 &&
            tiles.slice(0, 4).map((tile, index) => {
              return (
                <ComparisonTile
                  key={index}
                  index={index}
                  tile={tile}
                  numOfTiles={tiles.slice(0, 4)?.length}
                />
              );
            })}
        </Grid>

        {tiles?.length > 0 && (
          <Swiper
            grabCursor
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            slidesPerView={1.4}
            spaceBetween={16}
            threshold={25}
            breakpoints={{
              768: {
                slidesPerView: tiles.slice(0, 4).length < 3 ? 2 : 2.4,
                slidesOffsetBefore: tiles.slice(0, 4).length < 3 ? 0 : 32,
                slidesOffsetAfter: tiles.slice(0, 4).length < 3 ? 0 : 32,
                spaceBetween: 22,
              },
            }}
            sx={{
              display: ['block', null, 'none'],
            }}
          >
            {tiles.slice(0, 4).map((tile, index) => {
              return (
                <SwiperSlide key={index}>
                  <ComparisonTile
                    key={index}
                    index={index}
                    tile={tile}
                    numOfTiles={tiles.slice(0, 4).length}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Container>
    </Section>
  );
});

Comparison.displayName = 'Comparison';
Comparison.Schema = Schema;
