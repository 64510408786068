import { useLocale } from '@backpackjs/storefront';

import { LocaleProduct } from './Locale.Product';
import { LocaleVariant } from './Locale.Variant';
import { LocaleCartLine } from './Locale.CartLine';
import { LocaleCartTotals } from './Locale.CartTotals';
import { LocaleOrderLine } from './Locale.OrderLine';
import { LocaleOrderTotals } from './Locale.OrderTotals';

export const Locale = ({ children }) => {
  const locale = useLocale();
  return children({ locale });
};

// shard sub components
Locale.Loader = ({ children }) => children;
Locale.Loader.displayName = 'Locale.Loader';

Locale.Error = ({ children }) => children;
Locale.Error.displayName = 'Locale.Error';

Locale.Price = ({ children, ...props }) => children(props);
Locale.Price.displayName = 'Locale.Price';

// locale components
Locale.Product = LocaleProduct;
Locale.Variant = LocaleVariant;
Locale.OrderLine = LocaleOrderLine;
Locale.OrderTotals = LocaleOrderTotals;
Locale.CartLine = LocaleCartLine;
Locale.CartTotals = LocaleCartTotals;
