export default {
  label: 'Register Settings',
  name: 'register',
  component: 'group',
  fields: [
    // RegisterForm settings
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Create account',
    },
    {
      label: 'Sub heading',
      name: 'subHeading',
      component: 'textarea',
      defaultValue: '',
    },
    {
      label: 'Button text',
      name: 'buttonText',
      component: 'text',
      defaultValue: 'Create Account',
    },
    {
      label: 'Return link text',
      name: 'return',
      component: 'text',
      defaultValue: 'Already have an account? Sign in',
    },
  ],
};
