import { useEffect, useState } from 'react';

export const useInveterate = ({ customer }) => {
  const url = `/api/inveterate`;
  const [inveterateData, setInveterateData] = useState({});
  const [inveterateCancelResponse, setInveterateCancelResponse] = useState();
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const customerId = customer?.id.split('/').pop();

  const fetchInveterate = async (action, setData, body) => {
    console.log(`Fetching ${action} for customer ID: ${customerId}`);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        action,
        id: customerId,
        ...body,
      }),
    });
    const { data } = await response.json();
    if (!setData || typeof setData !== 'function') return data;
    setData(data);
    return data;
  };

  const generateDiscountCode = (callback, amount) => {
    fetchInveterate('get-discount-code', callback, { amount });
  };

  const cancelSubscription = () => {
    setInveterateCancelResponse({
      status: 'loading',
    });
    fetchInveterate('cancel-subscription', setInveterateCancelResponse);
  };

  const updatePaymentMethod = async () => {
    setIsLoading(true);
    const response = await fetchInveterate('update-payment-method');
    if (response) {
      setIsLoading(false);
      setMessage(response.message);
    }
  };
  const getInveterateData = async () => {
    setIsLoading(true);
    const response = await fetchInveterate('get-inveterate', setInveterateData);

    if (response) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customer?.email && customer?.id) {
      getInveterateData();
    }
  }, [customer?.email, customer?.id]);

  return {
    ...inveterateData,
    inveterateCancelResponse,
    isLoading,
    message,
    generateDiscountCode,
    cancelSubscription,
    updatePaymentMethod,
  };
};
