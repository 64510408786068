export const animation = () => ({
  label: 'Animation settings',
  name: 'animation',
  description: 'Select animation and duration',
  component: 'group',
  fields: [
    {
      name: 'showAnimation',
      label: 'Enable / Disable animation',
      description: 'Activate animation, requires refresh',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'time',
      component: 'text',
      label: 'Animation Time',
      description: 'Set the animation time in seconds',
      defaultValue: 1,
    },
  ],
});
