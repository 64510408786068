export const video = ({ color }) => ({
  label: 'Video settings',
  name: 'video',
  description: 'video, video position',
  component: 'group',
  fields: [
    {
      label: 'Title (mobile)',
      name: 'titleMb',
      component: 'text',
      description: 'Title of the video (mobile)',
    },
    {
      name: 'videoMb',
      description: 'Video url must end with .mp4',
      label: 'Video url (mobile)',
      component: 'text',
    },
    {
      name: 'posterMb',
      label: 'Video poster (mobile)',
      component: 'image',
      description:
        'First frame of the video to display briefly while video loads',
    },
    {
      label: 'Title (desktop)',
      name: 'titleDt',
      component: 'text',
      description: 'Title of the video (desktop)',
    },
    {
      name: 'videoDt',
      description:
        'Video url must end with .mp4 \n For better results on desktop use minimum resolution of 1280px',
      label: 'Video url (desktop)',
      component: 'text',
    },
    {
      name: 'posterDt',
      label: 'Video poster (desktop)',
      description:
        'First frame of the video to display briefly while video loads',
      component: 'image',
    },
    {
      label: 'Activate overlay',
      description: 'Activate video overlay',
      name: 'videoOverlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      defaultValue: 50,
    },
  ],
  defaultValue: {
    titleMb: 'Hero video',
    videoMb:
      'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
    titleDt: 'Hero video',
    videoDt:
      'https://cdn.shopify.com/s/files/1/1368/3463/files/STAPLES_3x1_Hero_01.mp4?v=1649123562',
    overlayOpacity: 50,
    overlayColor: '',
    videoOverlay: false,
  },
});
