import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.accordions = {
      mt: 10,
    };

    this.accordion = {
      heading: {
        variant: 'text.label.2',
      },

      contents: {
        pt: 4,
        'p, ul, ol, li': {
          variant: 'text.sm',
        },
        'ul, ol': {
          pl: 0,
        },
        'p:first-of-type': {
          pt: 0,
          mt: 0,
        },
      },
    };

    this.fabric = {
      grid: {
        py: [8, 10],
      },

      item: {
        variant: 'flex.column.center',
      },

      icon: {
        color: 'black',
        width: '50px',
      },

      label: {
        variant: 'text.sm',
        fontWeight: 700,
        textAlign: 'center',
      },
    };

    this.looks = {
      variant: 'flex.column',
      mt: 11,

      heading: {
        variant: 'text.label.2',
      },

      products: {
        gridTemplateColumns: '1fr 1fr',
        columGap: '20px',
        mt: 8,
      },
    };
  })()
);
