import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.dropdown = {
      minWidth: [0, '200px'],
      position: 'relative',
      zIndex: 1,
      minWidth: ['40px', null, '200px'],
      height: '32px',
    };

    this.optionsWrapper = {
      border: '1px solid',
      flexDirection: 'column',
      left: 0,
      height: '120px',
      overflow: 'hidden',
      position: 'absolute',
      top: 'calc(100% - 2px)',
      width: '100%',
      bg: 'black',
      borderColor: 'white',
    };

    this.scroll = {
      '::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      webkitOverflowScrolling: 'touch',
      height: 'auto',
      maxHeight: '100%',
      overflowY: 'auto',
      position: 'relative',
      width: '100%',
    };

    this.option = {
      alignItems: 'center',
      bg: 'white',
      color: 'text',
      display: 'flex',
      flexDirection: 'row',
      height: '32px',
      justifyContent: 'space-between',
      lineHeight: '32px',
      overflow: 'hidden',
      px: 0,
      py: 0,
      textAlign: 'left',
      textOverflow: 'ellipsis',
      transition: 'background-color 0.1s ease-in-out',
      whiteSpace: 'nowrap',
      width: '100%',
      zIndex: 1,
      borderStyle: 'none',
      borderRadius: '0px',
      '&:first-of-type': {
        mt: 0,
      },
      '&:last-of-type': {
        mb: 0,
      },
    };

    this.updatingBox = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'rgba(0, 0, 0, 0.5)',
      overflow: 'hidden',
    };

    this.updatingSpinner = {
      color: 'white',
      width: '16px',
    };

    this.item = {
      display: 'flex',
      variant: 'flex.row.start.center',
      width: '100%',
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      height: 'inherit',
      px: [4, 6],
      '@media screen and (min-width: 480px)': {
        '&:hover': {
          backgroundColor: 'white',
          color: 'black',
        },
      },

      icon: {
        width: [18, 22],
      },

      name: {
        variant: 'text.sm',
        ml: 4,
      },

      toggle: {
        width: '14px',
        ml: 'auto',
      },
    };
  })()
);
