import { useEffect } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { getYotpoLoyaltyData } from '@utils';
import store, { useRecoilState } from '@store';

export const useYotpoLoyalty = () => {
  const [yotpoLoyaltyData, setYotpoLoyaltyData] = useRecoilState(
    store.yotpoLoyaltyData
  );
  const customer = useCustomer();

  useEffect(() => {
    if (!customer) return;
    getYotpoLoyaltyData({
      action: (data) => setYotpoLoyaltyData(data),
      email: customer.email,
    });
  }, [customer]);

  return yotpoLoyaltyData;
};
