import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      flexDirection: 'column',
    };
    this.heroWrap = {
      px: [12, 14, '80px'],
    };
    this.hero = {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
      color: 'white',
      height: '35vw',
    };
    this.heroTitle = {
      fontWeight: 700,
    };
    this.content = {
      margin: '32px auto 64px',
      maxWidth: '960px',
      padding: '0 8px',
      textAlign: 'center',
    };
    this.link = {
      width: 'fit-content',
      margin: '24px auto',
    };
    this.infoHeading = {
      fontSize: '24px',
      fontWeight: 700,
      mb: '24px',
    };
    this.infoSubHeading = {
      ...this.infoHeading,
      fontSize: '16px',
    };
    this.infoText = {
      fontFamily: 'body',
      whiteSpace: 'break-spaces',
      lineHeight: 1.5,
      fontWeight: 300,
    };
    this.icons = {
      flexDirection: ['column', 'row'],
      justifyContent: 'center',
      alignItems: ['center', 'flex-start'],
      my: [16, 20],
    };
    this.iconWrap = {
      width: '220px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      mb: ['36px', 0],
    };
    this.icon = {
      width: '50px',
      height: '50px',
      margin: '0 auto',
      mb: '16px',
      img: {
        objectFit: 'contain',
      },
    };
    this.bottomHeading = {
      ...this.infoHeading,
    };
  })()
);
