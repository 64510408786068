import store, { useRecoilValueLoadable } from '@store';

import { useProduct } from '@backpackjs/storefront';

const defaultProps = {
  legacyResourceId: null,
};

export const useYotpoProductQuestions = ({
  legacyResourceId: passedId,
} = defaultProps) => {
  const product = useProduct();
  const legacyResourceId = passedId || product?.legacyResourceId;

  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoProductQuestions$({
      legacyResourceId,
    })
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    ...(state === 'hasValue' && contents ? contents : null),
  };
};
