export const heading = ({ color, text }) => ({
  label: 'Heading settings',
  name: 'heading',
  description: 'Heading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'text',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      component: 'select',
      name: 'color',
      label: 'Text color ',
      description: 'Choose a color for the heading text',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      component: 'select',
      name: 'textVariant',
      label: 'Text type',
      description: 'Choose a text type',
      options: [{ label: 'Default', value: '' }, ...text.options],
    },
  ],
});

export const superheading = ({ color, text }) => ({
  label: 'SuperHeading settings',
  name: 'superheading',
  description: 'SuperHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'text',
      component: 'markdown',
      label: 'SuperHeading',
      description: 'You can use markdown e.g. <u>**bold**</u>',
    },
    {
      component: 'select',
      name: 'color',
      label: 'Text color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      component: 'select',
      name: 'textVariant',
      label: 'Text type',
      description: 'Choose a text type',
      options: [{ label: 'Default', value: '' }, ...text.options],
    },
  ],
});
