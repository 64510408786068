const defaultInput = {
  variant: 'text.base',
  fontSize: 3,
  color: 'text',
  pt: 8,
  pb: 7,
  mb: ['40px'],
  pl: '40px',
  pr: 8,
  border: (t) => `1px solid ${t.colors.gray}`,
  borderRadius: 0,
  ':focus': {
    outline: 'none !important',
  },
};

export const input = {
  ...defaultInput,

  search: {
    ...defaultInput,
    pl: '35px',
    py: 4,
    mb: 0,
    border: 'none',
    borderBottom: '2px solid',
    boxShadow: 'none !important',
    outline: 'none !important',
    ':focus': {
      outline: 'none !important',
      // border: t => `1px solid ${t.colors.text}`,
      // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);'
    },
  },
};
