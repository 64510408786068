import { Box, Heading, Text } from 'theme-ui';
import { themed } from './BreadCrumbs.theme';

export const BreadCrumbs = themed(
  ({ theme, levelOne, collectionTitle, levelTwo, overrideStyles = {} }) => {
    return (
      <Box sx={{ minWidth: 150, pl: 4, ...overrideStyles }}>
        <Text sx={theme.title}>{levelOne}</Text>
        <Text
          sx={{
            ...theme.title,
            ':before': {
              content: '"- "',
              mr: 1,
            },
          }}
        >
          {levelTwo}
        </Text>
        <Heading as="h1" sx={theme.collection}>
          {collectionTitle}
        </Heading>
      </Box>
    );
  }
);
