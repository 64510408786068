import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      borderRight: '1px solid',
      borderColor: 'gray.mediumLight',
      pr: '10px',
      height: ['20px', '32px'],
    };
    this.label = {
      mb: '0px',
      mr: ['0px', '5px'],
      variant: 'text.sm',
      textTransform: 'capitalize',
      width: 'fit-content',
      letterSpacing: 0,
      fontSize: '14px',
    };
    this.button = {
      px: '5px',
      pt: '6px',
      ml: '5px',
    };
  })()
);
