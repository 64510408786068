import { create } from '@theme/create'

export const themed = create(new function () {
  this.image = {
    mb: 8,
    bg: '#FAFAFA',
    width: '100%',
    display: 'block',
    position: 'relative',
  }

  this.spinner = {
    width: '30px',
    color: 'lightGray',
    m: 'auto'
  }
});