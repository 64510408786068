import dynamic from 'next/dynamic';
import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';
import { convertAlignToFlex } from '@utils/common';
import { useBreakpointValue } from '@hooks';

import { Schema } from './TextBlock.schema';
import { themed } from './TextBlock.theme';

const TextBlockContent = dynamic(
  () => import('./TextBlockContent').then((module) => module.TextBlockContent),
  { ssr: false, suspense: false }
);

export const TextBlock = withInView(
  themed(({ theme, cms, inView }) => {
    const { content, section } = cms;
    const textAlign = useBreakpointValue([
      content?.textAlign,
      content?.textAlignDt,
    ]);

    return (
      <Section section={section}>
        <Container
          data-comp={TextBlock.displayName}
          sx={{
            ...theme.wrapper,
            alignItems: convertAlignToFlex(textAlign),
          }}
        >
          <TextBlockContent content={content} textAlign={textAlign} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TextBlock.displayName = 'TextBlock';
TextBlock.Schema = Schema;
