export const onSaleBadge = {
  label: 'On Sale Badge',
  name: 'onSaleBadge',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enabled',
      component: 'toggle',
      description: 'Show badge in the pdp image',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'enabledPrice',
      label: 'Enabled in Price',
      component: 'toggle',
      description: 'Show badge next to price',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'text',
      label: 'On Sale  Badge Text',
      component: 'text',
      description:
        'If is empty the the discount will by calculated price/compare at price',
      defaultValue: '40% Off',
    },
    {
      name: 'textColor',
      label: 'Text Color',
      component: 'color',
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'color',
    },
    {
      name: 'borderColor',
      label: 'Border Color',
      component: 'color',
    },
    {
      name: 'saleBadgeStyle',
      label: 'Badge Style',
      component: 'toggle',
      description: 'Choose the style of the badge',
      toggleLabels: {
        true: 'Secondary',
        false: 'Primary',
      },
      defaultValue: true,
    },
  ],
};
