import { useEffect } from 'react';
/*
  Hides the top customizer bar.
  this can be potentially useful (temporarily)
  while developing a new header.
*/
export const useHideCustomizerBar = ({ hide } = { hide: false }) => {
  const killTinaToolBar = () => {
    let _next;
    try {
      _next = document.getElementById('__next');
    } catch (error) {
      return;
    }

    console.groupCollapsed('Hiding customizer toolbar');

    for (let i = 0; i < _next.children.length; i++) {
      const child = _next.children[i];
      for (var y = 0, l = child.classList.length; y < l; ++y) {
        const childClass = child.classList[y];
        const classesToHide = /ToolbarPlaceholder*|StyledToolbar*/;
        if (classesToHide.test(childClass)) {
          console.log('Hiding element with class', childClass);
          child.style.display = 'none';
          break;
        }
      }
    }
    console.groupEnd('Hiding editor toolbar');
  };

  hide && useEffect(() => { killTinaToolBar() }, []);
};
