import { useEffect, useMemo } from 'react';
import { Badge, Flex, Text } from 'theme-ui';

import { Link } from '@snippets';

import { getValueFromTag } from '@utils';
import { SwatchesContainer } from '../Swatches';

import { themed } from './Details.theme';
import { Price } from '../Price';

export const Details = themed(
  ({
    theme,
    initialProduct,
    swatches,
    handleClick,
    selectedVariant,
    selectedProduct,
    setProductFromColorSelector,
    setVariantFromColorSelector,
    productUrl,
    settings,
    product,
  }) => {
    const title = selectedProduct?.title?.split('|')[0];

    const colorName = selectedProduct?.optionsMap?.color?.[0]?.toLowerCase();

    const showColorName = useMemo(() => {
      return colorName
        ? Object.keys(swatches).some((color) => color === colorName)
        : null;
    }, [colorName, swatches]);

    const colorGroupsMap = useMemo(() => {
      if (!initialProduct) return null;
      return initialProduct?.grouping?.isTransformed
        ? initialProduct.grouping.colorGroupsMap
        : initialProduct?.colorGroupsMap;
    }, [initialProduct?.id]);

    const showSwatches = useMemo(() => {
      if (!colorGroupsMap) return false;
      return Object.keys(colorGroupsMap).length > 0;
    }, [colorGroupsMap]);

    const {
      enabledPrice: showSaleBadges,
      text: saleBadgeText,
      textColor: saleBadgeTextColor,
      bgColor: saleBadgeBgColor,
      borderColor: saleBadgeBorderColor,
      saleBadgeStyle: primary,
    } = settings?.product?.onSaleBadge || {};

    const hasSaleBadge = getValueFromTag(product, 'saleBadge');
    const saleBadge = useMemo(() => {
      if (hasSaleBadge === 'true' && selectedVariant) {
        const percentOff = (
          100 -
          (Number(selectedVariant?.priceV2?.amount) /
            Number(selectedVariant?.compareAtPriceV2?.amount)) *
            100
        ).toFixed(0);
        return percentOff;
      }
      return null;
    }, [hasSaleBadge, selectedVariant]);

    // sets initial variant from initial color
    useEffect(() => {
      if (!initialProduct || !showSwatches) return;
      setProductFromColorSelector(initialProduct);
      setVariantFromColorSelector(initialProduct.variants?.[0]);
    }, [initialProduct?.id, showSwatches]);
    const useBlackColor =
      selectedProduct?.tags?.includes('bundle') ||
      selectedProduct?.tags?.includes('grey-strikethrough');
    return (
      <Flex data-comp={Details.displayName} sx={theme.wrapper}>
        {showColorName && <Text sx={theme.colorName}>{colorName}</Text>}

        {/* Title */}
        <Link
          href={productUrl}
          tabIndex="-1"
          sx={theme.title}
          onClick={handleClick}
        >
          {title}
        </Link>

        {/* Price */}
        <Flex
          sx={{
            gap: [4, 6],
            alignItems: 'center',
          }}
        >
          <Price
            selectedVariant={selectedVariant}
            useBlackColor={useBlackColor}
            theme={theme}
          />

          {showSaleBadges && saleBadge && (
            <Badge
              sx={{
                px: '6px',
                py: 1,
                variant: `${primary ? 'text.base' : 'text.xs'}`,
                fontWeight: `${primary ? 'normal' : 'bold'}`,
                borderRadius: `${primary ? 0 : '20px'}`,
                color: saleBadgeTextColor,
                bg: saleBadgeBgColor,
                border: `1px solid ${saleBadgeBorderColor}`,
              }}
            >
              {saleBadgeText || `${saleBadge}% off`}
            </Badge>
          )}
        </Flex>

        {swatches && showSwatches && selectedVariant && (
          <SwatchesContainer
            swatches={swatches}
            selectedVariant={selectedVariant}
            setProductFromColorSelector={setProductFromColorSelector}
            setVariantFromColorSelector={setVariantFromColorSelector}
            colorGroupsMap={colorGroupsMap}
            initialProduct={initialProduct}
          />
        )}
      </Flex>
    );
  }
);

Details.displayName = 'Details';
