import { Box, Flex, Label, Switch as BasicSwitch } from 'theme-ui';

import { themed } from './Switch.theme';

export const Switch = themed(
  ({ theme, leftLabel, rightLabel, onCheck, isSelected = false }) => {
    return (
      <Flex
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          ml: '10px',
          mt: '10px',
        }}
      >
        {leftLabel && <Label sx={theme.label}>{leftLabel}</Label>}
        <Box>
          <BasicSwitch
            checked={isSelected}
            onChange={onCheck}
            sx={theme.switcher}
          />
        </Box>
        {rightLabel && <Label sx={theme.label}>{rightLabel}</Label>}
      </Flex>
    );
  }
);
