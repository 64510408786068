const TEXT = '#0C0C0C';
const BACKGROUND = '#FFF';

export const colors = {
  // Required
  black: '#000',
  white: '#FFFFFF',

  transparent: 'transparent',

  text: TEXT,
  background: BACKGROUND,
  background_medium: '#F4F5F7',

  blue: '#3CADD4',
  red: '#da3832',
  orange: '#E2592D',
  yellow: '#FFEF03',

  gray: {
    dark: '#727272',
    medium: '#939393',
    mediumLight: '#E6E6E6',
    light: '#F2F2F2',
  },
};
