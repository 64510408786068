import { useCallback, useEffect, useState } from 'react';

// e.g. const isDesktopViewport = useMatchMedia('(min-width: 768px)');
// NOTE: do not use match media to dictate the layout or visual elements between breakpoints, as it will cause hydration errors or layout shift and flashing of changing content with SSG upon hydration
// INSTEAD: use Theme UI breakpoint styling to avoid layout shift with SSG

function fallbackMatchMedia(query) {
  if (
    typeof window === 'undefined' ||
    typeof window.matchMedia !== 'function'
  ) {
    return null;
  }
  return window.matchMedia(query);
}

function omitMatchMediaResult(matchMediaResult) {
  if (!matchMediaResult) {
    return null;
  }
  return { media: matchMediaResult.media, matches: matchMediaResult.matches };
}

function useMedia(query) {
  const [mounted, setMounted] = useState(false);

  const [result, setResult] = useState(() => {
    return omitMatchMediaResult(fallbackMatchMedia(query));
  });

  const callback = useCallback((matchMediaResult) => {
    return setResult(omitMatchMediaResult(matchMediaResult));
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const matchMediaResult = fallbackMatchMedia(query);
    callback(matchMediaResult);
    if (matchMediaResult) {
      matchMediaResult.addEventListener('change', callback);
    }
    return () => {
      if (matchMediaResult) {
        matchMediaResult.removeEventListener('change', callback);
      }
    };
  }, [query]);

  if (!mounted) {
    return null;
  }

  return result;
}

export function useMatchMedia(query) {
  const result = useMedia(query);
  return (result && result.matches) || false;
}
