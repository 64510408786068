import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    (this.sidebar = {
      item: {
        variant: 'flex.row.between.center',
        gridTemplateColumns: '104px .66fr .33fr',
        alignItems: 'stretch',
        overflow: 'hidden',
        py: 8,
        width: '100%',
        px: 10,

        between: {
          borderBottom: (t) => `1px solid ${colors.gray.mediumLight}`,
        },

        last: {
          borderBottom: 'none',
        },
      },

      column: {
        left: {
          variant: 'flex.column.start.start',
        },

        // center column  — title, quantity etc..
        center: {
          variant: 'flex.column.start.center',
          flex: 1,
        },

        // center column  — title, quantity etc..
        right: {
          variant: 'flex.column.end.start',
        },
      },

      // elements
      picture: {
        cursor: 'pointer',
        width: '90px',
        objectFit: 'contain',
        mb: 'auto',
        mr: 9,
      },

      title: {
        product: {
          variant: 'text.base',
          fontWeight: 700,
          cursor: 'pointer',
          textAlign: 'left',
        },

        variant: {
          variant: 'text.sm',
          color: 'gray.dark',
        },

        plan: {
          variant: 'text.xs',
        },
      },

      remove: {
        cursor: 'pointer',
        width: '16px',
        height: '16px',
      },
      showDiscount: {
        variant: 'text.sm',
        color: `${colors.gray.medium}`,
        px: 10,
        pb: 5,
      },
    }),
      // cart empty — rendered in /cart
      (this.page = {
        item: {
          ...this.sidebar.item,
          gridTemplateColumns: '150px .66fr .33fr',

          between: {
            ...this.sidebar.item.between,
            py: 12,
          },
          last: {
            ...this.sidebar.item.last,
          },
        },

        // left column  — picture..
        column: {
          left: {
            ...this.sidebar.column.left,
          },

          center: {
            ...this.sidebar.column.center,
          },

          right: {
            ...this.sidebar.column.right,
          },
        },

        // elements
        picture: {
          ...this.sidebar.picture,
          width: '120px',
          mr: 12,
        },

        title: {
          product: {
            variant: 'text.sm-base',
            fontWeight: 600,
            mb: 1,
            cursor: 'pointer',
            textAlign: 'left',
          },

          variant: {
            variant: 'text.xs-sm',
          },

          plan: {
            variant: 'text.xs-sm',
          },
        },

        remove: {
          ...this.sidebar.remove,
        },
      });
  })()
);
