import { Flex, Heading } from 'theme-ui';

import { themed } from './ContentIcons.theme';

export const ContentIcons = themed(({ theme, icons = [] }) => (
  <Flex
    sx={{
      ...theme.wrapper,
      flexDirection: 'column',
      maxHeight: ['48px', '60px'],
      overflow: 'hidden',
    }}
  >
    {icons &&
      icons.slice(0, 3).map((item, index) => (
        <Heading key={index} sx={theme.text}>
          {item?.text}
        </Heading>
      ))}
  </Flex>
));
