import React from 'react';
import { themed } from './AmbassadorSlider.theme';
import { Picture } from '@snippets';
import { Flex, Paragraph, Text, Box } from 'theme-ui';

export const AmbassadorSlide = themed(({ theme, slide }) => {
  const { image, heading, name, text } = slide;
  return (
    <Flex sx={theme.slide}>
      <Box sx={theme.slideLeft}>
        <Picture
          images={[
            {
              src: image,
              ratio: 1,
            },
          ]}
          sx={theme.slideImage}
        ></Picture>
      </Box>
      <Flex variant="flex.column.start.center" sx={theme.slideContent}>
        {heading && <Paragraph variant="text.h6">{heading}</Paragraph>}
        <Paragraph variant="text.h4">{name}</Paragraph>
        <Text variant="text.base">{text}</Text>
      </Flex>
    </Flex>
  );
});
