import { useCallback } from 'react';
import store, {
  useGotoRecoilSnapshot,
  useRecoilValue,
  useRecoilCallback,
} from '@store';

export const useSidebar = () => {
  const sidebar = useRecoilValue(store.sidebar);
  const gotoSnapshot = useGotoRecoilSnapshot();

  const closeSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      // animate Out
      // eg — await animations.hideDrawer();

      const updatedState = snapshot
        .map(({ set }) => set(store.sidebar, null))
        .map(({ set }) => set(store.overlay, false));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const openSidebar = useRecoilCallback(({ snapshot }) => async (type) => {
    const release = snapshot.retain();

    if (!type) {
      console.warn('A sidebar type not provided. Must be "cart" or "search"');
      release();
      return;
    }

    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.sidebar, type || null))
        .map(({ set }) => set(store.overlay, true));

      // update state
      gotoSnapshot(updatedState);

      // animateIn
      // eg — await animations.revealDrawer();
    } finally {
      release();
    }
  });

  const openCartSidebar = useCallback(() => {
    openSidebar('cart');
  }, []);

  const openSearchSidebar = useCallback(() => {
    openSidebar('search');
  }, []);
  const openFilterSidebar = useCallback(() => {
    openSidebar('filter');
  }, []);

  const closeSidebarToNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot.map(({ set }) => {
            set(store.sidebar, null);
            set(store.overlay, false);
            set(store.selectedItem, false);
            set(store.menuSidebar, false);
            set(store.modal, null);
            set(store.modalProps, {});
          });

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      sidebar,
      isCart: sidebar === 'cart',
      isSearch: sidebar === 'search',
      isFilter: sidebar === 'filter',
    },
    // actions
    {
      closeSidebar,
      openSidebar,
      openCartSidebar,
      openSearchSidebar,
      openFilterSidebar,
      closeSidebarToNavigate,
    },
  ];
};
