import { useRef, useState } from 'react';
import { Flex, Box } from 'theme-ui';

import { Link, Countdown } from '@snippets';

import { useDate } from '@hooks/useDate';
import { PromoBarMessageCopy } from './PromoBarMessageCopy';
import { themed } from './Promo.theme';

export const PromoBarMessage = themed(
  ({
    theme,
    link,
    copy,
    color,
    background,
    align,
    isActive,
    index,
    dateTimes = {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      enabled: false,
    },
    ...props
  }) => {
    const copyRef = useRef();

    const { startDate, startTime, endDate, endTime, enabled } = dateTimes;
    const formattedDates = useDate({ startDate, startTime, endDate, endTime });
    const [status, setStatus] = useState('live');

    return (
      <Flex data-comp={PromoBarMessage.displayName} sx={theme.message}>
        <Box
          data-comp="PromoBarMessageCopyWrapper"
          ref={copyRef}
          sx={theme.message.copy}
        >
          {link?.url ? (
            <Link href={link.url} prefetch={false}>
              <PromoBarMessageCopy copy={copy} align={align} />
            </Link>
          ) : (
            <PromoBarMessageCopy copy={copy} align={align} />
          )}
        </Box>
        {enabled && formattedDates && (
          <Countdown
            startTime={formattedDates?.start}
            endTime={formattedDates?.end}
            status={status}
            style={{
              fontSize: '12px',
              letterSpacing: '.08em',
              display: 'inline',
              ml: 2,
            }}
            actions={{
              setEnded: () => {
                setStatus('ended');
              },
              setAwaiting: () => {
                setStatus('awaiting');
              },
              setLive: () => {
                setStatus('live');
              },
            }}
          />
        )}
      </Flex>
    );
  }
);

PromoBarMessage.displayName = 'PromoBarMessage';
