import { useEffect, useCallback } from 'react';
import store, { useRecoilValueLoadable } from '@store';

import { useProduct } from '@backpackjs/storefront';

const defaultProps = {
  legacyResourceId: null,
  limit: 3,
};

export const useYotpoProductReviewHighlights = ({
  legacyResourceId: passedId,
  limit,
} = defaultProps) => {
  const product = useProduct();
  const legacyResourceId = passedId || product?.legacyResourceId;

  const { state, contents } = useRecoilValueLoadable(
    store.getYotpoProductReviews$({
      legacyResourceId,
      per_page: limit,
      page: 1,
      sort: 'rating',
      direction: 'desc',
      star: 5,
    })
  );
  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',
    reviews: (state === 'hasValue' && contents?.reviews) || null,
  };
};
