import { Spinner } from 'theme-ui';

import { Locale } from '@snippets';

import { Amount } from './Amount';
import { themed } from './Price.theme';

export const Price = themed(({ theme, selectedVariant, useBlackColor }) => {
  if (!selectedVariant) return null;
  return (
    <Locale.Variant variant={selectedVariant} sx={theme.wrapper}>
      <Locale.Price>
        {(props) => <Amount useBlackColor={useBlackColor} {...props} />}
      </Locale.Price>

      <Locale.Loader>
        <Spinner sx={theme.loader} />
      </Locale.Loader>

      <Locale.Error>
        {({ errors }) => errors?.join(',') || 'Price error'}
      </Locale.Error>
    </Locale.Variant>
  );
});
