import { useCallback, useEffect, useState } from 'react';
import store, { useRecoilValue, useSetRecoilState } from '@store';

export const useGeolocation = () => {
  const [userHeaders, setUserHeaders] = useState();

  const currentCountry = useRecoilValue(store.country);
  const setCurrentCountry = useSetRecoilState(store.country);

  const getHeadersLocation = useCallback(async () => {
    try {
      const response = await fetch('/api/geolocation');
      const data = await response.json();
      return data || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const getUserCountry = useCallback(async () => {
    const data = await getHeadersLocation();
    setUserHeaders({ isoCode: data?.country });
    if (!currentCountry?.name) {
      setCurrentCountry({ isoCode: data?.country });
    }
  }, []);

  useEffect(() => {
    getUserCountry();
  }, []);

  return {
    userHeaders,
    currentCountry,
  };
};
