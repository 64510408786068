import React from 'react';
import { Box, Container, Flex, Heading, Spinner, Text } from 'theme-ui';
import { useSettings, useProductItemByHandle } from '@backpackjs/storefront';
import { Markdown, Picture, Locale, Link } from '@snippets';
import { themed } from './MembershipPopup.theme';
import { AddToCart } from '../../../templates/product/Main/Details/AddToCart';

export const MembershipPopup = themed(({ theme, closeModal }) => {
  const settings = useSettings();
  const { productItem: product } = useProductItemByHandle({
    handle: 'vip-membership',
  });

  const {
    heading = 'the perks of being a member',
    perksList,
    linkHeading,
    link,
    description,
  } = { ...settings?.product?.vipMembership };

  if (!product) return null;

  const { images, title, variants } = { ...product };
  const selectedVariant = variants?.[0];
  const onClick = (e) => e.target.tagName === 'A' && closeModal();
  return (
    <Container sx={theme.container} onClick={onClick}>
      <Flex sx={theme.wrap}>
        <Picture
          images={[
            {
              src: images?.[0].src,
              ratio: 1,
            },
          ]}
          sx={theme.image}
        />
        <Flex sx={theme.content}>
          <Flex sx={theme.top}>
            <Heading sx={theme.title}>{title}</Heading>
            <Locale.Variant variant={selectedVariant}>
              <Locale.Price>
                {({ priceV2 }) => (
                  <Text sx={theme.price}>{priceV2.locale}</Text>
                )}
              </Locale.Price>
            </Locale.Variant>
          </Flex>
          <Heading sx={theme.heading}>{heading}</Heading>
          <Markdown text={perksList} sx={theme.markdown} />
          <AddToCart
            product={product}
            selectedVariant={selectedVariant}
            attributes={[{ key: '_recharge', value: 'true' }]}
          />

          <Box sx={theme.bottom}>
            <Text variant="text.h6">{linkHeading}</Text>
            <Link href={link.url} sx={theme.link}>
              {link.text}
            </Link>
            <Text variant="text.sm" sx={theme.desc}>
              {description}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
});

MembershipPopup.defaultProps = {
  closeModal: () => {},
};
