import { swatches } from './swatches';
import { fits } from './fits';
import { sizeGuides } from './sizeGuides';
import { shipping } from './shipping';
import { backInStock } from './backInStock';
import { vipMembership } from './vipMembership';
import { colorsOrder } from './colorsGroup';
import { stickyCTA } from './stickyCTA';
import { preOrder } from './preOrder';

import { vipModal } from './vipModal';
import { vipProductBanner } from './vipProductBanner';
import { onSaleBadge } from './saleBadge';

export default {
  label: 'Product Settings',
  description:
    'Manage product swatches, fits and other global product settings',
  name: 'product',
  component: 'group',
  fields: [
    {
      name: 'narVarShipScript',
      label: 'Show / Hide Narvar Shipping Script',
      component: 'toggle',
      description: 'Show / Hide Narvar Shipping Script',
      toggleLabels: {
        true: 'use Script',
        false: 'Hide Script',
      },
      defaultValue: true,
    },
    stickyCTA,
    backInStock,
    vipProductBanner,
    vipModal,
    vipMembership,
    shipping,
    preOrder,
    onSaleBadge,
    fits,
    sizeGuides,
    colorsOrder,
    swatches,
  ],
  defaultValue: {
    swatches: [
      { name: 'Adobe', color: '#d8835c' },
      { name: 'Anchor', color: '#504a48' },
      { name: 'Aqua Velvet', color: '#c7f8ff' },
      { name: 'Arctic Haze', color: '#bac2bd' },
      { name: 'Army Olive', color: '#887a49' },
      { name: 'Black', color: '#000000' },
      { name: 'Black Forest', color: '#0a2e21' },
      { name: 'Black Wash', color: '#444444' },
      { name: 'Blue Moon', color: '#162f5a' },
      { name: 'Blue Night', color: '#343645' },
      { name: 'Blush', color: '#efc1b3' },
      { name: 'Bonsai', color: '#3a514b' },
      { name: 'Bonsai Wash', color: '#3a514b' },
      { name: 'Brown Spruce', color: '#866b57' },
      { name: 'Burnt Pink', color: '#a05450' },
      { name: 'Burr Blue', color: '#627782' },
      { name: 'Cabernet', color: '#791a3e' },
      { name: 'Canyon', color: '#716355' },
      { name: 'Cast Iron', color: '#514c59' },
      { name: 'Charcoal', color: '#565c5d' },
      { name: 'Chateau', color: '#9f6734' },
      { name: 'Coast', color: '#c5ad8a' },
      { name: 'Coastal Fog', color: '#eae4d4' },
      { name: 'Concrete', color: '#c0b1b4' },
      { name: 'Coral', color: '#f1cfc3' },
      { name: 'Coyote', color: '#8a8774' },
      { name: 'Cream', color: '#ffe6d0' },
      { name: 'Dark Pine', color: '#4a544a' },
      { name: 'Deep Amber', color: '#44282d' },
      { name: 'Deep Amber Wash', color: '#44282d' },
      { name: 'Deep Forest', color: '#2a4853' },
      { name: 'Desert Palm', color: '#d6c99e' },
      { name: 'Dusk', color: '#e2dae9' },
      { name: 'Dusk Wash', color: '#dac4f0' },
      { name: 'Egg Shell', color: '#ebe4d4' },
      { name: 'Fig Leaf', color: '#5d6351' },
      { name: 'Fjord', color: '#394e64' },
      { name: 'Flint', color: '#b4a6b4' },
      { name: 'Fossil', color: '#b0a097' },
      { name: 'Glacier', color: '#d1dae0' },
      { name: 'Golden Harvest', color: '#edbf6d' },
      { name: 'Granite', color: '#b1afb4' },
      { name: 'Gray', color: '#333333' },
      { name: 'Green', color: '#4f6345' },
      { name: 'Grey', color: '#333333' },
      { name: 'Heather Black', color: '#878692' },
      { name: 'Heather Bonsai', color: '#757b7b' },
      { name: 'Heather Cabernet', color: '#b48583' },
      { name: 'Heather Cast Iron', color: '#c0c0c0' },
      { name: 'Heather Gray', color: '#484b54' },
      { name: 'Heather Green', color: '#8da593' },
      { name: 'Heather Grey', color: '#81848d' },
      { name: 'Heather River', color: '#acb7c0' },
      { name: 'Heather Sage', color: '#a3a8a3' },
      { name: 'Heather Winter Solstice', color: '#e8c8c0' },
      { name: 'Honeycomb', color: '#bb8118' },
      { name: 'Ivory', color: '#f6f4dd' },
      { name: 'Ivory Tie Dye', color: '#d8cbbb' },
      { name: 'Laurel Oak', color: '#8a8369' },
      { name: 'Martini Olive', color: '#887a49' },
      { name: 'Melon', color: '#f6ffb3' },
      { name: 'Military Moss', color: '#69542e' },
      { name: 'Mirage', color: '#b8c7d2' },
      { name: 'Moonlight', color: '#e1d4c5' },
      { name: 'Moscato', color: '#ffd1b3' },
      { name: 'Mountain Mist', color: '#a98f87' },
      { name: 'Mountain Mist Wash', color: '#a98f87' },
      { name: 'Mulberry', color: '#925d56' },
      { name: 'Night Owl', color: '#283143' },
      { name: 'Nimbus', color: '#ded6ce' },
      { name: 'Nimbus Tie Dye', color: '#d6d6d6' },
      { name: 'Nine Iron', color: '#5a4e5a' },
      { name: 'Obsidian', color: '#3f3f47' },
      { name: 'Ocean Storm', color: '#5d6479' },
      { name: 'Oxy Drive', color: '#fb4530' },
      { name: 'Pacific Blue', color: '#363c4f' },
      { name: 'Pacific Blue Tie Dye', color: '#191461' },
      { name: 'Pacific Mist', color: '#c4d4cf' },
      { name: 'Pacific Mist Wash', color: '#d7f4e4' },
      { name: 'Paloma', color: '#e0a79f' },
      { name: 'Pine', color: '#5e6655' },
      { name: 'Pine Wash', color: '#5e6655' },
      { name: 'Pink', color: '#ffe9dc' },
      { name: 'Plum', color: '#6c3947' },
      { name: 'Polar', color: '#e7e3d2' },
      { name: 'Port', color: '#7f6066' },
      { name: 'Purple', color: '#b290bd' },
      { name: 'Rally Blue', color: '#3cadd4' },
      { name: 'Red', color: '#af4952' },
      { name: 'Red Pear', color: '#973a47' },
      { name: 'Red Rock', color: '#a36050' },
      { name: 'Red Rum', color: '#7e4747' },
      { name: 'Redwood', color: '#c96469' },
      { name: 'River', color: '#92abbc' },
      { name: 'River Tie Dye', color: '#a6d5ec' },
      { name: 'Roasted Cashew', color: '#d7c5b7' },
      { name: 'Saddle', color: '#a38569' },
      { name: 'Sage', color: '#7e8680' },
      { name: 'Sage Wash', color: '#7e8680' },
      { name: 'Salt', color: '#fbfbe3' },
      { name: 'Shark', color: '#acb2b4' },
      { name: 'Silver Sand', color: '#e6decc' },
      { name: 'Sky', color: '#b4cedd' },
      { name: 'Slate', color: '#605758' },
      { name: 'Smoke', color: '#c0b7a0' },
      { name: 'Spruce', color: '#548776' },
      { name: 'Stone Gray', color: '#c8c6c7' },
      { name: 'Stone Grey', color: '#c8c6c7' },
      { name: 'Stratus', color: '#b3bdc8' },
      { name: 'Sunset', color: '#e59678' },
      { name: 'Sweet Basil', color: '#7c7c63' },
      { name: 'Vintage', color: '#556373' },
      { name: 'White', color: '#ffffff' },
      { name: 'Willow', color: '#ced6c1' },
      { name: 'Willow Wash', color: '#e7f5df' },
      { name: 'Winter Solstice', color: '#e8c8c0' },
    ],
  },
};
