import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      variant: 'buttons.plain',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: ['15px'],
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      backgroundColor: 'white',
      borderRadius: '50%',
      width: '40px',
      height: ' 40px',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.18)',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: ['15px'],
    };

    this.icon = {
      width: '16px',
      margin: '0 auto',
      userSelect: 'none',
    };
  })()
);
