import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      margin: 0,
      mt: '12px',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      columnGap: '6px',
      rowGap: [4, 3],
      mb: '36px',
    };
  })()
);
