import { Picture } from '@snippets';

export const ShopifyImage = ({ alt, src, ratio = 0.75, id, ...props }) => {
  return (
    <Picture
      alt={alt || 'Product image'}
      key={id}
      images={[
        { src, ratio, width: 768 },
        { src, ratio, width: 1024 },
        { src, ratio, width: 768 },
      ]}
    />
  );
};

/*
  Can be used to size a src directly or inline.
  for example: sizing a src for a background image:
    ->  url(sizeShopifySrc(src, {width: 800 }))
*/
// export const sizeShopifySrc = _sizeShopifySrc

/**
<ShopifyImage
  src={image ? image : null}
  ratio={1200/640}
  objectFit='cover'
  fluid
  sizes='(max-width: 640px) 300px, (max-width: 1023px) 1023px, (min-width: 1024px) 1000px'
  layout='fill'
  sx={{
    width: '100%'
  }}
/>
 */
