import React from 'react';
import { themed } from './Button.theme';
import { Flex } from 'theme-ui';
import { Link, Section } from '@snippets';
import { convertAlignToFlex } from '@utils/common';
import { useBreakpointValue } from '@hooks';
import { Schema } from './Button.schema';
import { withInView } from '@utils';

export const Button = withInView(
  themed(({ theme, cms, inView }) => {
    const { button, section } = cms;
    const { cta, ctaStyle } = button;
    const textAlign = useBreakpointValue([
      button?.textAlign,
      button?.textAlignDt,
    ]);
    return (
      <Section section={section}>
        <Flex
          sx={{
            ...theme.wrap,
            justifyContent: convertAlignToFlex(textAlign),
          }}
        >
          <Link href={cta.url} variant={`buttons.${ctaStyle || 'primary'}`}>
            {cta.text}
          </Link>
        </Flex>
      </Section>
    );
  })
);

Button.displayName = 'Button';
Button.Schema = Schema;
