import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.medias = {
      gridTemplateColumns: '1fr',
      sticky: {
        alignSelf: 'flex-start',
        justifySelf: 'flex-end',
        width: '100%',
        wrapper: {
          variant: 'flex.column',
        },
      },
    };

    this.swiper = {
      display: [null, null, 'none'],
      width: '100%',
    };

    this.slide = {
      m: 0,
      outline: 'none',
    };

    this.badgeWrapperStyle = {
      minWidth: [180, 200],
      display: 'flex',
      justifyContent: 'space-between',
      bottom: [0, null],
      top: [null, 5],
      height: 'fit-content',
      left: 5,
    };
  })()
);
