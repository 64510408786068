export const Schema = ({ page, blog, article, product }) => {
  if (page?.resourceType === 'home_page' || blog || article || product)
    return null;
  return {
    label: 'Collection Grid',
    key: 'collection-grid',
    component: 'group',
    fields: [
      {
        label: 'Enable View Per Row Override',
        name: 'enableViewPerRowOverride',
        description: 'Overrides View Per Row site settings',
        component: 'toggle',
        toggleLabels: {
          true: true,
          false: false,
        },
        defaultValue: true,
      },
      {
        label: 'View Per Row (Desktop)',
        name: 'viewPerRowDesktop',
        description: 'Sets number of grid items per row on desktop',
        component: 'radio-group',
        options: [
          {
            label: '3',
            value: 3,
          },
          {
            label: '5',
            value: 5,
          },
        ],
        defaultValue: 3,
      },
      {
        label: 'View Per Row (Mobile)',
        name: 'viewPerRowMobile',
        description: 'Sets number of grid items per row on mobile',
        component: 'radio-group',
        options: [
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
        ],
        defaultValue: 2,
      },
    ],
  };
};
