import { section } from '@settings/common';

export const Schema = ({ page }) => {
  if (page?.handle !== 'track-my-order') return null;

  return {
    label: 'Narvar Tracking',
    key: 'narvar-tracking',
    fields: [
      section,
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Track My Order',
      },
      {
        label: 'Sub Heading',
        name: 'subHeading',
        component: 'textarea',
        defaultValue:
          'Enter your order number in the form below to get the most up to date tracking information.',
      },
      {
        label: 'Field Label',
        name: 'fieldLabel',
        component: 'text',
        defaultValue: 'Enter Order Number',
      },
      {
        label: 'Button Text',
        name: 'buttonText',
        component: 'text',
        defaultValue: 'Track Order',
      },
    ],
  };
};
