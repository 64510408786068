import { useMemo, Children, cloneElement } from 'react';
import { useLocalizedCartLine } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { formatAmountItem } from './formatAmountItem';

export const LocaleCartLine = ({ line, children, ...props }) => {
  if (!line) {
    return null;
  }

  const { localized } = useLocalizedCartLine({ line: { ...line } });

  const Components = useMemo(() => {
    let PriceComp = null;
    let LoaderComp = null;
    let ErrorComp = null;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Locale.Price': {
          const quantity = localized?.quantity;

          const discountAmount = formatAmountItem(
            localized?.estimatedCost?.discountAmount,
            quantity,
            localized?.locale?.defaultLocale
          );
          const savingsAmount = formatAmountItem(
            localized?.estimatedCost?.savingsAmount,
            quantity,
            localized?.locale?.defaultLocale
          );

          const isOnSale = savingsAmount?.amount > 0 || false;
          const isSubscription = localized?.sellingPlanAllocation !== null;
          const isDiscounted = discountAmount?.amount > 0;

          PriceComp = localized
            ? cloneElement(child, {
              key: 'Locale.Price',
              estimatedCost: {
                ...localized.estimatedCost,
                totalAmount: formatAmountItem(
                  localized?.estimatedCost?.totalAmount,
                  quantity,
                  localized?.locale?.defaultLocale
                ),
                compareAtPriceAmount: formatAmountItem(
                  {
                    ...localized?.estimatedCost?.compareAtPriceAmount,
                    amount:
                      localized?.estimatedCost?.compareAtPriceAmount
                        ?.amountTotal,
                  },
                  quantity,
                  localized?.locale?.defaultLocale
                ),
                subtotalAmount: formatAmountItem(
                  localized?.estimatedCost?.subtotalAmount,
                  quantity,
                  localized?.locale?.defaultLocale
                ),
                discountAmount,
                savingsAmount,
              },
              discountAllocations: localized?.discountAllocations,
              sellingPlanAllocation: isSubscription
                ? localized.sellingPlanAllocation
                : null,
              locale: localized.locale,
              isOnSale,
              isSubscription,
              isDiscounted,
              ...child.props,
            })
            : null;
          break;
        }

        case 'Locale.Error':
          ErrorComp = null;
          break;

        case 'Locale.Loader':
          LoaderComp = null;
          break;

        default:
          break;
      }
    });

    // active components
    return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
  }, [
    localized?.estimatedCost?.subtotalAmount,
    localized?.sellingPlanAllocation,
    localized?.discountAllocations?.length,
  ]);

  return (
    <Box data-comp={LocaleCartLine.displayName} {...props} sx={{ ...props.sx }}>
      {Components.map((Component) => Component)}
    </Box>
  );
};

LocaleCartLine.displayName = 'Locale.CartLine';
