import { Box, Flex, Text, Image, Link } from 'theme-ui';
import { themed } from './FridayProjects.theme';
import { Svg } from '@snippets';
import { position } from '@settings/common';

export const FridayProjectsHeader = themed(({ theme, ...props }) => {
  return (
    <Box>
      <Flex
        sx={{
          bg: '#FFFFFF',
          height: ['52px', '64px'],
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <Flex>
          <Link href="/">
            <Svg
              alt="Cuts Logo"
              viewBox="0 0 61 20"
              src="/svgs/logo-friday-projects-2.svg"
              sx={{ height: '30px', ml: '20px', mr: '20px' }}
            />
          </Link>
        </Flex>

        <Image
          sx={{
            width: [140, 169],
            position: 'absolute',
            left: 0,
            right: 0,
            m: 'auto',
          }}
          src="/images/friday-projects-logo.png"
        />
      </Flex>
    </Box>
  );
});
