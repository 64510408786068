// todo expose on @backpackjs/storefront

import animation from '@animations';
import store, {
  useRecoilCallback,
  useRecoilValue,
  useGotoRecoilSnapshot,
} from '@store';

export const useMenuSidebar = () => {
  // @refresh reset
  const gotoSnapshot = useGotoRecoilSnapshot();
  const menuSidebar = useRecoilValue(store.menuSidebar);
  const selectedItem = useRecoilValue(store.selectedItem);
  const selectedIndex = useRecoilValue(store.selectedIndex);

  // on click, close the sideBar and navigate to url
  const closeSideBarAndNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          animation.sidebarContentOut();
          animation.sideBarOut();

          const updatedState = snapshot
            .map(({ set }) => set(store.selectedItem, false))
            .map(({ set }) => set(store.menuSidebar, false));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  const closeSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();

    try {
      animation.sidebarContentOut();
      animation.sideBarOut();

      const updatedState = snapshot
        .map(({ set }) => set(store.selectedItem, false))
        .map(({ set }) => set(store.menuSidebar, false));

      // update state
      gotoSnapshot(updatedState);
    } catch (error) {
      console.error('closeMenuSidebar', error.message);
      throw error;
    } finally {
      release();
    }
  });

  const toggleNestedSidebar = useRecoilCallback(
    ({ snapshot }) =>
      async (selected) => {
        const release = snapshot.retain();

        const selectors = {
          menuSidebar: `[data-comp="MenuSidebar"]`,
          selectedAccordion: null, // will be set on toggle of a selectedIndex
          firstAccordion: `[data-comp="AccordionNavItem"]:nth-child(1)`,
          unSelectedAccordions: `[data-comp='AccordionNavItem']:not(.selected)`,
          nestedSidebar: `[data-comp='SubNavSidebar']`,
          menuLinks: `[data-comp='MenuLinksLink']`,
        };

        try {
          let nextState;
          const prevSelectedIndex = snapshot.getLoadable(
            store.selectedIndex
          ).contents;
          const isOpening = selected?.item;

          if (isOpening) {
            const selectedIndex = selected.index + 1;

            selectors.selectedAccordion = `[data-comp='AccordionNavItem']:nth-child(${selectedIndex})`;

            // mark item as selected
            // await animation.nestedSidebarIn(selectors);

            nextState = snapshot
              .map(({ set }) => set(store.selectedIndex, selectedIndex))
              .map(({ set }) => set(store.selectedItem, selected.item));

            gotoSnapshot(nextState);
          } else {
            // isClosing
            selectors.selectedAccordion = `[data-comp='AccordionNavItem']:nth-child(${prevSelectedIndex})`;

            nextState = snapshot
              .map(({ set }) => set(store.selectedIndex, 0))
              .map(({ set }) => set(store.selectedItem, null));

            gotoSnapshot(nextState);

            animation.nestedSidebarOut(selectors);
          }
        } finally {
          release();
        }
      }
  );

  const toggleSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      let nextState;
      const prevMenuSidebar = snapshot.getLoadable(store.menuSidebar).contents;

      const isOpening = prevMenuSidebar === false;
      if (isOpening) {
        // open the drawer
        animation.sidebarIn();

        // isOpening
        nextState = snapshot.map(({ set }) => set(store.menuSidebar, true));

        // update state
        gotoSnapshot(nextState);
      } else {
        animation.sidebarContentOut();

        nextState = snapshot
          // reset selected item
          // close sidebar
          .map(({ set }) => set(store.selectedIndex, 0))
          .map(({ set }) => set(store.selectedItem, null))
          .map(({ set }) => set(store.menuSidebar, false));

        // update state
        gotoSnapshot(nextState);

        // close the drawer
        animation.sideBarOut();
      }
    } finally {
      release();
    }
  });

  return [
    // state
    {
      menuSidebar,
      menuSidebarNested: Boolean(selectedItem),
      selectedItem,
      selectedIndex,
    },
    // actions
    {
      toggleSidebar,
      toggleNestedSidebar,
      closeSidebar,
      closeSideBarAndNavigate,
    },
  ];
};
