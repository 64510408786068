import { Button } from 'theme-ui';

import { Svg } from '@snippets';

import { useMenuSidebar } from '../../MenuSidebar';
import { themed } from '../Menu.theme';

export const CtaMenu = themed(({ theme, transparentBGEnabled, ...props }) => {
  const [{ menuSidebar }, { toggleSidebar }] = useMenuSidebar();

  return (
    <Button
      data-comp={CtaMenu.displayName}
      {...props}
      onClick={toggleSidebar}
      variant="buttons.plain"
      aria-label={menuSidebar ? 'Close menu sidebar' : 'Open menu sidebar'}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      {menuSidebar ? (
        <Svg
          alt="Close menu"
          src="/svgs/close.svg#close"
          sx={theme.cta.icon}
          viewBox="0 0 48 48"
        />
      ) : (
        <Svg
          alt="Open menu"
          src="/svgs/cta/menu.svg#menu"
          sx={{
            ...theme.cta.icon,
            color: transparentBGEnabled ? 'white' : 'black',
            fill: transparentBGEnabled ? 'white' : 'black',
            stroke: transparentBGEnabled ? 'white' : 'black',
          }}
          viewBox="0 0 48 48"
        />
      )}
    </Button>
  );
});

CtaMenu.displayName = 'CtaMenu';
