import { useState, useEffect } from 'react';
import { Container, Flex, Box, Paragraph, Spinner } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { useKitProducts } from '../useKitProducts';
import { useKitSizes } from '../useKitSizes';
import { Timer } from './Timer';
import { Products } from './Products';
import { Options } from './Options';
import { AddToCart } from './AddToCart/AddToCart';
import { themed } from './Details.theme';
import { Header } from './Header';

export const Details = themed(({ theme, cms }) => {
  const settings = useSettings();
  const { productsByType, products } = useKitProducts({
    products: cms.products?.list,
  });
  const { activeSizesByType, sizeAvailsByType, setActiveSizesByType } =
    useKitSizes({ productsByType, products });

  const [status, setStatus] = useState(null);
  const [activeKit, setActiveKit] = useState(null);

  const setActiveKitOnSizeChange = () => {
    if (!productsByType || !activeSizesByType) return;
    let breakIfUndefinedVariant = false;

    const newKitByType = Object.entries(productsByType).reduce(
      (obj, [type, products]) => {
        const selectedProducts = products.map((product) => {
          const variant = product[activeSizesByType[type]];
          if (!variant) breakIfUndefinedVariant = true;
          return variant;
        });
        obj[type] = selectedProducts;
        return obj;
      },
      {}
    );

    if (breakIfUndefinedVariant) {
      setActiveKit(null);
      return;
    }

    const newKit = Object.values(newKitByType).reduce((arr, type) => {
      return [...arr, ...type];
    }, []);

    setActiveKit(newKit);
  };

  useEffect(() => {
    setActiveKitOnSizeChange();
  }, [productsByType, activeSizesByType]);

  return (
    <Container data-comp={Details.displayName} sx={theme.wrapper}>
      <Box data-comp="StickyContainer" sx={theme.sticky}>
        <Flex sx={theme.sticky.wrapper}>
          <Header cms={cms} />

          <Flex sx={theme.ctas}>
            {products?.length ? (
              <>
                <Products products={products} />

                {/* Sizes */}
                <Options
                  activeSizesByType={activeSizesByType}
                  setActiveSizesByType={setActiveSizesByType}
                  sizeAvailsByType={sizeAvailsByType}
                  products={products}
                />
              </>
            ) : (
              <Flex
                variant="flex.row.center.center"
                sx={{
                  minHeight: '400px',
                  width: '100%',
                }}
              >
                <Spinner sx={{ width: '30px', color: 'gray.dark' }} />
              </Flex>
            )}

            <AddToCart
              activeKit={activeKit}
              disabled={
                status !== 'live' || activeKit?.length !== products?.length
              }
            />

            <Timer
              dateTimes={cms.dateTimes}
              status={status}
              setStatus={setStatus}
            />

            {/* Free shipping message */}
            {settings?.product?.shipping?.message ? (
              <Paragraph sx={theme.shipping}>
                {settings.product.shipping.message}
              </Paragraph>
            ) : null}

            <Paragraph sx={theme.description}>{cms.description}</Paragraph>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
});

Details.displayName = 'Details';
