import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';

import { useBreakpointValue } from '@hooks';

import { ProductItem } from '../Content/ProductItem';
import { Navigation } from './Navigation';
import { featuredProductCarouselParams } from './swiperDefaultParams';
import { themed } from './FeaturedSwiper.theme';

import 'swiper/css/pagination';
import 'swiper/css/bundle';

export const FeaturedSwiper = themed(
  ({ theme, products, params = {}, settings, children, ...props }) => {
    const defaultParams = featuredProductCarouselParams;

    const [swiper, setSwiper] = useState();

    const [swiperContentHeight, setSwiperContentHeight] = useState(null);

    const showNavigation = useBreakpointValue([
      null,
      products?.length > 4,
      null,
      products?.length > 4,
    ]);

    const swiperTheme =
      products?.length < 4 && !showNavigation
        ? theme.swiperInitial
        : theme.swiper;

    const hasIcons = products.some((product) => product?.icons?.length > 0);

    useEffect(() => {
      const onResize = () => {
        if (!swiper?.mounted) return;
        const swiperEl = swiper?.$el?.[0];
        const swiperContent = swiperEl.querySelector(
          '[data-comp="Details"]'
        )?.parentNode;
        const height = swiperContent?.getBoundingClientRect().height;
        if (height) {
          setSwiperContentHeight(Math.floor(height));
        }
      };

      swiper?.on('breakpoint', () => {
        onResize();
      });

      onResize();
    }, [swiper]);

    return (
      <Box
        data-comp={FeaturedSwiper.displayName}
        role="list"
        {...props}
        sx={{
          ...swiperTheme,
          ...props.sx,
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          {...defaultParams}
          {...params}
          modules={[Pagination, A11y]}
          a11y={{ enabled: true }}
          loop={products?.length && products?.length > 3}
          pagination={{
            el: '.swiper-pagination-feat',
            type: 'bullets',
            clickable: true,
          }}
          sx={{
            '.swiper-slide': {
              display: 'flex',
              height: 'auto',
              alignSelf: 'stretch',
            },
          }}
        >
          {products?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ProductItem
                  product={item}
                  settings={settings}
                  hasIcons={hasIcons}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Box className="swiper-pagination-feat" sx={theme.bullets} />
        {showNavigation && (
          <Navigation
            swiperContentHeight={swiperContentHeight}
            swiper={swiper}
          />
        )}
      </Box>
    );
  }
);

FeaturedSwiper.displayName = 'FeaturedSwiper';
FeaturedSwiper.propTypes = {
  products: PropTypes.array,
  params: PropTypes.object,
};
