export const requestYotpoLoyalty = async ({ action, data }) => {
  try {
    const url = `/api/yotpo-loyalty`;
    const options = {
      headers: {
        action,
        ...data,
      },
    };
    const request = await fetch(url, options);

    if (!request.ok) {
      throw new Error(`${action} request failed!`);
    }

    const response = await request.json();

    return response;
  } catch (err) {
    console.log(`YotpoLoyalty:${action} failed due`, err.message);
    throw new Error(err);
  }
};
