import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Comparison',
    key: 'comparison',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Cuts Comparison',
      },
      {
        name: 'subheading',
        component: 'markdown',
        label: 'SubHeading',
        description: 'This heading is below the main heading.',
        defaultValue:
          'Define your lock with every Cut. Here’s how each shape can elevate your style.',
      },
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 4 Tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 4,
        },
        defaultItem: {
          heading: 'Classic Curve Hem',
          description:
            '<ul><li><p>Elevated regular bottom cut</p></li><li><p>Curved hem to eliminate bunch around the waist</p></li><li><p>Traditional fitting body</p></li></ul>',
          cta: {
            text: 'Shop Classic Curve Hem',
            url: '/collections/all-shirts',
          },
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/split-hem-transparent4x.png?v=1679965003',
          },
          featureCallout: {
            text: 'Curve-Hem',
            textPosition: 'left',
            textDistancePosition: 80,
            pointerPosition: 97,
          },
        },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            name: 'cta',
            label: 'Link',
            component: 'link',
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            name: 'image',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'featureCallout',
            label: 'Feature Callout Settings',
            component: 'group',
            fields: [
              {
                name: 'text',
                label: 'Text',
                component: 'text',
              },
              {
                name: 'textPosition',
                label: 'Text Position from Pointer',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                description:
                  'Controls placement of text to be either left or right of pointer',
                options: [
                  {
                    value: 'left',
                    label: 'Left',
                  },
                  {
                    value: 'right',
                    label: 'Right',
                  },
                ],
              },
              {
                name: 'textDistancePosition',
                label: 'Distance Between Text and Browser (px)',
                description:
                  'Controls distance between text and browser in pixels',
                component: 'number',
              },
              {
                name: 'pointerPosition',
                label: 'Vertical Position Within Image (%)',
                description: 'Starting from Top to Bottom in percentage',
                component: 'number',
              },
            ],
          },
        ],
        defaultValue: [
          {
            heading: 'Classic Curve Hem',
            description:
              '<ul><li><p>Elevated regular bottom cut</p></li><li><p>Curved hem to eliminate bunch around the waist</p></li><li><p>Traditional fitting body</p></li></ul>',
            cta: {
              text: 'Shop Classic Curve Hem',
              url: '/collections/all-shirts',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/split-hem-transparent4x.png?v=1679965003',
            },
            featureCallout: {
              text: 'Curve-Hem',
              textPosition: 'left',
              textDistancePosition: 80,
              pointerPosition: 97,
            },
          },
          {
            heading: 'Split-Hem',
            description:
              '<ul><li><p>Dynamic functionality lends to a casual yet distinctive look</p></li><li><p>Split-Hem provides more room throughout the body</p></li></ul>',
            cta: {
              text: 'Shop Split-Hem',
              url: '/collections/all-shirts',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/elongated-transparent4x.png?v=1679964959',
            },
            featureCallout: {
              text: 'Split-Hem',
              textPosition: 'right',
              textDistancePosition: 100,
              pointerPosition: 90,
            },
          },
          {
            heading: 'Elongated-Hem',
            description:
              '<ul><li><p>Crafted with the modern man in mind</p></li><li><p>Longer torso: 1.5” longer than Curve-Hem</p></li><li><p>Extended curve demonstrates true class with urban feel</p></li></ul>',
            cta: {
              text: 'Shop Elongated-Hem',
              url: '/collections/all-shirts',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/split-hem-transparent4x.png?v=1679965003',
            },
            featureCallout: {
              text: 'Elongated-Hem',
              textPosition: 'right',
              textDistancePosition: 10,
              pointerPosition: 97,
            },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: '#F2F2F2',
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
