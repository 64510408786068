import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Box } from 'theme-ui';
import { Svg } from '@snippets/Svg';

import { themed } from './PortalModal.theme';

export const PortalModal = themed(
  ({ theme, title, showModal = false, onClose, children, ...props }) => {
    const [isBrowser, setIsBrowser] = useState(false);
    const modalWrapperRef = useRef();

    const handleCloseClick = (e) => {
      e.preventDefault();
      onClose();
    };
    const backDropHandler = e => {
      if (modalWrapperRef?.current?.contains(e.target)) {
        onClose();
      }
    }

    useEffect(() => {
      setIsBrowser(true);
      window.addEventListener('click', backDropHandler);
      return () => window.removeEventListener('click', backDropHandler);
    }, []);

    const modalContent = showModal && (
      <>
        <Box
          ref={modalWrapperRef}
          data-comp="portal-modal-overlay"
          sx={{
            position: 'fixed',
            display: 'block',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9,
            cursor: 'pointer',
          }}
        />
        <Box

          data-comp={PortalModal.displayName}
          sx={{
            ...(showModal ? theme.open : theme.closed),
            ...props.sx,
          }}
        >
          {/* X close modal */}
          <Box
            data-comp={`${PortalModal.displayName}Close`}
            sx={theme.close}
            onClick={handleCloseClick}
          >
            <Svg
              alt="Close modal"
              src="/svgs/close.svg#close"
              viewBox="0 0 48 48"
              sx={theme.icon}
            />
          </Box>

          <Box
            data-comp={`${PortalModal.displayName}Content`}
            sx={{
              ...(showModal ? theme.contentVisible : theme.contentHidden),
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    )

    if (isBrowser) {
      return ReactDOM.createPortal(
        modalContent,
        document.getElementById('modal-root')
      );
    }
    return null;
  }
);

PortalModal.displayName = 'PortalModal';
