import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = ({ stickyTopPosition, transparentBGEnabled }) => ({
      px: 0,
      alignItems: 'center',
      // transition: '0.3s ease-in-out background, 0.3s ease-in-out border-color',
      bg: transparentBGEnabled ? 'transparent' : 'background',
      // boxShadow: 'bottom',
      borderBottom: (t) =>
        transparentBGEnabled
          ? '1px solid transparent'
          : `1px solid ${t.colors.gray.mediumLight}`,
      color: 'text', // sets the color of all nav items and icons
      flexDirection: 'column',
      height: 'auto',
      position: 'sticky',
      top: stickyTopPosition || 0,
      left: 0,
      width: '100%',
      zIndex: 30,
    });
  })()
);
