import { color, section, button, align } from '@settings/common';

export const Schema = ({ article, blog, collection }) => {
  if (article || blog || collection) return null;

  return {
    label: 'Four Tile',
    key: 'four-tile',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 4 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'New Tile',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
          alt: 'Tile image',
          image: {
            src: 'https://images.unsplash.com/photo-1528629297340-d1d466945dc5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
          },
          cta: {
            text: '',
            url: '',
          },
        },
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            heading: 'New Releases',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            cta: {
              text: '',
              url: '/collections/all',
            },
            alt: 'Tile image',
            image: {
              src: 'https://images.unsplash.com/photo-1528629297340-d1d466945dc5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
            },
          },
          {
            heading: 'Top Products',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            alt: 'Tile image',
            image: {
              src: 'https://images.unsplash.com/photo-1571333250176-3c316199994b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
            },
            cta: {
              text: '',
              url: '/collections/all',
            },
          },
          {
            heading: 'Bundles',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            alt: 'Tile image',
            image: {
              src: 'https://images.unsplash.com/photo-1523357585206-175e971f2ad9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=80',
            },
            cta: {
              text: '',
              url: '/collections/all',
            },
          },
          {
            heading: 'Top Products',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            alt: 'Tile image',
            image: {
              src: 'https://images.unsplash.com/photo-1571333250176-3c316199994b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
            },
            cta: {
              text: '',
              url: '/collections/all',
            },
          },
        ],
      },
      {
        label: 'Heading settings',
        name: 'header',
        description: 'Heading, text align, heading button',
        component: 'group',
        fields: [
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            description: "Doesn't apply on desktop if button is in header",
            variant: 'radio',
            name: 'textAlign',
            label: 'Heading alignment',
            options: align.options,
          },
          {
            name: 'ctas',
            label: 'Heading Button',
            component: 'group-list',
            description: 'Max of 1 button',
            itemProps: {
              label: '{{item.cta.text}}',
            },
            validate: {
              maxItems: 1,
            },
            defaultItem: {
              cta: {
                text: "Shop What's Trending",
                url: '/collections/all',
              },
              style: button.value.outline,
            },
            fields: [
              {
                name: 'cta',
                component: 'link',
                label: 'Button',
              },
              {
                name: 'style',
                label: 'Button style',
                component: 'select',
                options: button.options,
              },
            ],
          },
          {
            name: 'showButton',
            label: 'Hide / Show button (mobile)',
            description: 'You can hide the button on mobile',
            component: 'toggle',
            toggleLabels: {
              true: 'Show',
              false: 'Hide',
            },
            defaultValue: true,
          },
        ],
        defaultValue: {
          heading: 'Trending Now',
          textAlign: align.value.left,
          ctas: [
            {
              cta: {
                text: "Shop What's Trending",
                url: '/collections/all',
              },
              style: button.value.outline,
            },
          ],
        },
      },
      {
        label: 'Tile settings',
        name: 'tile',
        description: 'Display, image ratio, text align, link style',
        component: 'group',
        fields: [
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'display',
            label: 'Tile display (mobile)',
            options: [
              { label: 'Slider', value: 'slider' },
              { label: 'Stacked', value: 'stacked' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textPosition',
            label: 'Text position',
            options: [
              { label: 'Contained', value: 'absolute' },
              { label: 'Below', value: 'relative' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            label: 'Text alignment',
            options: align.options,
          },
          {
            name: 'color',
            label: 'Text color',
            component: 'select',
            options: color.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'overlay',
            label: 'Image overlay',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Dark', value: 'dark' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'imageRatio',
            label: 'Image aspect ratio',
            options: [
              { label: '1:1', value: 'square' },
              { label: '3:4', value: 'portrait' },
            ],
          },
        ],
        defaultValue: {
          display: 'slider',
          textPosition: 'relative',
          textAlign: align.value.left,
          color: color.value.text,
          overlay: 'none',
          imageRatio: 'portrait',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
