import { useMemo } from 'react';
import { useThemeUI } from 'theme-ui';

import { useBreakpoint } from './useBreakpoint';

export const useBreakpointValue = (values = []) => {
  const { theme } = useThemeUI();

  if (!values?.length) {
    console.warn(
      'No values provided. Please provide an array of responsive values'
    );
    return null;
  }

  // refresh result memo on value change
  const refresh = values?.map((value) => JSON.stringify(value)).join(',');

  const { s, m_, l_, xl_ } = useBreakpoint();

  return useMemo(() => {
    const [valueS, valueM, valueL, valueXl] = new Array(
      theme.breakpoints.length
    )
      .fill()
      .reduce((_values, _, index) => {
        const bpValue = values[index] || _values[index - 1] || null;
        return [..._values, bpValue];
      }, []);

    return values?.length
      ? xl_
        ? valueXl
        : l_
          ? valueL
          : m_
            ? valueM
            : s
              ? valueS
              : null
      : null; // no values provided
  }, [s, m_, l_, xl_, refresh]);
};
