export const backInStock = {
  label: 'Back In Stock',
  name: 'backInStock',
  component: 'group',
  fields: [
    {
      label: 'Back In Stock Button Text',
      name: 'buttonText',
      component: 'text',
    },
    {
      label: 'Modal Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Modal Description',
      name: 'description',
      component: 'textarea',
    },
    {
      label: 'Modal Submit Button Text',
      name: 'modalButtonText',
      component: 'text',
    },
  ],
  defaultValue: {
    buttonText: 'Notify Me When Available',
    heading: 'Notify Me When Available',
    description: `Enter your email below and we'll notify you when this product is back in stock.`,
    modalButtonText: 'Submit',
  },
};
