import { Box } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaAccount = themed(
  ({ theme, transparentBGEnabled, ...props }) => {
    const customer = useCustomer();

    return (
      <Box
        data-comp={CtaAccount.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.cta,
        }}
      >
        {customer ? (
          <Link href="/account" aria-label="View account page">
            <Svg
              alt="View account"
              src="/svgs/cta/account.svg#account"
              viewBox="0 0 48 48"
              sx={{
                ...theme.cta.icon,
                color: transparentBGEnabled ? 'white' : 'black',
                fill: transparentBGEnabled ? 'white' : 'black',
              }}
            />
          </Link>
        ) : (
          <Link href="/account/login" aria-label="Go to login page">
            <Svg
              alt="Login"
              src="/svgs/cta/login.svg#login"
              viewBox="0 0 24 24"
              sx={{
                ...theme.cta.icon,
                color: transparentBGEnabled ? 'white' : 'black',
                fill: transparentBGEnabled ? 'white' : 'black',
              }}
            />
          </Link>
        )}
      </Box>
    );
  }
);

CtaAccount.displayName = 'CtaAccount';
