import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { Box } from 'theme-ui';

import { Slide } from '../Slide';
import { themed } from './Slider.theme';

import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import 'swiper/css/bundle';
import 'swiper/css';

export const Slider = themed(
  ({ theme, activeIndex, setSwiper, showContentSlider, slider, slides }) => {
    return (
      <Box
        data-comp={Slider.displayName}
        sx={{
          ...theme.slider,
          '.swiper-pagination-bullet-active': {
            bg: slider?.activeNavigationColor || 'white',
          },
          '.swiper-button-prev, .swiper-button-next': {
            color: slider?.activeNavigationColor || 'white',
          },
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          effect={slider?.effect === 'fade' ? 'fade' : null}
          centeredSlides
          autoplay={
            slider?.autoplay
              ? { delay: slider.delay || 5000, disableOnInteraction: true }
              : false
          }
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          navigation={slider?.navigation}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          threshold={25}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {slides?.map((slide, index) => (
            <SwiperSlide key={index}>
              <Slide
                slide={slide}
                isSlider
                showContentSlider={showContentSlider}
                visible={slider?.effect !== 'fade' || index === activeIndex}
                isActiveSlide={index === activeIndex}
                isFirstSlide={index === 0}
              />
            </SwiperSlide>
          ))}
          <Box className="swiper-pagination" sx={theme.bullets} />
        </Swiper>
      </Box>
    );
  }
);

Slider.displayName = 'Slider';
