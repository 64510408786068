import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      px: [10, 20],
      margin: '0 auto',
      minHeight: ['280px', '480px'],
      position: 'relative',
      // py: [14, 18],
    };
    this.video = {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      objectFit: 'cover',
    };
    this.overlay = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
    };
    this.playBtn = {
      variant: 'buttons.plain',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      left: '10px',
      bottom: '16px',
      zIndex: 3,
      fontSize: '11px',
      lineHeight: '12px',
      color: 'white',
      textTransform: 'uppercase',
      '@media screen and (min-width: 480px)': {
        ':hover': {
          backgroundColor: 'transparent',
          color: 'white',
        },
      },
    };
    this.icon = {
      width: '30px',
      height: '30px',
      mr: '2px',
    };
  })()
);
