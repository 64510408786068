import { useState, useEffect, useCallback } from 'react';

import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(({ swiperContentHeight, swiper }) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const setStartAndEndOnSwiperChange = useCallback(() => {
    if (!swiper) return;
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    swiper.on('slideChange', () => {
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    });
  }, [swiper]);

  useEffect(() => {
    setStartAndEndOnSwiperChange();
  }, [swiper]);

  return (
    <>
      <NavigationButton
        onClick={() => swiper.slidePrev()}
        inactive={isStart}
        swiperContentHeight={swiperContentHeight}
      />
      <NavigationButton
        onClick={() => swiper.slideNext()}
        src="/svgs/chevron/right.svg#right"
        alt="Next product"
        inactive={isEnd}
        isNext
        swiperContentHeight={swiperContentHeight}
      />
    </>
  );
});

Navigation.displayName = 'Navigation';
