import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
      justifyContent: 'flex-start',
    };

    // this.title = {
    //   variant: 'text.h6',
    //   pl: 7,
    //   mb: 3,
    //   overflow: 'hidden',
    //   textOverflow: 'ellipsis',
    //   maxWidth: '95%',
    //   whiteSpace: 'nowrap',
    //   fontWeight: 'bold',
    // };

    this.title = {
      fontSize: '18px',
      fontWeight: 700,
      mb: 3,
    };
  })()
);
