export const Schema = ({ blog, page, article }) => {
  if (page?.resourceType === 'home_page' || blog || article) return null;

  return {
    label: 'Collection Sidebar',
    key: 'side-bar',
    fields: [
      {
        label: 'Breadcrumb',
        name: 'breadcrumb',
        component: 'group',
        fields: [
          {
            label: 'Level 1',
            name: 'level1',
            component: 'text',
            defaultValue: 'Men',
          },
          {
            label: 'Level 2',
            name: 'level2',
            component: 'text',
            defaultValue: 'Tops',
          },
          {
            label: 'Collection title',
            name: 'collection',
            component: 'text',
            defaultValue: 'Shirts',
          },

          {
            label: 'Add link for first level category',
            description:
              'This link will be added to the first level of the breadcrumb,\n it will be rendered as All "level 1 text"',
            name: 'link',
            component: 'link',
          },
        ],
      },
      {
        label: 'Sections',
        name: 'sections',
        component: 'group-list',
        itemProps: {
          label: '{{item.group}}',
          name: '{{item.group}}',
        },
        defaultItem: {
          group: 'Group Name',
          links: [],
        },
        fields: [
          {
            label: 'Group title',
            name: 'group',
            component: 'text',
            defaultValue: 'Tops',
          },
          {
            label: 'Links',
            name: 'links',
            component: 'group-list',
            itemProps: {
              label: '{{item.link.text}}',
              name: '{{item.link.text}}',
            },
            defaultItem: {
              url: '',
              text: '',
            },
            fields: [
              {
                label: 'Sub link',
                name: 'link',
                component: 'link',
              },
            ],
            defaultValue: [
              {
                url: 'collections/all-shirts',
                text: 'shirts',
              },
            ],
          },
        ],
      },
    ],
  };
};
