export const socialIcons = () => ({
  name: 'iconImage',
  component: 'select',
  label: 'Social Network',
  options: [
    {
      value: 'facebook',
      label: 'Facebook',
    },
    {
      value: 'instagram',
      label: 'Instagram',
    },
    {
      value: 'pinterest',
      label: 'Pinterest',
    },
    {
      value: 'twitter',
      label: 'Twitter',
    },
    {
      value: 'youtube',
      label: 'YouTube',
    },
    {
      value: 'vimeo',
      label: 'Vimeo',
    },
    {
      value: 'tiktok',
      label: 'TikTok',
    },
  ],
});
