export const space = [
  /*
    DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  0, // 0
  2, // 1
  4, // 2
  6, // 3
  8, // 4
  10, // 5
  12, // 6
  14, // 7
  16, // 8
  18, // 9
  20, // 10
  24, // 11
  32, // 12
  36, // 13
  40, // 14
  48, // 15
  56, // 16
  64, // 17
  72, // 18
  100, // 19
  128, // 20
  150, // 21
  175, // 22
  200, // 23
  256, // 24
  /* DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER */
];
