import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridArea: 'menu',
      display: ['grid', 'none'],
      width: '100%',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridGap: 0,
    };

    this.accordion = {
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      transition: 'max-height .5s ease-in-out',
      position: 'relative',
      borderBottom: '1px solid',
      borderColor: 'darkBlue',
    };

    this.link = {
      variant: 'text.sm',
      width: 'fit-content',
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        maxWidth: 0,
        height: '1px',
        bg: 'currentColor',
        transition: 'max-width 0.2s ease-in-out',
      },
      '@media screen and (min-width: 480px)': {
        ':hover:after': {
          maxWidth: '100%',
        },
      },
    };
  })()
);
