import { button, color, text } from '@settings/common';

export const vipModal = {
  name: 'vipModal',
  label: 'VIP Modal',
  component: 'group',
  fields: [
    {
      label: 'Heading settings',
      name: 'heading',
      description: 'Heading text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'text',
          component: 'text',
          defaultValue: 'Get VIP Treatment with a Membership!',
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the heading text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      label: 'Subheading settings',
      name: 'subheading',
      description: 'Heading text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Subheading',
          name: 'text',
          component: 'text',
          defaultValue: 'This is a VIP product',
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the heading text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      label: 'Description settings',
      name: 'description',
      description: 'description text, position and color',
      component: 'group',
      fields: [
        {
          label: 'Description',
          name: 'text',
          component: 'markdown',
          defaultValue:
            'This is a VIP product, you need to be a VIP member to purchase it.',
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color ',
          description: 'Choose a color for the description text',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'textVariant',
          label: 'Text type',
          description: 'Choose a text type',
          options: [{ label: 'Default', value: '' }, ...text.options],
        },
      ],
    },
    {
      name: 'showProduct',
      label: 'Show VIP Product',
      component: 'toggle',
      description:
        'Show the VIP product selected in the modal (not the membership product)',
      options: [
        { label: 'Show', value: true },
        { label: 'Hide', value: false },
      ],
      defaultValue: true,
    },
    {
      name: 'useLink',
      label: 'Use Link',
      component: 'toggle',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      defaultValue: false,
    },
    {
      name: 'cta',
      label: 'CTA',
      component: 'link',
      defaultValue: {
        url: '/products/vip-membership',
        text: 'Purchase Membership',
      },
    },
    {
      name: 'buttonStyle',
      label: 'Button Style',
      component: 'select',
      options: button.options,

    },
    {
      name: 'secondaryButton',
      label: 'Secondary Button',
      component: 'text',
      defaultValue: 'No thanks',
    },
    {
      name: 'secondaryButtonStyle',
      label: 'Secondary Button Style',
      component: 'select',
      options: button.options,
    },
    {
      name: 'membershipProduct',
      label: 'Product',
      component: 'productSearch',
    },
  ],
  defaultValue: {
    heading: {
      text: 'Welcome to the VIP Club',
      color: '#000',
      textVariant: '',
    },
    subheading: {
      text: 'This is a VIP product',
      color: '#000',
      textVariant: '',
    },
    description: {
      text: 'This is a VIP product, you need to be a VIP member to purchase it.',
      color: '#000',
      textVariant: '',
    },
    showProduct: true,
    useLink: false,
    cta: {
      url: '/products/vip-membership',
      text: 'Purchase Membership',
    },
    buttonStyle: 'primary',
    secondaryButton: 'No thanks',
    secondaryButtonStyle: 'secondary',
  },
};
