import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
    };

    this.title = {
      variant: 'text.nav',
      pr: 6,
      flex: 1,
      my: 2,
    };
    this.colorName = {
      variant: 'text.label.1',
      minHeight: 11,
    };

    this.price = {
      p: {
        cursor: 'default',
      },
      lineThrough: {
        variant: 'text.navList',
        color: 'gray.medium',
        textDecoration: 'line-through',
        mr: 4,
        mb: 0,
        '@media screen and (min-width: 480px)': {
          ':hover': {
            textDecoration: 'line-through',
          },
        },
      },
      regularRed: {
        variant: 'text.navList',
        color: 'red',
        mb: 0,
        '@media screen and (min-width: 480px)': {
          ':hover': {
            textDecoration: 'initial',
          },
        },
      },

      regular: {
        variant: 'text.navList',
        mb: 0,
        '@media screen and (min-width: 480px)': {
          ':hover': {
            textDecoration: 'initial',
          },
        },
      },
    };

    this.loader = {
      height: '20px',
      color: 'gray.light',
    };
  })()
);
