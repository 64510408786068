import { useEffect, useMemo, useState } from 'react';
import {
  useCartAddItem,
  useCart,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';

export const useFondueCashback = () => {
  const setCashbackInCart = useSetRecoilState(store.cashbackInCart);
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const cart = useCart();
  const settings = useSettings();
  const [cashback, setCashback] = useState();
  const { product } =
    settings?.cart?.cashback?.cashbackProducts.find((pdp) =>
      pdp.product.handle.includes(cashback)
    ) || {};

  const { product: cashbackProduct, ...status } = useProductByHandle({
    handle: product?.handle,
  });

  const hasCashbackInCart = useMemo(() => {
    if (cart === null) return true;
    const hasItem = cart?.lines?.some(
      (item) => item?.variant?.id === cashbackProduct?.variants?.[0]?.id
    );
    setCashbackInCart(hasItem);
    return hasItem;
  }, [cart?.updatedAt, cashbackProduct]);

  const cartIsValidForCashBack = useMemo(() => {
    if (cart === null) return true;
    const hasNonCashBackItem = cart?.lines?.some(
      (item) => item?.variant?.id !== cashbackProduct?.variants?.[0]?.id
    );
    return hasNonCashBackItem;
  }, [cart?.updatedAt, cashbackProduct]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cashbackHandle =
      params?.get('cashback') ||
      window.sessionStorage?.getItem('cashback') ||
      null;
    const cashbackPdp = cashbackHandle?.match(/\d+/);

    setCashback(cashbackPdp?.length ? cashbackPdp[0] : null);

    if (params?.get('cashback')) {
      window.sessionStorage?.setItem('cashback', params?.get('cashback'));
    }

    if (
      cashback &&
      !hasCashbackInCart &&
      cashbackProduct &&
      cartIsValidForCashBack
    ) {
      cartAddItem({
        merchandiseId: cashbackProduct?.variants?.[0]?.id,
        quantity: 1,
      });
    }
  }, [
    cart?.updatedAt,
    hasCashbackInCart,
    cartIsValidForCashBack,
    cashbackProduct,
    product,
  ]);
  return { cashbackProduct };
};
