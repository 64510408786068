import { Container, Box } from 'theme-ui';

import { themed } from './Slider.theme';
import { FeaturedSwiper } from '../FeaturedSwiper/FeaturedSwiper';

export const Slider = themed(({ theme, products, settings }) => (
  <Container data-comp={Slider.displayName} sx={theme.wrapper}>
    <Box sx={theme.swiper}>
      <FeaturedSwiper settings={settings} products={products} />
    </Box>
  </Container>
));

Slider.displayName = 'Slider';
