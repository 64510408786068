import { sectionWithHeight, position } from '@settings/common';

export const Schema = () => {

  return ({
    label: 'YouTube Embed Video',
    key: 'YouTubeEmbedVideo',
    fields: [
      {
        name: 'youtubeId',
        label: 'Youtube video id',
        component: 'text',
        defaultValue: 'YgIykiZk8fg',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'text',
        defaultValue: 'In this episode Steven and Duncan discuss NBA politics, performing under pressure, and the best places to go out in Miami. ' +
          'Aside from being a great basketball player, Duncan has a huge personality and truly embodies the “Sport of Business” ethos.'
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: null,
            minHeight: 210,
            mt: 'm',
          },
          desktop: {
            maxHeight: null,
            minHeight: 450,
            mt: 'm',
          }
        }
      }
    ],
  })
};