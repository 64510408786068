import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// create gsap fadeInReveal function
const fadeInReveal = (selectors, duration = 1) => {
  gsap.fromTo(
    selectors.revealRef.current,
    {
      y: 50,
      autoAlpha: 0,
      opacity: 0,
    },
    {
      y: 0,
      duration,
      autoAlpha: 1,
      opacity: 1,
      ease: 'power3.inOut',
      scrollTrigger: {
        trigger: selectors.container.current,
        toggleActions: 'restart none restart none',
      },
    }
  );
};

export default {
  fadeInReveal,
};
