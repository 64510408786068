import { useMemo } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { Picture, Link } from '@snippets';

export const Product = ({ product, isLast, ...props }) => {
  const defaultVariant = product?.variants?.[0];
  const color = defaultVariant?.selectedOptionsMap?.color;
  const src = defaultVariant?.image?.src || product?.images?.[0]?.src;
  const type = product?.productType;
  const handle = product?.handle;

  const title = useMemo(
    () => product?.title?.split('|')?.[0]?.trim(),
    [product?.title]
  );

  return (
    <Box
      data-comp={Product.displayName}
      {...props}
      sx={{
        width: '100%',
        borderBottom: !isLast
          ? (t) => `1px solid ${t.colors.gray.mediumLight}`
          : 0,
        py: '6px',
        ...props.sx,
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Picture
          alt={title}
          aboveTheFold
          images={[
            {
              src,
              width: 36,
              ratio: 0.75,
            },
          ]}
          sx={{
            width: '36px',
            height: '48px',
            bg: 'ghostwhite',
            mr: '12px',
          }}
        />

        <Flex sx={{ flexDirection: 'column' }}>
          {color && (
            <Paragraph
              sx={{
                mb: '5px',
                fontSize: '8px',
                lineHeight: '8px',
                textTransform: 'uppercase',
              }}
            >
              {color}
            </Paragraph>
          )}

          <Link href={`/products/${handle}`}>
            <Paragraph
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '14px',
              }}
            >
              {title}
            </Paragraph>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

Product.displayName = 'Product';
