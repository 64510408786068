import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    };

    this.outer = {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      px: 10,
      mb: [11, 15],
    };

    this.heading = {
      variant: 'text.h2',
      textAlign: 'center',
      mb: 6,
    };

    this.description = {
      textAlign: 'center',
      fontSize: 4, // 18px
    };

    this.icons = {
      maxWidth: '100%',
      display: 'grid',
      gridTemplateColumns: [
        'repeat(3, 1fr)',
        'repeat(auto-fit, minmax(180px, 300px))',
      ],
      gridColumnGap: '0px',
      gridRowGap: '0px',
      mx: 'auto',
    };

    this.iconWrap = {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      mx: 'auto',
      mb: ['1rem', '1.25rem'],
      width: ['20rem', '402px'],
      '@media only screen and (max-width: 479px)': {
        // width: '6.5rem',
      },
    };

    this.icon = {
      maxWidth: [75, 100],
      '@media only screen and (max-width: 479px)': {
        width: 50,
      },
    };

    this.text = {
      variant: 'text.sm-base',
      textAlign: 'center',
      mb: '5px',
    };
  })()
);
