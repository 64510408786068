const getPositionStylesFor3Tiles = (index) => {
  switch (index) {
    case 0:
      return {
        left: [null, null, '25%', '35%'],
      };
    case 2:
      return {
        right: [null, null, '25%', '35%'],
      };
    default:
      return {};
  }
};

const getPositionStylesFor4Tiles = (index) => {
  switch (index) {
    case 0:
      return {
        left: [null, null, '25%', '35%'],
      };
    case 1:
      return {
        left: [null, null, '7.5%', '12.5%'],
      };
    case 2:
      return {
        right: [null, null, '7.5%', '12.5%'],
      };
    case 3:
      return {
        right: [null, null, '25%', '35%'],
      };
    default:
      return {};
  }
};

export const positionStyles = (index, numOfTiles) => {
  const styles = {
    3: getPositionStylesFor3Tiles(index),
    4: getPositionStylesFor4Tiles(index),
  };
  if (styles[numOfTiles]) {
    return styles[numOfTiles];
  }
  return 'auto';
};

export const contentWidthStyles = (numOfTiles) => {
  switch (numOfTiles) {
    case 3:
      return {
        maxWidth: [null, null, '250px', '300px'],
      };
    case 4:
      return {
        maxWidth: [null, null, '250px', '275px'],
      };
    default:
      return 'auto';
  }
};
