import { useState, useEffect, useCallback } from 'react';
import { Container, Box, Heading, Paragraph, Input, Text } from 'theme-ui';
import { useCustomer, useSettings } from '@backpackjs/storefront';
import startCase from 'lodash.startcase';

import { StatefulButton } from '@snippets';
import { useModal, useSelectedOptions } from '@hooks';

import { useBackInStock } from './useBackInStock';

export const BackInStockModal = ({ legacyResourceId, title }) => {
  const settings = useSettings();
  const customer = useCustomer();
  const { subscribeToBackInStock, ...status } = useBackInStock();
  const [selectedOptions] = useSelectedOptions();
  const { closeModal } = useModal()[1];
  const [email, setEmail] = useState(customer?.email || '');
  const {
    heading = 'Notify Me When Available',
    description,
    modalButtonText = 'Submit',
  } = { ...settings?.product?.backInStock };

  const handleSubscribe = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || !legacyResourceId) return;
      await subscribeToBackInStock({ email, variantId: legacyResourceId });
    },
    [email, legacyResourceId]
  );

  useEffect(() => {
    if (status.success) {
      setTimeout(() => closeModal(), 2000);
    }
  }, [status.success]);

  return (
    <Container
      data-comp={BackInStockModal.displayName}
      sx={{
        width: '100%',
        minWidth: '300px',
        maxWidth: '500px',
        '@media only screen and (max-width: 479px)': {
          minWidth: '250px',
        },
        textAlign: 'center',
        p: 10,
      }}
    >
      <Heading as="h2">{heading}</Heading>

      {description && (
        <Paragraph variant="sm" mt={6}>
          {description}
        </Paragraph>
      )}

      <Box
        sx={{
          my: 10,
        }}
      >
        <Heading as="h3" variant="text.h5">
          {title}
        </Heading>

        <Box>
          {Object.entries(selectedOptions || {}).map(([key, value], index) => {
            return (
              <Paragraph key={key + index} variant="text.h5">
                {startCase(key)}: {startCase(value)}
              </Paragraph>
            );
          })}
        </Box>
      </Box>

      <Box as="form" onSubmit={handleSubscribe}>
        <Input
          name="email"
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          mb={11}
          px={11}
        />

        <StatefulButton
          {...status}
          showErrors={false}
          text={{
            started: '',
            success: 'Email Subscribed!',
            error: 'Please Try Again Later',
            default: modalButtonText,
          }}
          sx={{ width: '100%', minHeight: '52px' }}
        />
      </Box>
    </Container>
  );
};

BackInStockModal.displayName = 'BackInStockModal';
