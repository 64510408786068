import { Box, Flex, Paragraph } from 'theme-ui';

import { YotpoStars } from '@snippets/yotpo/YotpoStars';
import { YotpoVotes } from '../YotpoVotes/YotpoVotes';

export const YotpoListReviewItemInfo = ({
  score,
  title,
  content,
  ...review
}) => {
  return (
    <Box
      data-comp={YotpoListReviewItemInfo.displayName}
      sx={{
        width: '100%',
      }}
    >
      <Flex variant="flex.row.between.center">
        <YotpoStars
          withCta={false}
          bottomline={{
            averageScore: score,
            totalReviews: 1,
          }}
        />

        {/* Votes up/down */}
        <YotpoVotes
          id={review.id}
          endpoint="reviews"
          votesUp={review.votesUp}
          votesDown={review.votesDown}
          alignedRight={true}
        />
      </Flex>

      <Paragraph
        dangerouslySetInnerHTML={{ __html: `${title}...` }}
        variant="xl"
        sx={{
          fontFamily: 'Self Modern',
          fontStyle: 'italic',
          mt: 4,
          mb: 3,
        }}
      />

      <Paragraph
        dangerouslySetInnerHTML={{ __html: content }}
        sx={{
          pr: [10, null, 12, 15],
        }}
      />
    </Box>
  );
};

YotpoListReviewItemInfo.displayName = 'YotpoListReviewItemInfo';
