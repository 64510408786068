import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.swiper = {
      width: '100%',
      position: 'relative',
      cursor: 'grab',
      '.swiper-container, .swiper-wrapper': {
        width: '100%',
        height: 'auto !important',
      },
      '.swiper-slide': {
        width: '100%',
      },
    }),
      (this.badge = {
        my: 7,
        mx: [0, -5],
        minHeight: 24,
        px: [3, 5],
        py: 5,
        borderRadius: '0px 4px 4px 0px',
      });
    this.wrapper = {
      top: 0,
      left: [0, 5],
      bottom: [null, null],
    };
  })()
);
