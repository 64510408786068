export const lineHeights = {
  body: '22px',
  11: '11px',
  15: '15px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  32: '32px',
  40: '40px',
  48: '48px',
  60: '60px',
  100: '100px',
  200: '200px',
};
