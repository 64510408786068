export const Schema = ({ product }) => {
  if (!product) return null;

  return {
    label: 'Product meta',
    key: 'productMeta',
    fields: [
      {
        label: 'Details',
        name: 'details',
        component: 'group',
        fields: [
          {
            label: 'Detail Groups',
            component: 'group-list',
            name: 'groups',
            itemProps: {
              label: '{{item.heading}}',
            },
            defaultItem: {
              heading: 'Detail Group',
              contents: `* Fit: Lorem ipsum dolor sit amet, consectetur adipiscing elit\n* Length: Sed ut perspiciatis unde omnis iste natus`,
            },
            fields: [
              {
                component: 'text',
                name: 'heading',
                label: 'Group heading',
              },
              {
                component: 'markdown',
                name: 'contents',
                label: 'Contents',
              },
              {
                label: 'Auto Open',
                name: 'autoOpen',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                description: 'Refresh page to observe change',
                defaultValue: false,
              },
            ],
            defaultValue: [
              {
                heading: 'Material & Care',
                contents: `* Machine wash cold\n* Wash with like colors\n* Tumble dry low\n* Do not iron`,
              },
              {
                heading: 'Sizing Details',
                contents: `* Fit: Lorem ipsum dolor sit amet, consectetur adipiscing elit\n* Length: Sed ut perspiciatis unde omnis iste natus`,
              },
            ],
          },
        ],
      },
      {
        label: 'Fabric',
        name: 'fabric',
        component: 'group',
        fields: [
          {
            label: 'Fabric Icons',
            component: 'list',
            name: 'icons',
            field: {
              component: 'select',
              options: [
                {
                  label: '4-Way Stretch',
                  value: '4-Way Stretch|4-way-stretch',
                },
                { label: 'Anti-Odor', value: 'Anti-Odor|anti-odor' },
                { label: 'Anti-Pilling', value: 'Anti-Pilling|anti-pilling' },
                { label: 'Breathable', value: 'Breathable|breathable' },
                { label: 'Buttery Soft', value: 'Buttery Soft|buttery-soft' },
                {
                  label: 'Climate Control',
                  value: 'Climate Control|climate-control',
                },
                {
                  label: 'Color & Fit Retention',
                  value: 'Color & Fit Retention|color-and-fit-retention',
                },
                {
                  label: 'Color & Fit Retention Bottoms',
                  value:
                    'Color & Fit Retention|color-and-fit-retention-bottoms',
                },
                { label: 'Durable', value: 'Durable|durable' },
                { label: 'Easy Care', value: 'Easy Care|easy-care' },
                {
                  label: 'Engineered Support',
                  value: 'Engineered Support|engineered-support',
                },
                {
                  label: 'Goose Down',
                  value: 'Goose Down|goose-down',
                },
                {
                  label: 'Insulated Warmth',
                  value: 'Insulated Warmth|insulated-warmth',
                },

                { label: 'Lightweight', value: 'Lightweight|lightweight' },
                {
                  label: 'Lightweight & Packable',
                  value: 'Lightweight & Packable|lightweight-packable',
                },

                {
                  label: 'Moisture Wicking',
                  value: 'Moisture Wicking|moisture-wicking',
                },
                {
                  label: 'Natural Softness',
                  value: 'Natural Softness|natural-softness',
                },
                { label: 'Opaque', value: 'Opaque|opaque' },
                { label: 'Packable', value: 'Packable|packable' },
                { label: 'Pre-Shrunk', value: 'Pre-Shrunk|pre-shrunk' },
                { label: 'Quick Dry', value: 'Quick Dry|quick-dry' },
                {
                  label: 'Recycled Material',
                  value: 'Recycled Material|recycled-material',
                },
                {
                  label: 'Secure Storage',
                  value: 'Secure Storage|secure-storage',
                },
                {
                  label: 'Sheepskin Suede Leather',
                  value: 'Sheepskin Suede Leather|sheepskin-suede-leather',
                },
                { label: 'Slim Fit', value: 'Slim Fit|slim-fit' },
                {
                  label: 'Soft Texture/Cool Hand Feel',
                  value: 'Cool Hand Feel|soft-texture',
                },
                { label: 'Soft Texture', value: 'Soft Texture|soft-texture' },
                {
                  label: 'Textured Materials',
                  value: 'Textured Materials|textured-materials',
                },
                {
                  label: 'Thermoregulating',
                  value: 'Thermoregulating|thermoregulating',
                },
                { label: 'Trash Can', value: 'Trash Can|trash' },
                {
                  label: 'UV Protection',
                  value: 'UV Protection|uv-protection',
                },
                { label: 'Vintage Wash', value: 'Vintage Wash|vintage-wash' },

                {
                  label: 'Water Resistant',
                  value: 'Water Resistant|water-resistant',
                },
                {
                  label: 'Windproof',
                  value: 'Windproof|wind-proof',
                },
                {
                  label: 'Wrinkle Resistant',
                  value: 'Wrinkle Resistant|wrinkle-free',
                },
              ],
            },
            defaultItem: '4-Way Stretch|4-way-stretch',
            defaultValue: [
              'Anti-Pilling|anti-pilling',
              'Buttery Soft|buttery-soft',
              'Pre-Shrunk|pre-shrunk',
              'Wrinkle Resistant|wrinkle-free',
              'Color & Fit Retention|color-and-fit-retention',
              '4-Way Stretch|4-way-stretch',
            ],
          },
        ],
      },
      {
        label: 'Complete The Look',
        name: 'looks',
        component: 'group',
        fields: [
          {
            component: 'text',
            name: 'heading',
            label: 'Heading',
            defaultValue: 'Complete The Look',
          },
          {
            label: 'Products',
            description: 'Max of 2 products',
            component: 'group-list',
            name: 'products',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'black-crew-curve-hem' },
            },
            fields: [
              {
                component: 'productSearch',
                name: 'product',
                label: 'Look product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'black-crew-curve-hem' },
              },
              {
                product: { handle: 'cast-iron-ao-jogger' },
              },
            ],
          },
        ],
      },
    ],
  };
};
