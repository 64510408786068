export const linksList = {
  label: 'Links List (mobile only)',
  name: 'links',
  component: 'group-list',
  itemProps: {
    label: '{{item.link.text}}',
  },
  defaultItem: {
    name: 'New link',
    link: {
      url: '',
      text: '',
    },
  },
  fields: [
    {
      label: 'Link',
      name: 'link',
      component: 'link',
      defaultValue: {
        url: '',
        text: '',
      },
    },
  ],
};
