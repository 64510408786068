import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      width: '100%',
      position: 'relative',
      cursor: 'grab',
      '.swiper-container, .swiper-wrapper': {
        width: '100%',
        height: 'auto !important',
      },
      '.swiper-slide': {
        maxWidth: '100%',
        height: '100%',
      },
      alignItems: 'left',
    };

    this.bullets = {
      display: ['flex', 'none'],
      justifyContent: 'center',
      alignItems: 'center',
      gap: 3,
      textAlign: 'center',
      position: 'relative',
      bottom: '0 !important',
      span: {
        backgroundColor: 'gray.medium',
        opacity: 1,
      },
      '& .swiper-pagination-bullet': {
        width: '6px',
        height: '6px',
        position: 'relative',
        m: '0 !important',
      },
      '& .swiper-pagination-bullet-active': {
        width: '10px',
        height: '10px',
        position: 'relative',
      },
      cursor: 'pointer',
    };

    this.swiperInitial = {
      width: '100%',
      position: 'relative',
      cursor: 'grab',
      '.swiper-container, .swiper-wrapper': {
        width: '100%',
        height: 'auto !important',
        transform: 'none !important',
      },
      '.swiper-slide': {
        maxWidth: '100%',
        height: '100%',
      },
      alignItems: 'left',
    };
  })()
);
