import React from 'react';
import { themed } from './Returns.theme';
import { withInView } from '@utils';
import { Schema } from './Returns.schema';
import { Section, Markdown, Link, Picture } from '@snippets';
import { Box, Flex, Paragraph, Text } from 'theme-ui';

export const Returns = withInView(
  themed(({ theme, cms }) => {
    const { section, heroTitle, info, iconsBlock, bottom } = cms;
    return (
      <Section section={section}>
        <Box sx={theme.heroWrap}>
          <Flex sx={theme.hero}>
            <Paragraph variant="text.h3" sx={theme.heroTitle}>
              {heroTitle}
            </Paragraph>
          </Flex>
        </Box>
        <Box sx={theme.content}>
          <Box sx={theme.info}>
            <Paragraph sx={theme.infoHeading}>{info.heading}</Paragraph>
            <Paragraph sx={theme.infoSubHeading}>{info.subheading}</Paragraph>
            <Markdown text={info?.text} textSx={theme.infoText} />
            <Link
              href={info.cta.url}
              variant={`buttons.${info?.ctaStyle || 'primary'}`}
              sx={theme.link}
            >
              {info.cta.text}
            </Link>
          </Box>

          <Flex sx={theme.icons}>
            {iconsBlock?.icons?.map(({ name, text, icon }) => (
              <Flex key={name} sx={theme.iconWrap}>
                <Picture
                  images={[
                    {
                      src: `/images/${icon}-icon.webp`,
                      ratio: 1,
                    },
                  ]}
                  sx={theme.icon}
                />
                <Text>{text}</Text>
              </Flex>
            ))}
          </Flex>
          <Box>
            <Paragraph sx={theme.bottomHeading}>{bottom.heading}</Paragraph>
            <Paragraph sx={theme.bottomHeading}>{bottom.subheading}</Paragraph>
            <Link
              href={bottom.cta.url}
              variant={`buttons.${bottom?.ctaStyle || 'primary'}`}
              sx={theme.link}
            >
              {bottom.cta.text}
            </Link>
          </Box>
        </Box>
      </Section>
    );
  })
);

Returns.displayName = 'Returns';
Returns.Schema = Schema;
