import { useState } from 'react';
import dynamic from 'next/dynamic';
import { Container, Box } from 'theme-ui';

import { withInView, pxWithFullBleed } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductsSlider.schema';
import { themed } from './ProductsSlider.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const ProductsSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { groups, content, section } = cms;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section}>
        <Container data-comp={ProductsSlider.displayName} sx={theme.wrapper}>
          <Box sx={{ ...theme.header, ...pxWithFullBleed(section) }}>
            {inView && (
              <Header
                activeIndex={activeIndex}
                groups={groups}
                content={content}
                setActiveIndex={setActiveIndex}
                section={section}
              />
            )}
          </Box>

          <Box sx={theme.slider}>
            {inView && (
              <Slider
                groups={groups}
                content={content}
                activeIndex={activeIndex}
                ctaStyle={content?.ctaStyle}
              />
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
