export const flex = {
  // <Flex variant="flex.row" />
  inline: {
    display: 'inline-flex',
  },
  row: {
    // short cut to bottom
    around: {
      justifyContent: 'space-around',
      alignItems: 'flex-start',
      start: {
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'space-around',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'space-around',
        alignItems: 'flex-end',
      },
    },
    between: {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      start: {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
    },
    evenly: {
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
      start: {
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
      },
    },
    start: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      start: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      },
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
      start: {
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
    },
    // short cut
    end: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      start: {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
      },
      center: {
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      end: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
  },
  /*
    Flex Column
  */
  column: {
    flexDirection: 'column',
    // short cut to bottom
    start: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      start: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      center: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      end: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      between: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      around: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
      },
      evenly: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
      },
    },
    center: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      start: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      center: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      end: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      between: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      around: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
      },
      evenly: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      },
    },
    stretch: {
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      start: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      },
      center: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
      },
      end: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-end',
      },
      between: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
      },
      around: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
      },
      evenly: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
      },
    },
    // short cut
    end: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      start: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      },
      center: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      end: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
      between: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      },
      around: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-around',
      },
      evenly: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-evenly',
      },
    },
  },
  // row mobile / column desktop
  'row-column': {
    flexDirection: ['row', null, null, null, 'column'],
    start: {
      flexDirection: ['row', null, null, null, 'column'],
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    center: {
      flexDirection: ['row', null, null, null, 'column'],
      justifyContent: 'center',
      alignItems: 'center',
    },
    end: {
      flexDirection: ['row', null, null, null, 'column'],
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },
  // column mob / row desktop
  'column-row': {
    flexDirection: ['column', null, null, null, 'row'],
    start: {
      flexDirection: ['column', null, null, null, 'row'],
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    center: {
      flexDirection: ['column', null, null, null, 'row'],
      justifyContent: 'center',
      alignItems: 'center',
    },
    end: {
      flexDirection: ['column', null, null, null, 'row'],
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },
};
