import { useMemo } from 'react';
import { Box, Container } from 'theme-ui';
import { calcResponsiveHeight, withInView } from '@utils';

import { Section } from '@snippets';

import { Slider } from './Slider/Slider';
import { Schema } from './Testimonials.schema';
import { themed } from './Testimonials.theme';

import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';
import 'swiper/css';

export const Testimonials = withInView(
  themed(({ theme, cms }) => {
    const { testimonials, section, slider } = cms;

    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    return (
      <Section section={section}>
        <Container
          data-comp={Testimonials.displayName}
          sx={{
            ...theme.container,
            height,
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <Box sx={{ backgroudColor: `${section?.bgColor}` }}>
            {testimonials?.length > 0 ? (
              <Slider testimonials={testimonials} slider={slider} />
            ) : null}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Testimonials.displayName = 'Testimonials';
Testimonials.Schema = Schema;
