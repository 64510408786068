import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
    };

    this.body = {
      variant: 'text.base',
    };

    this.link = {
      mt: [10, 12],
    };
  })()
);
