export function formatDate(dateString = '01.01.21') {
  if (!dateString) {
    return {
      monthName: '',
      dayNumber: '',
      fullYear: '',
    };
  }
  const [month, day, year] = dateString.split('.');

  const date = new Date(`20${year}`, month - 1, day);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthName = monthNames[date.getMonth()];
  const dayNumber = date.getDate();
  const fullYear = date.getFullYear();

  return {
    monthName,
    dayNumber,
    fullYear,
  };
}
