import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.hero = {
      bg: 'ghostwhite',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    };

    this.innerWrapper = {
      position: 'absolute',
      inset: 0,
      width: '100%',
      height: '100%',
    };
  })()
);
