import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
    };

    this.header = {
      minHeight: ['35px', '45px', '60px'],
    };

    this.slider = {
      minHeight: ['200px'],
    };
  })()
);
