import { useMemo } from 'react';

import { useProduct } from '@backpackjs/storefront';

import {
  useYotpoSearchReviews,
  useYotpoProductQuestions,
  useYotpoProductTopics,
} from '@hooks/yotpo';

import store, { useRecoilValue } from '@store';

export const useYotpoProductList = ({
  legacyResourceId: passedId,
  perPage,
  bottomline,
}) => {
  const product = useProduct();
  const legacyResourceId = passedId || product?.legacyResourceId;
  const yoptoActivePage = useRecoilValue(
    store.yoptoActivePage(legacyResourceId)
  );
  const yoptoActiveTerm = useRecoilValue(
    store.yoptoActiveTerm(legacyResourceId)
  );
  const isTermAll = yoptoActiveTerm === 'all';

  const { topTopics, ...topicsStatus } = useYotpoProductTopics({
    legacyResourceId,
  });

  const { page, pagination, reviews, ...reviewsStatus } = useYotpoSearchReviews(
    {
      legacyResourceId,
      perPage: perPage,
      page: yoptoActivePage,
      free_text_search: isTermAll ? '' : yoptoActiveTerm ?? '',
      scores: [],
    }
  );

  const { totalQuestions, totalAnswers, questions, ...questionsStatus } =
    useYotpoProductQuestions({
      legacyResourceId,
    });

  return {
    // status
    started:
      reviewsStatus.started || questionsStatus.started || topicsStatus.started,
    finished:
      reviewsStatus.finished &&
      questionsStatus.finished &&
      topicsStatus.finished,
    errors: [
      ...reviewsStatus.errors,
      ...questionsStatus.errors,
      ...topicsStatus.errors,
    ],

    // state
    page: yoptoActivePage,
    pages: pagination?.pages || 1,
    term: yoptoActiveTerm,
    reviews,
    questions,
    totalQuestions,
    totalAnswers,
    topTopics,
  };
};
