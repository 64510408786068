import { color, section, button, align } from '@settings/common';
import {
  header,
  buttons,
  settings,
  text,
  defaultTile1,
  defaultTile2,
} from './schemas';

const video = ({ color }) => ({
  label: 'Video settings',
  name: 'video',
  description: 'video, video position',
  component: 'group',
  fields: [
    {
      label: 'Title (mobile)',
      name: 'titleMb',
      component: 'text',
      description: 'Title of the video (mobile)',
    },
    {
      name: 'videoMb',
      description: 'Video url must end with .mp4',
      label: 'Video url (mobile)',
      component: 'text',
    },
    {
      name: 'posterMb',
      label: 'Video poster (mobile)',
      component: 'image',
      description:
        'First frame of the video to display briefly while video loads',
    },
    {
      label: 'Title (desktop)',
      name: 'titleDt',
      component: 'text',
      description: 'Title of the video (desktop)',
    },
    {
      name: 'videoDt',
      description:
        'Video url must end with .mp4 \n For better results on desktop use minimum resolution of 1280px',
      label: 'Video url (desktop)',
      component: 'text',
    },
    {
      name: 'posterDt',
      label: 'Video poster (desktop)',
      description:
        'First frame of the video to display briefly while video loads',
      component: 'image',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'videoRatio',
      label: 'Video aspect ratio (mobile)',
      options: [
        { label: '3:4', value: 'portrait' },
        { label: '1:1', value: 'square' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'videoRatioDt',
      label: 'Video aspect ratio (desktop)',
      options: [
        { label: '3:4', value: 'portrait' },
        { label: '1:1', value: 'square' },
      ],
    },
    {
      label: 'Activate overlay',
      description: 'Activate video overlay',
      name: 'videoOverlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      defaultValue: 50,
    },
  ],
  defaultValue: {
    titleMb: 'Hero video',
    videoMb:
      'https://cdn.shopify.com/s/files/1/1368/3463/files/FESTIVAL_3x1_HPHero.mp4?v=1648492115',
    titleDt: 'Hero video',
    videoDt:
      'https://cdn.shopify.com/s/files/1/1368/3463/files/STAPLES_3x1_Hero_01.mp4?v=1649123562',
    videoRatio: 'square',
    videoRatioDt: 'square',
    overlayOpacity: 50,
    overlayColor: '',
    videoOverlay: false,
  },
});

export const Schema = ({ article, blog }) => {
  if (article || blog) return null;
  return {
    label: 'Two tile',
    key: 'two-tile',
    fields: [
      header({ button, align }),
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 2 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading}}',
        },
        defaultItem: defaultTile1({ align, button }),
        validate: {
          maxItems: 2,
        },
        fields: [
          text({ color }),
          buttons({ button }),
          settings({ button, align }),
          {
            label: 'Select Media Type (mobile)',
            description: 'Allows select between image or video for mobile',
            name: 'mediaTypeMb',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'image',
          },
          {
            label: 'Select Media Type (desktop)',
            description: 'Allows select between image or video for desktop',
            name: 'mediaTypeDt',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'video',
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          video({ color }),
        ],
        defaultValue: [
          defaultTile1({ align, button }),
          defaultTile2({ align, button }),
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.black,
          mobile: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
