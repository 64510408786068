import { useMemo } from 'react';
import { Box, Container, Heading } from 'theme-ui';
import { calcResponsiveHeight, withInView } from '@utils';

import { Section } from '@snippets';

import { Slider } from './Slider/Slider';
import { Schema } from './TestimonialsVIP.schema';
import { themed } from './TestimonialsVIP.theme';

import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';
import 'swiper/css';

export const TestimonialsVIP = withInView(
  themed(({ theme, cms }) => {
    const { testimonials, section, slider, isVersion2, title } = cms;

    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    return (
      <Section section={section}>
        <Container
          data-comp={TestimonialsVIP.displayName}
          sx={{
            backgroundColor: `${section?.bgColor}`,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            gap: 12,
            py: 8,
            px: 4,
            height,
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <Heading
            sx={{
              color: `${section?.textColor}`,
              fontSize: 5,
              fontWeight: 'bold',
              fontFamily: 'heading',
              lineHeight: 'heading',
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Heading>
          <Box sx={{ backgroundColor: `${section?.bgColor}` }}>
            {testimonials?.length > 0 && (
              <Slider
                testimonials={testimonials}
                isVersion2={isVersion2}
                slider={slider}
                backgroundColor={section?.bgColor}
              />
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TestimonialsVIP.displayName = 'TestimonialsVIP';
TestimonialsVIP.Schema = Schema;
