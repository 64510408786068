/**
 * Function to select the first n elements of an array
 * @param {Array} array
 * @param {number} minimum
 * @param {number} maximum
 * @returns {Array} first n elements
 */
export const selectTheFirstNElementsFromArray = (array, minimum, maximum) => {
  if (array && array.length > 1) {
    return array.length > maximum
      ? array.slice(0, maximum)
      : array.length >= minimum
      ? array
      : null;
  }
  return array;
};
