import { text as textOptions } from '@theme/theme.text';

// can be used in any select field as its options

export const text = {
  options: [...Object.keys(textOptions)]
    .map((textKey) => ({
      label: textKey,
      value: textKey,
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }),
  value: Object.keys(textOptions).reduce((obj, key) => {
    obj[key] = key;
    return obj;
  }, {}),
};
