import { useState, forwardRef } from 'react';
import { Grid } from 'theme-ui';

import { YotpoStarsIcon } from './YotpoStarsIcon';

export const YotpoStarsIcons = forwardRef(
  (
    {
      averageScore,
      emptyColor = 'beige',
      fullColor = 'red',
      isSelectable = false,
      onClickedStar = () => {},
      totalReviews,
      width = '16px',
      ...props
    },
    ref
  ) => {
    if (typeof averageScore === 'undefined') return null;

    const [hoveredIndex, setHoveredIndex] = useState(0);

    return (
      <Grid
        data-comp={YotpoStarsIcons.displayName}
        ref={ref}
        variant="flex.row.start.center"
        {...props}
        sx={{
          gridTemplateColumns: 'repeat(5, 1fr)',
          columnGap: '3px',
          ...props.sx,
          pointerEvents: isSelectable ? 'auto' : 'none',
        }}
      >
        {/* render each star icon */}
        {[...new Array(5)].map((_, index) => (
          <YotpoStarsIcon
            averageScore={averageScore}
            emptyColor={emptyColor}
            fullColor={fullColor}
            hoveredIndex={hoveredIndex}
            index={index}
            isSelectable={isSelectable}
            key={averageScore + index}
            onClickedStar={onClickedStar}
            setHoveredIndex={setHoveredIndex}
            totalReviews={totalReviews}
            width={width}
          />
        ))}
      </Grid>
    );
  }
);

YotpoStarsIcons.displayName = 'YotpoStarsIcons';
