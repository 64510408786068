import { useEffect } from 'react';

export const useKustomerChat = () => {
  const kustomerChat =
    window && document
      ? document.getElementById('kustomer-ui-sdk-iframe')
      : null;

  useEffect(() => {
    if (kustomerChat) {
      kustomerChat.style.zIndex = 10;
    }
  }, []);

  return {};
};
