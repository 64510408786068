import { useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

import { Box, Flex, Image } from 'theme-ui';

import { themed } from './Slider.theme';

import { TestimonialSlide } from '../Testimonial/TestimonialSlide';
import { TestimonialSlideV2 } from '../Testimonial/TestimonialSlideVersion2';

import 'swiper/css/navigation';

export const Slider = themed(
  ({ theme, testimonials, isVersion2, slider, backgroundColor }) => {
    const [swiper, setSwiper] = useState();

    const [activeIndex, setActiveIndex] = useState(0);

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper || testimonials?.length < 2) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
      });
    }, [swiper, testimonials?.length]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper, testimonials?.length]);

    return (
      <Box
        data-comp={Slider.displayName}
        sx={{
          '.swiper-pagination-bullet-active': {
            bg: slider?.activeNavigationColor || 'gray.medium',
            width: '0.75rem',
            height: '0.75rem',
          },
          '.swiper-button-prev, .swiper-button-next': {
            color:
              slider?.activeNavigationColor || isVersion2
                ? 'black'
                : 'gray.light',
            width: '0.5rem',
            height: '0.5rem',
            // transform: isVersion2 ? 'translateY(150px)' : 'none',
          },
          '.swiper-button-prev': {
            transform: [
              isVersion2 ? 'translate(120px, 150px)' : 'none',
              'none',
            ],
          },
          '.swiper-button-next': {
            transform: [
              isVersion2 ? 'translate(-120px, 150px)' : 'none',
              'none',
            ],
          },
          '.swiper-button-next::after, .swiper-button-prev::after': {
            fontSize: '1rem',
          },
        }}
      >
        <Swiper
          init={false}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          preloadImages={false}
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}
          slidesPerView={1}
          freeMode
          loop
          navigation={isVersion2}
          loopedSlides={1}
          initialSlide={0}
          threshold={25}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
          centeredSlides
          effect={slider?.effect === 'fade' ? 'fade' : null}
          autoplay={
            slider?.autoplay
              ? { delay: slider.delay || 5000, disableOnInteraction: true }
              : false
          }
          pagination={
            isVersion2
              ? false
              : {
                  el: '.swiper-pagination',
                  type: 'bullets',
                  clickable: true,
                }
          }
        >
          {testimonials?.map((slide, index) => (
            <SwiperSlide key={index}>
              {isVersion2 ? (
                <TestimonialSlideV2
                  media={slide.media}
                  text={slide.testimonialText}
                  stars={slide.stars}
                  location={slide.locationDt}
                  visible={slider?.effect !== 'fade' || index === activeIndex}
                  isActiveSlide={index === activeIndex}
                  isFirstSlide={index === 0}
                />
              ) : (
                <TestimonialSlide
                  media={slide.media}
                  text={slide.testimonialText}
                  stars={slide.stars}
                  location={slide.locationDt}
                  visible={slider?.effect !== 'fade' || index === activeIndex}
                  isActiveSlide={index === activeIndex}
                  isFirstSlide={index === 0}
                />
              )}
            </SwiperSlide>
          ))}
          {!isVersion2 && (
            <Box className="swiper-pagination" sx={theme.bullets} />
          )}
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'end',
              backgroundColor,
              height: '30px',
              width: '100%',
            }}
          />
        </Swiper>
      </Box>
    );
  }
);

Slider.displayName = 'Slider';
