import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      width: ['100%', '850px'],
      fontFamily: 'body',
    };
    this.wrap = {
      flexDirection: ['column', 'row'],
      '& > div': {
        width: ['100%', '50%'],
      },
    };
    this.image = {
      minHeight: ['250px', '600px'],
    };
    this.content = {
      variant: 'flex.column.center.center',
      alignItems: 'flex-start',
      padding: [10, 12],
    };
    this.top = {
      width: '100%',
      variant: 'flex.row.between.center',
      mb: 4,
    };
    this.title = {
      variant: 'text.h4',
      textTransform: 'uppercase',
    };
    this.price = {
      variant: 'text.h5',
    };
    this.heading = {
      variant: 'text.h6',
      mb: 4,
    };
    this.link = {
      fontWeight: 700,
      mb: 10,
      fontSize: 15,
      textDecoration: 'underline',
    };
    this.desc = {
      color: 'gray.dark',
    };
    this.markdown = {
      p: {
        m: 0,
      },
      ul: {
        m: 0,
        p: 0,
      },
    };
  })()
);
