import { useState, useEffect } from 'react';
import { useRouter, useSettings } from '@backpackjs/storefront';

import { useBreakpoint } from '@hooks';
import { useMenuDrawer } from './MenuDrawer';
import { useMenuSidebar } from './MenuSidebar';

export const useHeader = ({ promoBarRef, rotatingBarRef }) => {
  const settings = useSettings();
  const router = useRouter();

  const {
    enableTransparentBackgroundDesktop,
    enableTransparentBackgroundMobile,
  } = {
    ...settings?.header,
  };
  const { isSticky: promoBarIsSticky } = {
    ...settings?.header?.promoBar,
  };
  const { isSticky: rotatingBarIsSticky } = {
    ...settings?.header?.rotatingBar,
  };

  const { l_: isDesktop } = useBreakpoint();

  const enable = isDesktop
    ? enableTransparentBackgroundDesktop
    : enableTransparentBackgroundMobile;

  const [isHomepage, setIsHomepage] = useState(false);
  const [scrollIsActive, setScrollIsActive] = useState(false);
  const [transparentBGEnabled, setTransparentBGEnabled] = useState(false);
  const [stickyTopPosition, setStickyTopPosition] = useState(0);
  const [{ hovered }] = useMenuDrawer();
  const [{ menuSidebar }] = useMenuSidebar();

  const onRountChangeComplete = (url) => {
    if (url === '/' && enable) {
      setIsHomepage(true);
      setTransparentBGEnabled(true);
    } else {
      setIsHomepage(false);
      setTransparentBGEnabled(false);
    }
  };

  // if mobile menu is open, disable transparent bg
  // if mobile menu is closed, keep transparent bg if scroll is active
  useEffect(() => {
    if (menuSidebar) {
      setTransparentBGEnabled(false);
    } else if (!scrollIsActive && enable) {
      setTransparentBGEnabled(true);
    }
  }, [menuSidebar]);

  useEffect(() => {
    const getTopPositionValues = (_promoBarIsSticky, _rotatingBarIsSticky) => {
      const promoBarHeight = promoBarRef?.current?.offsetHeight || 0;
      const rotatingBarHeight = rotatingBarRef?.current?.offsetHeight || 0;
      return {
        stickyTopValue:
          (_promoBarIsSticky ? promoBarHeight : 0) +
          (_rotatingBarIsSticky ? rotatingBarHeight : 0),
        scrollTopValue:
          (_promoBarIsSticky ? 0 : promoBarHeight) +
          (_rotatingBarIsSticky ? 0 : rotatingBarHeight),
      };
    };

    const updateNavTheme = () => {
      const scrollTop = window.pageYOffset;
      const scrollTopTriggerValue = getTopPositionValues(
        promoBarIsSticky,
        rotatingBarIsSticky
      );

      setStickyTopPosition(scrollTopTriggerValue?.stickyTopValue);

      if (window.location.pathname !== '/' || hovered || menuSidebar) {
        setTransparentBGEnabled(false);
        return;
      }

      if (scrollTop > scrollTopTriggerValue?.scrollTopValue) {
        setScrollIsActive(true);
        setTransparentBGEnabled(false);
      } else {
        setScrollIsActive(false);
        setTransparentBGEnabled(true);
      }
    };

    window.addEventListener('scroll', updateNavTheme);
    router.events.on('routeChangeComplete', onRountChangeComplete);

    return () => {
      window.removeEventListener('scroll', updateNavTheme);
      router.events.off('routeChangeComplete', onRountChangeComplete);
    };
  }, [hovered, menuSidebar, promoBarIsSticky, rotatingBarIsSticky]);

  // sets background state on page load
  useEffect(() => {
    onRountChangeComplete(router?.asPath?.split('?')[0]);
  }, [enable]);

  return {
    isLocked: menuSidebar || scrollIsActive, // prevents event listeners in react components and state updates
    transparentBGEnabled: enable && isHomepage && transparentBGEnabled,
    setTransparentBGEnabled,
    stickyTopPosition,
  };
};
