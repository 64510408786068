import { section, color, align, button } from '@settings/common';

export const Schema = ({ article, blog, collection }) => {
  if (article || blog || collection) return null;

  return {
    label: 'Products slider',
    key: 'products-slider',
    fields: [
      {
        name: 'groups',
        label: 'Product groups',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        defaultItem: {
          name: 'New Group',
          cta: {
            text: 'Shop All Products',
            url: '/collections/all',
          },
          products: [
            {
              product: { handle: 'black-crew-curve-hem' },
              showBadge: true,
              badgeStyle: 'primary',
            },
            {
              product: { handle: 'black-ao-jogger' },
              showBadge: true,
              badgeStyle: 'primary',
            },
            {
              product: { handle: 'heather-grey-hoodie-classic' },
              showBadge: true,
              badgeStyle: 'primary',
            },
            {
              product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
              showBadge: true,
              badgeStyle: 'primary',
            },
            {
              product: { handle: 'hoodie-classic-mini-logo' },
              showBadge: true,
              badgeStyle: 'primary',
            },
          ],
        },
        fields: [
          {
            name: 'name',
            label: 'Group name',
            component: 'text',
          },
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'black-crew-curve-hem' },
              showBadge: true,
              badgeStyle: 'primary',
            },
            fields: [
              {
                name: 'showBadge',
                label: 'Hide / Show badge',
                description: 'Show badge',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                defaultValue: true,
              },
              {
                name: 'badgeStyle',
                label: 'Badge style',
                description:
                  'Change between primary and secondary styles for the badge',
                component: 'radio-group',
                direction: 'horizontal',
                options: [
                  { label: 'Primary', value: 'primary' },
                  { label: 'Secondary', value: 'inverse' },
                ],
                defaultValue: 'primary',
              },

              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'black-crew-curve-hem' },
                showBadge: true,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'hoodie-classic-mini-logo' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'black-ao-jogger' },
                showBadge: true,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'heather-grey-hoodie-classic' },
                showBadge: true,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
                showBadge: true,
                badgeStyle: 'primary',
              },
            ],
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Group button',
          },
        ],
        defaultValue: [
          {
            name: 'Shop All',
            products: [
              {
                product: { handle: 'black-crew-curve-hem' },
                showBadge: false,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'black-ao-jogger' },
                showBadge: false,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'heather-grey-hoodie-classic' },
                showBadge: false,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'hoodie-classic-mini-logo' },
                showBadge: false,
                badgeStyle: 'primary',
              },
              {
                product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
                showBadge: false,
                badgeStyle: 'primary',
              },
            ],
            cta: {
              text: 'Shop All Products',
              url: '/collections/all',
            },
          },
          {
            name: 'New Products',
            products: [
              {
                product: { handle: 'heather-grey-hoodie-classic' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'hoodie-classic-mini-logo' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'black-ao-jogger' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'black-crew-curve-hem' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
            ],
            cta: {
              text: 'Shop New Products',
              url: '/collections/all',
            },
          },
          {
            name: 'Bundles',
            products: [
              {
                product: { handle: 'hoodie-classic-mini-logo' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'black-ao-jogger' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'heather-grey-hoodie-classic' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'nord-cabernet-l-s-hooded-elongated' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
              {
                product: { handle: 'black-crew-curve-hem' },
                showBadge: true,
                badgeStyle: 'inverse',
              },
            ],
            cta: {
              text: 'Shop All Bundles',
              url: '/collections/all',
            },
          },
        ],
      },
      {
        label: 'Content settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Group button style',
            component: 'select',
            options: button.options,
          },
          {
            name: 'slidesPerViewMobile',
            label: 'Slides Per View (mobile)',
            description:
              'Change between 1 and 2 slides per view on mobile layout.',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: '1', value: 'one' },
              { label: '2', value: 'two' },
            ],
            defaultValue: 'one',
          },
        ],
        defaultValue: {
          heading: 'Shop Best Sellers',
          textAlign: align.value.left,
          ctaStyle: 'primary',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
