import { useCallback } from 'react';
import { useCartItems, useRouter, useCustomer } from '@backpackjs/storefront';

import { useSearchspring } from '@hooks';
import store, { useRecoilState, useRecoilValue } from '@store';
import { getValidId, isBrowser } from '@utils';

export const useSearchResults = () => {
  const { requestSearchspring } = useSearchspring();
  const customer = useCustomer();
  const router = useRouter();
  const cartItems = useCartItems();

  const [searchData, setSearchData] = useRecoilState(store.searchData);
  const [searchPageData, setSearchPageData] = useRecoilState(
    store.searchPageData
  );
  const [currentResultsPage] = useRecoilState(store.searchCurrentResultsPage);
  const [selectedFilters] = useRecoilState(store.searchSelectedFilters);
  const selectedSort = useRecoilValue(store.searchSelectedSort);

  const customerId = customer?.id || '';
  const isVip =
    customer &&
    customer.tags.filter((tag) => tag === 'VIP Membership').length > 0;
  const vipBgFilter = isVip
    ? `&bgfilter.ss_vip_only=1&bgfilter.ss_vip_only=0`
    : `&bgfilter.ss_vip_only=0`;

  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';
  const lastViewed =
    (isBrowser && window.localStorage.getItem('lastViewed')) || '';

  const { asPath } = router;

  // Get search results from query from ss
  const getSearchResults = useCallback(
    async ({ query, isAutocomplete, isPage, resultsPerPage, tag }) => {
      try {
        const shopper = getValidId(customerId);

        const data = await requestSearchspring({
          action: 'getSearchResults',
          params: {
            query,
            bgFilters: '',
            filters: selectedFilters, // comma-separated list of filters
            resultsPerPage: isPage ? resultsPerPage || 60 : 10, // limit # of results shown; integer from 1-100
            page: isPage ? currentResultsPage : 1,
            isAutocomplete: isPage ? false : isAutocomplete,
            domain: `${process.env.SITE_URL}/${asPath?.split('?')[0]}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(tag ? { tag } : null), // tag in url for landing pages
            ...(shopper ? { shopper } : null), // customer id (if logged in)
            ...(selectedSort ? { sort: selectedSort } : null), // sort order
          },
        });

        let _data = data;
        const banners = data?.merchandising?.content?.inline;
        if (isPage && banners?.length) {
          const resultsLength = _data.results?.length;
          const results = banners.reduce(
            (arr, item, index) => {
              const pos = item.config.position.index;
              if (resultsLength + index <= pos) return arr;
              return [
                ...arr.slice(0, pos),
                {
                  isBanner: true,
                  value: item.value,
                  id: `banner-index-${index}`,
                },
                ...arr.slice(pos),
              ];
            },
            [..._data.results]
          );
          _data = { ..._data, results };
        }

        if (isPage) {
          return setSearchPageData(_data || null);
        }

        if (!query && !isPage) {
          return setSearchData(null);
        }

        return setSearchData(_data || null);
      } catch (error) {
        console.error(`Error with getSearchResults: ${error.message}`);
        throw error;
      }
    },
    [
      customerId,
      asPath,
      cart,
      lastViewed,
      currentResultsPage,
      selectedSort,
      selectedFilters,
    ]
  );

  return {
    results: searchData?.results || [],
    totalResults: searchData?.pagination?.totalResults || 0,
    pageResults: searchPageData?.results || [],
    totalPageResults: searchPageData?.pagination?.totalResults || 0,
    merchandising: searchPageData?.merchandising || null,
    getSearchResults,
  };
};

// Example data from api
// {
//   "pagination": {...},
//   "sorting": {
//       "options": [...]
//   },
//   "resultLayout": "grid",
//   "results": [
//       {
//           "brand": "Cuts Clothing",
//           "handle": "merino-crew-sweater-pacific-blue",
//           "id": "857a83a6620bcd84620c384e5270818c",
//           "imageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/SoldOutTemplateUPDATEDcopy_0c56ee39-683e-4e83-8090-e0a05fafd056_720x.jpg?v=1637021328",
//           "intellisuggestData": "eJwyNzIpLK5i0M0JyHI2KfQPd0nOikrONK6Kco3wYSguT00sSS0qZvANNjQwMDH0izcxMIkPZjBkMGQwYDBiSC_KTAEEAAD__423Emk",
//           "intellisuggestSignature": "a6ce27ca96e70d0dd65bf503ae25ff29f21f66fec01f9bdabe74b48553e231c2",
//           "msrp": "0",
//           "name": "Merino Crew Sweater | Pacific Blue Signature-fit Merino Wool",
//           "price": "150",
//           "product_type_unigram": "wool",
//           "sku": "MS10041N_404_S",
//           "ss_available": "1",
//           "ss_has_image": "1",
//           "ss_id": "3.965104206652e+13",
//           "ss_instock_pct": "40",
//           "ss_inventory_count": "3",
//           "ss_is_published": "1",
//           "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/SoldOutTemplateUPDATEDcopy_0c56ee39-683e-4e83-8090-e0a05fafd056_720x.jpg?v=1637021328",
//           "uid": "6798711226456",
//           "url": "https://www.cutsclothing.com/products/merino-crew-sweater-pacific-blue"
//       },
//       ...
//   ],
//   "facets": [...],
//   "breadcrumbs": [...],
//   "filterSummary": [...],
//   "merchandising": {...}
// }
