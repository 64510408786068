import { Grid } from 'theme-ui';

import { themed } from './Body.theme';

export const Body = themed(({ theme, index, children, ...props }) => {
  return (
    <Grid
      data-comp={Body.displayName}
      role="tabpanel"
      as="ul"
      id={`tabpanel-id-${index}`}
      {...props}
      sx={{
        ...theme.body,
        ...props.sx,
      }}
    >
      {children}
    </Grid>
  );
});

Body.displayName = 'Body';
