import { useCallback } from 'react';
import { nanoid } from 'nanoid';

export const useSearchspring = () => {
  const getClientIp = useCallback(async () => {
    const response = await fetch('/api/client-ip');
    const data = await response.json();
    return data?.query || null;
  }, []);

  const getIdOrGenerateId = useCallback(async (name) => {
    if (!name) return null;

    const cookie = document.cookie
      ?.split('; ')
      .find((_cookie) => _cookie.startsWith(`${name}=`));

    if (cookie) {
      const [_, value] = cookie.split('=');
      return value;
    }

    const newId = nanoid();
    document.cookie = `${name}=${newId}`;
    return newId;
  }, []);

  const requestSearchspring = useCallback(async ({ action, params }) => {
    if (!action) throw new Error('No Searchspring action provided');

    try {
      const hasUserTracking = ['getCollection', 'getSearchResults'].includes(
        action
      );
      const ssUserId = hasUserTracking
        ? await getIdOrGenerateId('ssUserId')
        : null;

      // const ip = await getClientIp();

      const url = `/api/searchspring`;

      const headers = hasUserTracking
        ? {
            'searchspring-session-id': await getIdOrGenerateId(
              'ssSessionIdNamespace'
            ),
            'searchspring-user-id': ssUserId,
            'searchspring-page-load-id': nanoid(),
            'User-Agent': navigator.userAgent || '',
            // 'X-Forwarded-For': ip || '',
          }
        : {};

      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify({
          action,
          ...params,
          ...(hasUserTracking ? { userId: ssUserId } : null),
        }),
      };

      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          `${response?.status} - ${response?.statusText} - ${data?.errors}`
        );
      }
      return data?.data;
    } catch (error) {
      console.error(`Error with requestSearchspring: ${error.message}`);
    }
  }, []);

  return {
    requestSearchspring,
  };
};
