import { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Spinner, Flex, Heading } from 'theme-ui';

import { useYotpoLoyalty } from '@hooks/yotpo';
import store, { useRecoilValue } from '@store';

import { Schema } from './YotpoRewards.schema';

export const YotpoRewards = ({ cms }) => {
  const ref = useRef();
  const scriptRef = useRef(null);
  useYotpoLoyalty();
  const { yotpoCustomer } = useRecoilValue(store.yotpoLoyaltyData);

  const [injected, setInjected] = useState(false);

  const injectYotpoScriptOnMount = useCallback(async () => {
    try {
      const script = document.createElement('script');
      scriptRef.current = script;
      script.type = 'text/javascript';
      script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${process.env.NEXT_PUBLIC_YOTPO_LOYALTY_GUID}`;
      script.setAttribute('id', `swell-rewards`);
      script.setAttribute('strategy', 'afterInteractive');
      script.setAttribute('async', true);
      if (ref.current) {
        await ref.current.appendChild(script);
      };
      setInjected(true);
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  useEffect(() => {
    setTimeout(injectYotpoScriptOnMount, 2500);
  }, [yotpoCustomer?.email]);

  return (
    <Box
      ref={ref}
      sx={{
        minHeight: '800px',
      }}
    >
      {!injected && (
        <Flex
          variant="flex.column.center.center"
          sx={{
            height: '500px',
          }}
        >
          <Heading
            variant="text.24.r"
            sx={{
              mb: 10,
              px: 10,
              textAlign: 'center',
            }}
          >
            {cms?.text}
          </Heading>
          <Spinner />
        </Flex>
      )}

      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={yotpoCustomer?.email}
        data-id={yotpoCustomer?.third_party_id}
        data-tags='"[]"'
        style={{ display: 'none' }}
      />

      <Box className="yotpo-widget-instance" data-yotpo-instance-id="161261" />
    </Box>
  );
};

YotpoRewards.displayName = 'YotpoRewards';
YotpoRewards.Schema = Schema;
