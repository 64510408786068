import Head from 'next/head';

const HeadFonts = () => {
  return (
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Fragment+Mono&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://crossborder-integration.global-e.com/resources/css/1249/US"
      />
    </Head>
  );
};

export default HeadFonts;
