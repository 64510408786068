import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      display: 'grid',
      gridGap: [10],
      gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
    };

    this.portrait = {
      width: '100%',
      ':before': {
        content: '""',
        pb: `${(1 / 0.75) * 100}%`,
        float: 'left',
      },
    };

    this.square = {
      width: '100%',
      ':before': {
        content: '""',
        pb: '100%',
        float: 'left',
      },
    };
  })()
);
