import { Box, Spinner } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(
  ({ theme, src, title, handle, status, handleClick }) => {
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Link
          data-comp={Image.displayName}
          href={`/pages/${handle}`}
          sx={theme.image}
          onClick={handleClick}
        >
          <Picture
            alt={title}
            images={[
              {
                src,
                width: 350,
                ratio: 3 / 4,
              },
            ]}
            sx={{
              opacity: status === 'ended' ? 0.6 : 1,
            }}
          >
            {!src && <Spinner sx={theme.spinner} />}
          </Picture>
        </Link>
      </Box>
    );
  }
);

Image.displayName = 'Image';
