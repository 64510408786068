export const countries = [
  {
    currency: {
      isoCode: 'MXN',
      name: 'Mexican Peso',
      symbol: '$',
    },
    isoCode: 'MX',
    locale: {
      name: 'Mexico',
      alpha2: 'MX',
      alpha3: 'MEX',
      numeric: '484',
      locales: ['es_MX'],
      default_locale: 'es_MX',
      currency: 'MXN',
      currency_name: 'Mexican Peso',
      languages: ['es'],
      capital: 'Mexico City',
      emoji: '🇲🇽',
      flag: '/svgs/noprecache/flags/MX.svg',
      emojiU: 'U+1F1F2 U+1F1FD',
      fips: 'MX',
      internet: 'MX',
      continent: 'Americas',
      region: 'Central America',
    },
    name: 'Mexico',
    unitSystem: 'METRIC_SYSTEM',
  },
  {
    currency: {
      isoCode: 'COP',
      name: 'Colombian Peso',
      symbol: '$',
    },
    isoCode: 'CO',
    locale: {
      name: 'Colombia',
      alpha2: 'CO',
      alpha3: 'COL',
      numeric: '170',
      locales: ['es_CO'],
      default_locale: 'es_CO',
      currency: 'COP',
      currency_name: 'Colombian Peso',
      languages: ['es'],
      capital: 'Bogotá',
      emoji: '🇨🇴',
      flag: '/svgs/noprecache/flags/CO.svg',
      emojiU: 'U+1F1E8 U+1F1F4',
      fips: 'CO',
      internet: 'CO',
      continent: 'Americas',
      region: 'South America',
    },
    name: 'Colombia',
    unitSystem: 'METRIC_SYSTEM',
  },
  {
    currency: {
      isoCode: 'CHF',
      name: 'Swiss Franc',
      symbol: 'Fr',
    },
    isoCode: 'CH',
    locale: {
      name: 'Switzerland',
      alpha2: 'CH',
      alpha3: 'CHE',
      numeric: '756',
      locales: ['de_CH', 'fr_CH', 'it_CH'],
      default_locale: 'de_CH',
      currency: 'CHF',
      currency_name: 'Swiss Franc',
      languages: ['de', 'fr', 'it'],
      capital: 'Bern',
      emoji: '🇨🇭',
      flag: '/svgs/noprecache/flags/CH.svg',
      emojiU: 'U+1F1E8 U+1F1ED',
      fips: 'SZ',
      internet: 'CH',
      continent: 'Europe',
      region: 'Western Europe',
    },
    name: 'Switzerland',
    unitSystem: 'METRIC_SYSTEM',
  },
  {
    currency: {
      isoCode: 'CLP',
      name: 'Chilean Peso',
      symbol: '$',
    },
    isoCode: 'CL',
    locale: {
      name: 'Chile',
      alpha2: 'CL',
      alpha3: 'CHL',
      numeric: '152',
      locales: ['es_CL'],
      default_locale: 'es_CL',
      currency: 'CLP',
      currency_name: 'Chilean Peso',
      languages: ['es'],
      capital: 'Santiago',
      emoji: '🇨🇱',
      flag: '/svgs/noprecache/flags/CL.svg',
      emojiU: 'U+1F1E8 U+1F1F1',
      fips: 'CI',
      internet: 'CL',
      continent: 'Americas',
      region: 'South America',
    },
    name: 'Chile',
    unitSystem: 'METRIC_SYSTEM',
  },
  {
    currency: {
      isoCode: 'BHD',
      name: 'Bahraini Dinar',
      symbol: '.د.ب',
    },
    isoCode: 'BH',
    locale: {
      name: 'Bahrain',
      alpha2: 'BH',
      alpha3: 'BHR',
      numeric: '048',
      locales: ['ar_BH', 'en'],
      default_locale: 'ar_BH',
      currency: 'BHD',
      currency_name: 'Bahraini Dinar',
      languages: ['ar'],
      capital: 'Manama',
      emoji: '🇧🇭',
      flag: '/svgs/noprecache/flags/BH.svg',
      emojiU: 'U+1F1E7 U+1F1ED',
      fips: 'BA',
      internet: 'BH',
      continent: 'Asia',
      region: 'Middle East',
    },
    name: 'Bahrain',
    unitSystem: 'METRIC_SYSTEM',
  },
  {
    currency: {
      isoCode: 'HRK',
      name: 'Croatian Kuna',
      symbol: 'kn',
    },
    isoCode: 'HR',
    locale: {
      name: 'Croatia',
      alpha2: 'HR',
      alpha3: 'HRV',
      numeric: '191',
      locales: ['hr_HR'],
      default_locale: 'hr_HR',
      currency: 'HRK',
      currency_name: 'Croatian Kuna',
      languages: ['hr'],
      capital: 'Zagreb',
      emoji: '🇭🇷',
      flag: '/svgs/noprecache/flags/HR.svg',
      emojiU: 'U+1F1ED U+1F1F7',
      fips: 'HR',
      internet: 'HR',
      continent: 'Europe',
      region: 'Southern Europe',
    },
    name: 'Croatia',
    unitSystem: 'METRIC_SYSTEM',
  }

];
