import { Children, useMemo, cloneElement } from 'react';
import { Flex } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';
import { themed } from './Content.theme';

export const Content = themed(
  ({ theme, children, isOverFlowing, setIsOverFlowing, ...props }) => {
    const cartCount = useCartCount();

    const clonedChildren = useMemo(
      () =>
        Children.map(children, (child) => {
          if (!child) return null;
          return cloneElement(child, {
            setIsOverFlowing,
          });
        }),
      [Children.count(children), setIsOverFlowing, cartCount, isOverFlowing]
    );

    return (
      <Flex data-comp={Content.displayName} {...props} sx={theme.content}>
        {clonedChildren}
      </Flex>
    );
  }
);

Content.displayName = 'Content';
