import { color } from '@settings/common';

export const shipping = {
  label: 'Shipping',
  name: 'shipping',
  component: 'group',
  fields: [
    {
      label: 'Free shipping message',
      name: 'message',
      component: 'text',
      defaultValue: 'Free Shipping + Free Returns. No Hassel.',
    },
    {
      label: 'International Free shipping message',
      name: 'internationalMessage',
      component: 'text',
      defaultValue: 'Free International Shipping + Free Returns. No Hassel.',
    },
    {
      name: 'textColor',
      label: 'Text Color',
      component: 'select',
      options: color.options,
    },
  ],
};
