import { create } from '@theme/create';

const MENU_HEIGHT = ['52px', null, '56px'];

export const themed = create(
  new (function () {
    this.wrapper = (transparentBGEnabled) => ({
      alignItems: 'center',
      // transition: '0.3s ease-in-out background',
      bg: transparentBGEnabled ? 'transparent' : 'background',
      columnGap: [4, 8, 12, 14],
      gridTemplateColumns: ['1fr 104px 1fr ', '1fr 114px 1fr'],
      justifyContent: 'space-between',
      position: 'relative',
      height: MENU_HEIGHT,
      width: '100%',
      variant: 'layout.contentMedium',
      zIndex: 2,
    });

    // burger + search — mobile
    (this.toggle = {
      display: ['flex', null, 'none'],
      width: 100,
    }),
      (this.logo = (transparentBGEnabled) => ({
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: transparentBGEnabled ? 'transparent' : 'black',
        fill: transparentBGEnabled ? 'transparent' : 'black',
        stroke: transparentBGEnabled ? 'transparent' : 'black',
        // transition: '0.3s ease-in-out all',
        svg: {
          height: '40px',
        },
      })),
      (this.nav = {
        variant: 'flex.row.start.center',
        display: ['none', null, 'flex'],
        height: 'inherit',
        flex: 1,
      }),
      (this.item = (transparentBGEnabled) => ({
        variant: 'text.nav',
        display: 'block',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        mr: [10, 11, 12],
        opacity: 1,
        position: 'relative',
        color: transparentBGEnabled ? 'white' : 'black',

        // hovered menu item
        hover: {
          bg: 'text',
          bottom: 0,
          content: '" "',
          display: 'block',
          height: '1px',
          left: 0,
          position: 'absolute',
          right: 0,
          transition: 'transform .125s ease-out',
          transformOrigin: 'bottom left',
          width: '100%',
          willChange: 'transform',

          hidden: {
            transform: 'scaleX(0)',
          },
          visible: {
            transform: 'scaleX(1)',
          },
        },
      })),
      (this.ctas = {
        variant: 'flex.row.end.center',
        columnGap: [6, 8, 10],
        gridTemplateColumns: ['1fr 1fr', 'auto 1fr 1fr 1fr'],
        ml: 'auto',
        width: ['auto'],
      }),
      (this.cta = {
        icon: {
          width: [24, null, 22],
          cursor: 'pointer',
        },
      });
  })()
);
