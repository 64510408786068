import { Container, Box } from 'theme-ui';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Content } from '../Slide';
import { themed } from './Slider.theme';

export const ContentSlider = themed(
  ({
    theme,
    activeIndex,
    bgColor,
    contentSwiper,
    setContentSwiper,
    showContentSlider,
    slider,
    slides,
  }) => {
    return (
      <Container
        data-comp={ContentSlider.displayName}
        sx={{
          display: showContentSlider ? 'block' : 'none',
        }}
      >
        {slides?.length > 1 ? (
          <Swiper
            {...defaultParams}
            autoplay={
              slider?.autoplay
                ? { delay: slider.delay || 5000, disableOnInteraction: true }
                : false
            }
            effect={slider?.effect === 'fade' ? 'fade' : null}
            onSwiper={setContentSwiper}
            modules={[EffectFade]}
          >
            {slides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    ...theme.contentSlide,
                    bg: bgColor || 'background',
                  }}
                >
                  <Content
                    slide={slide}
                    index={index}
                    visible={slider?.effect !== 'fade' || index === activeIndex}
                    isBelowHero
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : slides?.length > 0 ? (
          <Box
            sx={{
              ...theme.contentSlide,
              bg: bgColor || 'background',
            }}
          >
            <Content slide={slides[0]} isBelowHero />
          </Box>
        ) : null}
      </Container>
    );
  }
);

ContentSlider.displayName = 'ContentSlider';

const defaultParams = {
  allowTouchMove: false,
  centeredSlides: false,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: false,
  navigation: false,
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 25,
  watchOverflow: true,
};
