import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { themed } from './GridDisplay.theme';

import 'swiper/css/bundle';

const Tile = dynamic(() => import('../Tile').then((module) => module.Tile), {
  ssr: false,
  suspense: false,
});

export const GridDisplay = themed(({ theme, tiles, inView }) => {
  return (
    <Container data-comp={GridDisplay.displayName} sx={theme.grid}>
      {tiles?.slice(0, 2).map((item, index) => {
        return <Box key={index}>{inView && <Tile item={item} />}</Box>;
      })}
    </Container>
  );
});

GridDisplay.displayName = 'GridDisplay';
