import { Box } from 'theme-ui';

import { themed } from './Sidebar.theme';

export const SidebarShell = themed(
  ({ theme, open = false, rtl = true, children, ...props }) => {
    return (
      <Box
        as="aside"
        data-comp={SidebarShell.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.shell,
          ...(open ? theme.shell.open : theme.shell.closed),
        }}
      >
        {open ? children : null}
      </Box>
    );
  }
);

SidebarShell.displayName = 'SidebarShell';
