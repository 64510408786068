export const heading = ({ align }) => ({
  label: 'Heading settings',
  name: 'header',
  description: 'Heading, text align, heading button',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'textAlign',
      label: 'Heading alignment',
      options: align.options,
    },
  ],
  defaultValue: {
    heading: '#MadeTheCut',
    textAlign: align.value.center,
  },
});
