export const salesLinks = {
  label: 'Sales Links',
  name: 'salesLinks',
  component: 'group',
  fields: [
    {
      label: 'Show Links',
      name: 'enable',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: false,
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'color',
    },
    {
      label: 'Desktop Link',
      name: 'desktop',
      component: 'group',
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
    },
    {
      label: 'Mobile Links',
      name: 'mobile',
      component: 'group',
      fields: [
        {
          label: 'Link (Mens)',
          name: 'mensLink',
          component: 'link',
        },
        {
          label: 'Link (Womens)',
          name: 'womensLink',
          component: 'link',
        },
      ],
    },
  ],
};
