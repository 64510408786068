import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      height: '100%',
      width: '100%',
      position: 'relative',
      '@media screen and (min-width: 480px)': {
        '&:hover [data-comp=QuickAdd]': { opacity: 1.0 },
      },
    };
    this.badge = {
      borderRadius: '0px 4px 4px 0px',
      minWidth: 'unset',
      px: 5,
    };

    this.badgeWrapper = {
      top: '12px',
      height: 'fit-content',
      position: 'absolute',
      left: 0,
      zIndex: 1,
    };
  })()
);
