import store, { useRecoilValue, useSetRecoilState } from '@store';
import { useProduct } from '@backpackjs/storefront';

export const useSelectedOptions = () => {
  const product = useProduct();
  const selectedOptions = useRecoilValue(
    store.selectedOptions(product?.handle)
  );
  const setSelectedOptions = useSetRecoilState(
    store.setSelectedOptions$(product?.handle)
  );

  return [selectedOptions, setSelectedOptions];
};
