/**
 *
 * @param {object} product product object
 * @param {string} tagKey tag to get value from
 * @returns
 */
export const getValueFromTag = (product, tagKey) => {
  const tagValue = product?.tags?.find(
    (tag) => tag.includes(`${tagKey}::`) || ''
  );
  const value = tagValue?.split('::')[1];
  return value || null;
};
