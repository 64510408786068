import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import { useProductByHandle } from '@backpackjs/storefront';

import { Image } from './Image';
import { Details } from './Details';
import { themed } from './ProductItem.theme';
import { Links } from './Links';

export const ProductItem = themed(
  ({ theme, product, index, settings, hasIcons = true, ...props }) => {
    if (!product) return null;

    const { ref, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });

    const { showTitle, showIcons, showButton } = settings;

    const showBox = showTitle || showIcons || showButton;

    const { product: fullProduct } = useProductByHandle({
      handle: product?.product?.handle,
      fetchOnMount: inView,
    });

    return (
      <Box
        data-comp={ProductItem.displayName}
        ref={ref}
        {...props}
        sx={{
          ...theme.wrapper,
          ...props.sx,
        }}
      >
        <Image product={product} fullProduct={fullProduct} />
        {showBox && (
          <Box
            sx={{
              py: [5, 8],
              // px: [6, 10],
              overflow: 'hidden',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Details
              product={product}
              showTitle={showTitle}
              showContent={showIcons}
            />
            {settings?.showButton && (
              <Links theme={theme} link={product?.button} />
            )}
          </Box>
        )}
      </Box>
    );
  }
);

ProductItem.displayName = 'ProductItem';
