import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      fontFamily: 'body',
      py: 16,
    };
    this.title = {
      variant: 'text.h4',
      fontWeight: 700,
      textAlign: 'center',
      my: 10,
    };
    this.list = {
      display: 'grid',
      gridTemplateColumns: ['repeat(3, 1fr)', 'repeat(6, 1fr)'],
      gridGap: [0, 8],
      gridRowGap: [4, 0],
      px: [10, 11, 11, 12],
      maxWidth: '90em',
      margin: '0 auto',
    };
    this.item = {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        left: ['5px', 10],
        right: ['5px', 10],
        top: 0,
        bottom: 0,
        border: '1px solid',
        borderColor: 'gray.light',
        transition: 'border-color .3s',
      },
      '@media screen and (min-width: 480px)': {
        '&:hover:after': {
          borderColor: 'gray.dark',
        },
      },
      '&:nth-child(n+4)': {
        flexDirection: 'column-reverse',
      },
    };
    this.name = {
      fontSize: 1,
      fontWeight: 700,
      py: 10,
    };
    this.image = {
      position: 'relative',
      maxHeight: ['70px', '130px'],
      zIndex: 3,
    };
    this.imageSx = {
      objectFit: 'contain',
      objectPosition: 'center bottom',
    };
  })()
);
