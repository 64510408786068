export default {
  label: 'Super Collection Settings',
  name: 'superCollection',
  component: 'group',
  fields: [
    {
      label: 'Show Flat/Model Option (Mens)',
      name: 'showFlatModelOptionMens',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: true,
    },
    {
      label: 'Show Flat/Model Option (Womens)',
      name: 'showFlatModelOptionWomens',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: true,
    },
  ],
};
