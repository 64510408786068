import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      py: [16, 18],
      fontFamily: 'body',
    };
    this.container = {
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
      gridRowGap: ['36px'],
      gridGap: ['36px'],
      justifyContent: 'space-between',
      px: [10, 12, 14],
      maxWidth: '980px',
      margin: '0 auto',
    };
    this.title = {
      variant: 'text.h3',
      display: 'flex',
      justifyContent: 'center',
      mb: 12,
    };
    this.item = {
      display: 'flex',
      maxWidth: '420px',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flex: ['50%', null, 1],
      color: 'text',
    };
    this.image = {
      width: ['160px', '200px'],
      height: ['160px', '200px'],
      mb: [8],
      position: 'relative',
      overflow: 'visible',
    };
    this.itemText = {
      variant: 'text.base',
      maxWidth: '70%',
    };
    this.iconWrap = {
      display: 'flex',
      variant: 'flex.row.center',
      width: '26px',
      height: '26px',
      backgroundColor: 'black',
      borderRadius: '50%',
      mt: '16px',
      '& + &': {
        marginLeft: '8px',
      },
    };
    this.icon = {
      width: '16px',
      fill: 'white',
      color: 'white',
    };
  })()
);
