export const layout = {
  options: [
    { label: 'Full Bleed', value: 'container' },
    { label: 'Padded', value: 'padded' },
    { label: 'Contained (Narrow)', value: 'contentNarrow' },
    { label: 'Contained (Medium)', value: 'contentMedium' },
    { label: 'Contained (Wide)', value: 'content' },
  ],
  value: {
    container: 'container',
    padded: 'padded',
    contentNarrow: 'contentNarrow',
    contentMedium: 'contentMedium',
    content: 'content',
  },
};
