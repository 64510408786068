import { useState, useEffect } from 'react';
import { Text } from 'theme-ui';

export const Timer = ({ nextDropTime }) => {
  const [dropTime, setDropTime] = useState({});

  useEffect(() => {
    updateDropTime();

    const interval = setInterval(() => {
      updateDropTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const updateDropTime = () => {
    const now = new Date().getTime();
    const timeleft = Date.parse(nextDropTime) - now;
    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    // if an the ends, do something
    if (days == -1 && hours == -1 && minutes == -1 && seconds == -1) {
      console.log('timer is up');
    }

    const newTime =
      timeleft >= 0
        ? {
            days: Number(days).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            }),
            hours: Number(hours).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            }),
            minutes: Number(minutes).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            }),
            seconds: Number(seconds).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            }),
          }
        : {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
          };

    setDropTime(newTime);
  };

  return (
    <Text
      sx={{
        fontFamily: 'Fragment Mono !important',
        textTransform: 'uppercase',
        my: '16px',
      }}
    >
      {`NEXT DROP IN: ${dropTime.days}:${dropTime.hours}:${dropTime.minutes}:${dropTime.seconds}`}
    </Text>
  );
};
