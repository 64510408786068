export const usePickedSections = (
  { firstOf, anyOf, sections } = {
    firstOf: [],
    anyOf: [],
    sections: [],
  }
) => {
  if (!Array.isArray(sections) || !sections.length) {
    return {
      firstOf: [],
      anyOf: [],
      otherSections: [],
    };
  }

  const filteredFirstOfs = [];
  let filteredAnyOfs = [];

  const firstOfSections = firstOf?.length
    ? firstOf.reduce((_firstOf, key) => {
        const matchingKey = (section) => section?.props?.['data-comp'] === key;
        const firstOfKeyIndex = sections.findIndex(matchingKey);

        // track filtered
        filteredFirstOfs.push(firstOfKeyIndex);

        _firstOf[key] = sections[firstOfKeyIndex];
        return _firstOf;
      }, {})
    : null;

  const anyOfSections = anyOf?.length
    ? anyOf.reduce((_anyOf, key) => {
        const matchingKey = (section) => section?.props?.['data-comp'] === key;
        const matchingIndexes = sections.reduce((_indexes, section, index) => {
          const matchesKey = section?.props?.['data-comp'] === key;
          if (matchesKey) {
            _indexes = [..._indexes, index];
          }
          return _indexes;
        }, []);

        // track anyOfs
        filteredAnyOfs = [...new Set([...filteredAnyOfs, ...matchingIndexes])];

        _anyOf[key] = sections.filter(matchingKey);
        return _anyOf;
      }, {})
    : [];

  const pickedIndexes = [...new Set([...filteredFirstOfs, ...filteredAnyOfs])];

  const unpickedSections = sections.filter(
    (_, index) => !pickedIndexes.includes(index)
  );

  return {
    firstOf: firstOfSections,
    anyOf: anyOfSections,
    otherSections: unpickedSections,
  };
};
