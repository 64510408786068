import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.video = {
      width: '100%',
    };

    this.icon = {
      position: 'relative',
      mt: '50%',
      ml: 0,
      width: [115, 100],
    };
  })()
);
