import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mt: [12, 15],
      mb: [15, 18],
      ml: 0,
    };

    this.title = {
      variant: 'text.label.1',
      mx: 1,
    };
    this.collection = {
      variant: [null, 'text.h3', 'text.h2'],
      mt: [0, 5],
    };
  })()
);
