import Script from 'next/script';

export const CookiesOneTrustScript = () => (
  <>
    <Script
      defer={true}
      key="one-trust-script"
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script="deb9b185-5c50-428a-afed-96d8bcdfe987"
    />
    <Script
      key="one-trust-script-function"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        function OptanonWrapper() { }
        `,
      }}
    />
  </>
);
