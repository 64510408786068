import React, { useMemo } from 'react';
import { Section } from '@snippets';
import { calcResponsiveHeight, withInView } from '@utils';
import { themed } from './VideoSection.theme';
import { Schema } from './VideoSection.schema';
import { Container } from 'theme-ui';
import { VideoPlayer } from '@snippets/VideoPlayer';

export const VideoSection = withInView(
  themed(({ theme, cms }) => {
    const { section, source, playBtnColor, overlay } = cms;
    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);
    return (
      <Section section={section}>
        <Container
          data-comp={VideoSection.displayName}
          sx={{
            ...theme.container,
            height,
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <VideoPlayer
            styles={theme.video}
            source={source}
            showPlayButton
            autoPlay={false}
            playButtonIcon={'/svgs/video_play.svg#videoPlay'}
            playIconStyle={{
              ...theme.icon,
              svg: {
                color: playBtnColor,
              },
            }}
            playButtonStyle={theme.playButton}
            viewBox={'-2 -5 115 40'}
            poster={overlay}
          />
        </Container>
      </Section>
    );
  })
);

VideoSection.displayName = 'VideoSection';
VideoSection.Schema = Schema;
