import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      display: ['none', null, 'block'],
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 'calc(100% + 1px)',
      width: '100%',
      zIndex: -1,
      visibility: 'hidden',
      // transition: '0.3s ease-in-out opacity',

      closed: {
        boxShadow: 'none',
      },

      // revealed via animation
      open: {
        boxShadow: 'bottom',
      },
    };

    this.drawer = {
      variant: 'layout.contentMedium',
      bg: 'background',
      py: [10, 11, 12, 14],
    };
  })()
);
