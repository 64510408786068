import Script from "next/script"
import { useEffect } from 'react';

export function FueledTikTokScript({ withKey: tiktokPixelId }) {
  useEffect(() => {
    const identify = (event) => {
      if (event.detail.user_properties?.customer_email) {
        // tiktok
        window.ttq.identify({
          external_id: event.detail.user_properties?.customer_id,
          email: event.detail.user_properties?.customer_email,
        });
      }
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.detail.products;

      // tiktok
      window.ttq.instance(tiktokPixelId).track(
        'ViewContent',
        {
          content_name: product.name,
          content_category: product.category,
          content_id: product.product_id,
          content_type: 'product_group',
          price: product.price,
          value: product.price,
          currency: event.detail.ecommerce.currency_code,
        },
        { eventID: event.detail.event_id }
      );
    };

    const addToCartListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.add.products;

      // tiktok
      window.ttq.instance(tiktokPixelId).track(
        'AddToCart',
        {
          content_name: product.name,
          content_category: product.category,
          content_id: product.product_id,
          content_type: 'product_group',
          quantity: product.quantity,
          price: product.price,
          value: product.price * product.quantity,
          currency: event.detail.ecommerce.currency_code,
        },
        { eventID: event.detail.event_id }
      );
    };

    const viewSearchResultsListener = (event) => {
      try {
        identify(event);

        const searchTerm = event?.detail?.ecommerce?.actionField?.search_term

        if(searchTerm) {
          ttq.instance(tiktokPixelId).track('Search', {
            query: searchTerm,
          }, {eventID: 'dl_view_search_results'});
        }
      } catch (e) {
        console.log(e)
      }
    }

    const signUpListener = (event) => {
      try {
        identify(event);

        ttq.instance(tiktokPixelId).track('CompleteRegistration', {}, {eventID: 'dl_sign_up'});
      } catch (e) {
        console.log(e)
      }
    }

    const viewItemListListener = (event) => {
      try {
        identify(event);

        const collection = event?.detail?.ecommerce?.collection_id
        const currencyCode = event?.detail?.ecommerce?.currency_code;
        if(collection) {
          ttq.instance(tiktokPixelId).track('ViewContent', {
            contents: collection,
            currency: currencyCode,
            value: event?.detail?.ecommerce?.products?.reduce(function(acc, item){return acc += parseFloat(item.price);}, 0) || []
          }, {eventID: 'dl_view_item_list'});
        }
      } catch (e) {
        console.log(e)
      }
    }

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);
    window.addEventListener('dl_view_search_results', viewSearchResultsListener);
    window.addEventListener('dl_sign_up', signUpListener);
    window.addEventListener('dl_view_item_list', viewItemListListener)

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
      window.removeEventListener('dl_view_search_results', viewSearchResultsListener);
      window.removeEventListener('dl_sign_up', signUpListener);
      window.removeEventListener('dl_view_item_list', viewItemListListener)
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          !function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
          ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
          ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
          for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
          ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
          ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
          var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
          var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('${tiktokPixelId}'); ttq.page(); }(window, document, 'ttq');
        `,
      }}
    />
  );
}
