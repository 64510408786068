import { useState } from 'react';
import { Section, Markdown, Accordions, Accordion } from '@snippets';
import { withInView } from '@utils';
import { Button, Container, Paragraph, Text } from 'theme-ui';
import { Schema } from './FAQSection.schema';
import { themed } from './FAQSection.theme';

export const FAQSection = withInView(
  themed(({ theme, cms }) => {
    const { section, title, faqItems, itemsToShow } = cms;
    const [maxItems, setMaxItems] = useState(itemsToShow);

    return (
      <Section section={section}>
        <Container sx={theme.wrapper}>
          <Paragraph sx={theme.title}>{title}</Paragraph>
          <Accordions minHeight="60px" sx={theme.accordion}>
            {faqItems?.slice(0, maxItems)?.map((item, index) => {
              const { question, answer } = item;
              return (
                <Accordion key={index} sx={theme.accordionItem}>
                  <Accordion.Header sx={theme.accordionHeader} plusIcon>
                    <Text sx={theme.top}>{question}</Text>
                  </Accordion.Header>

                  <Accordion.Body
                    sx={{
                      ...theme.accordionBody,
                      pt: 4,
                    }}
                  >
                    <Markdown text={answer} textSx={theme.content} />
                  </Accordion.Body>
                </Accordion>
              );
            })}
          </Accordions>
          {maxItems <= faqItems?.length && (
            <Button onClick={() => setMaxItems(maxItems + 3)} sx={theme.more}>
              Load more
            </Button>
          )}
        </Container>
      </Section>
    );
  })
);

FAQSection.displayName = 'FAQSection';
FAQSection.Schema = Schema;
