import { useCallback, useState, useEffect } from 'react';
import { store } from '@backpackjs/storefront';

// Generates 3 variables:
// 1. Object of size availabilites by product type
// 2. Object of selected sizes by product type
// 3. Function to update sizes

export const useKitSizes = ({ productsByType, products }) => {
  const [inventoryMapByHandle, setInventoryMapByHandle] = useState(null);
  const [activeSizesByType, setActiveSizesByType] = useState(null);
  const [sizeAvailsByType, setSizeAvailsByType] = useState(null);

  const fetchProductInventoryByHandle = store.recoil.useRecoilCallback(
    ({ snapshot }) =>
      async (_handle) => {
        try {
          const { product } = await snapshot.getPromise(
            store.state.fetchProductInventoryByHandle({ handle: _handle })
          );
          return product;
        } catch (error) {
          return null;
        }
      },
    []
  );

  const generateInventoryMapFromProducts = useCallback(async (_products) => {
    const inventoryPromises = _products.map(async (product) => {
      return fetchProductInventoryByHandle(product.handle);
    });
    const inventories = await Promise.all(inventoryPromises);

    const _inventoryMapByHandle = inventories?.reduce((acc, inventory) => {
      if (!inventory) return acc;
      return {
        ...acc,
        [inventory.handle]: inventory,
      };
    }, {});

    setInventoryMapByHandle(_inventoryMapByHandle);
  }, []);

  useEffect(() => {
    if (!products?.length) return undefined;
    generateInventoryMapFromProducts(products);
    return () => {
      setInventoryMapByHandle(null);
    };
  }, [products]);

  const setSizesUponKitProductsByType = () => {
    if (!productsByType) return;

    const _activeSizesByType = {};
    const _sizeAvailsByType = Object.entries(productsByType).reduce(
      (obj, [type, _products]) => {
        // two types of bottoms
        // - shorts with size option uses alpha sizing: 'XS', 'S', ...
        // - shorts with waist option uses numeric sizing: 28, 30, ...

        const useAlphaSizing =
          (type === 'Bottoms' || type === 'Womens Bottoms') &&
          _products?.length &&
          !Object.values(_products[0])[0]?.selectedOptionsMap?.Waist;

        const sizes =
          type === 'Accessories'
            ? ['O / S', 'S / M', 'L / XL']
            : type === 'Gift Wrap'
            ? ['O / S']
            : type === 'Headwear'
            ? ['OS']
            : type === 'Pants'
            ? [
                '28 / 32',
                '30 / 32',
                '31 / 32',
                '32 / 32',
                '32 / 34',
                '33 / 32',
                '34 / 32',
                '34 / 34',
                '36 / 32',
                '36 / 34',
                '38 / 32',
                '38 / 34',
              ]
            : type === 'Womens Bottoms' && !useAlphaSizing
            ? ['0', '2', '4', '6', '8', '10', '12', '14', '16']
            : type === 'Womens Bottoms'
            ? ['XS', 'S', 'M', 'L', 'XL', 'XXL']
            : type === 'Bottoms' && !useAlphaSizing
            ? ['28', '30', '32', '34', '36', '38']
            : ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

        const checkSizeAvailability = (size) => {
          return _products.every((variant) => {
            const variantId = variant[size]?.id;
            const productHandle = variant[size]?.product?.handle;
            const availableForSale = inventoryMapByHandle[
              productHandle
            ]?.variants?.find(
              (_variant) => _variant.id === variantId
            )?.availableForSale;
            return availableForSale;
          });
        };

        // Generate object of each size and its availability across all products of that type
        // Set first size as available as first avail
        let _firstAvailSize = null;
        const _kitSizeAvails = sizes.reduce((sizeAvailObj, size) => {
          const isAvail = checkSizeAvailability(size);
          !_firstAvailSize && isAvail ? (_firstAvailSize = size) : null;
          sizeAvailObj[size] = isAvail;
          return sizeAvailObj;
        }, {});

        obj[type] = _kitSizeAvails;

        // Set first avail as first active size
        _activeSizesByType[type] = _firstAvailSize;

        return obj;
      },
      {}
    );

    setSizeAvailsByType(_sizeAvailsByType);
    setActiveSizesByType(_activeSizesByType);
  };

  useEffect(() => {
    if (!inventoryMapByHandle) return;
    setSizesUponKitProductsByType();
  }, [inventoryMapByHandle, productsByType]);

  return { activeSizesByType, sizeAvailsByType, setActiveSizesByType };
};
