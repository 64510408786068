import { Container } from 'theme-ui';

import { withInView } from '@utils';

import { KitItem } from './KitItem';
import { themed } from './KitsCollection.theme';
import { Schema } from './KitsCollection.schema';

export const KitsCollection = withInView(
  themed(({ theme, cms }) => {
    return (
      <Container
        data-comp={KitsCollection.displayName}
        variant="content"
        sx={theme.wrapper}
      >
        <Container
          sx={{
            ...theme.grid,
            gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(3, 1fr)'],
          }}
        >
          {cms?.kits?.map((kit, index) => {
            if (!kit) return null;
            return <KitItem key={index} kit={kit} index={index} />;
          })}
        </Container>
      </Container>
    );
  }),
  { triggerOnce: true }
);

KitsCollection.displayName = 'KitsCollection';
KitsCollection.Schema = Schema;
